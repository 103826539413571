import React, { Component } from 'react'
import { Breadcrumb, BreadcrumbItem, } from 'reactstrap'
import { withRouter } from 'react-router-dom'
// import { setStep } from '../actions/checkOutActions'
import { connect } from 'react-redux'


class CheckoutNavBar extends Component {

    componentDidMount() {
        if (this.props.history.location.hash === '#userDetails' && this.props.checkout.shippingMethod === null) {
            this.props.history.push('#shipping')
            this.props.goToStep(1)
        }
        if (this.props.history.location.hash === '#payment' && this.props.checkout.checkOutVerified === false) {
            this.props.history.push('#shipping')
            this.props.goToStep(1)
        }
    }

    handleClick = (step) => {
        if ((this.props.currentStep === 2 || this.props.currentStep === 3) && step === 1) {
            this.props.history.push('#shipping')
            this.props.goToStep(1)
        }
        if (step === 2 && this.props.checkout.shippingMethod !== null) {
            this.props.history.push('#userDetails')
            this.props.goToStep(2)
        }
        if (step === 3 && this.props.checkout.checkOutVerified !== false) {
            this.props.history.push('#payment')
            this.props.goToStep(3)
        }

    }
    render() {
        return (
            <div className='text-center mt-3'>
                <Breadcrumb tag="nav" listTag="div" style={{ display: 'inline-flex' }}>
                    <BreadcrumbItem style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.props.history.push('/cart')}>Cart</BreadcrumbItem>
                    <BreadcrumbItem style={{ cursor: 'pointer', color: this.props.currentStep === 1 ? 'rgb(236,23,79)' : 'black' }} onClick={() => this.handleClick(1)} >Shipping</BreadcrumbItem>
                    <BreadcrumbItem style={{ cursor: 'pointer', color: this.props.currentStep === 2 ? 'rgb(236,23,79)' : 'black' }} onClick={() => this.handleClick(2)}>Information</BreadcrumbItem>
                    <BreadcrumbItem style={{ cursor: 'pointer', color: this.props.currentStep === 3 ? 'rgb(236,23,79)' : 'black' }} onClick={() => this.handleClick(3)}>Payment</BreadcrumbItem>
                </Breadcrumb>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    checkout: state.checkout,
    shoppingCart: state.cart,
    guest: state.guest

})
export default connect(mapStateToProps, {
    // setStep,
})(withRouter(CheckoutNavBar))