import {
    GET_ALL_ORDERS,
    GET_ALL_ORDERS_LOADING,
    GET_ALL_ORDERS_LOADING_RESET,
    GET_ALL_ORDERS_COUNT,
    GET_SELECTED_ORDER,
    UPDATE_DELIVERY_DATE,
    RESET_SELECTED_ORDER,
    ORDER_UPDATE_LOADING,
    ORDER_UPDATE_LOADING_RESET,
    ORDER_STATUS_UPDATED,
    RESET_ORDER_STATUS_UPDATED
} from './types'
import axios from 'axios'
import { logoutUser } from './formActions'



export const getOrderList = (page, filter, query, orderStatus, paymentType, shippingType) => dispatch => {
    dispatch({
        type: GET_ALL_ORDERS_LOADING
    })
    axios.get(`/api/admin/getOrders/${page}/${filter}/${query}/${orderStatus}/${paymentType}/${shippingType}`)
        .then(res => {
            dispatch({
                type: GET_ALL_ORDERS,
                payload: res.data.orders
            })
            dispatch({
                type: GET_ALL_ORDERS_COUNT,
                payload: res.data.count
            })

            dispatch({
                type: GET_ALL_ORDERS_LOADING_RESET
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ALL_ORDERS_LOADING_RESET
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
            console.log(err)
        })

}

export const getSelectedOrder = id => dispatch => {
    dispatch({
        type: GET_ALL_ORDERS_LOADING
    })
    axios.get(`/api/admin/getSelectedOrder/${id}`)
        .then(res => {
            dispatch({
                type: GET_SELECTED_ORDER,
                payload: res.data
            })

            dispatch({
                type: GET_ALL_ORDERS_LOADING_RESET
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ALL_ORDERS_LOADING_RESET
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
            console.log(err)
        })
}

export const updateDeliveryDate = (orderid, data) => dispatch => {
    dispatch({
        type: ORDER_UPDATE_LOADING
    })
    axios.post(`/api/admin/updateDeliveryDate/${orderid}`, data)
        .then(res => {
            dispatch({
                type: UPDATE_DELIVERY_DATE,
                payload: res.data
            })
            dispatch({
                type: ORDER_UPDATE_LOADING_RESET
            })
        })
        .catch(err => {
            dispatch({
                type: ORDER_UPDATE_LOADING_RESET
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
            console.log(err)
        })
}

export const orderStatusChange = (orderid, data) => dispatch => {
    axios.post(`/api/admin/updateOrderStatus/${orderid}`, data)
        .then(res => {
            dispatch({
                type: ORDER_STATUS_UPDATED,
                payload: res.data
            })
        })
        .catch(err => {
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
            console.log(err)
        })
}

export const resetOrderStatusUpdate = () => dispatch => {
    dispatch({
        type: RESET_ORDER_STATUS_UPDATED
    })
}

export const resetSelectedOrder = () => dispatch => {
    dispatch({
        type: RESET_SELECTED_ORDER
    })
}
