import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { StripeProvider } from 'react-stripe-elements';
import LoadingOverlay from 'react-loading-overlay';
import FullPageSpinner from './Components/FullPageSpinner'
import ScrollToTop from './Components/ScrollToTop';
import Routes from './Routes';
import { resetLocalStorageUser } from '../src/actions/formActions'

class App extends React.Component {
  state = {
    stripe: null,
  }
  componentDidMount() {


    this.setState({ stripe: window.Stripe('pk_live_51H8bIwB9KhLls9IlafrLm5DdQFYtoFWpTvA6y9AHbCGedlTcW3cbcmj686aNqv1Fn1wpWKiy7nBHsnbHqDJHSmNl00Ynf94Wl8') });

    if (this.props.auth.checkUser.userName !== null) {
      if (!localStorage.jwtToken) {
        this.props.resetLocalStorageUser()
      }
    }
  }


  render() {

    return (
      <LoadingOverlay
        active={this.props.fullPageLoading.fullPageLoading}
        spinner={<FullPageSpinner />}
        classNamePrefix='fullPageLoading_'
      >
        <StripeProvider stripe={this.state.stripe}>
          <div className="App overflow-hidden" >
            <BrowserRouter>
              <ScrollToTop />
              <Routes />
            </BrowserRouter>
          </div>
        </StripeProvider>
      </LoadingOverlay>
    )
  }
}

const mapStateToProps = state => ({
  notfound: state.notfound,
  auth: state.form,
  fullPageLoading: state.fullPageLoading,
})

export default connect(mapStateToProps, { resetLocalStorageUser })(App);
