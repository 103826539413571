import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Row, Col, Badge, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Alert } from 'reactstrap'
import DatePicker from "react-datepicker";
import { getSelectedOrder, updateDeliveryDate, resetSelectedOrder, orderStatusChange, resetOrderStatusUpdate } from '../../actions/adminOrdersDash'


class SelectedOrder extends Component {

    state = {
        order: {},
        deliveryDate: '',
        showUpdateModal: false,
        showUpdateMessage: false,
        updateClicked: false,
        adminOrderMessage: '',
        orderStatus: '',
        showWarning: false,
        orderStatusUpdated: false
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (this.state.showWarning) {
            this.setState({
                showWarning: false
            })
        }
    }

    componentWillUnmount() {
        this.props.resetSelectedOrder()
    }

    componentDidMount() {

        if (this.props.location.state === undefined) {
            this.props.getSelectedOrder(this.props.match.params.orderid)

        }

        if (this.props.location.state !== undefined && this.props.location.state.order) {
            this.setState({
                order: this.props.location.state.order,
                orderStatus: this.props.location.state.order.orderStatus
            })
        }

    }

    showStatusUpdateSuccess = () => {
        this.setState({
            orderStatusUpdated: true
        })
        setTimeout(this.resetStatusUpdate, 3000)
    }

    resetStatusUpdate = () => {
        this.setState({
            orderStatusUpdated: false
        })

        this.props.resetOrderStatusUpdate()
    }



    componentDidUpdate(prevProps) {

        if (this.props.adminOrders.orderStatusUpdated !== prevProps.adminOrders.orderStatusUpdated && this.props.adminOrders.orderStatusUpdated) {
            this.showStatusUpdateSuccess()
        }

        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                order: {}
            })
            this.props.getSelectedOrder(this.props.match.params.orderid)
        }


        if (this.props.adminOrders.order !== prevProps.adminOrders.order) {
            this.setState({
                order: this.props.adminOrders.order,
                orderStatus: this.props.adminOrders.order.orderStatus
            })
        }
        if (this.props.adminOrders.orderUpdated !== prevProps.adminOrders.orderUpdated && this.state.updateClicked) {
            this.setState({
                updateClicked: false,
                showUpdateMessage: true
            })

            setTimeout(this.closeModal, 3000)

        }
    }

    closeModal = () => {
        this.setState({
            showUpdateModal: false
        })
    }

    setDate = date => {
        this.setState({
            deliveryDate: date
        })
    }


    toggleUpdateModal = () => {
        if (!this.props.adminOrders.loading) {
            this.setState({
                showUpdateModal: !this.state.showUpdateModal
            })
        }
    }

    updateApproved = e => {
        let data = {}
        data.shippingDate = this.state.deliveryDate
        data.orderStatus = 'Confirmed'

        if (this.state.orderAdminMessage !== '') {
            data.orderAdminMessage = this.state.orderAdminMessage
        }
        let orderid = this.props.match.params.orderid
        this.setState({
            updateClicked: true
        })

        this.props.updateDeliveryDate(orderid, data)
    }

    ucFirstAllWords = (str) => {
        var pieces = str.split(" ");
        for (var i = 0; i < pieces.length; i++) {
            var j = pieces[i].charAt(0).toUpperCase();
            pieces[i] = j + pieces[i].substr(1);
        }
        return pieces.join(" ");
    }

    routeToOrder = order => {
        this.props.history.push({
            pathname: `/adminpanel/orders/${order._id}`,
            state: { order }
        })
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    updateOrderStatus = e => {
        if (this.state.order.orderStatus !== this.state.orderStatus) {
            let data = {}
            data.orderStatus = this.state.orderStatus
            let orderid = this.props.match.params.orderid

            this.props.orderStatusChange(orderid, data)

        } else {
            this.setState({
                showWarning: true
            })
        }
    }



    render() {


        let SubTotal;
        if (this.state.order.products) {
            SubTotal = Math.round((this.state.order.products.map(eachItem => eachItem.productQuantity * eachItem.productPrice).reduce((total, currentValue) => total + currentValue)) * 100) / 100
        }

        let total = this.state.order.total / 100;

        let discount;


        if (this.state.order.coupon) {
            if (SubTotal >= this.state.order.coupon.minimumSpend) {
                if (this.state.order.coupon.isPercent) {
                    discount = (SubTotal / 100) * this.state.order.coupon.amount
                } else {
                    discount = this.state.order.coupon.amount
                }
            }
        }


        return (
            <div>

                <div>
                    <Modal isOpen={this.state.showUpdateModal} toggle={this.toggleUpdateModal}>
                        <ModalHeader >Are you sure you want to update delivery date with {moment(this.state.deliveryDate).format("Do MMM YYYY ")}? </ModalHeader>
                        <ModalBody>
                            <p>Note: Updating delivery date will send the buyer an email with the delivery date selected. This action can't be stopped.</p>
                        </ModalBody>
                        {this.state.showUpdateMessage && (
                            <ModalBody>
                                <p className='text-success'>Delivery date updated and buyer informed. This window will close in 3 seconds.</p>
                            </ModalBody>
                        )}

                        <ModalFooter>
                            <Button color="danger" onClick={this.updateApproved}>{this.props.adminOrders.updateButtonLoading ? 'Please Wait.' : 'Update'} </Button>
                            <Button color="light" className='border' onClick={this.toggleUpdateModal}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>
                {this.props.adminOrders.loading && (
                    <div className='text-center text-light my-5'>
                        <h5>Please wait. Order is being fetched.</h5>
                    </div>
                )}


                {Object.keys(this.state.order).length !== 0 && (
                    <div>


                        <Row>
                            <Col md='8' >
                                <div>

                                    <div className='bg-white  border-0  shadow card'>
                                        <div className="bg-secondary card-header">
                                            <div className="align-items-center row">
                                                <div className="col-8">
                                                    <h3 className="mb-0 text-light">Order Details</h3>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='card-body bg-white'>
                                        <p className='my-2 px-2 text-muted'>Order Status :
                                        <Badge className='ml-2' style={{ fontSize: '100%' }} color={this.state.order.orderStatus === 'Processing' ? 'warning' : 'success'}>
                                                {this.state.order.orderStatus}
                                            </Badge></p>
                                        <p className='my-2 px-2 text-muted'>Order Number : {this.state.order.orderid.toUpperCase()}</p>
                                        <p className='my-2 px-2 text-muted'>Ordered {moment(this.state.order.created_at).fromNow()}</p>



                                        <div className=' mt-2'>
                                            <div className='table-responsive'>
                                                <table className="table text-muted">
                                                    <thead className='table-borderless'>
                                                        <tr>
                                                            <th scope="col">PRODUCT</th>
                                                            <th scope="col">NAME</th>
                                                            <th scope="col">QUANTITY</th>
                                                            <th scope="col">PRICE</th>
                                                            <th scope="col">TOTAL</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody >
                                                        {this.state.order.products.map(product => (
                                                            <tr key={product._id}>
                                                                <td style={{ width: '100px' }}>
                                                                    <img src={product.productImage} alt={product.productName} className='img-fluid' />
                                                                </td>
                                                                <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{product.productName}</td>
                                                                <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{product.productQuantity}</td>
                                                                <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>AUD {product.productPrice.toFixed(2)}</td>
                                                                <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>AUD {(product.productPrice * product.productQuantity).toFixed(2)}</td>

                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>




                                </div>

                                <div className='bg-white  border-0  shadow card my-3'>
                                    <div className="bg-secondary card-header">
                                        <div className="align-items-center row">
                                            <div className="col-8">
                                                <h3 className="mb-0 text-light">Other orders by {this.ucFirstAllWords(this.state.order.firstName)}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        {this.state.order.user && this.state.order.user.orders.length > 1 ? (
                                            <div>
                                                <div className='table-responsive'>
                                                    <table className="table">
                                                        <thead className='table-borderless'>
                                                            <tr>
                                                                <th scope="col">ORDER ID</th>
                                                                <th scope="col">TIME</th>
                                                                <th scope="col">TOTAL</th>
                                                                <th scope='col'>SHIPPING</th>
                                                                <th scope='col'>PAYMENT TYPE</th>
                                                                <th scope='col'>ORDER STATUS</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody >
                                                            {this.state.order.user.orders.map(order => (
                                                                order.orderid !== this.state.order.orderid &&
                                                                <React.Fragment key={order._id}>
                                                                    <tr onClick={(e) => this.routeToOrder(order)} className='admin-each-product ' style={{ cursor: 'pointer' }}>
                                                                        <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{order.orderid.toUpperCase()}</td>
                                                                        <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{moment(order.created_at).startOf('hour').fromNow()}</td>
                                                                        <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>AUD {(order.total / 100).toFixed(2)}</td>
                                                                        <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{this.ucFirstAllWords(order.shippingType)}</td>
                                                                        <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'> {this.ucFirstAllWords(order.paymentType)}</td>
                                                                        <td style={{ verticalAlign: 'middle' }} className={order.orderStatus === 'Processing' ? 'text-warning font-weight-bold' : (order.orderStatus === 'Confirmed' ?
                                                                            'text-success font-weight-bold' : 'text-danger font-weight-bold')} > {order.orderStatus}</td>

                                                                    </tr>

                                                                </React.Fragment>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        ) : (
                                                <div>
                                                    <p>No other orders found.</p>
                                                </div>
                                            )}
                                    </div>
                                </div>

                            </Col>
                            <Col md='4' >
                                <div className='text-muted ' >

                                    <div className='bg-white  border-0  shadow card'>
                                        <div className="bg-secondary card-header">
                                            <div className="align-items-center row">
                                                <div className="col-8">
                                                    <h3 className="mb-0 text-light">Shipping Summary</h3>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='card-body bg-white'>
                                        {this.state.order.shippingType === 'pickup' ? (
                                            <React.Fragment>
                                                <h5 className='font-weight-bold'>Pick Up</h5>
                                                <h5><Badge color={(this.state.order.user.orders.length === 1 || this.state.order.user.orders.length === 0) ? 'secondary' : this.state.order.user.orders.length > 5 ? 'success' : 'primary'}>{(this.state.order.user.orders.length === 1 || this.state.order.user.orders.length === 0) ? 'New Customer' : this.state.order.user.orders.length > 5 ? 'Loyal Customer' : 'Returning Customer'}</Badge></h5>
                                                <p>{this.state.order.firstName} {this.state.order.lastName}</p>
                                                <p>Email: {this.state.order.email}</p>
                                                <p>Phone Number: {this.state.order.phoneNumber}</p>
                                                <p>Pick up date: {moment(this.state.order.pickUpDate).format('L')}</p>
                                                <p>Pick up time: {(this.state.order.pickUpTime / 3600)}:00 </p>
                                            </React.Fragment>) : (
                                                <React.Fragment>
                                                    <h5 className='font-weight-bold'>Delivery Address </h5>
                                                    <h5><Badge color={(this.state.order.user.orders.length === 1 || this.state.order.user.orders.length === 0) ? 'secondary' : this.state.order.user.orders.length > 5 ? 'success' : 'primary'}>{((this.state.order.user.orders.length === 1 || this.state.order.user.orders.length === 0) || this.state.order.user.orders.length === 0) ? 'New Customer' : this.state.order.user.orders.length > 5 ? 'Loyal Customer' : 'Returning Customer'}</Badge></h5>
                                                    <p>{this.state.order.firstName} {this.state.order.lastName}</p>
                                                    <p>Phone Number: {this.state.order.phoneNumber}</p>
                                                    <p>{this.state.order.address}</p>
                                                    <p>{this.state.order.suburb.toUpperCase()} VIC {this.state.order.postcode}</p>
                                                    {this.state.order.shippingDate && this.state.order.shippingDate !== '' && (
                                                        <p>Delivery Date: {moment(this.state.order.shippingDate).format("Do MMM YYYY ")}</p>
                                                    )}



                                                    {this.state.order.orderStatus === 'Processing' && (


                                                        <div className='bg-light  text-danger py-3 '>
                                                            <p>Please update order with a delivery date.</p>
                                                            <DatePicker
                                                                selected={this.state.deliveryDate}
                                                                onChange={this.setDate}
                                                                placeholderText="Select a day"
                                                                minDate={moment().toDate()}
                                                            />
                                                            <div className='my-2'>
                                                                <form onSubmit={(e) => e.preventDefault()}>
                                                                    <Input onChange={this.handleChange} type="textarea" rows='8' name='adminOrderMessage' value={this.state.adminOrderMessage} placeholder='Please add any notes regarding this order. This will be attached to the email.' />
                                                                </form>
                                                            </div>
                                                            <div className='my-2'>
                                                                <Button onClick={this.toggleUpdateModal} disabled={this.state.deliveryDate === ''} outline size='sm' color='success' >Update</Button>
                                                            </div>



                                                        </div>

                                                    )}
                                                </React.Fragment>
                                            )}
                                    </div>

                                </div>
                                <div className=' text-muted  mt-3' >

                                    <div className='bg-white  border-0  shadow card'>
                                        <div className="bg-secondary card-header">
                                            <div className="align-items-center row">
                                                <div className="col-8">
                                                    <h3 className="mb-0 text-light">Order Summary</h3>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                    {/* <h5 className='font-weight-bold'>Order Summary</h5> */}
                                    <div className='card-body bg-white border-bottom py-2'>
                                        <div>
                                            <p >SubTotal <span className='float-right'>AU ${SubTotal.toFixed(2)}</span></p>
                                            <p >Shipping  <span className='float-right'>{this.state.order.shippingType !== 'pickup' ? ('AU $' + this.state.order.shippingFee.toFixed(2)) : 'Free'}</span></p>
                                            {this.state.order.coupon && discount !== undefined && (
                                                <React.Fragment>
                                                    <p>Discount  <span className='float-right'>
                                                        {this.state.order.coupon.isPercent ? '%' : 'AU $'}{this.state.order.coupon.amount}
                                                    </span>
                                                    </p>
                                                    <p className='text-danger'>Discount Applied <span className='float-right text-danger'>
                                                        - AU ${discount.toFixed(2)}
                                                    </span>
                                                    </p>
                                                </React.Fragment>
                                            )}
                                        </div>
                                        <h5 className='py-3'>Total <span className='float-right'>AU ${total.toFixed(2)}</span></h5>
                                    </div>

                                </div>


                                <div className=' text-muted  mt-3' >

                                    <div className='bg-white  border-0  shadow card'>
                                        <div className="bg-secondary card-header">
                                            <div className="align-items-center row">
                                                <div className="col-8">
                                                    <h3 className="mb-0 text-light">Update Order Status</h3>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                    <div className='card-body bg-white border-bottom py-2'>

                                        <form onSubmit={(e) => e.preventDefault()}>
                                            <Input type="select" onChange={this.onChange} value={this.state.orderStatus} name='orderStatus'>
                                                <option hidden>Status</option>
                                                <option value='Cancelled'>Cancelled</option>
                                                <option value='Confirmed'>Confirmed</option>
                                                <option value='Processing'>Processing</option>
                                            </Input>
                                        </form>
                                        {this.state.showWarning && (
                                            <div className='my-3'>
                                                <Alert color="warning">
                                                    Order status is already {this.state.order.orderStatus}
                                                </Alert>
                                            </div>
                                        )}
                                        {this.state.orderStatusUpdated && (
                                            <div className='my-3'>
                                                <Alert color="success">
                                                    Order status has been updated as {this.state.order.orderStatus}
                                                </Alert>
                                            </div>
                                        )}



                                        <div className='my-2'>
                                            <Button onClick={this.updateOrderStatus} outline size='sm' color='success' >Update</Button>
                                        </div>
                                    </div>

                                </div>

                            </Col>
                        </Row>

                    </div>

                )}
            </div>
        )
    }
}


const mapStateToProps = state => ({
    adminOrders: state.adminOrders
})


export default connect(mapStateToProps, { getSelectedOrder, updateDeliveryDate, resetSelectedOrder, orderStatusChange, resetOrderStatusUpdate })(SelectedOrder)
