import React, { Component } from 'react'
import { Alert, Container, Col, Row } from 'reactstrap'
import Base from './Base'
import { Redirect } from 'react-router-dom'


class VerifyAccount extends Component {


    render() {
        if (this.props.location.state === undefined) {

            return <Redirect to='/' />
        }
        return (
            <React.Fragment>
                <Base title={`Welcome ${this.props.location.state.firstName.toUpperCase()}`} />
                <Container className='mt-5' style={{ minHeight: '30vh' }}>
                    <Row>

                        <Col className='  py-auto'>

                            <h4 className='font-weight-light mb-3 text-center '>
                                Thank you for signing up !</h4>
                            <Alert color='info'>
                                We're excited to have you get started. First, you need to confirm your account.
                                A code has been sent to your email. Please verify your account.
                        </Alert>
                       

                        </Col>

                    </Row>
                    {/* <Row>
                        <Col>
                            <div>
                                <p className='text-muted'>
                                    We suggest using a gmail account when signing up on our website as 
                                    </p>
                                </div>
                        </Col>
                        </Row> */}
                </Container>
            </React.Fragment>
        )
    }
}
export default VerifyAccount