import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { connect } from 'react-redux'
import { revenueDetails } from '../../actions/adminRevenueAction'
import Spinner from '../Spinner'


class TotalRevenue extends Component {
    componentDidMount() {
        this.props.revenueDetails()
    }

    render() {
        let percent;
        let dailyRevenue = Math.round(this.props.adminRevenueCalc.dailyRevenue / 100)
        let yesterdayRevenue = Math.round(this.props.adminRevenueCalc.yesterdayRevenue / 100)
        if (dailyRevenue > yesterdayRevenue) {
            if (yesterdayRevenue === 0) {
                percent = dailyRevenue
            } else {
                percent = ((dailyRevenue - yesterdayRevenue) / yesterdayRevenue * 100)
            }
        }
        else if (dailyRevenue < yesterdayRevenue) {
            if (dailyRevenue === 0) {
                percent = -yesterdayRevenue
            } else {
                percent = ((dailyRevenue - yesterdayRevenue) / yesterdayRevenue * 100)
            }
        } else {
            percent = 0
        }
        percent = Math.floor(percent);


        return (
            <div className=' text-left bg-light px-3 py-3 my-2' style={{ borderRadius: 10 }}>
                <Row>
                    <Col xs='8' >
                        <h6 className='font-weight-bold text-secondary'>TOTAL SALES TODAY</h6>
                        {this.props.adminRevenueCalc.loading ? (
                            <div className='text-center my-auto'>
                                <Spinner />

                            </div>
                        ) : (
                                <React.Fragment>
                                    <h5 className='font-weight-bold text-dark'>${(this.props.adminRevenueCalc.dailyRevenue / 100).toFixed(2)}</h5>
                                    <p className={percent > 0 ? 'text-success' : percent < 0 ? 'text-danger' : ''}>
                                        {percent > 0 ?
                                            <i className="fas fa-arrow-up mr-1"></i> : percent < 0 ?
                                                <i className="fas fa-arrow-down mr-1"></i> :
                                                <i className="fas fa-square mr-1 "></i>
                                        }
                                        {percent}% <span className='pl-2 text-secondary'>Since yesterday</span></p>
                                </React.Fragment>
                            )}

                    </Col>
                    <Col xs='4' className='my-3  text-center'>
                        <div className='rounded-circle text-white shadow px-3 py-3 bg-danger' style={{ width: '3em', height: '3em', alignItems: 'center', justifyContent: 'center', display: 'inline-flex', textAlign: 'center' }}>
                            <i className="fas fa-dollar-sign  " style={{ fontSize: '1.25em' }}></i>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.form,
    adminRevenueCalc: state.adminRevenueCalc
})


export default connect(mapStateToProps, { revenueDetails })(TotalRevenue)