import {
    GET_PRODUCTS,
    FILTER_PRODUCTS,
    LOAD_PRODUCTS,
    GET_SINGLE_PRODUCT,
    GET_TOTAL_PAGE_NUMBER,
    RESET_PRODUCTS,
    GET_YOU_MAY_ALSO_LIKE_PRODUCTS,
    RESET_YOU_MAY_ALSO_LIKE_PRODUCTS,
    SINGLE_PRODUCT_LOADING,
    RESET_SINGLE_PRODUCT_LOADING
} from '../actions/types'


const initialState = {
    products: [],
    filter: 'all',
    productsLoading: false,
    product: {},
    totalPageNumbers: 0,
    youMayAlsoLike: [],
    singleProductLoading: false
}

export default function (state = initialState, action) {

    switch (action.type) {
        case SINGLE_PRODUCT_LOADING:
            return {
                ...state,
                singleProductLoading: true
            }
        case RESET_SINGLE_PRODUCT_LOADING:
            return {
                ...state,
                singleProductLoading: false
            }
        case GET_YOU_MAY_ALSO_LIKE_PRODUCTS:
            return {
                ...state,
                youMayAlsoLike: action.payload
            }
        case RESET_YOU_MAY_ALSO_LIKE_PRODUCTS:
            return {
                ...state,
                youMayAlsoLike: []
            }
        case GET_TOTAL_PAGE_NUMBER:
            return {
                ...state,
                totalPageNumbers: action.payload
            }
        case LOAD_PRODUCTS:
            return {
                ...state,
                productsLoading: true
            }
        case GET_PRODUCTS:
            return {
                ...state,
                products: action.payload,
                productsLoading: false
            }
        case GET_SINGLE_PRODUCT:
            return {
                ...state,
                product: action.payload
            }

        case FILTER_PRODUCTS:
            return {
                ...state,
                filter: action.payload
            }
        case RESET_PRODUCTS:
            return {
                ...state,
                products: []
            }
        default: return state;
    }
}