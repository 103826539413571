import React, { Component } from 'react'
import { Form, FormGroup, Label, Input, Row, Container, Col, Alert } from 'reactstrap';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import classnames from 'classnames'
import axios from 'axios'
import { withScriptjs, GoogleMap, withGoogleMap, Marker } from "react-google-maps"
import { Helmet } from "react-helmet";


function Map() {
    return (
        <GoogleMap
            defaultZoom={10}
            defaultCenter={{ lat: -37.712065, lng: 144.863587 }}>
            <Marker position={{ lat: -37.712065, lng: 144.863587 }} />
        </GoogleMap>
    )
}


const WrappedMap = withScriptjs(withGoogleMap(Map))



class Contact extends Component {

    state = {
        name: '',
        email: '',
        subject: '',
        message: '',
        errors: {},
        success: ''
    }

    valueChanged = (e) => {
        this.setState({
            [e.target.name]: e.target.value,

        })
    }

    submitForm = (e) => {
        e.preventDefault();
        let data = this.state
        axios.post('/contact', data)
            .then(res => {
                this.setState({
                    success: res.data.success,
                    errors: {},
                    name: '',
                    email: '',
                    subject: '',
                    message: ''
                })
            })
            .catch(err => {
                this.setState({
                    errors: err.response.data,
                })
            })
    }
    render() {
        return (
            <div className='pb-4 contact' id='contact'>
                <div>
                    <Helmet>
                        <title>Contact - Frozberries Victoria</title>
                        <meta name="description" content="Contact Us - Frozberries Victoria" />
                    </Helmet>
                </div>
                <Row>
                    <Col style={{ minHeight: '40vh' }}>
                        <WrappedMap
                            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div className='w-100 h-100 mapContainer' />}
                            mapElement={<div style={{ height: `100%` }} />}
                        />
                    </Col>
                </Row>
                <Container className='text-light py-5' >
                    <Fade>
                        <h1 className='text-center'>GET IN TOUCH WITH US</h1>
                        <Row>
                            <Col>
                                <h5 className='mx-3 mt-3 text-center'>Have a question? We'd love to hear from you. Reach us using one of the
                                following methods and we'll respond to you as soon as possible.  </h5>
                            </Col>
                        </Row>
                    </Fade>
                    <Row className='pt-5'>
                        <Col className='contact-details' md='6'>
                            <Slide left>
                                <div>
                                    <h1>Frozberries</h1>

                                    <h4 className='py-3'>
                                        <i className="fas fa-map-marked-alt mr-1"></i> 11 Saligna Dr, Tullamarine VIC 3043
                                </h4>
                                    <h4 className='pb-3'>
                                        <i className="fas fa-envelope mr-1"></i> sales@harvestimevic.com.au
                                </h4>
                                    <h4 className='pb-3'>
                                        <i className="fas fa-phone mr-1"></i>  03 9335 6331
                                </h4>

                                    <h4 className='pb-1'>
                                        <i className="fas fa-clock"></i>  Monday - Friday 8AM-4PM
                                </h4>

                                </div>
                            </Slide>
                        </Col>

                        <Col md='6'>
                            <Slide right>

                                <Form className='mx-3 mt-2 mb-3' onSubmit={this.submitForm}>
                                    <FormGroup>
                                        <Label for="name">Name*</Label>
                                        <Input type="text" value={this.state.name} name="name" placeholder="Name"
                                            onChange={this.valueChanged}
                                            className={classnames('', { 'is-invalid': this.state.errors.name })}
                                        />
                                        <div className='invalid-feedback'>{this.state.errors.name}</div>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="exampleEmail">Email*</Label>
                                        <Input type="email" value={this.state.email} name="email" placeholder="Email"
                                            onChange={this.valueChanged}
                                            className={classnames('', { 'is-invalid': this.state.errors.email })}
                                        />
                                        <div className='invalid-feedback'>{this.state.errors.email}</div>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="subject">Subject*</Label>
                                        <Input type="text" value={this.state.subject} name="subject" placeholder="Subject"
                                            onChange={this.valueChanged}
                                            className={classnames('', { 'is-invalid': this.state.errors.subject })}
                                        />
                                        <div className='invalid-feedback'>{this.state.errors.subject}</div>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="exampleText">Your message*</Label>
                                        <Input type="textarea" rows={10} value={this.state.message} name="message" placeholder='Drop your message here'
                                            onChange={this.valueChanged}
                                            className={classnames('', { 'is-invalid': this.state.errors.message })}
                                        />
                                        <div className='invalid-feedback'>{this.state.errors.message}</div>
                                    </FormGroup>

                                    {this.state.success !== '' && this.state.success !== undefined &&
                                        <Alert color='success'>{this.state.success}</Alert>

                                    }
                                    <FormGroup>
                                        <Input type='submit' className='bg-success border-0 text-light' value='Send' />
                                    </FormGroup>
                                </Form>
                            </Slide>
                        </Col>
                    </Row>

                </Container>
            </div>
        )
    }
}
export default Contact