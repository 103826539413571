import React, { Component } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Slide from 'react-reveal'
import { Row, Col } from 'reactstrap'
const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,

    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    }
};

const reviews = [
    {
        title: '   Amazing!',
        comment: `Amazing costumer service. Amazing Blue Berries. Amazing delivery Man. Love everyone I've delt with so far. Your loveliness and happiness and kindness is rare and wonderful and it has been honestly an absolutely joy doing business with you. SERVICE so FAST ordered yesterday contacted so quickly and 13kg of Berries delivered to my door today, delivery man kept in contact to let me know where he was at. AMAZING AMAZING AMAZING Thank you gorgeous people.`,
        author: 'Juliette'
    }, {
        title: 'Love Frozberries',
        comment: `I love visiting, you're always busy but make time to smile, chat and look after me. Thank you!`,
        author: 'The Preservers Pot'
    },
    {
        title: '    Highly Recommend',
        comment: `Love this place. Great service, great prices.`,
        author: 'Joana'
    }
    ,
    {
        title: '  Fabulous',
        comment: `Thanks for taking care of me with those organic blueberries and so lovely to have met you. You are fabulous people!`,
        author: 'Lotus Balance Kerry'
    }
    , {
        title: '  Great Customer Service',
        comment: `Amazing customer service and berries...love that service comes with a smile and that they stock organic fruits!`,
        author: 'Lara'
    }
]

export default class Testimonials extends Component {
    state = {
        windowWidth: 0,
    }

    componentDidMount() {
        this.setState({
            windowWidth: window.innerWidth,
        })
        window.addEventListener('resize', this.updateWindowDimension);

    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimension);
    }

    updateWindowDimension = (e) => {
        this.setState({
            windowWidth: window.innerWidth
        })
    }

    render() {
        return (
            <div >

                <Slide right>
                    <Carousel
                        responsive={responsive}
                        arrows={false}
                        infinite={true}
                        autoPlay={true}
                        showDots={true}
                        autoPlaySpeed={3000}
                        keyBoardControl={true}
                        containerClass="carousel-container"
                        itemClass="carousel-item-padding-40-px"
                        className='h-100'
                    >
                        {reviews.map((eachReview, index) => (
                            <Row key={index} className='h-100 bg-light text-dark' style={{
                                minHeight: '35vh',
                            }}>

                                <Col className=' my-auto '>
                                    <div
                                        key={index}

                                        className=' w-100 text-center px-5 py-5'>
                                        <h1>
                                            {eachReview.title}
                                        </h1>
                                        <p className={this.state.windowWidth > 767 ? `px-5 py-3 mx-5  font-italic` : `py-3  font-italic`}>
                                            "{eachReview.comment}"
                            </p>
                                        <h5>
                                            -{eachReview.author}
                                        </h5>
                                    </div>
                                </Col>
                            </Row>
                        ))}
                    </Carousel>
                </Slide>
            </div>
        )
    }
}
