import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from '../src/reducers/rootReducer'
import { persistStore } from 'redux-persist'


const initialState = {};

const middleware = [thunk];


export const store = process.env.NODE_ENV === 'development' ? createStore(rootReducer, initialState, compose(applyMiddleware(...middleware),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)) :
    createStore(rootReducer, initialState, compose(applyMiddleware(...middleware)))
export const persistor = persistStore(store)

export default { store, persistor }