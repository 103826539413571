import {
    GET_ADMIN_BLOGS,
    GET_ADMIN_BLOGS_COUNT,
    GET_ADMIN_BLOGS_LOADING,
    GET_ADMIN_BLOGS_LOADING_RESET
} from './types'
import axios from 'axios'



export const getAdminBlogs = (page) => dispatch => {
    dispatch({
        type: GET_ADMIN_BLOGS_LOADING
    })
    axios.get(`/api/admin/getAdminBlogs/${page}`)
        .then(res => {
            dispatch({
                type: GET_ADMIN_BLOGS,
                payload: res.data.blogs
            })
            dispatch({
                type: GET_ADMIN_BLOGS_COUNT,
                payload: res.data.count
            })


            dispatch({
                type: GET_ADMIN_BLOGS_LOADING_RESET
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ADMIN_BLOGS_LOADING_RESET
            })
            console.log(err)
        })

}
