import React, { Component } from 'react'
import { getSingleFaq, updateFaq, deleteFaq, resetFaqForm } from '../../actions/adminFaqAction'
import { connect } from 'react-redux'
import {
    Col, Row,
    Button, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Collapse
} from 'reactstrap'; import { Helmet } from "react-helmet";



class SelectedFaq extends Component {

    state = {
        faq: {},
        replicaFaq: {},
        formDisabled: true,
        updateClicked: false,
        showUpdateSuccess: false,
        deleteModal: false,
        showDeleteSuccess: false
    }

    componentDidMount() {
        if (this.props.location.state === undefined) {
            this.props.getSingleFaq(this.props.match.params.faq_id)
        }

        if (this.props.location.state !== undefined && this.props.location.state.faq) {
            this.setState({
                faq: this.props.location.state.faq,
                replicaFaq: this.props.location.state.faq,
            })
        }
    }

    componentDidUpdate(prevProps) {

        if (this.props.adminFaqs.faq !== prevProps.adminFaqs.faq) {
            this.setState({
                faq: this.props.adminFaqs.faq,
                replicaFaq: this.props.adminFaqs.faq,
            })

            if (this.state.updateClicked) {
                this.setState({
                    showUpdateSuccess: true
                })

                setTimeout(this.resetUpdateClick, 3000)

            }
        }

        if (this.props.adminFaqs.faqDeleted !== prevProps.adminFaqs.faqDeleted && this.props.adminFaqs.faqDeleted) {
            this.setState({
                showDeleteSuccess: true
            })

            setTimeout(this.sendToFaqsDashboard, 3000)

        }
    }

    componentWillUnmount() {
        this.props.resetFaqForm()
    }

    sendToFaqsDashboard = () => {
        this.props.history.push('/adminpanel/faqs')

    }

    resetUpdateClick = () => {
        this.setState({
            updateClicked: false,
            showUpdateSuccess: false,
            formDisabled: true,
        })
    }



    activateForm = e => {
        this.setState({
            formDisabled: !this.state.formDisabled

        })
    }

    inputChange = e => {
        this.setState({ replicaFaq: { ...this.state.replicaFaq, [e.target.name]: e.target.value } })
    }

    cancelUpdate = e => {
        this.setState({
            formDisabled: true,
            replicaFaq: this.state.faq
        })
    }

    updateFaq = e => {
        let data = {}
        data.question = this.state.replicaFaq.question
        data.answer = this.state.replicaFaq.answer

        this.setState({
            updateClicked: true
        })

        this.props.updateFaq(this.props.match.params.faq_id, data)
    }

    showDeleteModal = e => {
        this.setState({
            deleteModal: !this.state.deleteModal
        })
    }

    toggleDeleteWarningModal = () => {
        this.setState({
            deleteModal: !this.state.deleteModal
        })
    }

    deleteFaq = e => {
        this.props.deleteFaq(this.props.match.params.faq_id)
    }



    render() {
        return (
            <div>
                <div>
                    <Modal isOpen={this.state.deleteModal} toggle={this.toggleDeleteWarningModal}>
                        <ModalHeader >Are you sure you want to permanently delete this FAQ? </ModalHeader>
                        <ModalBody>
                            <p>Note: This action is not reversable.</p>
                        </ModalBody>

                        {this.state.showDeleteSuccess && (
                            <ModalBody>
                                <p className='text-success'>FAQ successfully deleted. You're being redirected to FAQ's Dashboard.</p>
                            </ModalBody>
                        )}

                        <ModalFooter>

                            <Button color="danger" onClick={this.deleteFaq}>{this.props.adminFaqs.deleteButtonLoading ? 'Please Wait.' : 'Delete'} </Button>
                            <Button color="light" className='border' onClick={this.toggleDeleteWarningModal}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>



                {this.props.adminFaqs.loading && (
                    <div className='text-center text-light my-5'>
                        <h5>Please wait. FAQ is being fetched.</h5>
                    </div>
                )}

                {Object.keys(this.state.faq).length !== 0 && (
                    <React.Fragment>
                        <div>
                            <Helmet>
                                <title>{this.state.faq.question} - Frozberries Victoria</title>
                                <meta name="description" content="{this.state.faq.question} - Frozberries Victoria" />
                            </Helmet>
                        </div>

                        <div className='bg-white  border-0  shadow card'>

                            <div className="bg-secondary card-header">
                                <h3 className="mb-0 text-light">FAQ</h3>
                            </div>


                            <div className='card-body'>

                                <Row>
                                    <Col xs='8'>
                                        {/* <h6 className="heading-small  mb-4 font-weight-bold text-muted" style={{ fontSize: '0.75rem' }}>USER INFORMATION</h6> */}
                                    </Col>
                                    <Col xs='4'>
                                        <div className="text-right ">
                                            <i className="far fa-edit text-muted " onClick={this.activateForm} style={{ fontSize: '1.2rem', cursor: 'pointer' }}></i>
                                        </div>
                                    </Col>
                                </Row>


                                <Row>
                                    <Col>
                                        <Form>
                                            <FormGroup>
                                                <Label for="question">Question</Label>
                                                <Input onChange={this.inputChange} disabled={this.state.formDisabled} type="textarea" name="question" id="question" value={this.state.replicaFaq.question} placeholder="Question" />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="question">Answer</Label>
                                                <Input onChange={this.inputChange} disabled={this.state.formDisabled} type="textarea" name="answer" id="answer" value={this.state.replicaFaq.answer} placeholder="Answer" />
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>

                                {this.state.showUpdateSuccess && (
                                    <p className='text-success mb-2'>This question has been successfully updated.</p>

                                )}

                                <   Collapse isOpen={!this.state.formDisabled ? true : false}>
                                    <div>
                                        <   Button size='sm' color='danger' className='mx-1 my-1' onClick={this.showDeleteModal}>Delete </Button>
                                        <Button size='sm' color='primary' className='mx-1 my-1' onClick={this.updateFaq}>{this.props.adminFaqs.updateButtonLoading ? 'Please Wait' : 'Update'} </Button>
                                        <Button onClick={this.cancelUpdate} size='sm' color='light' className='mx-1 my-1'>Cancel </Button>
                                    </div>
                                </Collapse>
                            </div>

                        </div>
                    </React.Fragment>

                )}

            </div>
        )
    }
}




const mapStateToProps = state => ({
    adminFaqs: state.adminFaqs
})


export default connect(mapStateToProps, { getSingleFaq, updateFaq, deleteFaq, resetFaqForm })(SelectedFaq)
