import React, { Component } from 'react'
import Base from './Base'
import { Helmet } from "react-helmet";

export default class ElginOrganic extends Component {
    render() {
        return (
            <React.Fragment>
                <div>
                    <Helmet>
                        <title>Elgin Organic - Frozberries Victoria</title>
                        <meta name="description" content="Elgin Organic - Frozberries Victoria" />
                    </Helmet>
                </div>
                <Base title='   Elgin Organic' />
                <div className='px-5 py-5 '>
                    <h1 className='text-center pb-3' style={{ color: 'rgb(14,35,87)' }}>Certified Organic
</h1>

                    <p className='ourstory mx-auto' style={{ color: 'rgb(14,35,87)', lineHeight: '1.8', fontSize: '18px', }} >
                        In the prestigious world of organic food, Elgin Organic has unequivocally lamented its position as one of the most respected and prominent names. Elgin Organic products proudly display the Australian Certified Organic logo. After stringent processes and procedures, achieving the certification is no small feat.
                </p>
                    <p className='ourstory mx-auto' style={{ color: 'rgb(14,35,87)', lineHeight: '1.8', fontSize: '18px', }} >
                        Many people are concerned about the chemicals and pesticides contained in foods. Today, parents are more savvy than ever. Many families are actively making conscious choices on a daily basis to inject as much nutrition, unprocessed and chemical free foods into their bodies as possible. Couple this with the backdrop of social responsibility and sustainability and we arrive at the conclusion that certified organic food is the right way to go!                </p>
                    <p className='ourstory mx-auto' style={{ color: 'rgb(14,35,87)', lineHeight: '1.8', fontSize: '18px', }} >
                        Our Elgin Organic products are individually quick frozen. This means that whilst the product is free flowing, the nutrition and flavour is preserved, providing you with the highest quality frozen fruit and vegetables. Check out our massive range of Elgin Organic frozen berries, fruit and vegetables.                </p>
                </div>
            </React.Fragment>
        )
    }
}
