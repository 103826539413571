import {
    ADMIN_NEW_RECIPE,
    ADMIN_RECIPE_LOADING,
    RESET_ADMIN_NEW_RECIPE,
    ADMIN_RECIPE_ERRORS,
    GET_ADMIN_RECIPES,
    GET_RECIPE_COUNT,
    GET_SINGLE_ADMIN_RECIPE,
    RESET_ADMIN_RECIPE_FORM,
    UPDATE_RECIPE,
    DELETE_RECIPE,
    DELETE_BUTTON_LOADING_RECIPE,
    RESET_DELETE_BUTTON_LOADING_RECIPE
} from '../actions/types.js'


const initialState = {
    recipes: [],
    newRecipe: {},
    loading: false,
    errors: {},
    recipeCount: 0,
    recipe: {},
    recipeDeleted: false,
    recipeUpdated: false,
    deleteButtonLoading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case RESET_DELETE_BUTTON_LOADING_RECIPE:
            return {
                ...state,
                deleteButtonLoading: false,
            }
        case DELETE_BUTTON_LOADING_RECIPE:
            return {
                ...state,
                deleteButtonLoading: true,
            }
        case RESET_ADMIN_RECIPE_FORM:
            return {
                ...state,
                errors: {},
                deleteButtonLoading: false,
                recipeDeleted: false,
                recipeUpdated: false
            }
        case UPDATE_RECIPE:
            return {
                ...state,
                recipeUpdated: true,
                recipe: action.payload,
                errors: {}
            }
        case DELETE_RECIPE:
            return {
                ...state,
                recipeDeleted: true,
                recipe: action.payload,
                deleteButtonLoading:false
            }
        case GET_SINGLE_ADMIN_RECIPE:
            return {
                ...state,
                recipe: action.payload
            }
        case GET_RECIPE_COUNT:
            return {
                ...state,
                recipeCount: action.payload
            }
        case GET_ADMIN_RECIPES:
            return {
                ...state,
                recipes: action.payload
            }
        case ADMIN_NEW_RECIPE:
            return {
                ...state,
                newRecipe: action.payload
            }
        case ADMIN_RECIPE_LOADING:
            return {
                ...state,
                loading: true
            }
        case RESET_ADMIN_NEW_RECIPE:
            return {
                ...state,
                loading: false
            }
        case ADMIN_RECIPE_ERRORS:
            return {
                ...state,
                errors: action.payload
            }

        default: return state;
    }
}