import {
    ADMIN_PRODUCTS_LOADING,
    ADMIN_PRODUCTS_LOADING_RESET,
    GET_ADMIN_PRODUCTS,
    GET_ADMIN_PRODUCTS_COUNT,
    PRODUCT_FORM_ERRORS,
    NEW_PRODUCT_CREATED,
    GET_SINGLE_ADMIN_PRODUCT,
    DELETE_BUTTON_LOADING,
    RESET_DELETE_BUTTON_LOADING,
    DELETE_SINGLE_ADMIN_PRODUCT,
    UPDATE_PRODUCT,
    RESET_ADMIN_PRODUCT_FORM
} from '../actions/types.js'


const initialState = {
    products: [],
    productCount: 0,
    loading: false,
    errors: {},
    newProduct: {},
    product: {},
    deleteButtonLoading: false,
    productDeleted: false,
    productUpdated: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case RESET_ADMIN_PRODUCT_FORM:
            return {
                ...state,
                deleteButtonLoading: false,
                productDeleted: false,
                productUpdated: false,
                loading: false,
                errors: {}
            }
        case UPDATE_PRODUCT:
            return {
                ...state,
                productUpdated: true,
                product: action.payload,
                errors: {}
            }
        case DELETE_SINGLE_ADMIN_PRODUCT:
            return {
                ...state,
                productDeleted: true,
                product: action.payload
            }
        case GET_SINGLE_ADMIN_PRODUCT:
            return {
                ...state,
                product: action.payload
            }
        case GET_ADMIN_PRODUCTS:
            return {
                ...state,
                products: action.payload
            }
        case GET_ADMIN_PRODUCTS_COUNT:
            return {
                ...state,
                productCount: action.payload
            }
        case ADMIN_PRODUCTS_LOADING:
            return {
                ...state,
                loading: true
            }
        case ADMIN_PRODUCTS_LOADING_RESET:
            return {
                ...state,
                loading: false
            }
        case PRODUCT_FORM_ERRORS:
            return {
                ...state,
                errors: action.payload
            }
        case NEW_PRODUCT_CREATED:
            return {
                ...state,
                newProduct: action.payload,
                errors: {}
            }
        case DELETE_BUTTON_LOADING:
            return {
                ...state,
                deleteButtonLoading: true
            }
        case RESET_DELETE_BUTTON_LOADING:
            return {
                ...state,
                deleteButtonLoading: false
            }
        default: return state;
    }
}