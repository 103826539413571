import React, { Component } from 'react'
import frozberiesLogo from '../../images/frozberries-logo.png'
import HamburgerMenu from 'react-hamburger-menu'
import {
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    NavLink,
    NavbarBrand
} from 'reactstrap';
import { connect } from 'react-redux'
import { logoutUser } from '../../actions/formActions';
import { withRouter } from 'react-router-dom'


class MainBar extends Component {

    state = {
        isOpen: false,
    }

    toggleHamburger = (e) => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }


    onLogoutClick = () => {

        this.props.logoutUser()
        this.props.history.push('/login')

    }

    render() {
        return (
            <div >
                <div>
                    <div className='float-right d-inline d-lg-none'>
                        <UncontrolledDropdown className='border-0'>
                            <DropdownToggle className='text-light' caret tag='span' style={{ cursor: 'pointer', color: 'rgb(236,23,79)' }}>
                                {this.props.auth.user.firstName.toUpperCase()}
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={() => this.props.history.push('/')} >Home</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={this.onLogoutClick}>Log out</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                    <br />

                </div>
                <div className='d-lg-none'>
                    <Navbar
                        expand="lg"

                    >

                        <NavbarToggler onClick={this.toggle} >
                            <HamburgerMenu
                                isOpen={this.state.isOpen}
                                navbar
                                menuClicked={this.toggleHamburger}
                                borderRadi={0}
                                color='white'
                                animationDuration={0.5}
                            />
                        </NavbarToggler>

                        <NavbarBrand href='/' className='text-center mx-auto animate__bounceIn'>
                            <img style={{ maxHeight: 45 }} src={frozberiesLogo} alt='frozberries-logo' />
                        </NavbarBrand>


                        <Collapse id='nav-collapse' isOpen={this.state.isOpen} navbar className='text-center'>
                            <Nav className="mx-auto font-weight-bold" navbar>
                                <NavItem>
                                    <NavLink href="/adminpanel" className='px-4 text-light'>
                                        <i className="fas fa-tv  text-primary pr-2" style={{ fontSize: 14 }} />
                                        Dashboard</NavLink>
                                    <NavLink href="/adminpanel/orders" className='px-4 text-light'>
                                        <i className="fas fa-shopping-cart  text-secondary pr-2" style={{ fontSize: 14 }} />
                                        Orders</NavLink>
                                    <NavLink href="/adminpanel/users" className='px-4 text-light' >
                                        <i className="fas fa-user-friends  text-success pr-2" style={{ fontSize: 14 }} />
                                        Users</NavLink>
                                    <NavLink href="/adminpanel/products" className='px-4 text-light' >
                                        <i className="fas fa-tag  text-danger pr-2" style={{ fontSize: 14 }} />
                                        Products</NavLink>
                                    <NavLink href="/adminpanel/recipes" className='px-4 text-light' >
                                        <i className="fas fa-concierge-bell  text-warning pr-2" style={{ fontSize: 14 }} />
                                        Recipes</NavLink>
                                    <NavLink href="/adminpanel/faqs" className='px-4 text-light' >
                                        <i className="fas fa-question  text-info pr-2" style={{ fontSize: 14 }} />
                                        FAQ's</NavLink>
                                    <NavLink href="/components/" className='px-4 text-light' >
                                        <i className="fas fa-percent  text-muted pr-2" style={{ fontSize: 14 }} />
                                        Coupons</NavLink>
                                    <NavLink href="/adminpanel/reviews" className='px-4  text-dark'>
                                        <i className="far fa-comment  text-dark pr-2" style={{ fontSize: 14 }} />
                                        Reviews</NavLink>
                                    <NavLink href="/adminpanel/blogs-dashboard" className='px-4' style={{ color: 'grey' }}>
                                        <i className="fas fa-pen   pr-2" style={{ fontSize: 14, }} />
                                        Blog</NavLink>

                                </NavItem>
                            </Nav>
                        </Collapse>


                    </Navbar>
                </div>
                <div className='mt-3 '>
                    <h5 className='d-inline text-light font-weight-bold'>
                        {this.props.history.location.pathname === '/adminpanel' && 'DASHBOARD'}
                        {this.props.history.location.pathname === '/adminpanel/recipes' && 'RECIPES'}
                        {this.props.history.location.pathname === '/adminpanel/orders' && 'ORDERS'}
                        {this.props.history.location.pathname === '/adminpanel/users' && 'USERS'}
                        {this.props.history.location.pathname === '/adminpanel/coupons' && 'COUPONS'}
                        {this.props.history.location.pathname === '/adminpanel/products' && 'PRODUCTS'}
                        {this.props.history.location.pathname === '/adminpanel/faqs' && 'FAQS'}
                        {this.props.history.location.pathname === '/adminpanel/reviews' && 'REVIEWS'}
                        {this.props.history.location.pathname === '/adminpanel/products/newProduct' && 'NEW PRODUCT'}
                        {this.props.history.location.pathname === '/adminpanel/recipes/new-recipe' && 'NEW RECIPE'}

                        {this.props.history.location.pathname === '/adminpanel/blogs-dashboard' && 'BLOGS'}


                    </h5>
                    <div className='float-right d-inline ' >
                        <UncontrolledDropdown style={{ right: 30 }} className='border-0 d-none d-lg-block '>
                            <DropdownToggle className='text-light' caret tag='span' style={{ cursor: 'pointer', color: 'rgb(236,23,79)' }}>
                                {this.props.auth.user.firstName.toUpperCase()}
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={() => this.props.history.push('/')} >Home</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={this.onLogoutClick}>Log out</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>
            </div >
        )
    }
}
const mapStateToProps = state => ({
    auth: state.form
})


export default connect(mapStateToProps, { logoutUser })(withRouter(MainBar))

