import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getSingleRecipe } from '../actions/recipeActions'
import {
    Col, Row, ListGroup, ListGroupItem, Container
} from 'reactstrap';
import Spinner from './Spinner'
import { Helmet } from "react-helmet";
import { withRouter } from 'react-router'




class RecipeLink extends Component {
    state = {
        recipe: {}
    }

    componentDidUpdate(prevProps) {

        if (this.props.recipes.recipe !== prevProps.recipes.recipe) {

            this.setState({
                recipe: this.props.recipes.recipe
            })

        }
    }

    componentDidMount() {

        if (this.props.location.state === undefined) {
            this.props.getSingleRecipe(this.props.match.params.recipeid)
        }

        if (this.props.location.state !== undefined && this.props.location.state.recipe) {
            this.setState({
                recipe: this.props.location.state.recipe
            })
        }

    }
    render() {

        return (
            <div>
                <div>
                    <Helmet>
                        <title>{(this.props.location.state !== undefined && this.props.location.state.recipe) ?
                            this.props.location.state.recipe.title : (this.props.match.params.title.replace(/-/g, " "))} - Frozberries Victoria</title>
                        <meta name="description" content={`${(this.props.location.state !== undefined && this.props.location.state.recipe) ?
                            this.props.location.state.recipe.title : (this.props.match.params.title.replace(/-/g, " "))} - Frozberries Victoria`} />
                    </Helmet>
                </div>

                <div >
                    {Object.keys(this.state.recipe).length > 0 ? (
                        <div>
                            <div className=' mt-3 py-5 bg-light'>
                                <Container>
                                    <Row className=' px-3 py-3'>
                                        <Col md='6' sm='12' >
                                            <h1 className='font-weight-bold'>{this.state.recipe.title}</h1>
                                            <h5 className='text-muted'>{this.state.recipe.description}</h5>

                                        </Col>
                                        <Col md='6' sm='12'>
                                            <img className='img-fluid' src={this.state.recipe.recipeImage} alt={this.state.recipe.title} />
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <div className='container mt-3 py-5 '>
                                <Row className='px-3 py-3 text-center mt-2'>
                                    <Col md='3' sm='6' className='my-3'>
                                        <div >
                                            <i className="fas fa-utensils fa-4x text-info"></i>
                                        </div>
                                        <div >
                                            <h5 className='text-muted mt-3'>Prep Time</h5>
                                            <h1 className='font text-info' style={{ fontSize: '3rem' }}>{this.state.recipe.prepTime}</h1>
                                        </div>

                                    </Col>
                                    <Col md='3' sm='6' className='my-3'>
                                        <div >
                                            <i className="far fa-clock fa-4x text-info"></i>
                                        </div>
                                        <div >
                                            <h5 className='text-muted  mt-3 '>Total Time</h5>
                                            <h1 className='font  text-info' style={{ fontSize: '3rem' }}>{this.state.recipe.totalTime}</h1>
                                        </div>
                                    </Col>
                                    <Col md='3' sm='6' className='my-3'>
                                        <div >
                                            <i className="fas fa-fire fa-4x text-info"></i>
                                        </div>
                                        <div >
                                            <h5 className='text-muted  mt-3'>Calories Per Serving</h5>
                                            <h1 className='font text-info' style={{ fontSize: '3rem' }}>{this.state.recipe.caloriesPerServing}</h1>
                                        </div>

                                    </Col>
                                    <Col md='3' sm='6' className='my-3 '>
                                        <div >
                                            <i className="fas fa-concierge-bell fa-4x text-info"></i>
                                        </div>
                                        <div >
                                            <h5 className='text-muted  mt-3'>Servings</h5>
                                            <h1 className='font text-info' style={{ fontSize: '3rem' }}>{this.state.recipe.servings}</h1>
                                        </div>
                                    </Col>
                                </Row>


                                <Row className='px-3 py-3 mt-2'>
                                    <Col md='4' sm='12' className='my-3'>
                                        <div >
                                            <h3 className='mt-3 font-weight-bold border-bottom'>Ingredients ({this.state.recipe.ingredients.length})</h3>
                                        </div>
                                        <div >
                                            <ul style={{ paddingInlineStart: 10 }}>
                                                {this.state.recipe.ingredients.map(ingredient => (
                                                    <li className='px-0 my-3 ' style={{ fontSize: '1.1rem' }} >{ingredient.step}</li>
                                                ))}
                                            </ul>
                                        </div>

                                    </Col>
                                    <Col md='8' sm='12' className='my-3'>
                                        <div >
                                            <h3 className='mt-3 font-weight-bold border-bottom'>Directions</h3>
                                        </div>
                                        <div >
                                            <ListGroup >
                                                {this.state.recipe.directions.map((direction, index) => (
                                                    <ListGroupItem style={{ fontSize: '1.2rem' }} className='px-0 border-0'>
                                                        <div className='rounded-circle text-white shadow px-3 py-3 bg-danger mr-2  mb-1' style={{ width: '3em', height: '3em', alignItems: 'center', justifyContent: 'center', display: 'inline-flex', textAlign: 'center' }}>
                                                            {index + 1}
                                                        </div>
                                                        {direction.direction}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        </div>

                                    </Col>

                                </Row>

                            </div>
                        </div>

                    ) :
                        (this.props.recipes.loading &&
                            <div className='text-center'>
                                <Spinner />
                            </div>)}
                </div>
            </div>

        )
    }
}



const mapStateToProps = (state) => ({
    recipes: state.recipes
})


export default connect(mapStateToProps, { getSingleRecipe })(withRouter(RecipeLink))