import {
    GET_ALL_ORDERS,
    GET_ALL_ORDERS_LOADING,
    GET_ALL_ORDERS_LOADING_RESET,
    GET_ALL_ORDERS_COUNT,
    GET_SELECTED_ORDER,
    UPDATE_DELIVERY_DATE,
    ORDER_UPDATE_LOADING,
    ORDER_UPDATE_LOADING_RESET,
    RESET_SELECTED_ORDER,
    ORDER_STATUS_UPDATED,
    RESET_ORDER_STATUS_UPDATED
} from '../actions/types.js'


const initialState = {
    loading: false,
    orders: [],
    orderCount: 0,
    order: {},
    updateButtonLoading: false,
    orderUpdated: false,
    orderStatusUpdated: false

}

export default function (state = initialState, action) {
    switch (action.type) {
        case RESET_SELECTED_ORDER:
            return {
                ...state,
                loading: false,
                orders: [],
                orderCount: 0,
                order: {},
                updateButtonLoading: false,
                orderUpdated: false,
                orderStatusUpdated: false
            }
        case RESET_ORDER_STATUS_UPDATED:
            return {
                ...state,
                orderStatusUpdated: false
            }
        case ORDER_STATUS_UPDATED:
            return {
                ...state,
                order: action.payload,
                orderStatusUpdated: true
            }
        case GET_SELECTED_ORDER:
            return {
                ...state,
                order: action.payload
            }
        case UPDATE_DELIVERY_DATE:
            return {
                ...state,
                order: action.payload,
                orderUpdated: true
            }
        case GET_ALL_ORDERS:
            return {
                ...state,
                orders: action.payload
            }
        case GET_ALL_ORDERS_COUNT:
            return {
                ...state,
                orderCount: action.payload
            }
        case GET_ALL_ORDERS_LOADING:
            return {
                ...state,
                loading: true
            }
        case GET_ALL_ORDERS_LOADING_RESET:
            return {
                ...state,
                loading: false
            }

        case ORDER_UPDATE_LOADING:
            return {
                ...state,
                updateButtonLoading: true
            }
        case ORDER_UPDATE_LOADING_RESET:
            return {
                ...state,
                updateButtonLoading: false
            }

        default: return state;
    }
}