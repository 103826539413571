import React, { Component } from 'react'
import SideBar from './SideBar';



class LayOut extends Component {

    render() {
        return (
            <div>
                <SideBar
                />
                {this.props.children}
            </div>
        )
    }
}
export default LayOut


