import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getSingleProduct, getYouMayAlsoLike, resetYouMayAlsoLike } from '../actions/productsActions'
import { Row, Col, Button, Breadcrumb, BreadcrumbItem, } from 'reactstrap'
import Benefits from './Benefits';
import { Helmet } from "react-helmet";
import DidYouKnow from './DidYouKnow';
import { updateShoppingCart, addToCart, addTotalQuantityToCart } from '../actions/shoppingCart'
import Slide from 'react-reveal'
import ButtonSpinner from './ButtonSpinner'
// import {
//     EmailShareButton,
//     FacebookShareButton,
//     TwitterShareButton,
//     WhatsappShareButton,
//     EmailIcon,
//     WhatsappIcon,
//     TwitterIcon,
//     FacebookIcon
// } from "react-share";
// import NutritionalInfo from './NutritionalInfo';
import Spinner from './Spinner'
import Reviews from './Reviews';


class PageLink extends Component {

    state = {
        width: 0,
        height: 0,
        benefits: null,
        didYouKnow: null,
        didYouKnowImage: null,
        quantity: 1,
        showModal: false,
        product: {},
    };

    handleNutrition = e => {
        this.setState({
            showModal: !this.state.showModal
        })
    }

    adjustCart = () => {
        let product = this.state.product

        let totalQuantity;


        if (this.props.cart.addToCartLoading.productId !== product._id) {
            let cart = [...this.props.cart.cart]
            if (!this.props.auth.isAuthenticated) {
                if (this.props.cart.cart.length !== 0) {
                    if (cart.filter(val => val.product._id === product._id).length !== 0) {
                        cart = cart.map(eachItem => {
                            if (eachItem.product._id === product._id) {
                                return {
                                    ...eachItem,
                                    quantity: eachItem.quantity + this.state.quantity
                                }
                            } else {
                                return eachItem
                            }
                        })
                    } else {
                        cart = [...cart, {
                            product,
                            quantity: this.state.quantity
                        }]
                    }
                }
                else {
                    cart = [...cart, {
                        product,
                        quantity: this.state.quantity
                    }]
                }
                this.props.updateShoppingCart(cart)
            }
            totalQuantity = this.state.quantity
            if (this.props.auth.isAuthenticated) {
                this.props.addTotalQuantityToCart(product, totalQuantity, this.props.auth.user.id)
            }
            else if (this.props.guest.userid !== '') {
                this.props.addTotalQuantityToCart(product, totalQuantity, this.props.guest.userid)
            }

        }
        this.setState({
            quantity: 1
        })
    }

    handlePlusClick = e => {
        this.setState({
            quantity: this.state.quantity + 1
        })
    }
    handleMinusClick = e => {
        if (this.state.quantity > 1) {
            this.setState({
                quantity: this.state.quantity - 1
            })
        }
    }

    componentDidMount() {

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        if (this.props.location.state === undefined) {
            this.props.getSingleProduct(this.props.match.params.productid)
        }
        if (this.props.location.state !== undefined && this.props.location.state.product) {

            this.props.getYouMayAlsoLike(this.props.location.state.product)

            this.setState({
                product: this.props.location.state.product,
                benefits: this.props.location.state.product.benefits,
                didYouKnow: this.props.location.state.product.didYouKnow,
                didYouKnowImage: this.props.location.state.product.didYouKnowImage
            })
        }
    }
    componentDidUpdate(prevProps) {

        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                product: {}
            })
            this.props.getSingleProduct(this.props.match.params.productid)
        }


        if (this.props.products.product !== prevProps.products.product) {

            this.props.getYouMayAlsoLike(this.props.products.product)

            this.setState({
                product: this.props.products.product,
                benefits: this.props.products.product.benefits,
                didYouKnow: this.props.products.product.didYouKnow,
                didYouKnowImage: this.props.products.product.didYouKnowImage
            })

        }
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        this.props.resetYouMayAlsoLike()
    }
    updateWindowDimensions = (e) => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    handleProductRoute = product => {
        this.props.history.push({
            pathname: `/shop/${product.name.replace(/[^A-Z0-9]+/ig, "-")}/${product._id}`,
        })
    }

    render() {
        return (
            <div>
                <div>
                    <Helmet>
                        <title>{(this.props.location.state !== undefined && this.props.location.state.product) ?
                            this.props.location.state.product.name : (this.props.match.params.title.replace(/-/g, " "))} - Frozberries Victoria</title>
                        <meta name="description" content={`${(this.props.location.state !== undefined && this.props.location.state.product) ?
                            (this.props.location.state.product.name + '-' + this.props.location.state.product.description) : (this.props.match.params.title.replace(/-/g, " "))} - Frozberries Victoria`} />
                    </Helmet>
                </div>
                {this.props.products.singleProductLoading &&
                    (<div className='text-center'>
                        <Spinner />
                    </div>)}
                {Object.keys(this.state.product).length > 0 && (
                    <React.Fragment>

                        <Row className='mt-3'>
                            <Col>
                                <div>
                                    <Breadcrumb className='mx-5'>
                                        <BreadcrumbItem style={{ cursor: 'pointer' }} onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                                        <BreadcrumbItem style={{ cursor: 'pointer' }} onClick={() => this.props.history.goBack()}>Shop</BreadcrumbItem>
                                        <BreadcrumbItem style={{ cursor: 'pointer' }} active>{this.props.match.params.title.replace(/-/g, ' ')}</BreadcrumbItem>
                                    </Breadcrumb>
                                </div>
                            </Col>
                        </Row>
                        <Row className=' py-5'>
                            <Col lg='6' className='text-center'>
                                <div style={{ paddingLeft: this.state.width > 992 && '10%' }}>
                                    <img style={{ maxHeight: '600px' }} src={this.state.product.image}
                                        alt={this.state.product.name}
                                        className='img-fluid px-2 py-2' />
                                </div>
                            </Col>
                            <Col lg='6' className='px-5' >
                                <div style={{ paddingRight: this.state.width > 992 && '15%' }}>
                                    <h1 >{this.state.product.name}
                                    </h1>
                                    <h3 className='py-2 text-muted font-weight-bold'>${this.state.product.price.toFixed(2)}</h3>

                                    <div style={{ minHeight: '100px' }} className='border-top'>
                                        <p className='pt-3'>
                                            {this.state.product.description}
                                        </p>
                                        {/* <p className='pt-1 nutrition-text ' onClick={this.handleNutrition}>
                                            <span className='nutrition-text'>Nutrition Information
                                            </span>
                                        </p> */}
                                        {/* <Modal isOpen={this.state.showModal} toggle={this.handleNutrition} >
                                            <ModalHeader toggle={this.handleNutrition}>What's On The Label?</ModalHeader>
                                            <NutritionalInfo />
                                        </Modal> */}
                                        <p className='pt-1'>
                                            Unit Size: {this.state.product.unitSize}
                                        </p>
                                        <p className='pt-1'>
                                            Country of origin: {this.state.product.origin}
                                        </p>
                                        <p>
                                            {this.props.cart.cart.map(each => each.product._id === this.state.product._id && (
                                                <Slide key={each.product._id} warning={this.props.cart.cart.length === 0 ? false : (
                                                    this.props.cart.cart.map(each => each.product._id === this.state.product._id) ? true : false
                                                )}>
                                                    <span style={{ fontSize: '14px' }} className={'font-weight-bold text-muted'}>+{each.quantity} Added</span>
                                                </Slide>
                                            ))}
                                        </p>
                                    </div>
                                    {/* <div className=' my-2'>
                                        <EmailShareButton
                                            subject='Checkout Amazing Frozen Fruits and Vegetables at Frozberries'
                                            body='Test'
                                            url='frozberriesvic.com.au'
                                        >
                                            <EmailIcon
                                                className='mx-1'
                                                size={32}
                                                bgStyle={{ fill: 'rgb(236,23,79)' }}
                                                round={true} />
                                        </EmailShareButton>

                                        <FacebookShareButton
                                            url='frozberriesvic.com.au'
                                            quote='Checkout Amazing Frozen Fruits and Vegetables at Frozberries'
                                            hashtag='#frozberriesvic'
                                        >
                                            <FacebookIcon
                                                className='mx-1'
                                                size={32}
                                                bgStyle={{ fill: 'rgb(236,23,79)' }}
                                                round={true} />
                                        </FacebookShareButton>
                                        <TwitterShareButton
                                            url='frozberriesvic.com.au'
                                            title='Checkout Amazing Frozen Fruits and Vegetables at Frozberries'
                                            hashtags={['frozberriesvic']}
                                        >
                                            <TwitterIcon
                                                className='mx-1'
                                                size={32}
                                                bgStyle={{ fill: 'rgb(236,23,79)' }}
                                                round={true} />
                                        </TwitterShareButton>
                                        <WhatsappShareButton
                                            url='frozberriesvic.com.au'
                                            title='Checkout Amazing Frozen Fruits and Vegetables at Frozberries'
                                        >
                                            <WhatsappIcon className='mx-1'
                                                size={32}
                                                bgStyle={{ fill: 'rgb(236,23,79)' }}
                                                round={true} />
                                        </WhatsappShareButton >
                                    </div> */}

                                    {this.state.product.inStock ? (
                                        <div>
                                            <div className='text-center border-top border-bottom mt-5 py-3'>
                                                <h4> <i onClick={this.handleMinusClick} style={{ fontSize: '15px', color: 'rgb(14,35,87)', cursor: 'pointer' }}
                                                    className="fas fa-minus mr-3 text-muted" />
                                                    {this.state.quantity}
                                                    <i onClick={this.handlePlusClick} style={{ fontSize: '15px', color: 'rgb(14,35,87)', cursor: 'pointer' }}
                                                        className="fas fa-plus ml-3 text-muted" /></h4>
                                            </div>
                                            <div>
                                                <Button onClick={this.adjustCart} color='success' className='btn-block mt-4 py-3'
                                                    style={{ height: '60px' }}
                                                >
                                                    {this.props.cart.addToCartLoading.productId === this.state.product._id ? (

                                                        <ButtonSpinner />
                                                    ) :
                                                        '    Add to Cart'}
                                                </Button>
                                            </div>
                                        </div>
                                    ) : (
                                            <div>
                                                <h5 className='text-danger font-weight-bold'>Currently out of stock</h5>
                                            </div>
                                        )}



                                </div>

                            </Col>
                        </Row>


                        <Benefits product={this.state.product} />
                        <DidYouKnow didYouKnow={this.state.didYouKnow} didYouKnowImage={this.state.didYouKnowImage} />

                        <div className='container'>
                            <Row>
                                <Col>
                                    <Reviews product={this.state.product} />
                                </Col>
                            </Row>
                        </div>


                        {this.props.products.youMayAlsoLike.length > 0 && (
                            <div >
                                <h1 className='text-center'>YOU MAY ALSO LIKE</h1>
                                <Row className='youMayAlsoLike py-5'>

                                    {this.props.products.youMayAlsoLike.map(product => (
                                        <Col md='4' sm='6' xs='12' key={product._id} className=' text-center  item my-2'>
                                            <div className='img-outer ' style={{ cursor: 'pointer' }} onClick={() => this.handleProductRoute(product)}>
                                                <div className='position-relative'>
                                                    <img
                                                        style={{ maxHeight: 300 }}
                                                        className='img-fluid product-image'
                                                        src={product.image}
                                                        alt={product.name} />
                                                </div>
                                            </div>
                                            <div>
                                                <h5 className='pt-3'>{product.name}</h5>
                                            </div>
                                            <div>
                                                <h5 className='font-weight-bold d-inline' style={{ fontSize: '17px', color: 'rgb(236,23,79)' }} >
                                                    <span className={'text-center'}>
                                                        ${product.price.toFixed(2)}
                                                    </span>
                                                </h5>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        )}

                    </React.Fragment>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    products: state.products,
    cart: state.cart,
    auth: state.form,
    guest: state.guest
})


export default connect(mapStateToProps, { resetYouMayAlsoLike, getYouMayAlsoLike, getSingleProduct, addToCart, updateShoppingCart, addTotalQuantityToCart })(PageLink)