import {
    SET_RATING,
    RESET_REVIEW_FORM,
    UPDATE_REVIEW,
    SET_REVIEW_IMAGES,
    REVIEW_SUBMITTED,
    REVIEW_ERRORS,
    REVIEW_SUBMITTED_LOADING
} from '../actions/types'


const initialState = {
    rating: 0,
    review: '',
    errors: {},
    firstName: '',
    lastName: '',
    email: '',
    images: {},
    success: false,
    imageUrl: '',
    loading: false
}

export default function (state = initialState, action) {

    switch (action.type) {

        case REVIEW_SUBMITTED_LOADING:
            return {
                ...state,
                loading: true
            }
        case REVIEW_ERRORS:
            return {
                ...state,
                errors: action.payload,
                loading: false
            }
        case REVIEW_SUBMITTED:
            return {
                ...state,
                success: true,
                loading: false
            }
        case UPDATE_REVIEW:
            return {
                ...state,
                [action.payload.field]: action.payload.value,
            }
        case SET_REVIEW_IMAGES:
            return {
                ...state,
                images: action.payload.image,
                imageUrl: action.payload.url
            }
        case SET_RATING:
            return {
                ...state,
                rating: action.payload
            }
        case RESET_REVIEW_FORM:
            return {
                ...state,
                rating: 0,
                review: '',
                errors: {},
                firstName: '',
                lastName: '',
                email: '',
                images: {},
                success: false,
                imageUrl: '',
                loading: false
            }
        default: return state;
    }
}