import {
    GET_ADMIN_FAQS,
    ADMIN_FAQS_LOADING,
    ADMIN_FAQS_LOADING_RESET,
    GET_ADMIN_FAQS_COUNT,
    GET_SINGLE_FAQ,
    FAQ_UPDATE,
    FAQ_UPDATE_LOADING_RESET,
    FAQ_UPDATE_LOADING,
    CREATE_NEW_FAQ,
    CREATE_NEW_LOADING,
    CREATE_NEW_LOADING_RESET,
    CREATE_NEW_FAQ_ERRORS,
    FAQ_DELETE_BUTTON_LOADING,
    FAQ_DELETE_BUTTON_LOADING_RESET,
    DELETE_FAQ,
    RESET_FAQ_FORM
} from '../actions/types.js'


const initialState = {
    faqs: [],
    faqCount: 0,
    loading: false,
    faq: {},
    updateButtonLoading:false,
    createButtonLoading:false,
    errors: {},
    newFaq:{},
    deleteButtonLoading:false,
    faqDeleted:false

}

export default function (state = initialState, action) {
    switch (action.type) {
        case RESET_FAQ_FORM:
            return {
                ...state,
                faqs: [],
                faqCount: 0,
                loading: false,
                faq: {},
                updateButtonLoading:false,
                createButtonLoading:false,
                errors: {},
                newFaq:{},
                deleteButtonLoading:false,
                faqDeleted:false
            }
        case DELETE_FAQ:
            return {
                ...state,
                faqDeleted: true
            }
        case CREATE_NEW_FAQ:
            return {
                ...state,
                newFaq: action.payload
            }
        case CREATE_NEW_LOADING:
            return {
                ...state,
                createButtonLoading: true
            }
            case CREATE_NEW_LOADING_RESET:
                return {
                    ...state,
                    createButtonLoading: false
                }
        case CREATE_NEW_FAQ_ERRORS:
            return {
                ...state,
                errors: action.payload
            }
        case FAQ_UPDATE:
            return {
                ...state,
                faq: action.payload
            }
        case GET_SINGLE_FAQ:
            return {
                ...state,
                faq: action.payload
            }
        case GET_ADMIN_FAQS:
            return {
                ...state,
                faqs: action.payload
            }
        case GET_ADMIN_FAQS_COUNT:
            return {
                ...state,
                faqCount: action.payload
            }

        case ADMIN_FAQS_LOADING:
            return {
                ...state,
                loading: true
            }
        case ADMIN_FAQS_LOADING_RESET:
            return {
                ...state,
                loading: false
            }

            case FAQ_UPDATE_LOADING:
                return {
                    ...state,
                    updateButtonLoading: true
                }
            case FAQ_UPDATE_LOADING_RESET:
                return {
                    ...state,
                    updateButtonLoading: false
                }

                case FAQ_DELETE_BUTTON_LOADING:
                    return {
                        ...state,
                        deleteButtonLoading: true
                    }
                case FAQ_DELETE_BUTTON_LOADING_RESET:
                    return {
                        ...state,
                        deleteButtonLoading: false
                    }
        default: return state;
    }
}