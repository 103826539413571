import {
    GET_RECIPES_LOADING,
    GET_RECIPES_LOADING_RESET,
    GET_RECIPES,
    RECIPE_COUNT,
    GET_SINGLE_RECIPE
} from '../actions/types.js'


const initialState = {
    recipes: [],
    recipeCount: 0,
    loading: false,
    recipe: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SINGLE_RECIPE:
            return {
                ...state,
                recipe: action.payload
            }
        case RECIPE_COUNT:
            return {
                ...state,
                recipeCount: action.payload
            }
        case GET_RECIPES:
            return {
                ...state,
                recipes: action.payload
            }
        case GET_RECIPES_LOADING:
            return {
                ...state,
                loading: true
            }
        case GET_RECIPES_LOADING_RESET:
            return {
                ...state,
                loading: false
            }

        default: return state;
    }
}