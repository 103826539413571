import React, { Component } from 'react'
import StepWizard from "react-step-wizard";
import ReviewStep1 from './ReviewStep1';
import ReviewStep2 from './ReviewStep2';
import ReviewStep3 from './ReviewStep3';
import ReviewStep4 from './ReviewStep4';
import ReviewStep5 from './ReviewStep5';


export default class ReviewModal extends Component {
    render() {
        return (
            <div>
                <StepWizard>
                    <ReviewStep1 />
                    <ReviewStep2 />
                    <ReviewStep3 />
                    <ReviewStep4 product={this.props.product} />
                    <ReviewStep5 />
                </StepWizard>
            </div>
        )
    }
}
