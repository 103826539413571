import React, { Component } from 'react'
import Badge from '@material-ui/core/Badge';
import { connect } from 'react-redux'
import { toggleCart } from '../actions/shoppingCart'

class Cart extends Component {


    openCart = (e) => {
        this.props.toggleCart()
    }


    render() {
        return (
            <div className='mr-2 pl-3 ' onClick={this.openCart}>
                <i className="fas fa-shopping-cart nav-cart  px-3 py-3 "
                    style={{ fontSize: '20px', cursor: 'pointer', borderRadius: '99px' }}>
                    <Badge badgeContent={
                        this.props.shoppingCart.cart.length !== 0 ?
                            this.props.shoppingCart.cart.map(product => (
                                product.quantity
                            )).reduce((total, currentValue) => total + currentValue) : 0
                    }
                        color='error'
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    />
                </i>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    shoppingCart: state.cart,
    products: state.products,

})


export default connect(mapStateToProps, { toggleCart })(Cart)