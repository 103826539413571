import React, { Component } from 'react'
import {
    Row, Col, Form, Input, Button,Badge
} from 'reactstrap'
import { connect } from 'react-redux'
import { getAdminRecipes } from '../../actions/adminRecipes'
import queryString from 'query-string'
import { Helmet } from "react-helmet";
import moment from 'moment'

class RecipesDashBoard extends Component {

    state = {
        searchQuery: this.props.location.search,
        filter: '',
        queryInput: ''
    }


    handleInputChange = e => {
        this.setState({
            queryInput: e.target.value
        })
    }

    handleSubmit = e => {
        e.preventDefault()
        const parsed = queryString.parse(this.props.location.search)
        parsed.query = this.state.queryInput

        if (parsed.page) {
            parsed.page = undefined
        }
        let stringified = queryString.stringify(parsed)


        this.setState({
            queryInput: '',
            searchQuery: '?' + stringified
        })
    }

    handleReset = e => {
        this.setState({
            searchQuery: ''
        })
    }


    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search);
        let page = 1;
        let filter;
        let query;

        if (parsed.page) {
            page = parsed.page
        }
        if (parsed.filter) {
            filter = parsed.filter
            this.setState({
                filter
            })
        }

        if (parsed.query) {
            query = parsed.query
            this.setState({
                queryInput: ''
            })
        }


        this.props.getAdminRecipes(page, filter, query)
    }

    scrollToMyRef = () => window.scrollTo(0, 0)


    componentDidUpdate(prevProps, prevState) {
        const parsed = queryString.parse(this.props.location.search);

        if (this.state.searchQuery !== prevState.searchQuery) {
            this.props.history.push(`/adminpanel/recipes${this.state.searchQuery}`)
        }

        if (this.props.location.search !== prevProps.location.search) {
            let page;
            let filter;
            let query;


            if (parsed.page) {
                page = parsed.page
            }
            if (parsed.filter) {
                filter = parsed.filter
            }
            if (parsed.query) {
                query = parsed.query
            }

            this.scrollToMyRef()
            this.props.getAdminRecipes(page, filter, query)
        }
    }

    handleNewRecipeClick = e => {
        this.props.history.push('/adminpanel/recipes/new-recipe')
    }

    previousPage = (e) => {
        const parsed = queryString.parse(this.props.location.search);
        parsed.page = parseInt(parsed.page) - 1

        let stringified = queryString.stringify(parsed);

        this.setState({
            searchQuery: '?' + stringified,
        })

    }


    nextPage = e => {
        const parsed = queryString.parse(this.props.location.search);
        if (parsed.page) {
            parsed.page = parseInt(parsed.page) + 1
        } else {
            parsed.page = 2
        }

        let stringified = queryString.stringify(parsed);

        this.setState({
            searchQuery: '?' + stringified,
        })
    }

    handlePageChange = (i) => {
        const parsed = queryString.parse(this.props.location.search);
        parsed.page = i + 1

        let stringified = queryString.stringify(parsed);

        this.setState({
            searchQuery: '?' + stringified,
        })
    }
    
    handleRecipeClick = recipe => {
        this.props.history.push({
            pathname: `/adminpanel/recipes/${recipe.title.replace(/[^A-Z0-9]+/ig, "-")}/${recipe._id}`,
            state: { recipe }
        })
    }


    render() {
        const parsed = queryString.parse(this.props.location.search)
        let noResultWarning;

        if (parsed.query && this.props.adminRecipes.recipeCount===0 ) {
            noResultWarning = true
        }

        let end;
        let totalItemNumbers = this.props.adminRecipes.recipeCount
        let currentPage;
        if (parsed.page) {
            if (parsed.page > [...Array(Math.ceil(this.props.adminRecipes.recipeCount / 12))].length) {
                currentPage = [...Array(Math.ceil(this.props.adminRecipes.recipeCount / 12))].length
            }
            else if (parsed.page <= 1) {
                currentPage = 1

            }
            else {
                currentPage = parsed.page
            }
        } else {
            currentPage = 1
        }


        if (12 < totalItemNumbers) {
            end = 12 * currentPage
            if (end > totalItemNumbers) {
                end = totalItemNumbers
            }
        } else {
            end = totalItemNumbers
        }



        return (
            <div>
                <div>
                    <Helmet>
                        <title>Recipes - Frozberries Victoria</title>
                        <meta name="description" content="Recipes - Frozberries Victoria" />
                    </Helmet>
                </div>
                <div>
                    {this.props.adminRecipes.loading ? (
                        <div className='text-center text-light my-5'>
                            <h5>Please wait. Recipes are being fetched.</h5>
                        </div>
                    ) : (
                            <React.Fragment>
                                <div >
                                    <Row className='my-3'>
                                        <Col>
                                            <Form onSubmit={this.handleSubmit}>
                                                <Input placeholder='Search Recipes' value={this.state.queryInput} onChange={this.handleInputChange} />
                                            </Form>
                                            <Button color='success' className='my-3' onClick={this.handleNewRecipeClick}>New Recipe</Button>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='mx-2'>
                                {parsed.query && (
                                                    <h5 className='d-inline pr-3' style={{ cursor: 'pointer' }} onClick={this.handleReset}> <Badge color="danger">
                                                        {parsed.query}
                                                        <span className='ml-2' style={{ fontSize: '1.2rem' }}>
                                                            &times;
                                                     </span></Badge></h5>
                                                )}
                                    </div>
                                {noResultWarning ? (
                                    <div className='text-center text-light my-5'>
                                        <h5>No results found.</h5>
                                    </div>
                                ) : (
                                        <React.Fragment>
                                            <div>
                                                {(this.props.adminRecipes.recipes.length !== 0) && (
                                                    <h1 className='float-right text-light' style={{ fontSize: '0.9rem' }}>
                                                        Showing {queryString.parse(this.props.location.search).page ?
                                                            (queryString.parse(this.props.location.search).page <= 1 ? 1 :
                                                                (queryString.parse(this.props.location.search).page > [...Array(Math.ceil(this.props.adminRecipes.recipeCount / 12))].length ?
                                                                    (([...Array(Math.ceil(this.props.adminRecipes.recipeCount / 12))].length - 1) * 13 - 1)
                                                                    : ((parseInt(queryString.parse(this.props.location.search).page) - 1) * (12) + 1)))
                                                            : 1} – {end} of {this.props.adminRecipes.recipeCount} results</h1>
                                                )}
                                            </div>

                                            <div className='bg-light mt-5' style={{ borderRadius: 10 }}>
                                                <Row className='w-100'>
                                                    <Col >
                                                        <div className='table-responsive'>
                                                            <table className="table">
                                                                <thead className='table-borderless'>
                                                                    <tr>
                                                                        <th scope="col">RECIPE</th>
                                                                        <th scope="col">TITLE</th>
                                                                        <th scope="col">CALORIES PER SERVING</th>
                                                                        <th scope="col">TOTAL TIME</th>
                                                                        <th scope='col'>SERVINGS</th>
                                                                        <th scope='col'>LAST UPDATED</th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody >
                                                                    {this.props.adminRecipes.recipes.map(item => (
                                                                        <tr onClick={() => this.handleRecipeClick(item)} key={item._id} className='admin-each-product' style={{ cursor: 'pointer' }}>
                                                                            <td style={{ width: '100px' }}>
                                                                                <img src={item.recipeImage} alt={item.name} className='img-fluid' />
                                                                            </td>
                                                                            <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>  {item.title}</td>
                                                                            <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{item.caloriesPerServing}</td>
                                                                            <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{item.totalTime}</td>
                                                                            <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{item.servings}</td>
                                                                            <td style={{ verticalAlign: 'middle' }} className='font-weight-bold '> {moment(item.lastUpdated).format("MMM Do YY")}</td>
                                                                        </tr>
                                                                    ))}

                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </Col>
                                                </Row>

                                                {[...Array(Math.ceil(this.props.adminRecipes.recipeCount / 12))].length > 1 &&
                                                    (this.props.adminRecipes.recipes.length !== 0) && (
                                                        <Row className='text-center mt-5'>
                                                            <Col className='text-center '>

                                                                <nav aria-label="Page navigation example">
                                                                    <ul className="pagination justify-content-center">
                                                                        <li className={(!queryString.parse(this.props.location.search).page ||
                                                                            queryString.parse(this.props.location.search).page <= 1) ?
                                                                            'disabled page-item ' : `page-item`}>
                                                                            <span className="page-link" style={{ color: 'rgb(235,24,79)', cursor: 'pointer' }}
                                                                                onClick={this.previousPage}>Previous</span>
                                                                        </li>
                                                                        {[...Array(Math.ceil(this.props.adminRecipes.recipeCount / 12))].map((page, i) => (
                                                                            <li key={i} className={(!queryString.parse(this.props.location.search).page && (i + 1 === 1)) ? 'active page-item'
                                                                                : (queryString.parse(this.props.location.search).page <= 1 && (i + 1 === 1) ? 'active page-item' :
                                                                                    (queryString.parse(this.props.location.search).page >= 2 && (i + 1 === parseInt(queryString.parse(this.props.location.search).page)) ? 'active page-item'
                                                                                        : ((queryString.parse(this.props.location.search).page > [...Array(Math.ceil(this.props.adminRecipes.recipeCount / 12))].length &&
                                                                                            (i + 1 === [...Array(Math.ceil(this.props.adminRecipes.recipeCount / 12))].length)) ? 'active page-item' : 'page-item')))}>
                                                                                <span className="page-link" style={{ color: 'rgb(235,24,79)', cursor: 'pointer' }}
                                                                                    onClick={() => this.handlePageChange(i)} >  {i + 1}</span></li>
                                                                        ))}

                                                                        <li className={(queryString.parse(this.props.location.search).page >= [...Array(Math.ceil(this.props.adminRecipes.recipeCount / 12))].length ?
                                                                            'disabled page-item' : "page-item")}>
                                                                            <span style={{ color: 'rgb(235,24,79)', cursor: 'pointer' }} className="page-link"
                                                                                onClick={this.nextPage}>Next</span>
                                                                        </li>
                                                                    </ul>
                                                                </nav>
                                                            </Col>

                                                        </Row>
                                                    )}


                                            </div>

                                        </React.Fragment>


                                    )}
                            </React.Fragment>
                        )}
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    adminRecipes: state.adminRecipes
})

export default connect(mapStateToProps, { getAdminRecipes })(RecipesDashBoard)


