import React, { Component } from 'react'
import {
    Elements
} from 'react-stripe-elements'
import StripeForm from './StripeForm'


class Stripe extends Component {



    render() {
        return (
            <div >
                <Elements>
                    <StripeForm />
                </Elements>
            </div>
        )
    }
}
export default Stripe