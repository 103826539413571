import {
    GET_ADMIN_FAQS,
    ADMIN_FAQS_LOADING,
    ADMIN_FAQS_LOADING_RESET,
    GET_ADMIN_FAQS_COUNT,
    GET_SINGLE_FAQ,
    FAQ_UPDATE,
    FAQ_UPDATE_LOADING_RESET,
    FAQ_UPDATE_LOADING,
    CREATE_NEW_FAQ,
    CREATE_NEW_LOADING,
    CREATE_NEW_LOADING_RESET,
    CREATE_NEW_FAQ_ERRORS,
    FAQ_DELETE_BUTTON_LOADING,
    FAQ_DELETE_BUTTON_LOADING_RESET,
    DELETE_FAQ,
    RESET_FAQ_FORM

} from './types'
import axios from 'axios'
import { logoutUser } from './formActions'



export const getAdminFaqs = (page, query) => dispatch => {
    dispatch({
        type: ADMIN_FAQS_LOADING
    })
    axios.get(`/api/admin/getAdminFaqs/${page}/${query}`)
        .then(res => {
            dispatch({
                type: GET_ADMIN_FAQS,
                payload: res.data.faqs
            })

            dispatch({
                type: GET_ADMIN_FAQS_COUNT,
                payload: res.data.count
            })

            dispatch({
                type: ADMIN_FAQS_LOADING_RESET
            })
        })
        .catch(err => {
            dispatch({
                type: ADMIN_FAQS_LOADING_RESET
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
            console.log(err)
        })

}

export const getSingleFaq = (id) => dispatch => {
    dispatch({
        type: ADMIN_FAQS_LOADING
    })
    axios.get(`/api/admin/getSingleFaq/${id}`)
        .then(res => {
            dispatch({
                type: GET_SINGLE_FAQ,
                payload: res.data
            })

            dispatch({
                type: ADMIN_FAQS_LOADING_RESET
            })
        })
        .catch(err => {
            dispatch({
                type: ADMIN_FAQS_LOADING_RESET
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
            console.log(err)
        })
}


export const updateFaq = (id, data) => dispatch => {
    dispatch({
        type: FAQ_UPDATE_LOADING
    })
    axios.post(`/api/admin/updateFaq/${id}`, data)
        .then(res => {
            dispatch({
                type: FAQ_UPDATE,
                payload: res.data
            })
            dispatch({
                type: FAQ_UPDATE_LOADING_RESET
            })
        })
        .catch(err => {
            dispatch({
                type: FAQ_UPDATE_LOADING_RESET
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
            console.log(err)
        })
}


export const createNewFaq = (data) => dispatch => {
    dispatch({
        type: CREATE_NEW_LOADING
    })
    axios.post(`/api/admin/createNewFaq`, data)
        .then(res => {
            dispatch({
                type: CREATE_NEW_FAQ,
                payload: res.data
            })
            dispatch({
                type: CREATE_NEW_LOADING_RESET
            })
        })
        .catch(err => {
            dispatch({
                type: CREATE_NEW_FAQ_ERRORS,
                payload: err.response.data
            })
            dispatch({
                type: CREATE_NEW_LOADING_RESET
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
            console.log(err)
        })
}

export const deleteFaq = (id) => dispatch => {
    dispatch({
        type: FAQ_DELETE_BUTTON_LOADING
    })
    axios.get(`/api/admin/deleteFaq/${id}`)
        .then(res => {
            dispatch({
                type: DELETE_FAQ,
            })
            dispatch({
                type: FAQ_DELETE_BUTTON_LOADING_RESET
            })
        })
        .catch(err => {
            dispatch({
                type: FAQ_DELETE_BUTTON_LOADING_RESET
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
            console.log(err)
        })
}


export const resetFaqForm = () => dispatch => {
    dispatch({
        type: RESET_FAQ_FORM
    })

}