import {
    TOTAL_USER_COUNT,
    TOTAL_WEEKLY_USER_COUNT,
    ADMIN_USER_DETAILS_LOADING,
    RESET_ADMIN_USER_DETAILS_LOADING,
    CHANGE_STREAM_TOTAL_USER_COUNT
} from '../actions/types.js'


const initialState = {
    loading: false,
    totalUserCount: 0,
    previousWeekCount: 0
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CHANGE_STREAM_TOTAL_USER_COUNT:
            return {
                ...state,
                totalUserCount: action.payload
            }
        case TOTAL_USER_COUNT:
            return {
                ...state,
                totalUserCount: action.payload
            }
        case TOTAL_WEEKLY_USER_COUNT:
            return {
                ...state,
                previousWeekCount: action.payload
            }
        case ADMIN_USER_DETAILS_LOADING:
            return {
                ...state,
                loading: true
            }
        case RESET_ADMIN_USER_DETAILS_LOADING:
            return {
                ...state,
                loading: false
            }
        default: return state;
    }
}