import React, { Component } from 'react'
import { analyticDetails } from '../../actions/adminAnalytics'
import { connect } from 'react-redux'
import { Row, Col, DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap'
import { Pie } from 'react-chartjs-2';

class UsersByDevice extends Component {

    state = {
        data: {},
        options: {},
        dropdownOpen: false,
        dropDownDate: 'Today',
        fetchDate: 'today',
    }
    componentDidMount() {
        this.props.analyticDetails(this.state.fetchDate)

        let chartData = {}
        let labels = []
        let backgroundColor = []
        let hoverBackgroundColor = []
        let datasets = []
        let datasetObject = {}
        let data = []
        let options = {}
        let tooltips = {}


        if (this.props.adminAnalytics.userByDevice.totalUsers === 0) {
            labels.push('Not enough data')
            backgroundColor.push('#CCC')
            hoverBackgroundColor.push('#CCC')
            data.push(100)
            tooltips.enabled = false
            options.tooltips = tooltips

        } else {
            labels.push('Desktop', 'Mobile', 'Tablet')
            backgroundColor.push('#36A2EB', '#FFCE56', '#df0841')
            hoverBackgroundColor.push('#36A2EB', '#FFCE56', '#ec174f')
            data.push(this.props.adminAnalytics.userByDevice.desktop, this.props.adminAnalytics.userByDevice.mobile, this.props.adminAnalytics.userByDevice.tablet)
        }
        chartData.labels = labels
        datasetObject.data = data
        datasetObject.backgroundColor = backgroundColor
        datasetObject.hoverBackgroundColor = hoverBackgroundColor
        datasets.push(datasetObject)
        chartData.datasets = datasets

        this.setState({
            data: chartData, options
        })

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.adminAnalytics.loading !== prevProps.adminAnalytics.loading && !this.props.adminAnalytics.loading) {
            let chartData = {}
            let labels = []
            let backgroundColor = []
            let hoverBackgroundColor = []
            let datasets = []
            let datasetObject = {}
            let data = []
            let options = {}
            let tooltips = {}

            if (this.props.adminAnalytics.userByDevice.totalUsers === 0) {
                labels.push('Not enough data')
                backgroundColor.push('#CCC')
                hoverBackgroundColor.push('#CCC')
                data.push(100)
                tooltips.enabled = false
                options.tooltips = tooltips

            } else {
                labels.push('Desktop', 'Mobile', 'Tablet')
                backgroundColor.push('#36A2EB', '#FFCE56', '#df0841')
                hoverBackgroundColor.push('#36A2EB', '#FFCE56', '#ec174f')
                data.push(this.props.adminAnalytics.userByDevice.desktop, this.props.adminAnalytics.userByDevice.mobile, this.props.adminAnalytics.userByDevice.tablet)
                tooltips.enabled = true
                options.tooltips = tooltips
            }
            chartData.labels = labels
            datasetObject.data = data
            datasetObject.backgroundColor = backgroundColor
            datasetObject.hoverBackgroundColor = hoverBackgroundColor
            datasets.push(datasetObject)
            chartData.datasets = datasets

            this.setState({
                data: chartData, options
            })
        }
        if (this.state.fetchDate !== prevState.fetchDate) {
            this.props.analyticDetails(this.state.fetchDate)
        }
    }
    toggle = e => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    }
    handleDateChange = date => {
        let dropDownDate;
        let fetchDate;
        if (date === 'today') {
            dropDownDate = 'Today'
            fetchDate = 'today'
        }
        else if (date === 'yesterday') {
            dropDownDate = 'Yesterday'
            fetchDate = 'yesterday'
        }
        else if (date === 'last7days') {
            dropDownDate = 'Last 7 days'
            fetchDate = '7daysAgo'
        }
        else if (date === 'last28days') {
            dropDownDate = 'Last 28 days'
            fetchDate = '28daysAgo'
        }
        else if (date === 'last90days') {
            dropDownDate = 'Last 90 days'
            fetchDate = '90daysAgo'
        }

        this.setState({
            dropDownDate,
            fetchDate
        })
    }

    render() {
        return (
            <div className=' text-left bg-light px-3 py-3 my-2 user-by-device' style={{ borderRadius: 10 }}>
                <Row>
                    <Col >
                        <h6 className='font-weight-bold text-secondary'>Sessions by device</h6>
                        <Pie
                            width={100}
                            height={50}
                            data={this.state.data}
                            options={this.state.options}
                        />

                        <Dropdown className='border-0' isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                            <DropdownToggle caret tag='span' style={{ cursor: 'pointer' }}>
                                {this.state.dropDownDate}
                            </DropdownToggle>
                            <DropdownMenu className='ml-auto'>
                                <DropdownItem header>  {this.state.dropDownDate}</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={() => this.handleDateChange('today')}>Today</DropdownItem>
                                <DropdownItem onClick={() => this.handleDateChange('yesterday')}>Yesterday</DropdownItem>
                                <DropdownItem onClick={() => this.handleDateChange('last7days')}>Last 7 days</DropdownItem>
                                <DropdownItem onClick={() => this.handleDateChange('last28days')}>Last 28 days</DropdownItem>
                                <DropdownItem onClick={() => this.handleDateChange('last90days')}>Last 90 days</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.form,
    adminAnalytics: state.adminAnalytics

})

export default connect(mapStateToProps, { analyticDetails })(UsersByDevice)