import {
    ADMIN_ANALYTICS_LIST_LOADING,
    ADMIN_ANALYTICS_LIST_LOADING_RESET,
    GET_COUNTRY_LIST,
    GET_REGION_LIST,
    GET_CITY_LIST
} from '../actions/types.js'


const initialState = {
    loading: false,
    countryList: [],
    regionList: [],
    cityList: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_COUNTRY_LIST:
            return {
                ...state,
                countryList: action.payload
            }
        case GET_REGION_LIST:
            return {
                ...state,
                regionList: action.payload
            }
        case GET_CITY_LIST:
            return {
                ...state,
                cityList: action.payload
            }
        case ADMIN_ANALYTICS_LIST_LOADING:
            return {
                ...state,
                loading: true
            }
        case ADMIN_ANALYTICS_LIST_LOADING_RESET:
            return {
                ...state,
                loading: false
            }
        default: return state;
    }
}