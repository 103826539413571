import React from 'react'
import {
    InputGroup,
    Label, Input
} from 'reactstrap';
import { connect } from 'react-redux'
import { registerUser,resetForm } from '../actions/formActions'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";
import Spinner from './Spinner';


class SignUp extends React.Component {

    state = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        password2: '',
        errors: {},
        registeredUser: true,
        phoneNumber: '',
        showSuccessMessage:false
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }


    onSubmit = (e) => {
        e.preventDefault();

        let newUser = {
            registeredUser: this.state.registeredUser,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            password: this.state.password,
            password2: this.state.password2,
            cart: this.props.cart.cart,
            phoneNumber: this.state.phoneNumber
        }

        this.props.registerUser(newUser, this.props.history)

    }

    componentWillUnmount(){
        this.props.resetForm()
    }

    componentDidUpdate(prevProps) {
        if (this.props.errors !== prevProps.errors) {
            this.setState({
                errors: this.props.errors
            })
        }

        if (this.props.auth.showRegistrationSuccess !== prevProps.auth.showRegistrationSuccess && this.props.auth.showRegistrationSuccess) {
            this.setState({
                showSuccessMessage: true
            })

            setTimeout(this.routeToLogin,3000)
        }
    }

    routeToLogin=()=>{
        this.props.history.push('/login')
    }


    render() {

        const { errors } = this.state
        return (
            <div >
                <div>
                    <Helmet>
                        <title>Sign Up - Frozberries Victoria</title>
                        <meta name="description" content="Sign Up - Frozberries Victoria" />
                    </Helmet>
                </div>

                <div className='container my-5 py-5' style={{ minHeight: '50vh' }}>
                    <div className='row'>
                        <div className='col-md-8 m-auto'>
                            <h1 className='display-4 text-center mb-5'>Register</h1>
                            <form onSubmit={this.onSubmit} >
                                <InputGroup className='my-2'>
                                    <Input placeholder="First Name" onChange={this.onChange} className={classnames('', { 'is-invalid': errors.firstName })} value={this.state.firstName} name='firstName' />
                                    <div className='invalid-feedback'>{errors.firstName}</div>


                                </InputGroup>
                                <InputGroup className='my-2'>
                                    <Input placeholder="Last Name" onChange={this.onChange} className={classnames('', { 'is-invalid': errors.lastName })} value={this.state.lastName} name='lastName' />
                                    <div className='invalid-feedback'>{errors.lastName}</div>


                                </InputGroup>
                                <InputGroup className='my-2'>
                                    <Input placeholder="Email" onChange={this.onChange} className={classnames('', { 'is-invalid': errors.email })} value={this.state.email} name='email' />
                                    <div className='invalid-feedback'>{errors.email}</div>
                                </InputGroup>

                                <InputGroup className='my-2'>
                                    <Input placeholder="Phone number" onChange={this.onChange} className={classnames('', { 'is-invalid': errors.phoneNumber })} value={this.state.phoneNumber} name='phoneNumber' />
                                    <div className='invalid-feedback'>{errors.phoneNumber}</div>
                                </InputGroup>

                                <InputGroup className='my-2'>
                                    <Input autoComplete="on" type='password' placeholder="Password" onChange={this.onChange} className={classnames('', { 'is-invalid': errors.password })} value={this.state.password} name='password' />
                                    <div className='invalid-feedback'>{errors.password}</div>
                                </InputGroup>

                                <InputGroup className='my-2'>
                                    <Input autoComplete="on" type='password' placeholder="Confirm password" onChange={this.onChange} className={classnames('', { 'is-invalid': errors.password2 })} value={this.state.password2} name='password2' />
                                    <div className='invalid-feedback'>{errors.password2}</div>
                                </InputGroup>
                                <small className='float-left pb-2'>Already a member? <Link to='/login'>Sign in</Link></small>

                                <InputGroup className='ml-4 ' >

                                    <Input type="checkbox" className='form-check-input' id="invalidCheck" required />
                                    <Label check className="form-check-label" for="invalidCheck">
                                        Agree to terms and conditions
                                        </Label>
                                </InputGroup>

                                {this.props.auth.spinner && (
                                    <div className='text-center'>
                                        <Spinner />

                                    </div>
                                )}

{this.state.showSuccessMessage && (
                            <div>
                                <p className='text-success'>Your account has been successfully created. You're being redirected to login page.</p>
                            </div>
                        )}

                                <Input
                                    disabled={(this.state.name === '' || this.state.email === '' || this.state.password === '' || this.state.password2 === '') ? true : false}
                                    type='submit' value={this.props.auth.spinner ? 'Please Wait..' : 'Register'}
                                    style={{ backgroundColor: 'rgb(236,23,79)' }}
                                    className='text-light btn-block mt-4 p-2 border-0' />

                            </form>
                            
                        </div>
                        
                    </div>
                </div>

            </div >
        )
    }
}


const mapStateToProps = state => ({
    errors: state.errors,
    auth: state.form,
    cart: state.cart

})

export default connect(mapStateToProps, { registerUser,resetForm })(SignUp)