import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getRecipes } from '../actions/recipeActions'
import {
    Col, Row, Badge
} from 'reactstrap';
import Spinner from './Spinner'
import queryString from 'query-string'
import { Helmet } from "react-helmet";
import { withRouter } from 'react-router'

class RecipePage extends Component {
    state = {
        searchQuery: this.props.location.search,
    }

    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search);
        let page = 1;
        let query;


        if (parsed.page) {
            page = parsed.page
        }

        if (parsed.query) {
            query = parsed.query

        }

        this.props.getRecipes(page, query)
    }

    handleReset = e => {

        this.props.history.push('/recipes')

    }

    scrollToMyRef = () => window.scrollTo(0, 0)


    componentDidUpdate(prevProps, prevState) {
        const parsed = queryString.parse(this.props.location.search);

        if (this.state.searchQuery !== prevState.searchQuery) {
            this.props.history.push(`/recipes${this.state.searchQuery}`)
        }


        if (this.props.location.search !== prevProps.location.search) {
            let page;
            let query;


            if (parsed.page) {
                page = parsed.page
            }

            if (parsed.query) {
                query = parsed.query
            }

            this.scrollToMyRef()
            this.props.getRecipes(page, query)
        }
    }

    previousPage = (e) => {
        const parsed = queryString.parse(this.props.location.search);
        parsed.page = parseInt(parsed.page) - 1

        let stringified = queryString.stringify(parsed);

        this.setState({
            searchQuery: '?' + stringified,
        })

    }


    nextPage = e => {
        const parsed = queryString.parse(this.props.location.search);
        if (parsed.page) {
            parsed.page = parseInt(parsed.page) + 1
        } else {
            parsed.page = 2
        }

        let stringified = queryString.stringify(parsed);

        this.setState({
            searchQuery: '?' + stringified,
        })
    }

    handlePageChange = (i) => {
        const parsed = queryString.parse(this.props.location.search);
        parsed.page = i + 1

        let stringified = queryString.stringify(parsed);

        this.setState({
            searchQuery: '?' + stringified,
        })
    }

    handleRecipeClick = recipe => {
        this.props.history.push({
            pathname: `/recipes/${recipe.title.replace(/[^A-Z0-9]+/ig, "-")}/${recipe._id}`,
            state: { recipe }
        })
    }


    render() {
        const parsed = queryString.parse(this.props.location.search)
        let noResultWarning;

        if (parsed.query && this.props.recipes.recipeCount === 0) {
            noResultWarning = true
        }

        let end;
        let totalItemNumbers = this.props.recipes.recipeCount
        let currentPage;
        if (parsed.page) {
            if (parsed.page > [...Array(Math.ceil(this.props.recipes.recipeCount / 24))].length) {
                currentPage = [...Array(Math.ceil(this.props.recipes.recipeCount / 24))].length
            }
            else if (parsed.page <= 1) {
                currentPage = 1

            }
            else {
                currentPage = parsed.page
            }
        } else {
            currentPage = 1
        }


        if (24 < totalItemNumbers) {
            end = 24 * currentPage
            if (end > totalItemNumbers) {
                end = totalItemNumbers
            }
        } else {
            end = totalItemNumbers
        }


        return (
            <div className='recipe-page container '>
                <div>
                    <Helmet>
                        <title>Recipes - Frozberries Victoria</title>
                        <meta name="description" content="Recipes - Frozberries Victoria" />
                    </Helmet>
                </div>
                {this.props.recipes.loading &&
                    <div className='text-center'>
                        <Spinner />
                    </div>}

                {parsed.query && (
                    <div className='my-2'>
                        <h5 className='d-inline pr-3' style={{ cursor: 'pointer' }} onClick={this.handleReset}> <Badge color="danger">
                            Search: {parsed.query}
                            <span className='ml-2' style={{ fontSize: '1.2rem' }}>
                                &times;
                                                     </span></Badge></h5>
                    </div>
                )}
                {noResultWarning && (
                    <div className='text-center  my-5'>
                        <h5>No results found.</h5>
                    </div>
                )}
                <Row className='text-right my-3'>
                    <Col>
                        <div>
                            {(this.props.recipes.recipes.length !== 0) && (
                                <h1 style={{ fontSize: '0.9rem' }}>
                                    Showing {queryString.parse(this.props.location.search).page ?
                                        (queryString.parse(this.props.location.search).page <= 1 ? 1 :
                                            (queryString.parse(this.props.location.search).page > [...Array(Math.ceil(this.props.recipes.recipeCount / 24))].length ?
                                                (([...Array(Math.ceil(this.props.recipes.recipeCount / 24))].length - 1) * 25 - 1)
                                                : ((parseInt(queryString.parse(this.props.location.search).page) - 1) * (24) + 1)))
                                        : 1} – {end} of {this.props.recipes.recipeCount} results</h1>
                            )}
                        </div>
                    </Col>
                </Row>


                <Row className=' py-3'>
                    {this.props.recipes.recipes.length !== 0 && (
                        this.props.recipes.recipes.map(recipe => (
                            <Col lg='4' md='6' sm='12' xs='12' key={recipe._id} className='  my-2' onClick={() => this.handleRecipeClick(recipe)}>
                                <div className='shadow-lg border-0 recipes' style={{ cursor: 'pointer' }}>
                                    <img src={recipe.recipeImage} className='recipe_image'
                                        alt={recipe.recipeImage} />
                                    <div style={{ minHeight: 120 }}>
                                        <h5 className='text-center  px-4 py-4' style={{ color: 'rgb(14,35,87)', fontSize: 25 }}>{recipe.title}</h5>
                                    </div>

                                </div>
                            </Col>
                        ))
                    )}
                </Row>

                {[...Array(Math.ceil(this.props.recipes.recipeCount / 24))].length > 1 &&
                    (this.props.recipes.recipes.length !== 0) && (
                        <Row className='text-center mt-5'>
                            <Col className='text-center '>

                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-center">
                                        <li className={(!queryString.parse(this.props.location.search).page ||
                                            queryString.parse(this.props.location.search).page <= 1) ?
                                            'disabled page-item ' : `page-item`}>
                                            <span className="page-link" style={{ color: 'rgb(235,24,79)', cursor: 'pointer' }}
                                                onClick={this.previousPage}>Previous</span>
                                        </li>
                                        {[...Array(Math.ceil(this.props.recipes.recipeCount / 24))].map((page, i) => (
                                            <li key={i} className={(!queryString.parse(this.props.location.search).page && (i + 1 === 1)) ? 'active page-item'
                                                : (queryString.parse(this.props.location.search).page <= 1 && (i + 1 === 1) ? 'active page-item' :
                                                    (queryString.parse(this.props.location.search).page >= 2 && (i + 1 === parseInt(queryString.parse(this.props.location.search).page)) ? 'active page-item'
                                                        : ((queryString.parse(this.props.location.search).page > [...Array(Math.ceil(this.props.recipes.recipeCount / 24))].length &&
                                                            (i + 1 === [...Array(Math.ceil(this.props.recipes.recipeCount / 24))].length)) ? 'active page-item' : 'page-item')))}>
                                                <span className="page-link" style={{ color: 'rgb(235,24,79)', cursor: 'pointer' }}
                                                    onClick={() => this.handlePageChange(i)} >  {i + 1}</span></li>
                                        ))}

                                        <li className={(queryString.parse(this.props.location.search).page >= [...Array(Math.ceil(this.props.recipes.recipeCount / 24))].length ?
                                            'disabled page-item' : "page-item")}>
                                            <span style={{ color: 'rgb(235,24,79)', cursor: 'pointer' }} className="page-link"
                                                onClick={this.nextPage}>Next</span>
                                        </li>
                                    </ul>
                                </nav>
                            </Col>

                        </Row>
                    )}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    recipes: state.recipes
})


export default connect(mapStateToProps, { getRecipes })(withRouter(RecipePage))