import React, { Component } from 'react'
import {
    Col, Row, Card, CardImg,

} from 'reactstrap'
import Slide from 'react-reveal/Slide';
import instapost1 from '../images/instapost1.jpg'
import instapost2 from '../images/instapost2.jpg'
import instapost3 from '../images/instapost3.jpg'
import instapost4 from '../images/instapost4.jpg'
import instapost5 from '../images/instapost5.jpg'
import instapost6 from '../images/instapost6.jpg'
import instapost7 from '../images/instapost7.jpg'
import instapost8 from '../images/instapost8.jpg'
import Fade from 'react-reveal/Fade';


class Instagram extends Component {
    render() {
        return (
            <div className=''>
                <div className='container pb-3 '>


                    <Fade bottom>
                        <Row className='mb-3'>
                            <Col className='text-center'>
                                <h3 >we <span role='img' aria-label='heart'>❤️</span> what you create with frozberries</h3>

                                <a href='https://www.instagram.com/frozberries_vic/' target='_blank' rel="noopener noreferrer">
                                    <h4 style={{ color: 'rgb(223, 8, 65) ', cursor: 'pointer' }}>Instagram @frozberries_vic
                                <i className="fas fa-arrow-right pl-2" style={{ fontSize: '16px' }}></i>
                                    </h4>
                                </a>


                            </Col>
                        </Row>
                    </Fade>
                    <Slide left>
                        <Row className='products-row pb-3'>
                            <Col md='3' className='text-center  px-2 upper-col' >
                                <a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/p/B-eKf5Mjjmn/?utm_source=ig_web_copy_link'>
                                    <Card className='shadow-lg border-0 products'>
                                        <CardImg top width='100%' src={instapost1} alt='conventional' />
                                    </Card>
                                </a>
                            </Col>
                            <Col md='3' className='text-center px-2 upper-col' >
                                <a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/p/B-eKDdKjc1-/?utm_source=ig_web_copy_link'>
                                    <Card className='shadow-lg border-0 products'>
                                        <CardImg top width='100%' src={instapost2} alt='conventional' />
                                    </Card>
                                </a>
                            </Col>
                            <Col md='3' className='text-center px-2 upper-col' >
                                <a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/p/B8_CjikAn9v/?utm_source=ig_web_copy_link'>
                                    <Card className='shadow-lg border-0 products'>
                                        <CardImg top width='100%' src={instapost8} alt='conventional' />
                                    </Card>
                                </a>
                            </Col>

                            <Col md='3' className='text-center px-2 upper-col' >
                                <a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/p/B4MvQ5MAF5w/?utm_source=ig_web_copy_link'>
                                    <Card className='shadow-lg border-0 products'>
                                        <CardImg top width='100%' src={instapost4} alt='conventional' />
                                    </Card>
                                </a>
                            </Col>
                        </Row>
                    </Slide>

                    <Slide right>

                        <Row className='products-row'>
                            <Col md='3' className='text-center px-2 pb-5' >
                                <a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/p/B016RG3gFcQ/?utm_source=ig_web_copy_link'>
                                    <Card className='shadow-lg border-0 products'>
                                        <CardImg top width='100%' src={instapost6} alt='conventional' />
                                    </Card>
                                </a>
                            </Col>
                            <Col md='3' className='text-center  px-2 pb-5' >
                                <a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/p/B17ubKNgjNV/?utm_source=ig_web_copy_link'>
                                    <Card className='shadow-lg border-0 products'>
                                        <CardImg top width='100%' src={instapost5} alt='conventional' />
                                    </Card>
                                </a>
                            </Col>
                            <Col md='3' className='text-center px-2 pb-5' >
                                <a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/p/B7TEGVDAgmN/?utm_source=ig_web_copy_link'>
                                    <Card className='shadow-lg border-0 products'>
                                        <CardImg top width='100%' src={instapost3} alt='conventional' />
                                    </Card>
                                </a>
                            </Col>
                            <Col md='3' className='text-center px-2 pb-5' >
                                <a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/p/B0zcTvYAafM/?utm_source=ig_web_copy_link'>
                                    <Card className='shadow-lg border-0 products'>
                                        <CardImg top width='100%' src={instapost7} alt='conventional' />
                                    </Card>
                                </a>
                            </Col>

                        </Row>
                    </Slide>

                </div>

            </div>
        )
    }
}
export default Instagram