import React, { Component } from 'react'
import { Helmet } from "react-helmet";
import classnames from 'classnames'
import { InputGroup, InputGroupAddon, InputGroupText, Input, Alert } from 'reactstrap';
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import { updatePassword } from '../actions/formActions'


class NewResetPassword extends Component {
    state = {
        password1: '',
        password2: '',
        errors: {},
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }


    onSubmit = (e) => {
        e.preventDefault();

        const userData = {
            password1: this.state.password1,
            password2: this.state.password2,
        }
        const linkData = {
            code: this.props.match.params.code,
            userid: this.props.match.params.userid
        }

        this.props.updatePassword(userData, linkData)

    }

    componentDidUpdate(prevProps) {

        if (this.props.errors !== prevProps.errors) {
            this.setState({
                errors: this.props.errors
            })
        }
        if (this.props.auth.passwordUpdated !== prevProps.auth.passwordUpdated && this.props.auth.passwordUpdated) {
            this.setState({
                password1: '',
                password2: ''
            })
            setTimeout(() => {
                this.props.history.push('/login')

            }, 3000);
        }
    }

    render() {
        if (localStorage.jwtToken) {
            return <Redirect to='/dashboard' />
        }
        const { errors } = this.state


        return (
            <div className='container my-5 py-5' style={{ minHeight: '50vh' }}>
                <Helmet>
                    <title>Reset Password - Frozberries Victoria</title>
                    <meta name="description" content="Reset Password - Frozberries Victoria" />
                </Helmet>
                <div className='row'>
                    <div className='col-md-8 m-auto'>
                        <h1 className='display-4 text-center mb-5'>Reset Password</h1>
                        {this.props.auth.passwordUpdated && (
                            <Alert color='success'>
                                Your password has been successfully updated. You are being redirected to the login page.
                            </Alert>
                        )}
                        {errors.code && (
                            <Alert color='danger'>
                                {errors.code} Click <Link to='/reset-password'>here</Link>.
                            </Alert>
                        )}
                        <form onSubmit={this.onSubmit} >

                            <InputGroup className='my-2'>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>@</InputGroupText>
                                </InputGroupAddon>
                                <Input type='password' placeholder="password" onChange={this.onChange} className={classnames('', { 'is-invalid': errors.password1 })} value={this.state.password1} name='password1' autoComplete="on" />
                                <div className='invalid-feedback'>{errors.password1}</div>
                            </InputGroup>
                            <InputGroup className='my-2'>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>@</InputGroupText>
                                </InputGroupAddon>
                                <Input type='password' placeholder="confirm password" onChange={this.onChange} className={classnames('', { 'is-invalid': errors.password2 })} value={this.state.password2} name='password2' autoComplete="on" />
                                <div className='invalid-feedback'>{errors.password2}</div>
                            </InputGroup>

                            <input
                                disabled={this.state.password1 === '' || this.state.password2 === '' ? true : false}
                                type='submit' value='Update Password' className=' text-light btn-block mt-4 p-2 border-0' style={{ backgroundColor: 'rgb(236,23,79)' }} />
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    errors: state.errors,
    auth: state.form,
    cart: state.cart
})

export default connect(mapStateToProps, { updatePassword })(NewResetPassword)