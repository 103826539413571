import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setRating } from '../actions/reviewActions'

let stars = [{
    title: 'Love it!',
    rating: 5,
},
{
    title: 'Like it',
    rating: 4,
},
{
    title: `It's ok`,
    rating: 3,
},
{
    title: `Didn't like it`,
    rating: 2,
},
{
    title: 'Not my thing',
    rating: 1,
},
]

class ReviewStep1 extends Component {

    handleRating = star => {
        this.props.setRating(star)
        this.props.nextStep()
    }


    render() {
        return (
            <div className='text-center px-5  py-2'>
                <h3 className='font-weight-bold mb-3 review-title'>HOW WOULD YOU RATE THIS ITEM?</h3>
                {stars.map(star => (
                    <div onClick={() => this.handleRating(star.rating)} style={{ cursor: 'pointer' }} key={star.rating}
                        style={{
                            cursor: 'pointer',
                            backgroundColor: this.props.reviews.rating === star.rating && 'black', color: this.props.reviews.rating === star.rating && 'white', transition: this.props.reviews.rating === star.rating && '.3s ease-in-out'
                        }} className='border my-2 reviewStars'>
                        <div className='star-modal' style={{ margin: '15 auto 0' }}>
                            <div className=' mr-2 mx-auto star-box'>
                                <span className='star-title' >

                                    {[...Array(Math.ceil(star.rating))].map((star, index) => (
                                        <i key={index} className="fas fa-star star-font-size"></i>
                                    ))}
                                    {[...Array(Math.ceil(5 - star.rating))].map((star, index) => (
                                        <i key={index} className="far fa-star star-font-size"></i>
                                    ))}
                                </span>
                                <span className='text-left ml-3 star-title' style={{ width: 162, fontWeight: 600 }}>{star.title}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    reviews: state.reviews
})

export default connect(mapStateToProps, { setRating })(ReviewStep1)
