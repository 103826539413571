import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'

class DidYouKnow extends Component {
    render() {
        return (
            <div className='py-5 my-5 didyouknow' style={{
                backgroundColor: 'rgb(255,182,193)'
            }}>
                <Row className='px-3'>
                    < Col md='6' className='pt-2 my-3'>
                        <h1>Did You Know?</h1>
                        <p style={{ fontSize: 18 }}>{this.props.didYouKnow}</p>
                    </ Col>
                    <Col className='text-center my-3' md='6'>
                        <img style={{ maxHeight: 300 }} className='img-fluid' src={this.props.didYouKnowImage} alt={this.props.didYouKnow} />
                    </Col>
                </Row>
            </div >
        )
    }
}
export default DidYouKnow