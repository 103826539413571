import React, { Component } from 'react'
import { Row, Col, Input, InputGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, InputGroupAddon, InputGroupText, } from 'reactstrap'
import { connect } from 'react-redux'
import { Helmet } from "react-helmet";
import classnames from 'classnames'
import { createNewRecipe } from '../../actions/adminRecipes'


class NewRecipe extends Component {


    state = {
        title: '',
        description: '',
        prepTime: '',
        totalTime: '',
        caloriesPerServing: '',
        servings: '',
        recipeImage: null,
        ingredients: [''],
        directions: [''],
        errors: {},
        imageUrl: '',
        showSuccessModal: false
    }

    componentDidUpdate(prevProps) {
        if (this.props.adminRecipes.errors !== prevProps.adminRecipes.errors) {
            this.setState({
                errors: this.props.adminRecipes.errors
            })
        }

        if (this.props.adminRecipes.newRecipe !== prevProps.adminRecipes.newRecipe) {
            this.setState({
                showSuccessModal: true,
                title: '',
                description: '',
                prepTime: '',
                totalTime: '',
                caloriesPerServing: '',
                servings: '',
                recipeImage: null,
                ingredients: [''],
                directions: [''],
                errors: {},
                imageUrl: '',
            })
        }
    }

    handleFormSubmit = e => {
        e.preventDefault()

        let formData = new FormData()

        formData.append('recipeImage', this.state.recipeImage)

        if (this.state.title !== '') {
            formData.append('title', this.state.title)
        }
        if (this.state.description !== '') {
            formData.append('description', this.state.description)
        }
        if (this.state.prepTime !== '') {
            formData.append('prepTime', this.state.prepTime)
        }
        if (this.state.totalTime !== '') {
            formData.append('totalTime', this.state.totalTime)
        }
        if (this.state.caloriesPerServing !== '') {
            formData.append('caloriesPerServing', this.state.caloriesPerServing)
        }
        if (this.state.servings !== '') {
            formData.append('servings', this.state.servings)
        }

        formData.append('ingredients', JSON.stringify(this.state.ingredients))
        formData.append('directions', JSON.stringify(this.state.directions))



        this.props.createNewRecipe(formData)


    }

    newIngredient = (index) => {
        let ingredients = [...this.state.ingredients]
        if (index !== 0 && ingredients[index] !== '') {
            this.setState({
                ingredients: [...this.state.ingredients, '']
            })
        }
        if (index === 0 && ingredients[index] !== '') {
            this.setState({
                ingredients: [...this.state.ingredients, '']
            })
        }
    }

    removeIngredient = index => {
        let ingredients = [...this.state.ingredients]
        ingredients.splice(index, 1)

        this.setState({
            ingredients
        })
    }

    handleIngredients = (index) => e => {
        let ingredients = [...this.state.ingredients]
        let errors = this.state.errors
        if (errors.ingredients) {
            delete errors.ingredients
        }
        ingredients[index] = e.target.value
        this.setState({
            ingredients, errors
        })
    }

    handleDirections = index => e => {
        let directions = [...this.state.directions]
        let errors = this.state.errors
        if (errors.directions) {
            delete errors.directions
        }
        directions[index] = e.target.value
        this.setState({
            directions, errors
        })
    }
    newStep = index => {
        let directions = [...this.state.directions]
        if (index !== 0 && directions[index] !== '') {
            this.setState({
                directions: [...this.state.directions, '']
            })
        }
        if (index === 0 && directions[index] !== '') {
            this.setState({
                directions: [...this.state.directions, '']
            })
        }
    }
    removeStep = index => {
        let directions = [...this.state.directions]
        directions.splice(index, 1)

        this.setState({
            directions
        })
    }

    onImageSelect = e => {
        let errors = this.state.errors

        if (errors.recipeImage) {
            delete errors.recipeImage
        }


        if (e.target.name === 'recipeImage') {
            let reader = new FileReader();
            let file = e.target.files[0];

            reader.onloadend = () => {
                this.setState({
                    recipeImage: file,
                    imageUrl: reader.result,
                    errors
                });
            }

            if (file) {
                reader.readAsDataURL(file);
              }

        }
    }


    onChange = (e) => {
        let value = e.target.value
        let errors = this.state.errors
        if (errors[e.target.name]) {
            delete errors[e.target.name]
        }
        if ((e.target.name === 'caloriesPerServing' || e.target.name === 'servings') && value !== '') {
            value = parseFloat(value)
        }
        this.setState({ [e.target.name]: value, errors })

    }

    toggle = () => {
        this.setState({
            showSuccessModal: !this.state.showSuccessModal
        })
    }

    handleNewRecipeRouting = () => {
        this.props.history.push(`/recipes/${this.props.adminRecipes.newRecipe.name.replace(/[^A-Z0-9]+/ig, "-")}/${this.props.adminRecipes.newRecipe._id}`)
    }



    render() {
        const { errors } = this.state

        return (
            <div>
                <div>
                    <Helmet>
                        <title>New Recipe - Frozberries Victoria</title>
                        <meta name="description" content="New Recipe - Frozberries Victoria" />
                    </Helmet>
                </div>
                <Row className='pb-5'>
                    <Col>
                        <Modal isOpen={this.state.showSuccessModal} toggle={this.toggle}>
                            <ModalHeader >New recipe has been successfully created.</ModalHeader>
                            <ModalBody>
                                <img style={{ maxHeight: 300 }} src={this.props.adminRecipes.newRecipe.recipeImage} alt={this.props.adminRecipes.newRecipe.title} />
                                <p>{this.props.adminRecipes.newRecipe.title}</p>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={this.handleNewRecipeRouting}>Go to created recipe</Button>{' '}
                                <Button color="danger" onClick={this.toggle}>Cancel</Button>
                            </ModalFooter>
                        </Modal>
                        <form encType="multipart/form-data" onSubmit={this.handleFormSubmit}>
                            <Label className='text-light h6 my-3' >*All fields are required. The form is case sensitive.</Label>

                            <InputGroup className='my-2'>
                                <FormGroup className='w-100 text-light'>
                                    <Label >Title </Label>
                                    <Input placeholder="Title" onChange={this.onChange} className={classnames('', { 'is-invalid': errors.title })} value={this.state.title} name='title' />
                                    <div className='invalid-feedback'>{errors.title}</div>
                                </FormGroup>
                            </InputGroup>
                            <InputGroup className='my-2'>
                                <FormGroup className='w-100 text-light'>

                                    <Label>Description</Label>
                                    <Input type="textarea" placeholder="Description" onChange={this.onChange} className={classnames('', { 'is-invalid': errors.description })} value={this.state.description} name='description' />
                                    <div className='invalid-feedback'>{errors.description}</div>
                                </FormGroup>
                            </InputGroup>
                            <InputGroup className='my-2'>
                                <FormGroup className='w-100 text-light'>
                                    <Label>Preperation time </Label>
                                    <Input placeholder="Preperation time" onChange={this.onChange} className={classnames('', { 'is-invalid': errors.prepTime })} value={this.state.prepTime} name='prepTime' />
                                    <div className='invalid-feedback'>{errors.prepTime}</div>
                                </FormGroup>
                            </InputGroup>
                            <InputGroup className='my-2'>
                                <FormGroup className='w-100 text-light'>
                                    <Label>Total time</Label>
                                    <Input placeholder="Total time" onChange={this.onChange} className={classnames('', { 'is-invalid': errors.totalTime })} value={this.state.totalTime} name='totalTime' />
                                    <div className='invalid-feedback'>{errors.totalTime}</div>
                                </FormGroup>
                            </InputGroup>
                            <InputGroup className='my-2'>
                                <FormGroup className='w-100 text-light'>
                                    <Label>Calories per serving</Label>
                                    <Input type='number' placeholder="Calories per serving" onChange={this.onChange} className={classnames('', { 'is-invalid': errors.caloriesPerServing })} value={this.state.caloriesPerServing} name='caloriesPerServing' />
                                    <div className='invalid-feedback'>{errors.caloriesPerServing}</div>
                                </FormGroup>
                            </InputGroup>
                            <InputGroup className='my-2'>
                                <FormGroup className='w-100 text-light'>
                                    <Label>Servings</Label>
                                    <Input type='number' placeholder="Servings" onChange={this.onChange} className={classnames('', { 'is-invalid': errors.servings })} value={this.state.servings} name='servings' />
                                    <div className='invalid-feedback'>{errors.servings}</div>
                                </FormGroup>
                            </InputGroup>

                            {this.state.ingredients.map((ingredient, index) => (
                                <InputGroup key={index} className='my-2'>
                                    {index === 0 && (
                                        <Label className='text-light' style={{ width: '100%' }}>Ingredients</Label>
                                    )}
                                    <React.Fragment>
                                        <Input placeholder={`${index + 1}. ingredient`} onChange={this.handleIngredients(index)} className={classnames('', { ' is-invalid': errors.ingredients })} value={ingredient} />
                                        {(this.state.ingredients.length !== index + 1) && (
                                            <InputGroupAddon addonType="append">
                                                <InputGroupText style={{ cursor: 'pointer' }} onClick={() => this.removeIngredient(index)}>-</InputGroupText>
                                            </InputGroupAddon>
                                        )}
                                        {this.state.ingredients.length === index + 1 && (
                                            <InputGroupAddon addonType="append">
                                                {index !== 0 && (
                                                    <InputGroupText style={{ cursor: 'pointer' }} onClick={() => this.removeIngredient(index)}>-</InputGroupText>
                                                )}
                                                <InputGroupText style={{ cursor: 'pointer' }} onClick={() => this.newIngredient(index)}>+</InputGroupText>
                                            </InputGroupAddon>
                                        )}
                                        <div className='invalid-feedback'>{errors.ingredients}</div>

                                    </React.Fragment>
                                </InputGroup>

                            ))}

                            {this.state.directions.map((direction, index) => (
                                <InputGroup key={index} className='my-2'>
                                    {index === 0 && (
                                        <Label className='text-light' style={{ width: '100%' }}>Directions</Label>
                                    )}
                                    <React.Fragment>
                                        <Input placeholder={`${index + 1}. step`} onChange={this.handleDirections(index)} className={classnames('', { ' is-invalid': errors.directions })} value={direction} />
                                        {(this.state.directions.length !== index + 1) && (
                                            <InputGroupAddon addonType="append">
                                                <InputGroupText style={{ cursor: 'pointer' }} onClick={() => this.removeStep(index)}>-</InputGroupText>
                                            </InputGroupAddon>
                                        )}
                                        {this.state.directions.length === index + 1 && (
                                            <InputGroupAddon addonType="append">
                                                {index !== 0 && (
                                                    <InputGroupText style={{ cursor: 'pointer' }} onClick={() => this.removeStep(index)}>-</InputGroupText>
                                                )}
                                                <InputGroupText style={{ cursor: 'pointer' }} onClick={() => this.newStep(index)}>+</InputGroupText>
                                            </InputGroupAddon>
                                        )}
                                        <div className='invalid-feedback'>{errors.directions}</div>

                                    </React.Fragment>
                                </InputGroup>

                            ))}

                            <InputGroup>
                                <Label className='text-light h5' for="recipeImage">Select an image for new recipe</Label>
                                <Input ref="recipeImage" type="file" name="recipeImage" id="recipeImage" onChange={this.onImageSelect} className={classnames('', { 'is-invalid': errors.recipeImage })} />
                                <div className='invalid-feedback'>{errors.recipeImage}</div>
                            </InputGroup>

                            {this.state.recipeImage !== null && (
                                <img className='my-3' style={{ height: 200 }} src={this.state.imageUrl} alt='new product' />
                            )}

                            <Row>
                                <Col xs='6'>
                                    <Input type='submit' value={this.props.adminRecipes.loading ? 'Please Wait..' : 'Create new recipe'} className='bg-success text-light btn-block mt-4 p-2 border-0 d-inline' />

                                </Col>
                                <Col xs='6'>
                                    <Button onClick={() => this.props.history.push('/adminpanel/recipes')} color='light' className=' btn-block mt-4 p-2 border-0 d-inline'>Go back to recipes </Button>
                                </Col>

                            </Row>

                        </form>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    adminRecipes: state.adminRecipes
})

export default connect(mapStateToProps, { createNewRecipe })(NewRecipe)


