import React, { Component } from 'react'
import { VectorMap } from 'react-jvectormap'
import { connect } from 'react-redux'
import { getMapData } from '../../actions/mapData'
import { Row, Col, DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap'


class Map extends Component {

    state = {
        dropdownOpen: false,
        dropDownDate: 'Today',
        fetchDate: 'today',
    }


    toggle = e => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    }

    handleDateChange = date => {
        let dropDownDate;
        let fetchDate;
        if (date === 'today') {
            dropDownDate = 'Today'
            fetchDate = 'today'
        }
        else if (date === 'yesterday') {
            dropDownDate = 'Yesterday'
            fetchDate = 'yesterday'
        }
        else if (date === 'last7days') {
            dropDownDate = 'Last 7 days'
            fetchDate = '7daysAgo'
        }
        else if (date === 'last28days') {
            dropDownDate = 'Last 28 days'
            fetchDate = '28daysAgo'
        }
        else if (date === 'last90days') {
            dropDownDate = 'Last 90 days'
            fetchDate = '90daysAgo'
        }

        this.setState({
            dropDownDate,
            fetchDate
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.fetchDate !== prevState.fetchDate) {
            this.props.getMapData(this.state.fetchDate)
        }
    }


    componentDidMount() {
        this.props.getMapData(this.state.fetchDate)
    }

    handleToolTip = (event, label, code) => {
        if (!this.props.map.loading) {
            if (this.props.map.countryList[code]) {
                label.html(
                    '<b>' + label.html() + '</b></br>' +
                    '<b>Total Visitors: </b>' + this.props.map.countryList[code]
                );
            } else {
                label.html(
                    '<b>' + label.html() + '</b></br>' +
                    '<b>Total Visitors: </b>' + 0
                );
            }
        } else {
            event.preventDefault()
        }

    }

    render() {

        return (
            <div className=' text-left bg-light px-3 py-3 ' style={{ borderRadius: 10 }}>
                <Row>
                    <Col>
                        <h6 className='font-weight-bold text-secondary'>Sessions by country</h6>
                        <VectorMap
                            map={"world_mill"}
                            backgroundColor="white" //change it to ocean blue: #0077be
                            zoomOnScroll={false}
                            containerStyle={{
                                width: "100%",
                                height: "520px"
                            }}
                            containerClassName="map"
                            regionStyle={{
                                initial: {
                                    fill: "#dddddd",
                                    "fill-opacity": 0.9,
                                    stroke: "none",
                                    "stroke-width": 0,
                                    "stroke-opacity": 0
                                },
                                hover: {
                                    "fill-opacity": 0.5,
                                    cursor: "pointer"
                                },
                                selected: {
                                    fill: "#e4e4e4" //color for the clicked country
                                },
                                selectedHover: {}
                            }}
                            regionsSelectable={false}
                            series={{
                                regions: [
                                    {
                                        values: Object.keys(this.props.map.countryList).length === 0 ? {} : this.props.map.countryList, //this is your data
                                        scale: ['#0071A4'],
                                        normalizeFunction: "polynomial"
                                    }
                                ]
                            }}
                            onRegionTipShow={this.handleToolTip}
                        />

                        <Dropdown className='border-0' isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                            <DropdownToggle caret tag='span' style={{ cursor: 'pointer' }}>
                                {this.state.dropDownDate}
                            </DropdownToggle>
                            <DropdownMenu className='ml-auto'>
                                <DropdownItem header>  {this.state.dropDownDate}</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={() => this.handleDateChange('today')}>Today</DropdownItem>
                                <DropdownItem onClick={() => this.handleDateChange('yesterday')}>Yesterday</DropdownItem>
                                <DropdownItem onClick={() => this.handleDateChange('last7days')}>Last 7 days</DropdownItem>
                                <DropdownItem onClick={() => this.handleDateChange('last28days')}>Last 28 days</DropdownItem>
                                <DropdownItem onClick={() => this.handleDateChange('last90days')}>Last 90 days</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Col>
                </Row>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    map: state.map,
})

export default connect(mapStateToProps, { getMapData })(Map);

