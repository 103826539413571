import React, { Component } from 'react'
import {
    Col,
    Row,
    Container,
    Form, Input, InputGroupAddon, InputGroup, InputGroupText
} from 'reactstrap';
import image from '../images/farm.jpeg'
import queryString from 'query-string'
import { withRouter } from 'react-router'


class Base extends Component {

    state = {
        queryInput: '',
        searchQuery: this.props.location.search,
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.location.pathname === '/recipes') {
            if (this.state.searchQuery !== prevState.searchQuery) {
                this.props.history.push(`/recipes${this.state.searchQuery}`)
            }

            if (this.props.location.search !== prevProps.location.search) {
                const parsed = queryString.parse(this.props.location.search)

                let page;
                let query;


                if (parsed.page) {
                    page = parsed.page
                }

                if (parsed.query) {
                    query = parsed.query
                }

                let stringified = queryString.stringify(parsed)

                if (stringified !== '') {
                    this.setState({
                        searchQuery: '?' + stringified

                    })
                } else {
                    this.setState({
                        searchQuery: ''

                    })
                }
            }
        }
    }


    handleRecipeSearch = e => {
        e.preventDefault()
        const parsed = queryString.parse(this.props.location.search)
        parsed.query = this.state.queryInput

        if (parsed.page) {
            parsed.page = undefined
        }

        let stringified = queryString.stringify(parsed)

        this.setState({
            queryInput: '',
            searchQuery: '?' + stringified
        })


    }
    handleChange = e => {
        this.setState({
            queryInput: e.target.value
        })
    }

    render() {
        return (
            <div>
                <img style={{ height: '30vh', minHeight: '350px', objectFit: 'cover', }} className="d-block w-100 overflow-hidden  position-absolute" src={this.props.image ? this.props.image : image} alt={this.props.title} />
                <div className=" text-center w-100 position-relative"
                    style={{
                        top: 0,
                        height: '30vh', minHeight: '350px',
                        backgroundColor: this.props.overlay && 'rgba(14, 13, 13, 0.303)'
                    }}>
                    <Container className=' w-100 h-100  '>
                        <Row className=' my-auto h-100 '>
                            <Col className=' my-auto '>
                                <div className=' text-center text-light'>
                                    <h1 className=' faq-header font-weight-bold ' >
                                        {this.props.title}
                                    </h1>
                                    {this.props.searchBar && (
                                        <Form style={{ paddingLeft: '20%', paddingRight: '20%' }} onSubmit={this.handleRecipeSearch}>

                                            <InputGroup className='pt-5 ' size="lg">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText style={{ backgroundColor: 'white' }}>  <i className="fas fa-search"></i></InputGroupText>
                                                </InputGroupAddon>
                                                <Input placeholder="Recipe Search" value={this.state.queryInput} name='queryInput' onChange={this.handleChange} />
                                            </InputGroup>
                                        </Form>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}

export default withRouter(Base)