import React, { Component } from 'react'
import { Row, Col, Button, Form, Input, Collapse, Label } from 'reactstrap'
import { connect } from 'react-redux'
import { setPaymentType, clickPay, sendPaypalPayment, fullPageLoading } from '../actions/checkOutActions'
import Stripe from './Stripe'
import visa from '../images/visa.png'
import mastercard from '../images/mastercard.png'
import { withRouter } from 'react-router-dom'
import { PayPalButton } from "react-paypal-button-v2";
import Spinner from './Spinner'



class Payment extends Component {

    state = {
        paypalPaymentAuthorized: false,
        paypalButtonLoading: true,
        windowWidth: 0,

    }
    componentDidMount() {
        this.setState({
            windowWidth: window.innerWidth,
        })
        window.addEventListener('resize', this.updateWindowDimension);


    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimension);
    }
    updateWindowDimension = (e) => {
        this.setState({
            windowWidth: window.innerWidth
        })
    }

    setPaymentType = e => {
        this.props.setPaymentType(e.target.name)
    }
    handlePayment = (e) => {
        this.props.clickPay()
    }

    onApprove = (paymentData, actions) => {
        this.setState({
            paypalPaymentAuthorized: true
        })
        this.props.fullPageLoading()
        actions.order.capture().then((details) => {
            if (this.props.checkout.paymentType === 'paypal') {
                let data = {}
                data.firstName = this.props.checkout.firstname;
                data.lastName = this.props.checkout.lastname;
                data.address = this.props.checkout.address;
                data.suburb = this.props.checkout.suburb;
                data.postcode = this.props.checkout.postcode;
                data.message = this.props.checkout.description;
                data.shippingFee = this.props.checkout.shippingFee;
                data.shippingType = this.props.checkout.shippingMethod;
                data.phoneNumber = this.props.checkout.phoneNumber;
                data.date = this.props.checkout.date;
                data.email = this.props.checkout.email
                data.pickupTime = this.props.checkout.pickupTime
                data.paymentId = details.purchase_units[0].payments.captures[0].id
                data.cart = this.props.shoppingCart.cart
                if (Object.keys(this.props.discount.userCoupon).length > 0) {
                    data.coupon = this.props.discount.userCoupon
                }

                if (this.props.auth.isAuthenticated) {
                    data.userid = this.props.auth.user.id
                }
                else if (this.props.guest.userid !== '') {
                    data.userid = this.props.guest.userid
                }
                this.props.sendPaypalPayment(data)
            }

        });
    }
    onReady = () => {
        this.setState({
            paypalButtonLoading: false
        })
    }

    render() {
        let paymentOptions = {
            clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
            currency: 'AUD'
        }
        let buttonStyle = {
            color: 'blue',
            layout: 'horizontal',
            label: 'paypal',
            shape: 'pill',
            tagline: 'false'
        }

        let subTotal = 0

        if (this.props.shoppingCart.cart.length !== 0) {
            subTotal = Math.round((this.props.shoppingCart.cart.map(eachItem => eachItem.quantity * eachItem.product.price).reduce((total, currentValue) => total + currentValue)) * 100) / 100
        }

        let total = subTotal;
        let discount;

        if (this.props.checkout.shippingFee !== null) {
            total = total + this.props.checkout.shippingFee
        }

        if (Object.keys(this.props.discount.userCoupon).length > 0) {
            if (subTotal >= this.props.discount.userCoupon.minimumSpend) {
                if (this.props.discount.userCoupon.isPercent) {
                    discount = (subTotal / 100) * this.props.discount.userCoupon.amount
                } else {
                    discount = this.props.discount.userCoupon.amount
                }
            }
        }

        if (discount !== undefined) {
            total = total - discount
        }
        if (total < 0) {
            total = 0
        }


        return (
            <div>
                <div className=' mx-4'>
                    <Row className=''>
                        <Col className=''>
                            <table className="table border">
                                <tbody >
                                    <tr>
                                        <td>Contact</td>
                                        <td>   {this.props.checkout.email}</td>
                                    </tr>
                                    {this.props.checkout.shippingMethod === 'delivery' && (
                                        <tr>
                                            <td>  Ship To</td>
                                            <td>      <span>{this.props.checkout.address}&nbsp;
                                    {this.props.checkout.suburb}&nbsp;
                                    {this.props.checkout.postcode}&nbsp;
                                    {this.props.checkout.region}&nbsp;
                                </span></td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td>Method</td>
                                        <td>     <span>
                                            {this.props.checkout.shippingMethod === 'pickup' && 'Pickup'}
                                            {this.props.checkout.shippingMethod === 'freedelivery' && ' FREE Standard Shipping'}
                                            {this.props.checkout.shippingMethod === 'delivery' && 'Standard Shipping'}
                                        </span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </div>
                <div className='mx-3 py-3'>
                    <h5 className='mx-2 pt-3 text-muted font-weight-bold'>Payment</h5>
                    <p className='mx-2'>All transactions are secure and encrypted.</p>
                    <div className='border '>
                        <Row className={`mx-3 `}>
                            <Col xs='12' className='border-bottom py-3'>
                                <Form className='d-inline pr-3'>
                                    <Input type='radio' name='credit-card' checked={this.props.checkout.paymentType === 'credit-card'} onChange={this.setPaymentType} />
                                </Form>
                                Credit Card
                                    <span className='float-right text-muted' style={{ cursor: 'default' }}>
                                    <img src={visa} alt='visa payment' style={{ height: 15 }} />
                                    <img src={mastercard} alt='visa payment' style={{ height: 20 }} />
                                    and more..
                                        </span>
                            </Col>
                            <Col xs='12'>
                                <Collapse isOpen={this.props.checkout.paymentType === 'credit-card'}>
                                    <Row className='py-3 border-bottom'>
                                        <Col>
                                            <Stripe />
                                        </Col>
                                    </Row>
                                </Collapse>
                            </Col>
                        </Row>
                    </div>
                </div>


                <div>
                    <Row className='my-3'>
                        <Col>
                            <Button
                                disabled={this.props.checkout.buttonLoading}
                                size='sm' color='dark' outline className='border-0 font-weight-bold' onClick={() => this.props.previousStep()}>&lt;  User Details</Button>
                        </Col>
                        <Col className='text-right' style={{ marginRight: this.state.windowWidth < 500 && 70 }}>
                            <div className='mx-3'>
                                {(this.props.checkout.paymentType === 'credit-card' || this.props.checkout.paymentType === null) && (
                                    <Button
                                        size='sm'
                                        disabled={this.props.checkout.paymentType !== 'paypal' && ((this.props.checkout.nameOnCard === '') ||
                                            (this.props.checkout.buttonLoading) ||
                                            (this.props.checkout.cardNumber === null || !this.props.checkout.cardNumber.complete) ||
                                            (this.props.checkout.cardExpiry === null || !this.props.checkout.cardExpiry.complete) ||
                                            (this.props.checkout.cardCvc === null || !this.props.checkout.cardCvc.complete))}
                                        onClick={this.handlePayment}
                                        color='success' style={{ minWidth: 182 }}>
                                        <span>
                                            Pay Now &gt;</span>
                                    </Button>
                                )}
                            </div>
                        </Col>
                    </Row>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    checkout: state.checkout,
    shoppingCart: state.cart,
    guest: state.guest,
    auth: state.form,
    discount: state.discount


})



export default connect(mapStateToProps, { setPaymentType, clickPay, sendPaypalPayment, fullPageLoading })(withRouter(Payment))