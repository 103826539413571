import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {
    Row, Col, Form, Input, Badge
} from 'reactstrap'
import { getUserList, updatedUsers, updatedUserCount } from '../../actions/adminUserDash'
import { Helmet } from "react-helmet";
import queryString from 'query-string'


class UserDashboard extends Component {


    state = {
        searchQuery: this.props.location.search,
        filter: '',
        queryInput: ''
    }

    scrollToMyRef = () => window.scrollTo(0, 0)

    componentWillUnmount() {
        this.channel.unbind()
    }


    componentDidMount() {

    

        const parsed = queryString.parse(this.props.location.search);
        let page = 1;
        let filter;
        let query;

        if (parsed.page) {
            page = parsed.page
        }
        if (parsed.filter) {
            filter = parsed.filter
            this.setState({
                filter
            })
        }

        if (parsed.query) {
            query = parsed.query
            this.setState({
                queryInput: ''
            })
        }

        this.props.getUserList(page, filter, query)
    }

    handleInputChange = e => {
        this.setState({
            queryInput: e.target.value
        })
    }


    handleSubmit = e => {
        e.preventDefault()
        const parsed = queryString.parse(this.props.location.search)
        parsed.query = this.state.queryInput

        if (parsed.page) {
            parsed.page = undefined
        }
        let stringified = queryString.stringify(parsed)


        this.setState({
            queryInput: '',
            searchQuery: '?' + stringified
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const parsed = queryString.parse(this.props.location.search);

        if (this.state.searchQuery !== prevState.searchQuery) {
            this.props.history.push(`/adminpanel/users${this.state.searchQuery}`)
        }

        if (this.props.location.search !== prevProps.location.search) {
            let page;
            let filter;
            let query;


            if (parsed.page) {
                page = parsed.page
            }
            if (parsed.filter) {
                filter = parsed.filter
            }
            if (parsed.query) {
                query = parsed.query
            }

            this.scrollToMyRef()
            this.props.getUserList(page, filter, query)
        }
    }

    previousPage = (e) => {
        const parsed = queryString.parse(this.props.location.search);
        parsed.page = parseInt(parsed.page) - 1

        let stringified = queryString.stringify(parsed);

        this.setState({
            searchQuery: '?' + stringified,
        })

    }


    nextPage = e => {
        const parsed = queryString.parse(this.props.location.search);
        if (parsed.page) {
            parsed.page = parseInt(parsed.page) + 1
        } else {
            parsed.page = 2
        }

        let stringified = queryString.stringify(parsed);

        this.setState({
            searchQuery: '?' + stringified,
        })
    }

    handlePageChange = (i) => {
        const parsed = queryString.parse(this.props.location.search);
        parsed.page = i + 1

        let stringified = queryString.stringify(parsed);

        this.setState({
            searchQuery: '?' + stringified,
        })
    }


    handleReset = e => {
        this.setState({
            searchQuery: ''
        })
    }

    ucFirstAllWords = (str) => {
        var pieces = str.split(" ");
        for (var i = 0; i < pieces.length; i++) {
            var j = pieces[i].charAt(0).toUpperCase();
            pieces[i] = j + pieces[i].substr(1);
        }
        return pieces.join(" ");
    }


    handleUserClick = user => {
        this.props.history.push({
            pathname: `/adminpanel/users/${user.firstName}${user.lastName}/${user._id}`,
            state: { user }
        })
    }

    render() {

        const parsed = queryString.parse(this.props.location.search)
        let noResultWarning;

        if (parsed.query && this.props.users.userCount === 0) {
            noResultWarning = true
        }

        let end;
        let totalItemNumbers = this.props.users.userCount
        let currentPage;
        if (parsed.page) {
            if (parsed.page > [...Array(Math.ceil(this.props.users.userCount / 24))].length) {
                currentPage = [...Array(Math.ceil(this.props.users.userCount / 24))].length
            }
            else if (parsed.page <= 1) {
                currentPage = 1

            }
            else {
                currentPage = parsed.page
            }
        } else {
            currentPage = 1
        }

        if (24 < totalItemNumbers) {
            end = 24 * currentPage
            if (end > totalItemNumbers) {
                end = totalItemNumbers
            }
        } else {
            end = totalItemNumbers
        }

        return (
            <div className='mb-3'>
                <div>
                    <Helmet>
                        <title>Users - Frozberries Victoria</title>
                        <meta name="description" content="Users - Frozberries Victoria" />
                    </Helmet>
                </div>
                {this.props.users.loading ? (
                    <div className='text-center text-light my-5'>
                        <h5>Please wait. Users are being fetched.</h5>
                    </div>
                ) : (
                        <React.Fragment>
                            <div >
                                <Row className='my-3'>
                                    <Col>
                                        <Form onSubmit={this.handleSubmit}>
                                            <Input placeholder='Search User' value={this.state.queryInput} onChange={this.handleInputChange} />
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                            <div className='mx-2'>
                                {parsed.query && (
                                    <h5 className='d-inline pr-3' style={{ cursor: 'pointer' }} onClick={this.handleReset}> <Badge color="danger">
                                        {parsed.query}
                                        <span className='ml-2' style={{ fontSize: '1.2rem' }}>
                                            &times;
                                                     </span></Badge></h5>
                                )}
                            </div>

                            {noResultWarning ? (
                                <div className='text-center text-light my-5'>
                                    <h5>No results found.</h5>
                                </div>
                            ) : (
                                    <React.Fragment>
                                        <div>
                                            {(this.props.users.users.length !== 0) && (
                                                <h1 className='float-right text-light' style={{ fontSize: '0.9rem' }}>
                                                    Showing {queryString.parse(this.props.location.search).page ?
                                                        (queryString.parse(this.props.location.search).page <= 1 ? 1 :
                                                            (queryString.parse(this.props.location.search).page > [...Array(Math.ceil(this.props.users.userCount / 24))].length ?
                                                                (([...Array(Math.ceil(this.props.users.userCount / 24))].length - 1) * 25 - 1)
                                                                : ((parseInt(queryString.parse(this.props.location.search).page) - 1) * (24) + 1)))
                                                        : 1} – {end} of {this.props.users.userCount} results</h1>
                                            )}
                                        </div>

                                        <div className='bg-light mt-5' style={{ borderRadius: 10 }}>
                                            <Row className='w-100'>
                                                <Col >
                                                    <div className='table-responsive'>
                                                        <table className="table">
                                                            <thead className='table-borderless'>
                                                                <tr>
                                                                    <th scope="col">NAME</th>
                                                                    <th scope="col">EMAIL</th>
                                                                    <th scope="col">PHONE NUMBER</th>
                                                                    <th scope='col'>MEMBER SINCE</th>
                                                                    <th scope='col'>TOTAL ORDERS</th>
                                                                    <th scope='col'>STATUS</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody >
                                                                {this.props.users.users.map(item => (
                                                                    <tr onClick={() => this.handleUserClick(item)} key={item._id} className='admin-each-product' style={{ cursor: 'pointer', color: (item.isAdmin ? 'green' : (!item.registeredUser && 'red')) }}>
                                                                        <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{this.ucFirstAllWords(item.firstName)} {this.ucFirstAllWords(item.lastName)}</td>
                                                                        <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{item.email}</td>
                                                                        <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{item.phoneNumber}</td>
                                                                        <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{moment(item.dateCreated).format("MMM Do YY")}</td>
                                                                        <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'> {item.orders.length}</td>
                                                                        <td style={{ verticalAlign: 'middle' }} className={item.isActive ? `font-weight-bold` : `font-weight-bold text-danger`}> {item.isActive ? 'Active' : 'Not Active'}</td>

                                                                    </tr>
                                                                ))}

                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </Col>
                                            </Row>

                                            {[...Array(Math.ceil(this.props.users.userCount / 24))].length > 1 &&
                                                (this.props.users.users.length !== 0) && (
                                                    <Row className='text-center mt-5'>
                                                        <Col className='text-center '>

                                                            <nav aria-label="Page navigation example">
                                                                <ul className="pagination justify-content-center">
                                                                    <li className={(!queryString.parse(this.props.location.search).page ||
                                                                        queryString.parse(this.props.location.search).page <= 1) ?
                                                                        'disabled page-item ' : `page-item`}>
                                                                        <span className="page-link" style={{ color: 'rgb(235,24,79)', cursor: 'pointer' }}
                                                                            onClick={this.previousPage}>Previous</span>
                                                                    </li>
                                                                    {[...Array(Math.ceil(this.props.users.userCount / 24))].map((page, i) => (
                                                                        <li key={i} className={(!queryString.parse(this.props.location.search).page && (i + 1 === 1)) ? 'active page-item'
                                                                            : (queryString.parse(this.props.location.search).page <= 1 && (i + 1 === 1) ? 'active page-item' :
                                                                                (queryString.parse(this.props.location.search).page >= 2 && (i + 1 === parseInt(queryString.parse(this.props.location.search).page)) ? 'active page-item'
                                                                                    : ((queryString.parse(this.props.location.search).page > [...Array(Math.ceil(this.props.users.userCount / 24))].length &&
                                                                                        (i + 1 === [...Array(Math.ceil(this.props.users.userCount / 24))].length)) ? 'active page-item' : 'page-item')))}>
                                                                            <span className="page-link" style={{ color: 'rgb(235,24,79)', cursor: 'pointer' }}
                                                                                onClick={() => this.handlePageChange(i)} >  {i + 1}</span></li>
                                                                    ))}

                                                                    <li className={(queryString.parse(this.props.location.search).page >= [...Array(Math.ceil(this.props.users.userCount / 24))].length ?
                                                                        'disabled page-item' : "page-item")}>
                                                                        <span style={{ color: 'rgb(235,24,79)', cursor: 'pointer' }} className="page-link"
                                                                            onClick={this.nextPage}>Next</span>
                                                                    </li>
                                                                </ul>
                                                            </nav>
                                                        </Col>

                                                    </Row>
                                                )}


                                        </div>

                                    </React.Fragment>


                                )}
                        </React.Fragment>
                    )}
            </div>
        )
    }
}


const mapStateToProps = state => ({
    users: state.users
})


export default connect(mapStateToProps, { getUserList, updatedUsers, updatedUserCount })(UserDashboard)
