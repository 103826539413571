import React, { Component } from 'react'
import { Helmet } from "react-helmet";
import { connect } from 'react-redux'
import queryString from 'query-string'
import { getOrderList } from '../../actions/adminOrdersDash'
import {
    Row, Col, Form, Input, Dropdown, DropdownItem,
    DropdownToggle, DropdownMenu, Button, Badge
} from 'reactstrap'
import moment from 'moment'


class OrdersDashboard extends Component {

    state = {
        searchQuery: this.props.location.search,
        filter: '',
        queryInput: '',
        paymentTypeDropdown: false,
        shippingTypeDropdown: false,
        dateDropdown: false,
        orderStatusDropdown: false,
        orderStatus: '',
        paymentType: '',
        shippingType: ''
    }
    setFilter = (value) => {
        const parsed = queryString.parse(this.props.location.search)
        if (parsed.page) {
            parsed.page = 1
        }
        parsed.filter = value

        let stringified = queryString.stringify(parsed)

        this.setState({
            filter: value,
            searchQuery: '?' + stringified
        })
    }

    shippingType = value => {
        const parsed = queryString.parse(this.props.location.search)

        if (value !== '') {
            parsed.shippingType = value
        } else {
            parsed.shippingType = undefined
        }

        let stringified = queryString.stringify(parsed)
        this.setState({
            shippingType: value,
            searchQuery: '?' + stringified
        })
    }

    paymentType = value => {
        const parsed = queryString.parse(this.props.location.search)

        if (value !== '') {
            parsed.paymentType = value
        } else {
            parsed.paymentType = undefined
        }

        let stringified = queryString.stringify(parsed)
        this.setState({
            paymentType: value,
            searchQuery: '?' + stringified
        })

    }

    orderStatus = value => {
        const parsed = queryString.parse(this.props.location.search)

        if (value !== '') {
            parsed.orderStatus = value
        } else {
            parsed.orderStatus = undefined
        }

        let stringified = queryString.stringify(parsed)
        this.setState({
            orderStatus: value,
            searchQuery: '?' + stringified
        })

    }

    handleReset = e => {
        const parsed = queryString.parse(this.props.location.search)

        if (parsed.page) {
            parsed.page = 1
        }
        parsed.query = undefined
        parsed.orderStatus = undefined
        parsed.filter = undefined
        parsed.paymentType = undefined
        parsed.shippingType = undefined
        let stringified = queryString.stringify(parsed)

        this.setState({
            queryInput: '',
            searchQuery: '?' + stringified,
        })
    }

    paymentTypeDropdownToggle = e => {
        this.setState({
            paymentTypeDropdown: !this.state.paymentTypeDropdown
        })
    }
    shippingTypeDropdownToggle = e => {
        this.setState({
            shippingTypeDropdown: !this.state.shippingTypeDropdown
        })
    }
    dateDropdownToggle = e => {
        this.setState({
            dateDropdown: !this.state.dateDropdown
        })
    }
    orderStatusDropdownToggle = e => {
        this.setState({
            orderStatusDropdown: !this.state.orderStatusDropdown
        })
    }


    scrollToMyRef = () => window.scrollTo(0, 0)


    componentDidMount() {

        const parsed = queryString.parse(this.props.location.search);
        let page = 1;
        let filter;
        let query;
        let orderStatus;
        let paymentType;
        let shippingType;

        if (parsed.paymentType) {
            paymentType = parsed.paymentType
            this.setState({
                paymentType
            })
        }

        if (parsed.orderStatus) {
            orderStatus = parsed.orderStatus
            this.setState({
                orderStatus
            })
        }
        if (parsed.shippingType) {
            shippingType = parsed.shippingType
            this.setState({
                shippingType
            })
        }

        if (parsed.page) {
            page = parsed.page
        }

        if (parsed.filter) {
            filter = parsed.filter
            this.setState({
                filter
            })
        }

        if (parsed.query) {
            query = parsed.query
            this.setState({
                queryInput: ''
            })
        }

        this.props.getOrderList(page, filter, query, orderStatus, paymentType, shippingType)
    }

    handleInputChange = e => {
        this.setState({
            queryInput: e.target.value
        })
    }


    handleSubmit = e => {
        e.preventDefault()
        const parsed = queryString.parse(this.props.location.search)
        parsed.query = this.state.queryInput

        if (parsed.page) {
            parsed.page = undefined
        }
        let stringified = queryString.stringify(parsed)


        this.setState({
            queryInput: '',
            searchQuery: '?' + stringified
        })
    }


    componentDidUpdate(prevProps, prevState) {
        const parsed = queryString.parse(this.props.location.search);

        if (this.state.searchQuery !== prevState.searchQuery) {
            this.props.history.push(`/adminpanel/orders${this.state.searchQuery}`)
        }

        if (this.props.location.search !== prevProps.location.search) {
            let page;
            let filter;
            let query;
            let orderStatus;
            let paymentType;
            let shippingType;

            if (parsed.shippingType) {
                shippingType = parsed.shippingType
                this.setState({
                    shippingType
                })
            } else {
                this.setState({
                    shippingType: ''
                })
            }


            if (parsed.paymentType) {
                paymentType = parsed.paymentType
                this.setState({
                    paymentType
                })
            } else {
                this.setState({
                    paymentType: ''
                })
            }

            if (parsed.orderStatus) {
                orderStatus = parsed.orderStatus
                this.setState({
                    orderStatus
                })
            } else {
                this.setState({
                    orderStatus: ''
                })
            }

            if (parsed.page) {
                page = parsed.page
            }

            if (parsed.filter) {
                filter = parsed.filter
                this.setState({
                    filter
                })
            } else {
                this.setState({
                    filter: ''
                })
            }

            if (parsed.query) {
                query = parsed.query
            }

            let stringified = queryString.stringify(parsed);

            this.setState({
                searchQuery: '?' + stringified,

            })

            this.scrollToMyRef()
            this.props.getOrderList(page, filter, query, orderStatus, paymentType, shippingType)
        }
    }

    previousPage = (e) => {
        const parsed = queryString.parse(this.props.location.search);
        parsed.page = parseInt(parsed.page) - 1

        let stringified = queryString.stringify(parsed);

        this.setState({
            searchQuery: '?' + stringified,
        })

    }


    nextPage = e => {
        const parsed = queryString.parse(this.props.location.search);
        if (parsed.page) {
            parsed.page = parseInt(parsed.page) + 1
        } else {
            parsed.page = 2
        }

        let stringified = queryString.stringify(parsed);

        this.setState({
            searchQuery: '?' + stringified,
        })
    }

    handlePageChange = (i) => {
        const parsed = queryString.parse(this.props.location.search);
        parsed.page = i + 1

        let stringified = queryString.stringify(parsed);

        this.setState({
            searchQuery: '?' + stringified,
        })
    }


    ucFirstAllWords = (str) => {
        var pieces = str.split(" ");
        for (var i = 0; i < pieces.length; i++) {
            var j = pieces[i].charAt(0).toUpperCase();
            pieces[i] = j + pieces[i].substr(1);
        }
        return pieces.join(" ");
    }

    routeToOrder = order => {
        this.props.history.push({
            pathname: `/adminpanel/orders/${order._id}`,
            state: { order }
        })
    }



    render() {

        const parsed = queryString.parse(this.props.location.search)
        let noResultWarning;

        if (this.props.adminOrders.orderCount === 0) {
            noResultWarning = true
        }

        let end;
        let totalItemNumbers = this.props.adminOrders.orderCount
        let currentPage;
        if (parsed.page) {
            if (parsed.page > [...Array(Math.ceil(this.props.adminOrders.orderCount / 24))].length) {
                currentPage = [...Array(Math.ceil(this.props.adminOrders.orderCount / 24))].length
            }
            else if (parsed.page <= 1) {
                currentPage = 1

            }
            else {
                currentPage = parsed.page
            }
        } else {
            currentPage = 1
        }

        if (24 < totalItemNumbers) {
            end = 24 * currentPage
            if (end > totalItemNumbers) {
                end = totalItemNumbers
            }
        } else {
            end = totalItemNumbers
        }


        return (
            <div>
                <div>
                    <Helmet>
                        <title>Orders - Frozberries Victoria</title>
                        <meta name="description" content="Orders - Frozberries Victoria" />
                    </Helmet>
                </div>
                {this.props.adminOrders.loading ? (
                    <div className='text-center text-light my-5'>
                        <h5>Please wait. Orders are being fetched.</h5>
                    </div>
                ) : (
                        <React.Fragment>
                            <div >
                                <Row className='my-3'>
                                    <Col>
                                        <Form onSubmit={this.handleSubmit}>
                                            <Input placeholder='Search Order' value={this.state.queryInput} onChange={this.handleInputChange} />
                                        </Form>
                                    </Col>
                                </Row>

                            </div>
                            <div >
                                {parsed.query && (
                                    <h5 className='d-inline pr-3' style={{ cursor: 'pointer' }} onClick={this.handleReset}> <Badge color="danger">
                                        {parsed.query}
                                        <span className='pl-2' style={{ fontSize: '1.2rem' }}>
                                            &times;
                                                     </span></Badge></h5>
                                )}
                            </div>


                            <React.Fragment>
                                <div className='mt-2'>
                                    <h4 className='text-light'>
                                        Filter by
                                            </h4>

                                    <div className='my-3'>
                                        <Dropdown className='btn-sm  ml-auto d-inline border-0' isOpen={this.state.dateDropdown} toggle={this.dateDropdownToggle}>
                                            <DropdownToggle caret className=' bg-light text-dark'>

                                                {((this.state.filter === 'newest' && 'Newest') || (this.state.filter === 'oldest' && 'Oldest')) || 'Date'}

                                            </DropdownToggle>
                                            <DropdownMenu >
                                                <DropdownItem onClick={() => this.setFilter('newest')}>Newest</DropdownItem>
                                                <DropdownItem onClick={() => this.setFilter('oldest')}>Oldest</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>

                                        <Dropdown className='btn-sm  ml-auto d-inline border-0' isOpen={this.state.orderStatusDropdown} toggle={this.orderStatusDropdownToggle}>
                                            <DropdownToggle caret className=' bg-light text-dark'>

                                                {((this.state.orderStatus === 'Confirmed' && 'Confirmed ') || (this.state.orderStatus === 'Processing' && 'Processing')
                                                    ||
                                                    (this.state.orderStatus === 'Cancelled' && 'Cancelled')
                                                ) || 'Order Status'}

                                            </DropdownToggle>
                                            <DropdownMenu >
                                                {this.state.orderStatus !== '' && (
                                                    <DropdownItem onClick={() => this.orderStatus('')}>All</DropdownItem>
                                                )}
                                                <DropdownItem onClick={() => this.orderStatus('Confirmed')}>Confirmed</DropdownItem>
                                                <DropdownItem onClick={() => this.orderStatus('Processing')}>Processing</DropdownItem>
                                                <DropdownItem onClick={() => this.orderStatus('Cancelled')}>Cancelled</DropdownItem>

                                            </DropdownMenu>
                                        </Dropdown>

                                        <Dropdown className='btn-sm  ml-auto d-inline border-0' isOpen={this.state.paymentTypeDropdown} toggle={this.paymentTypeDropdownToggle}>
                                            <DropdownToggle caret className=' bg-light text-dark'>
                                                {((this.state.paymentType === 'stripe' && 'Stripe') || (this.state.paymentType === 'paypal' && 'Paypal')) || 'Payment Type'}
                                            </DropdownToggle>
                                            <DropdownMenu >
                                                {this.state.paymentType !== '' && (
                                                    <DropdownItem onClick={() => this.paymentType('')}>All</DropdownItem>
                                                )}
                                                <DropdownItem onClick={() => this.paymentType('stripe')}>Stripe</DropdownItem>
                                                <DropdownItem onClick={() => this.paymentType('paypal')}>Paypal</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>

                                        <Dropdown className='btn-sm  ml-auto d-inline border-0' isOpen={this.state.shippingTypeDropdown} toggle={this.shippingTypeDropdownToggle}>
                                            <DropdownToggle caret className=' bg-light text-dark'>
                                                {((this.state.shippingType === 'pickup' && 'Pick up') || (this.state.shippingType === 'delivery' && 'Delivery')) || 'Shipping Type'}
                                            </DropdownToggle>
                                            <DropdownMenu >
                                                {this.state.shippingType !== '' && (
                                                    <DropdownItem onClick={() => this.shippingType('')}>All</DropdownItem>
                                                )}
                                                <DropdownItem onClick={() => this.shippingType('pickup')}>Pick up</DropdownItem>
                                                <DropdownItem onClick={() => this.shippingType('delivery')}>Delivery</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>

                                        <Button color='light' className='ml-2' onClick={this.handleReset}>Reset</Button>
                                    </div>

                                </div>
                                <div>

                                    {noResultWarning ? (
                                        <div className='text-center text-light my-5'>
                                            <h5>No results found.</h5>
                                        </div>
                                    ) : (
                                            <React.Fragment>
                                                <div>
                                                    {(this.props.adminOrders.orders.length !== 0) && (
                                                        <h1 className='float-right text-light' style={{ fontSize: '0.9rem' }}>
                                                            Showing {queryString.parse(this.props.location.search).page ?
                                                                (queryString.parse(this.props.location.search).page <= 1 ? 1 :
                                                                    (queryString.parse(this.props.location.search).page > [...Array(Math.ceil(this.props.adminOrders.orderCount / 24))].length ?
                                                                        (([...Array(Math.ceil(this.props.adminOrders.orderCount / 24))].length - 1) * 25 - 1)
                                                                        : ((parseInt(queryString.parse(this.props.location.search).page) - 1) * (24) + 1)))
                                                                : 1} – {end} of {this.props.adminOrders.orderCount} results</h1>
                                                    )}
                                                </div>
                                                <div className='bg-light mt-5' style={{ borderRadius: 10 }}>
                                                    <Row className='w-100'>
                                                        <Col >
                                                            <div className='table-responsive'>
                                                                <table className="table">
                                                                    <thead className='table-borderless'>
                                                                        <tr>
                                                                            <th scope="col">ORDER ID</th>
                                                                            <th scope="col">EMAIL</th>
                                                                            <th scope="col">TIME</th>
                                                                            <th scope="col">TOTAL</th>
                                                                            <th scope='col'>SHIPPING</th>
                                                                            <th scope='col'>CUSTOMER TYPE</th>
                                                                            <th scope='col'>ORDER STATUS</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody >
                                                                        {this.props.adminOrders.orders.map(order => (
                                                                            <React.Fragment key={order._id}>
                                                                                <tr onClick={(e) => this.routeToOrder(order)} className='admin-each-product' style={{ cursor: 'pointer' }}>
                                                                                    <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{order.orderid.toUpperCase()}</td>
                                                                                    <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{order.email}</td>
                                                                                    <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{moment(order.created_at).fromNow()}</td>
                                                                                    <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>AUD {(order.total / 100).toFixed(2)}</td>
                                                                                    <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{this.ucFirstAllWords(order.shippingType)}</td>
                                                                                    <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'><h5><Badge color={(order.user.orders.length === 1 || order.user.orders.length === 0) ? 'secondary' : order.user.orders.length > 5 ? 'success' : 'primary'}>{(order.user.orders.length === 1 || order.user.orders.length === 0) ? 'New Customer' : order.user.orders.length > 5 ? 'Loyal Customer' : 'Returning Customer'}</Badge></h5> </td>
                                                                                    <td style={{ verticalAlign: 'middle' }} className={order.orderStatus === 'Processing' ? 'text-warning font-weight-bold' : (order.orderStatus === 'Confirmed' ?
                                                                                        'text-success font-weight-bold' : 'text-danger font-weight-bold')} > {order.orderStatus}</td>

                                                                                </tr>

                                                                            </React.Fragment>
                                                                        ))}

                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                        </Col>
                                                    </Row>
                                                </div>
                                                {[...Array(Math.ceil(this.props.adminOrders.orderCount / 24))].length > 1 &&
                                                    (this.props.adminOrders.orders.length !== 0) && (
                                                        <Row className='text-center mt-5'>
                                                            <Col className='text-center '>

                                                                <nav aria-label="Page navigation example">
                                                                    <ul className="pagination justify-content-center">
                                                                        <li className={(!queryString.parse(this.props.location.search).page ||
                                                                            queryString.parse(this.props.location.search).page <= 1) ?
                                                                            'disabled page-item ' : `page-item`}>
                                                                            <span className="page-link" style={{ color: 'rgb(235,24,79)', cursor: 'pointer' }}
                                                                                onClick={this.previousPage}>Previous</span>
                                                                        </li>
                                                                        {[...Array(Math.ceil(this.props.adminOrders.orderCount / 24))].map((page, i) => (
                                                                            <li key={i} className={(!queryString.parse(this.props.location.search).page && (i + 1 === 1)) ? 'active page-item'
                                                                                : (queryString.parse(this.props.location.search).page <= 1 && (i + 1 === 1) ? 'active page-item' :
                                                                                    (queryString.parse(this.props.location.search).page >= 2 && (i + 1 === parseInt(queryString.parse(this.props.location.search).page)) ? 'active page-item'
                                                                                        : ((queryString.parse(this.props.location.search).page > [...Array(Math.ceil(this.props.adminOrders.orderCount / 24))].length &&
                                                                                            (i + 1 === [...Array(Math.ceil(this.props.adminOrders.orderCount / 24))].length)) ? 'active page-item' : 'page-item')))}>
                                                                                <span className="page-link" style={{ color: 'rgb(235,24,79)', cursor: 'pointer' }}
                                                                                    onClick={() => this.handlePageChange(i)} >  {i + 1}</span></li>
                                                                        ))}

                                                                        <li className={(queryString.parse(this.props.location.search).page >= [...Array(Math.ceil(this.props.adminOrders.orderCount / 24))].length ?
                                                                            'disabled page-item' : "page-item")}>
                                                                            <span style={{ color: 'rgb(235,24,79)', cursor: 'pointer' }} className="page-link"
                                                                                onClick={this.nextPage}>Next</span>
                                                                        </li>
                                                                    </ul>
                                                                </nav>
                                                            </Col>

                                                        </Row>
                                                    )}
                                            </React.Fragment>
                                        )}






                                </div>
                            </React.Fragment>


                        </React.Fragment>
                    )}
            </div>
        )
    }
}


const mapStateToProps = state => ({
    adminOrders: state.adminOrders
})


export default connect(mapStateToProps, { getOrderList })(OrdersDashboard)
