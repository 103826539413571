import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { connect } from 'react-redux'
import { totalUserDetails } from '../../actions/adminUserActions'
import Spinner from '../Spinner'


class TotalUsers extends Component {
    componentDidMount() {
        this.props.totalUserDetails()
    }
    render() {
        let increase = 0
        if (this.props.adminUserCalc.totalUserCount !== 0) {
            increase = Math.floor(this.props.adminUserCalc.previousWeekCount / this.props.adminUserCalc.totalUserCount * 100)
        }


        return (
            <div className=' text-left bg-light px-3 py-3 my-2' style={{ borderRadius: 10 }}>
                <Row>
                    <Col xs='8' >
                        <h6 className='font-weight-bold text-secondary'>TOTAL ACTIVE USERS</h6>
                        {this.props.adminUserCalc.loading ? (
                            <div className='text-center my-auto'>
                                <Spinner />

                            </div>
                        ) : (
                                <React.Fragment>
                                    <h5 className='font-weight-bold text-dark'>{this.props.adminUserCalc.totalUserCount}</h5>
                                    <p className={increase > 0 ? 'text-success' : increase < 0 ? 'text-danger' : ''}>
                                        {increase > 0 ?
                                            <i className="fas fa-arrow-up mr-1"></i>
                                            : increase < 0 ?
                                                <i className="fas fa-arrow-down mr-1"></i> :
                                                <i className="fas fa-square mr-1 "></i>
                                        }
                                        {increase}% <span className='pl-2 text-secondary'>Since last week</span></p>
                                </React.Fragment>
                            )}

                    </Col>
                    <Col xs='4' className='my-3  text-center'>
                        <div className='rounded-circle text-white shadow px-3 py-3 bg-warning' style={{ width: '3em', height: '3em', alignItems: 'center', justifyContent: 'center', display: 'inline-flex', textAlign: 'center' }}>
                            <i className="fas fa-user-friends   " style={{ fontSize: '1.25em' }}></i>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.form,
    adminUserCalc: state.adminUserCalc

})


export default connect(mapStateToProps, { totalUserDetails })(TotalUsers)