import React, { Component } from 'react'
import {
    InputGroup, Input,
    Form, Label, Button, FormGroup, Row, Col
} from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { guestUser, updateInputs, updateRadioBox, verifyUser, setShippingMethod } from '../actions/checkOutActions'
import { connect } from 'react-redux'
import classnames from 'classnames'
import ButtonSpinner from './ButtonSpinner'
import { logoutUser } from '../actions/formActions';


class UserInfo extends Component {

    state = {
        newAddress: true,
        windowWidth: 0,
    }

    componentDidMount() {

        this.setState({
            windowWidth: window.innerWidth,
        })
        window.addEventListener('resize', this.updateWindowDimension);


        if (this.props.auth.isAuthenticated) {
            if (this.props.auth.user.firstName) {
                let firstName = 'firstname'
                this.props.updateInputs(firstName, this.props.auth.user.firstName)
            }
            if (this.props.auth.user.lastName) {
                let lastName = 'lastname'
                this.props.updateInputs(lastName, this.props.auth.user.lastName)
            }
            if (this.props.auth.user.email) {
                let email = 'email'
                this.props.updateInputs(email, this.props.auth.user.email)
            }
            if (this.props.auth.user.adress.length !== 0) {
                let defaultAddress = this.props.auth.user.adress.filter(eachAdress => eachAdress.addressType === 'default')

                let address = defaultAddress[0].streetAdress
                let suburb = defaultAddress[0].suburb
                let postcode = defaultAddress[0].zipCode
                this.props.updateInputs('address', address)
                this.props.updateInputs('suburb', suburb)
                this.props.updateInputs('postcode', postcode)

                this.setState({
                    newAddress: false
                })
            }
            if (this.props.auth.user.phoneNumber) {
                let phoneNumber = 'phoneNumber'
                this.props.updateInputs(phoneNumber, this.props.auth.user.phoneNumber.replace(/\s/g, ''))
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.checkout.buttonLoading !== prevProps.checkout.buttonLoading) {
            if (!this.props.checkout.buttonLoading && Object.keys(this.props.checkout.errors).length === 0) {
                this.props.nextStep()
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimension);
    }
    updateWindowDimension = (e) => {
        this.setState({
            windowWidth: window.innerWidth
        })
    }
    onChange = (e) => {
        var field = e.target.name;
        var inputValue = e.target.value
        this.props.updateInputs(field, inputValue)
        if (!this.state.newAddress) {
            this.setState({
                newAddress: true
            })
        }

    }

    nextStep = (e) => {
        e.preventDefault()

        if (!this.props.auth.isAuthenticated) {
            var guestUser = {
                email: this.props.checkout.email,
                firstname: this.props.checkout.firstname,
                lastname: this.props.checkout.lastname,
                sendOffers: this.props.checkout.sendOffers,
                zipCode: this.props.checkout.postcode,
                cart: this.props.shoppingCart.cart,
                guestUser: this.props.checkout.guestUser,
                streetAdress: this.props.checkout.address,
                suburb: this.props.checkout.suburb,
                shippingMethod: this.props.checkout.shippingMethod,
                phoneNumber: this.props.checkout.phoneNumber.replace(/\s/g, '')
            }
            this.props.guestUser(guestUser)
        }
        else {
            var userData = {
                user: this.props.auth.user.id,
                zipCode: this.props.checkout.postcode,
                streetAdress: this.props.checkout.address,
                suburb: this.props.checkout.suburb,
                email: this.props.checkout.email,
                firstname: this.props.checkout.firstname,
                lastname: this.props.checkout.lastname,
                newAddress: this.state.newAddress,
                shippingMethod: this.props.checkout.shippingMethod,
                phoneNumber: this.props.checkout.phoneNumber.replace(/\s/g, '')

            }

            this.props.verifyUser(userData)
        }

    }

    handleRadioBox = (e) => {
        this.props.updateRadioBox(!this.props.checkout.sendOffers)
    }
    handleSavedAdress = (e) => {
        let val = e.target.value

        if (val === 'new address') {
            this.props.updateInputs('address', '')
            this.props.updateInputs('suburb', '')
            this.props.updateInputs('postcode', '')
            if (!this.state.newAddress) {
                this.setState({
                    newAddress: true
                })
            }
        }
        else {
            let selectedAddress = this.props.auth.user.adress.filter(eachAdress => eachAdress._id === val)
            let address = selectedAddress[0].streetAdress
            let suburb = selectedAddress[0].suburb
            let postcode = selectedAddress[0].zipCode
            this.props.updateInputs('address', address)
            this.props.updateInputs('suburb', suburb)
            this.props.updateInputs('postcode', postcode)

            if (this.state.newAddress) {
                this.setState({
                    newAddress: false
                })
            }
        }

    }

    onLogoutClick = () => {
        this.props.logoutUser()
        this.props.history.push('/login')
    }

    goBackToPrevious = (e) => {
        this.props.previousStep()
    }


    render() {
        return (
            <div className='container py-4' style={{ maxWidth: 650 }}>


                <div className=''>
                    <h5 className='text-left ' style={{ fontSize: '1.28571em' }} >Contact Information
                    {!this.props.auth.isAuthenticated && (
                            <span className='float-right ' style={{ fontSize: '14px', paddingTop: 5 }}>
                                <a href='/login' className='text-muted'> Already have an account? Log in</a></span>
                        )}
                    </h5>
                    {!this.props.auth.isAuthenticated ? (
                        <Form className='pt-2'>
                            <InputGroup>
                                <Input
                                    className={classnames('', { 'is-invalid': this.props.checkout.errors.email })}
                                    placeholder='Email*' type='email' onChange={this.onChange} value={this.props.checkout.email} name='email' />
                                <div className='invalid-feedback'>{this.props.checkout.errors.email}</div>

                            </InputGroup>

                            <InputGroup className='pl-4 pt-2'>
                                <Label check>
                                    <Input type="checkbox" id="offers" onClick={this.handleRadioBox} />
                                    Keep me up to date on news and exclusive offers
                                 </Label>
                            </InputGroup>
                        </Form>
                    ) : (
                            <div className='pt-2'>
                                <div style={{ display: 'table-cell' }}>
                                    <i className="far fa-user fa-2x  p-3 text-muted" style={{ borderRadius: 15, border: '2px solid' }}></i>
                                </div>
                                <p style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                                    <span className='px-2'>{this.props.auth.user.firstName} {this.props.auth.user.lastName}</span>
                                    <span>({this.props.auth.user.email})</span>
                                    <br />
                                    <a href='#' style={{ cursor: 'pointer' }} onClick={this.onLogoutClick} className='px-2 checkout-logout'>Log out</a>
                                </p>
                            </div>
                        )}
                </div>

                <div className='mt-3'>
                    {this.props.checkout.shippingMethod === 'delivery' && (
                        <h5 className='text-left' style={{ fontSize: '1.28571em' }}>Shipping Address</h5>

                    )}
                    {this.props.checkout.shippingMethod === 'pickup' && (
                        <h5 className='text-left' style={{ fontSize: '1.28571em' }}>Pick-up Details</h5>

                    )}
                    <Form className='pt-3'>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Input
                                        className={classnames('', { 'is-invalid': this.props.checkout.errors.firstname })}
                                        placeholder='First Name*' type='text' onChange={this.onChange} value={this.props.checkout.firstname} name='firstname' />
                                    <div className='invalid-feedback'>{this.props.checkout.errors.firstname}</div>

                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Input
                                        className={classnames('', { 'is-invalid': this.props.checkout.errors.lastname })}
                                        placeholder='Last Name*' type='text' onChange={this.onChange} value={this.props.checkout.lastname} name='lastname' />
                                    <div className='invalid-feedback'>{this.props.checkout.errors.lastname}</div>

                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Input
                                        className={classnames('', { 'is-invalid': this.props.checkout.errors.phoneNumber })}
                                        placeholder='Phone Number*' type='text' onChange={this.onChange} value={this.props.checkout.phoneNumber} name='phoneNumber' />
                                    <div className='invalid-feedback'>{this.props.checkout.errors.phoneNumber}</div>

                                </FormGroup>
                            </Col>
                        </Row>
                        {this.props.auth.isAuthenticated && this.props.auth.user.adress.length !== 0 && this.props.checkout.shippingMethod === 'delivery' && (
                            <FormGroup>
                                <Label for="saved-addresses">Saved Addresses</Label>
                                <Input type="select" name="saved-addresses" id="saved-addresses" onChange={this.handleSavedAdress}>
                                    {this.props.auth.user.adress.map(eachAdress => (
                                        <option value={eachAdress._id}
                                            key={eachAdress._id}
                                        >
                                            {eachAdress.streetAdress} {eachAdress.suburb} {eachAdress.zipCode} Victoria</option>
                                    ))}
                                    <option value='new address'>Use a new address</option>
                                </Input>
                            </FormGroup>
                        )}

                        {this.props.checkout.shippingMethod === 'delivery' && (
                            <React.Fragment>
                                <FormGroup>
                                    <Input
                                        disabled={!this.state.newAddress}
                                        placeholder='Address*' type='text' onChange={this.onChange} value={this.props.checkout.address} name='address' />
                                </FormGroup>

                                <FormGroup>
                                    <Input
                                        disabled={!this.state.newAddress}
                                        placeholder='Suburb*' type='text' onChange={this.onChange} value={this.props.checkout.suburb} name='suburb' />
                                </FormGroup>

                                <Row form>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Input
                                                placeholder='State*' type='text' onChange={this.onChange} value='Victoria' disabled={true} name='region' />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Input
                                                disabled={!this.state.newAddress}
                                                className={classnames('', { 'is-invalid': this.props.checkout.errors.postcode })}
                                                placeholder='Postal Code*' type='text' onChange={this.onChange} value={this.props.checkout.postcode} name='postcode' />
                                            <div className='invalid-feedback'>{this.props.checkout.errors.postcode}</div>
                                        </FormGroup>
                                    </Col>

                                </Row>
                            </React.Fragment>
                        )}
                        <FormGroup>
                            <Input type="textarea" rows='8' placeholder='Please add any notes regarding you order' onChange={this.onChange} value={this.props.checkout.description} name='description' />
                        </FormGroup>
                    </Form>
                    <Row>
                        <Col>
                            <Button
                                disabled={this.props.checkout.buttonLoading}
                                size='sm' color='dark' outline className='border-0 font-weight-bold' onClick={this.goBackToPrevious}>&lt;  Shipping</Button>
                        </Col>
                        <Col className='text-right ' style={{ marginRight: this.state.windowWidth < 500 && 50 }} >
                            <Button
                                size='sm'
                                disabled={this.props.checkout.shippingMethod === 'delivery' ? (this.props.checkout.buttonLoading || this.props.checkout.email === '' || this.props.checkout.firstname === '' ||
                                    this.props.checkout.lastname === '' || this.props.checkout.address === '' || this.props.checkout.suburb === ''
                                    || this.props.checkout.postcode === '' || this.props.checkout.phoneNumber === '') : (this.props.checkout.shippingMethod === 'pickup' && (this.props.checkout.email === '' || this.props.checkout.firstname === '' ||
                                        this.props.checkout.lastname === '' || this.props.checkout.phoneNumber === ''))}

                                color='success' onClick={this.nextStep} style={{ minWidth: 182, }}>
                                {this.props.checkout.buttonLoading ? <ButtonSpinner /> :
                                    <span>   Continue to Payment &gt;</span>
                                }

                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    checkout: state.checkout,
    shoppingCart: state.cart,
    guest: state.guest,
    auth: state.form


})



export default connect(mapStateToProps, {
    logoutUser,
    guestUser, updateInputs, updateRadioBox, verifyUser,
    setShippingMethod
})(withRouter(UserInfo))