import React, { Component } from 'react'
import classnames from 'classnames'
import { Helmet } from "react-helmet";
import { Row, Col, Form, Input, Label, Button, FormGroup, } from 'reactstrap'
import { connect } from 'react-redux'
import { createNewFaq } from '../../actions/adminFaqAction'


class NewFaq extends Component {

    state = {
        question: '',
        answer: '',
        errors: {},
        showUpdateSuccess: false
    }

    componentDidUpdate(prevProps) {
        if (this.props.adminFaqs.errors !== prevProps.adminFaqs.errors) {
            this.setState({
                errors: this.props.adminFaqs.errors
            })
        }
        if (this.props.adminFaqs.newFaq !== prevProps.adminFaqs.newFaq) {
            this.setState({
                showUpdateSuccess: true,
                question: '',
                answer: '',
                errors: {},
            })
        }
    }





    inputChange = e => {
        if (this.state.showUpdateSuccess) {
            this.setState({
                showUpdateSuccess: false
            })
        }
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onCreateClick = e => {
        let data = {}
        data.question = this.state.question
        data.answer = this.state.answer

        this.props.createNewFaq(data)
    }

    render() {

        const { errors } = this.state

        return (
            <div>
                <div>
                    <Helmet>
                        <title>New FAQ - Frozberries Victoria</title>
                        <meta name="description" content="New FAQ - Frozberries Victoria" />
                    </Helmet>
                </div>
                <div>
                    <Row>
                        <Col>
                            <div className='bg-white  border-0  shadow card'>

                                <div className="bg-secondary card-header">
                                    <h3 className="mb-0 text-light">Create a new FAQ</h3>
                                </div>


                                <div className='card-body'>



                                    <Row>
                                        <Col>
                                            <Form>
                                                <FormGroup>
                                                    <Label for="question">Question</Label>
                                                    <Input className={classnames('', { 'is-invalid': errors.question })} onChange={this.inputChange} type="textarea" name="question" id="question" value={this.state.question} placeholder="Question" />
                                                    <div className='invalid-feedback'>{errors.question}</div>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="question">Answer</Label>
                                                    <Input className={classnames('', { 'is-invalid': errors.answer })} onChange={this.inputChange} type="textarea" name="answer" id="answer" value={this.state.answer} placeholder="Answer" />
                                                    <div className='invalid-feedback'>{errors.answer}</div>
                                                </FormGroup>
                                            </Form>
                                        </Col>
                                    </Row>

                                    {this.state.showUpdateSuccess && (
                                        <p className='text-success mb-2'>New FAQ has been successfully created.</p>

                                    )}

                                    <div>
                                        <Button size='sm' color='success' className='mx-1 my-1' onClick={this.onCreateClick}>{this.props.adminFaqs.createButtonLoading ? 'Please Wait' : 'Create'} </Button>
                                    </div>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    adminFaqs: state.adminFaqs
})


export default connect(mapStateToProps, { createNewFaq })(NewFaq)
