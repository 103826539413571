import React, { Component } from 'react'
import {
    Row, Col
} from 'reactstrap'
import { connect } from 'react-redux'
import Spinner from '../Spinner'


class ListofCountries extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col>
                        <h6 className='font-weight-bold text-secondary'> Sessions by Countries</h6>

                        {this.props.adminLists.loading ? <div className='text-center'>
                            <Spinner />
                        </div> : (
                                <div className='table-responsive'>
                                    <table className="table">
                                        <thead className='table-borderless'>
                                            <tr>
                                                <th scope="col">COUNTRY</th>
                                                <th scope="col">TOTAL USERS</th>
                                            </tr>
                                        </thead>

                                        <tbody >
                                            {this.props.adminLists.countryList.map((item, index) => (
                                                <tr key={index} >
                                                    <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>  {item[0]}</td>
                                                    <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{item[1]}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                    </Col>
                </Row>

            </div>
        )
    }
}


const mapStateToProps = state => ({
    adminLists: state.adminLists,
})

export default connect(mapStateToProps, {})(ListofCountries);

