import {
    MONTLY_SALES,
    MONTLY_SALES_LOADING,
    MONTLY_SALES_LOADING_RESET,
    CHANGE_STREAM_SALES_GRAPH
} from './types'
import axios from 'axios'



export const getSaleStats = (months) => dispatch => {
    dispatch({
        type: MONTLY_SALES_LOADING,
    })

    axios.get(`/api/admin/getSaleStats/${months}`)
        .then(res => {
            dispatch({
                type: MONTLY_SALES,
                payload: res.data
            })
            dispatch({
                type: MONTLY_SALES_LOADING_RESET,
            })
        })
        .catch(err => {
            dispatch({
                type: MONTLY_SALES_LOADING_RESET,
            })
            console.log(err)
        })

}

export const changeStreamSaleStats = data => dispatch => {
    dispatch({
        type: CHANGE_STREAM_SALES_GRAPH,
        payload: data
    })
}
