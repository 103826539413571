import {
    GET_LATEST_ORDERS,
    GET_LATEST_ORDERS_LOADING,
    GET_LATEST_ORDERS_LOADING_RESET,
    TODAY_ORDERS_UPDATE
} from './types'
import axios from 'axios'



export const getLatestOrders = () => dispatch => {
    dispatch({
        type: GET_LATEST_ORDERS_LOADING,
    })

    axios.get(`/api/admin/getLatestOrders`)
        .then(res => {
            dispatch({
                type: GET_LATEST_ORDERS,
                payload: res.data
            })
            dispatch({
                type: GET_LATEST_ORDERS_LOADING_RESET,
            })
        })
        .catch(err => {
            dispatch({
                type: GET_LATEST_ORDERS_LOADING_RESET,
            })
            console.log(err)
        })

}


export const todaysOrdersUpdate=(orders)=>dispatch=>{
    dispatch({
        type:TODAY_ORDERS_UPDATE,
        payload:orders
    })
}
