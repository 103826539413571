import {
    FULL_PAGE_LOADING, RESET_FULL_PAGE_LOADING
} from '../actions/types.js'


const initialState = {
    fullPageLoading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FULL_PAGE_LOADING:
            return {
                ...state,
                fullPageLoading: true
            }
        case RESET_FULL_PAGE_LOADING:
            return {
                ...state,
                fullPageLoading: false
            }
        default: return state;
    }
}