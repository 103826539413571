import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateShoppingCart, removeFromCart } from '../actions/shoppingCart'
import { Row, Col, Button } from 'reactstrap'
import visa from '../images/visa.png'
import mastercard from '../images/mastercard.png'
import zerocontact from '../images/zerocontact.png'
import paypal from '../images/paypal-logo.png'
import LoadingOverlay from 'react-loading-overlay';


class MyCart extends Component {

    state = {
        windowWidth: 0,
    }

    componentDidMount() {
        this.setState({
            windowWidth: window.innerWidth,
        })
        window.addEventListener('resize', this.updateWindowDimension);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimension);
    }
    updateWindowDimension = (e) => {
        this.setState({
            windowWidth: window.innerWidth
        })
    }

    removeItem = (product) => {
        if (!this.props.auth.isAuthenticated && this.props.guest.userid === '') {
            let cart = [...this.props.shoppingCart.cart]
            cart = cart.filter(eachItem => eachItem.product._id !== product._id)
            this.props.updateShoppingCart(cart)
        }
        if (this.props.guest.userid !== '') {
            this.props.removeFromCart(product, this.props.guest.userid)
        }
        if (this.props.auth.isAuthenticated) {
            this.props.removeFromCart(product, this.props.auth.user.id)
        }
    }


    render() {
        return (
            <div style={{ minHeight: '40vh' }}>
                <Row className='px-5 pt-5'>
                    <Col className='px-3'>
                        <h1 className='display-4'> My Cart</h1>
                    </Col>
                </Row>
                {this.props.shoppingCart.cart.length === 0 ? (
                    <Row className='px-5 pt-5'>
                        <Col className='px-3'>
                            <h1  >Your cart is empty!!</h1>
                        </Col>
                    </Row>
                ) : (
                        <Row className={this.state.windowWidth < 415 ? `pt-3` : `px-5 pt-3`} >
                            <Col className='px-3'>
                                <LoadingOverlay
                                    active={this.props.shoppingCart.removeFromCartLoading.loading}
                                    spinner
                                >
                                    <table className="table">
                                        <thead className='table-borderless'>
                                            <tr>
                                                <th scope="col">PRODUCT</th>
                                                <th></th>
                                                <th scope="col">QTY</th>
                                                <th scope="col">PRICE</th>
                                                <th scope='col'></th>
                                            </tr>
                                        </thead>
                                        <tbody >

                                            {this.props.shoppingCart.cart.map(item => (
                                                <tr key={item.product._id}>
                                                    <td style={{ width: '100px' }}>
                                                        <img src={process.env.PUBLIC_URL + item.product.image} alt={item.product.name} className='img-fluid' />
                                                    </td>
                                                    <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>  {item.product.name}</td>
                                                    <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{item.quantity}</td>
                                                    <td style={{ verticalAlign: 'middle', width: '100px' }} className='font-weight-bold text-muted'>${(Math.round((item.quantity * item.product.price) * 100) / 100).toFixed(2)}</td>
                                                    <td style={{ verticalAlign: 'middle', width: '50px', cursor: 'pointer' }} className='close-btn   text-center' onClick={() => this.removeItem(item.product)}>&times;</td>
                                                </tr>
                                            ))}


                                        </tbody>
                                    </table>
                                </LoadingOverlay>
                            </Col>
                            <Col md='3' className='px-3'>
                                <div>
                                    <Row className='py-3 '>
                                        <Col >
                                            <div >
                                                <span style={{ fontSize: 20 }} className='d-inline pl-2 font-weight-bold '>Total:</span>
                                                <span style={{ fontSize: 20 }} className='float-right font-weight-bold text-muted  pr-2'>
                                                    ${
                                                        (Math.round((this.props.shoppingCart.cart.length !== 0 ?
                                                            this.props.shoppingCart.cart.map(eachItem => eachItem.quantity * eachItem.product.price).reduce((total, currentValue) => total + currentValue) : 0
                                                        ) * 100) / 100).toFixed(2)}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    {/* <Row className='py-3'>
                                        <Col>

                                            <Progress max={99}
                                                color="success"
                                                value={this.props.shoppingCart.cart.length !== 0 ?
                                                    ((this.props.shoppingCart.cart.map(eachItem => eachItem.quantity * eachItem.product.price).reduce((total, currentValue) => total + currentValue)) >= 99 ? 99 :
                                                        (this.props.shoppingCart.cart.map(eachItem => eachItem.quantity * eachItem.product.price).reduce((total, currentValue) => total + currentValue)))
                                                    :
                                                    0}
                                            >
                                                {this.props.shoppingCart.cart.length !== 0 ?
                                                    ((Math.round(((this.props.shoppingCart.cart.map(eachItem => eachItem.quantity * eachItem.product.price).reduce((total, currentValue) => total + currentValue)) * 100) / 100).toFixed(2)) >= 100 ? 100 :
                                                        (Math.round((((this.props.shoppingCart.cart.map(eachItem => eachItem.quantity * eachItem.product.price).reduce((total, currentValue) => total + currentValue)))) * 100) / 100)).toFixed(2)
                                                    :
                                                    0}%
                                </Progress>
                                            <div className='text-center  pt-4' style={{ fontSize: 12 }}>
                                                {(Math.round(((this.props.shoppingCart.cart.map(eachItem => eachItem.quantity * eachItem.product.price).reduce((total, currentValue) => total + currentValue)) * 100) / 100).toFixed(2)) >= 100 ?
                                                    (
                                                        'Eligible for FREE SHIPPING! '
                                                    ) : (
                                                        'Get FREE SHIPPING if you order $99.00!'
                                                    )}
                                            </div>
                                        </Col>
                                    </Row> */}
                                    <Row>
                                        <Col>
                                            <div className='text-center'>
                                                <img className='payment-type mx-2' src={paypal} alt='paypal' style={{ width: 60 }} />
                                                <img className='payment-type  mx-2' src={mastercard} alt='mastercard' style={{ width: 100 }} />
                                                <img className='payment-type  mx-2' src={visa} alt='visa' style={{ width: 60 }} />
                                            </div>

                                            <div className='text-center mt-3'>
                                                <img className=' mt-3 img-fluid' src={zerocontact} alt='zero-contact-delivery' style={{ maxHeight: 60, }} />
                                            </div>
                                            <Button className='font-weight-bold text-light btn-block px-3 py-2 my-3' color='success' onClick={() => this.props.history.push('/checkout')} style={{ borderRadius: 0 }}>
                                                <i className="fas fa-lock pr-1"></i>   CHECKOUT
                                                </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>

                    )}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    shoppingCart: state.cart,
    auth: state.form,
    guest: state.guest

})


export default connect(mapStateToProps, { updateShoppingCart, removeFromCart })(MyCart)