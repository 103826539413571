import {
    GET_MAP_DATA,
    MAP_DATA_LOADING,
    MAP_DATA_LOADING_RESET
} from './types'
import axios from 'axios'



export const getMapData = (date) => dispatch => {
    dispatch({
        type: MAP_DATA_LOADING,
    })

    axios.get(`/api/admin/getMapData/${date}`)
        .then(res => {
            dispatch({
                type: GET_MAP_DATA,
                payload: res.data
            })
            dispatch({
                type: MAP_DATA_LOADING_RESET,
            })
        })
        .catch(err => {
            dispatch({
                type: MAP_DATA_LOADING_RESET,
            })
            console.log(err)
        })

}

