import React, { Component } from 'react'
import TotalUsers from './TotalUsers'
import TotalRevenue from './TotalRevenue'
import TotalOrders from './TotalOrders'
import {
    Row, Col
} from 'reactstrap';
import { connect } from 'react-redux'
import { changeStreamRevenueData } from '../../actions/adminRevenueAction'
import { changeStreamUserData } from '../../actions/adminUserActions'
import { changeStreamSaleStats } from '../../actions/saleStats'



class AdminTopBar extends Component {
    render() {
        return (
            <div>
                <Row className=' text-center ' >
                    <Col lg='4' md='12'>
                        <TotalRevenue />
                    </Col>
                    <Col lg='4' md='12'>
                        <TotalOrders />
                    </Col>
                    <Col lg='4' md='12'>
                        <TotalUsers />
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    adminRevenueCalc: state.adminRevenueCalc
})

export default connect(mapStateToProps, { changeStreamRevenueData, changeStreamUserData, changeStreamSaleStats })(AdminTopBar)