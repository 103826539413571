import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import { Form, Input } from 'reactstrap'
import classnames from 'classnames'
import { updateReview } from '../actions/reviewActions'

class ReviewStep3 extends Component {

    state = {
        review: '',
        errors: {}
    }

    componentDidUpdate(prevProps) {
        if (this.props.reviews.errors !== prevProps.reviews.errors) {
            this.setState({
                errors: this.props.reviews.errors
            })
        }
    }

    handleChange = e => {

        let field = e.target.name
        let value = e.target.value

        if (Object.keys(this.state.errors)) {
            this.setState({
                errors: {}
            })
        }

        this.props.updateReview(field, value)
    }

    handleNext = e => {
        if (this.props.reviews.review === '') {
            this.setState({
                errors: { ...this.state.errors, review: 'Required field' }
            })

        } else {
            this.props.nextStep()
        }
    }


    render() {
        return (
            <div className='text-center  parent'>
                <div className='px-5  py-3'>
                    <div>
                        <h4 className='font-weight-bold my-3 '>TELL US MORE!</h4>
                    </div>

                    <div>
                        <Form onSubmit={e => e.preventDefault()}>
                            <Input
                                className={classnames('', { 'is-invalid': this.state.errors.review })}
                                onChange={this.handleChange} type='textarea' rows={12} name='review' value={this.props.reviews.review} placeholder='Share your experience' />
                            <div className='invalid-feedback'>{this.state.errors.review}</div>
                        </Form>

                    </div>
                </div>

                <div style={{ bottom: 0 }}>
                    <div className='float-left'>
                        <Button outline color='dark' className='border-0' onClick={() => this.props.previousStep()}> Back </Button>
                    </div>
                    <div className='float-right'>
                        <Button outline color='dark' className='border-0' onClick={(e) => this.handleNext()}> Next </Button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    reviews: state.reviews
})

export default connect(mapStateToProps, { updateReview })(ReviewStep3)
