import React, { Component } from "react";
import { Col, Row, Button, Container } from "reactstrap";
import Zoom from "react-reveal/Zoom";
import image1 from "../images/bg1.jpg";
import image2 from "../images/bg.jpg";
import image3 from "../images/bg3.jpg";
import image4 from "../images/pom.jpg";

const pages = [
  {
    title: "  Frozen Wild Blueberries Supplier",
    subTitle: "Full of Taste and Powerful Nutritional Wonders.",
    buttonText: " Grab Some Now",
    buttonLink: "/shop",
    imageSrc: image3,
    imageAltText: "Where to buy Wild Blueberries in Melbourne?",
    backgroundColor: "rgb(0, 0, 0, 0.3)",
  },
  {
    title: "Our Elgin Organic products are individually flash frozen.",
    subTitle: "",
    buttonText: "  Our Products",
    buttonLink: "/shop",
    imageSrc: image2,
    imageAltText:
      "Frozberries Victoria is 100% Australian owned family business. We deliver to Melbourne Metropolitan area. Checkout our mixed range of organic and imported fruits and vegetables.",
    backgroundColor: "rgb(0, 0, 0, 0.3)",
  },
  {
    title:
      "Frozen Australian Berries and Organic Veggies Delivered To Your Doorstep",
    subTitle: "",
    buttonText: "Shop Now",
    buttonLink: "/shop",
    imageSrc: image1,
    imageAltText: "Frozen berries Made in Australia.",
    backgroundColor: "rgb(0, 0, 0, 0.3)",
  },
  {
    title: `Frozen Organic Pomegranate Arils`,
    subTitle:
      "Finest pomegranates with the richest zesty flavor for your taste bud pleasure.",
    buttonText: "  Buy Now",
    buttonLink: "/shop?page=1&productSearch=Pomegranate%20Arils",
    imageSrc: image4,
    imageAltText: "Frozen Pomegranate Melbourne",
    backgroundColor: "rgb(0, 0, 0, 0.2)",
  },
];

class Landing extends Component {
  state = {
    offsetY: 0,
    windowWidth: 0,
  };
  componentDidMount() {
    this.setState({
      windowWidth: window.innerWidth,
    });
    window.addEventListener("resize", this.updateWindowDimension);
    window.addEventListener("scroll", this.listenToScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimension);
    window.removeEventListener("scroll", this.listenToScroll);
  }
  listenToScroll = () => {
    this.setState({
      offsetY: window.pageYOffset,
    });
  };
  updateWindowDimension = (e) => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  render() {
    return (
      <div
        id="landingCarousel"
        className="carousel slide carousel-fade "
        data-ride="carousel"
      >
        <ol className="carousel-indicators">
          <li
            data-target="#landingCarousel"
            data-slide-to="0"
            className="active"
          ></li>
          {pages.map(
            (page, index) =>
              index !== 0 && (
                <li data-target="#landingCarousel" data-slide-to={index}></li>
              )
          )}
        </ol>
        <div className="carousel-inner">
          {pages.map((page, index) => (
            <div
              key={index}
              className={index === 0 ? "carousel-item active" : "carousel-item"}
            >
              <img
                style={{
                  height: "80vh",
                  minHeight: "540px",
                  objectFit: "cover",
                  top: 0,
                  bottom: 0,
                }}
                className=" w-100 position-absolute img-fluid"
                src={page.imageSrc}
                alt={page.imageAltText}
              />
              <div
                className="overlay text-center w-100 position-relative"
                style={{
                  backgroundColor: page.backgroundColor,
                  top: 0,
                }}
              >
                <Container className=" w-100 h-100  ">
                  <Row className=" my-auto h-100 ">
                    <Col className=" my-auto ">
                      <div className=" text-center text-light position-relative ">
                        <h1 style={{ fontSize: 34 }}>{page.title}</h1>
                        <p style={{ fontSize: 23 }}>{page.subTitle} </p>
                        <Zoom>
                          <Button
                            href={page.buttonLink}
                            className="border-0 px-3 py-2"
                            size="md"
                            style={{
                              backgroundColor: "rgb(235,24,79)",
                              borderRadius: 30,
                            }}
                          >
                            <span
                              className="font-weight-bold"
                              style={{ fontSize: 15 }}
                            >
                              {page.buttonText}
                            </span>
                          </Button>
                        </Zoom>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          ))}
        </div>
        <a
          className="carousel-control-prev "
          href="#landingCarousel"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon "
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#landingCarousel"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    );
  }
}
export default Landing;
