import {
    ADMIN_NEW_RECIPE,
    ADMIN_RECIPE_LOADING,
    RESET_ADMIN_NEW_RECIPE,
    ADMIN_RECIPE_ERRORS,
    GET_ADMIN_RECIPES,
    GET_RECIPE_COUNT,
    GET_SINGLE_ADMIN_RECIPE,
    RESET_DELETE_BUTTON_LOADING_RECIPE,
    DELETE_RECIPE,
    DELETE_BUTTON_LOADING_RECIPE,
    RESET_ADMIN_RECIPE_FORM,
    UPDATE_RECIPE
} from './types'
import axios from 'axios'
import { logoutUser } from './formActions'



export const createNewRecipe = data => dispatch => {
    dispatch({
        type: ADMIN_RECIPE_LOADING
    })
    axios.post(`/api/admin/createNewRecipe`, data)
        .then(res => {
            dispatch({
                type: ADMIN_NEW_RECIPE,
                payload: res.data
            })
            dispatch({
                type: RESET_ADMIN_NEW_RECIPE
            })
        })
        .catch(err => {
            dispatch({
                type: ADMIN_RECIPE_ERRORS,
                payload: err.response.data
            })
            dispatch({
                type: RESET_ADMIN_NEW_RECIPE
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
        })
}


export const getAdminRecipes = (page, filter, query) => dispatch => {
    dispatch({
        type: ADMIN_RECIPE_LOADING
    })
    axios.get(`/api/admin/getAdminRecipes/${page}/${filter}/${query}`)
        .then(res => {
            dispatch({
                type: GET_ADMIN_RECIPES,
                payload: res.data.rangeRecipes
            })
            dispatch({
                type: GET_RECIPE_COUNT,
                payload: res.data.count
            })
            dispatch({
                type: RESET_ADMIN_NEW_RECIPE
            })
        })
        .catch(err => {
            dispatch({
                type: ADMIN_RECIPE_ERRORS,
                payload: err.response.data
            })
            dispatch({
                type: RESET_ADMIN_NEW_RECIPE
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
        })
}

export const getSingleAdminRecipe = (id) => dispatch => {
    dispatch({
        type: ADMIN_RECIPE_LOADING
    })
    axios.get(`/api/admin/getSingleAdminRecipe/${id}`)
        .then(res => {
            dispatch({
                type: GET_SINGLE_ADMIN_RECIPE,
                payload: res.data
            })
            dispatch({
                type: RESET_ADMIN_NEW_RECIPE
            })
        })
        .catch(err => {
            dispatch({
                type: ADMIN_RECIPE_ERRORS,
                payload: err.response.data
            })
            dispatch({
                type: RESET_ADMIN_NEW_RECIPE
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
        })
}

export const deleteRecipe = (id) => dispatch => {
    dispatch({
        type: DELETE_BUTTON_LOADING_RECIPE
    })
    axios.get(`/api/admin/deleteRecipe/${id}`)
        .then(res => {
            dispatch({
                type: DELETE_RECIPE,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: ADMIN_RECIPE_ERRORS,
                payload: err.response.data
            })
            dispatch({
                type: RESET_DELETE_BUTTON_LOADING_RECIPE
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
        })
}

export const resetForm = () => dispatch => {
    dispatch({
        type: RESET_ADMIN_RECIPE_FORM
    })
}



export const updateRecipe = (data, id) => dispatch => {
    dispatch({
        type: ADMIN_RECIPE_LOADING
    })
    axios.post(`/api/admin/updateRecipe/${id}`, data)
        .then(res => {
            dispatch({
                type: UPDATE_RECIPE,
                payload: res.data
            })
            dispatch({
                type: RESET_ADMIN_NEW_RECIPE
            })
        })
        .catch(err => {
            dispatch({
                type: ADMIN_RECIPE_ERRORS,
                payload: err.response.data
            })
            dispatch({
                type: RESET_ADMIN_NEW_RECIPE
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
        })
}

