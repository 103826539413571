import React, { Component } from 'react'
import { getSaleStats } from '../../actions/saleStats'
import { connect } from 'react-redux'
import { Bar } from 'react-chartjs-2';
import { Row, Col, DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap'


class SalesGraph extends Component {

    state = {
        salesData: {},
        data: {},
        options: {},
        dropdownOpen: false,
        dropDownDate: 6,
        fetchDate: 6,

    }
    componentDidMount() {
        this.props.getSaleStats(this.state.fetchDate)
    }
    componentDidUpdate(prevProps, prevState) {

        if (this.state.fetchDate !== prevState.fetchDate) {
            this.props.getSaleStats(this.state.fetchDate)
        }


        if (this.props.salesGraph.sales !== prevProps.salesGraph.sales) {

            let chartData = {}
            let labels = []
            let backgroundColor = []
            let hoverBackgroundColor = []
            let datasetObject = {}
            let data = []
            let label = ''
            let datasets = []

            for (let i = 0; i < this.props.salesGraph.sales.length; i++) {
                label = this.props.salesGraph.sales[i]._id.month + `-${this.props.salesGraph.sales[i]._id.year}`
                labels.push(label)
                label = ''
            }

            for (let i = 0; i < this.props.salesGraph.sales.length; i++) {
                let sale = (this.props.salesGraph.sales[i].total_sales / 100).toFixed(2)
                data.push(sale)
                backgroundColor.push('rgba(75,192,192,1)')
                hoverBackgroundColor.push('rgba(255,99,132,0.2)')
            }
            chartData.labels = labels
            datasetObject.data = data
            datasetObject.backgroundColor = backgroundColor
            datasetObject.hoverBackgroundColor = hoverBackgroundColor
            datasetObject.label = 'Monthly Sales'
            datasets.push(datasetObject)
            chartData.datasets = datasets

            this.setState({
                data: chartData
            })

        }
    }

    number_format = (number, decimals, dec_point, thousands_sep) => {
        number = (number + '').replace(',', '').replace(' ', '');
        var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            s = '',
            toFixedFix = function (n, prec) {
                var k = Math.pow(10, prec);
                return '' + Math.round(n * k) / k;
            };
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    }

    handleToolTips = (tooltipItem, chart) => {
        var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
        return datasetLabel + ': AUD ' + this.number_format(tooltipItem.yLabel, 2);
    }
    handleTicks = (value, index, values) => {
        return 'AUD ' + this.number_format(value);
    }
    toggle = e => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    }

    handleDateChange = date => {
        let dropDownDate;
        let fetchDate;
        if (date == 6) {
            dropDownDate = 6
            fetchDate = 6
        }
        else if (date == 12) {
            dropDownDate = 12
            fetchDate = 12
        }
        else if (date == 18) {
            dropDownDate = 18
            fetchDate = 18
        }
        else if (date == 24) {
            dropDownDate = 24
            fetchDate = 24
        }

        this.setState({
            dropDownDate,
            fetchDate
        })

    }


    render() {
        return (
            <div className='text-left bg-light px-3 py-4 my-2' style={{ borderRadius: 10 }}>
                <Row className='py-1'>
                    <Col >
                        <h6 className='font-weight-bold text-secondary'>Sales </h6>

                        <Bar
                            data={this.state.data}
                            width={100}
                            height={50}
                            options={{
                                scales: {
                                    yAxes: [{
                                        ticks: {
                                            beginAtZero: true,
                                            callback: this.handleTicks
                                        }
                                    }]
                                },
                                tooltips: {
                                    callbacks: {
                                        label: this.handleToolTips
                                    }
                                }
                            }}
                        />

                        <Dropdown className='border-0 mt-2' isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                            <DropdownToggle caret tag='span' style={{ cursor: 'pointer' }}>
                                {this.state.dropDownDate == 6 && 'Last 6 Months'}
                                {this.state.dropDownDate == 12 && 'Last Year'}
                                {this.state.dropDownDate == 18 && 'Last 18 Months'}
                                {this.state.dropDownDate == 24 && 'Last 2 Years'}

                            </DropdownToggle>
                            <DropdownMenu className='ml-auto'>
                                <DropdownItem header>    {this.state.dropDownDate == 6 && 'Last 6 Months'}
                                    {this.state.dropDownDate == 12 && 'Last Year'}
                                    {this.state.dropDownDate == 18 && 'Last 18 Months'}
                                    {this.state.dropDownDate == 24 && 'Last 2 Years'}
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={() => this.handleDateChange('6')}>Last 6 Months</DropdownItem>
                                <DropdownItem onClick={() => this.handleDateChange('12')}>Last Year</DropdownItem>
                                <DropdownItem onClick={() => this.handleDateChange('18')}>Last 18 Months</DropdownItem>
                                <DropdownItem onClick={() => this.handleDateChange('24')}>Last 2 Years</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.form,
    salesGraph: state.salesGraphReducer
})



export default connect(mapStateToProps, { getSaleStats })(SalesGraph)