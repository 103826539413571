import React from 'react'
import spinner from '../images/fullPageSpinner.gif'

const FullPageSpinner = () => {
    return (
        <div className='h-100 w-100 ' >
            <img style={{ width: '100px', height: '100px' }} src={spinner} alt='Loading' />
        </div>
    )
}

export default FullPageSpinner