import {
    GET_RECIPES_LOADING,
    GET_RECIPES_LOADING_RESET,
    GET_RECIPES,
    RECIPE_COUNT,
    GET_SINGLE_RECIPE
} from './types'
import axios from 'axios'



export const getRecipes = (page, query) => dispatch => {
    dispatch({
        type: GET_RECIPES_LOADING
    })

    axios.get(`/api/recipe/getRecipes/${page}/${query}`)
        .then(res => {

            dispatch({
                type: GET_RECIPES,
                payload: res.data.recipes

            })

            dispatch({
                type: RECIPE_COUNT,
                payload: res.data.count
            })

            dispatch({
                type: GET_RECIPES_LOADING_RESET
            })
        })
        .catch(err => {
            dispatch({
                type: GET_RECIPES_LOADING_RESET
            })
            console.log(err)
        })

}
export const getSingleRecipe = (id) => dispatch => {
    dispatch({
        type: GET_RECIPES_LOADING
    })

    axios.get(`/api/recipe/getSingleRecipe/${id}`)
        .then(res => {

            dispatch({
                type: GET_SINGLE_RECIPE,
                payload: res.data

            })

            dispatch({
                type: GET_RECIPES_LOADING_RESET
            })
        })
        .catch(err => {
            dispatch({
                type: GET_RECIPES_LOADING_RESET
            })
            console.log(err)
        })

}