import {
    ADMIN_ANALYTICS_LOADING,
    ADMIN_ANALYTICS_LOADING_RESET,
    SESSION_BY_DEVICE_TOTAL_USERS,
    SESSION_BY_DEVICE_TOTAL_DESKTOP,
    SESSION_BY_DEVICE_TOTAL_MOBILE,
    SESSION_BY_DEVICE_TOTAL_TABLET

} from '../actions/types.js'


const initialState = {
    loading: false,
    userByDevice: {
        totalUsers: 0,
        desktop: 0,
        mobile: 0,
        tablet: 0
    }

}

export default function (state = initialState, action) {
    switch (action.type) {
        case SESSION_BY_DEVICE_TOTAL_TABLET:
            return {
                ...state,
                userByDevice: {
                    ...state.userByDevice,
                    tablet: action.payload
                }
            }
        case SESSION_BY_DEVICE_TOTAL_USERS:
            return {
                ...state,
                userByDevice: {
                    ...state.userByDevice,
                    totalUsers: action.payload
                }
            }
        case SESSION_BY_DEVICE_TOTAL_DESKTOP:
            return {
                ...state,
                userByDevice: {
                    ...state.userByDevice,
                    desktop: action.payload
                }
            }
        case SESSION_BY_DEVICE_TOTAL_MOBILE:
            return {
                ...state,
                userByDevice: {
                    ...state.userByDevice,
                    mobile: action.payload
                }
            }
        case ADMIN_ANALYTICS_LOADING:
            return {
                ...state,
                loading: true
            }
        case ADMIN_ANALYTICS_LOADING_RESET:
            return {
                ...state,
                loading: false
            }
        default: return state;
    }
}