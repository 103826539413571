import {
    NEW_ORDER,
    GET_USER_ORDERS,
    RESET_ORDER,
    GET_SINGLE_ORDER,
    USER_ORDERS_LOADING,
    USER_ORDERS_LOADING_FINISHED
} from '../actions/types.js'


const initialState = {
    order: {},
    userOrders: [],
    fetching:false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case NEW_ORDER:
            return {
                ...state,
                order: action.payload,
            }
        case GET_SINGLE_ORDER:
            return {
                ...state,
                order: action.payload,
            }

        case GET_USER_ORDERS: {
            return {
                ...state,
                userOrders: action.payload
            }
        }
        case RESET_ORDER: {
            return {
                ...state,
                order: {}
            }
        }
        case USER_ORDERS_LOADING: {
            return {
                ...state,
                fetching: true
            }
        }
        case USER_ORDERS_LOADING_FINISHED: {
            return {
                ...state,
                fetching: false
            }
        }
        default: return state;
    }
}