import React, { Component } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import ReviewModal from './ReviewModal'
import { connect } from 'react-redux'
import { resetReviewForm } from '../actions/reviewActions'
import ReviewCards from './ReviewCards'


class Reviews extends Component {
    state = {
        showReviewModal: false
    }

    handleReviewModal = e => {
        if (this.state.showReviewModal) {
            this.props.resetReviewForm()
        }
        this.setState({
            showReviewModal: !this.state.showReviewModal
        })

    }
    render() {
        return (
            <div className='py-3 '>
                <div>
                    {this.props.product.reviews.length === 0 ? (
                        <div className='d-inline' >
                            <i className="far fa-star" style={{ color: 'gold' }}></i>
                            <i className="far fa-star" style={{ color: 'gold' }}></i>
                            <i className="far fa-star" style={{ color: 'gold' }}></i>
                            <i className="far fa-star" style={{ color: 'gold' }}></i>
                            <i className="far fa-star " style={{ color: 'gold' }}></i>
                        </div>
                    ) : (
                            <div className='d-inline' >
                                <i className="fas fa-star" style={{ color: 'gold' }}></i>
                                <i className="fas fa-star" style={{ color: 'gold' }}></i>
                                <i className="fas fa-star" style={{ color: 'gold' }}></i>
                                <i className="fas fa-star" style={{ color: 'gold' }}></i>
                                <i className="fas fa-star " style={{ color: 'gold' }}></i>
                                <span> {this.props.product.reviews.length} Reviews</span>
                            </div>
                        )}

                    <div className='d-inline float-right' >
                        <Button onClick={this.handleReviewModal} outline color='dark'>
                            <span>
                                Write a Review
                                </span>
                        </Button></div>

                    {(!this.props.product.reviews || this.props.product.reviews.length === 0) && (
                        <div className='py-3 mt-3'>
                            <h5 className='text-center'>Be the first to <span onClick={this.handleReviewModal} style={{ cursor: 'pointer', textDecoration: 'underline', textDecorationThickness: 'from-font' }}>write a review</span></h5>
                        </div>
                    )}
                    {this.props.product.reviews.length !== 0 && (
                        <ReviewCards reviews={this.props.product.reviews} />
                    )}

                </div>

                {this.state.showReviewModal && (


                    <Modal style={{ maxWidth: 700 }} isOpen={this.state.showReviewModal} toggle={this.handleReviewModal}>
                        <ModalHeader style={{ border: 0 }} toggle={this.handleReviewModal}></ModalHeader>
                        <ModalBody style={{ height: 500 }}>
                            <ReviewModal product={this.props.product} />
                        </ModalBody>
                    </Modal>

                )}
            </div>
        )
    }
}
const mapStateToProps = state => ({
    reviews: state.reviews
})

export default connect(mapStateToProps, { resetReviewForm })(Reviews)