import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { logoutUser } from '../actions/formActions';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


class Topbar extends Component {
    state = {
        dropdownOpen: false,
    }

    onLogoutClick = () => {
        let cartItems = JSON.parse(localStorage.getItem('shopping_cart'))
        if (cartItems) {
            cartItems = []
            localStorage.setItem('shopping_cart', JSON.stringify(cartItems))
        }

        this.props.logoutUser()
        this.props.history.push('/login')

    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    }


    render() {
        const { isAuthenticated } = this.props.auth;
        const { user } = this.props.auth;

        const guestLinks = (
            <React.Fragment>
                <span className='float-right pr-3 pl-3 pt-2  ' style={{ fontSize: 14 }}>
                    <a href='/login' className='login'>
                        <i className="fas fa-key pr-1" style={{ color: 'rgb(236,23,79)', fontSize: 12 }}></i>    Login
            </a>
                </span>
                <span className='float-right pt-2 ' style={{ fontSize: 14 }} >
                    <a href='/signup' className='signup'>
                        <i className="fas fa-user-plus pr-1" style={{ color: 'rgb(236,23,79)', fontSize: 12 }}></i>
                        Sign up
                    </a>

                </span>
            </React.Fragment>
        )
        const authLinks = (
            <React.Fragment>
                <Dropdown
                    style={{ right: 10 }}
                    size='sm'
                    isOpen={this.state.dropdownOpen} toggle={this.toggle} className='  position-absolute' >
                    <DropdownToggle tag='span'>
                        <span
                            style={{ cursor: 'pointer', color: 'rgb(236,23,79)' }}
                        >
                            <i className="fas fa-user pr-1 " style={{ fontSize: '13px' }}></i>
                            {user.firstName}
                            <i className="fas fa-caret-down pl-2" style={{ fontSize: '15px' }}></i>
                        </span>
                    </DropdownToggle>
                    <DropdownMenu right  >
                        <DropdownItem onClick={() => this.props.history.push('/dashboard')}>Profile</DropdownItem>
                        <DropdownItem onClick={() => this.props.history.push('/cart')}>Checkout</DropdownItem>
                        <DropdownItem onClick={this.onLogoutClick}>Logout</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        )

        return (
            (this.props.location.pathname !== '/checkout' &&
                this.props.location.pathname !== '/adminpanel') &&
            <div className='' style={{
                fontSize: 18, lineHeight: 1, backgroundColor: 'white'
            }} >

                <div className='topbar ' >
                    {isAuthenticated ? authLinks : guestLinks}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.form

})


export default connect(mapStateToProps, { logoutUser })(withRouter(Topbar))