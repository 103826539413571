import {
    GET_LATEST_ORDERS,
    GET_LATEST_ORDERS_LOADING,
    GET_LATEST_ORDERS_LOADING_RESET,
    TODAY_ORDERS_UPDATE
} from '../actions/types.js'


const initialState = {
    loading: false,
    orders: []

}

export default function (state = initialState, action) {
    switch (action.type) {
        case TODAY_ORDERS_UPDATE:
            return {
                ...state,
                orders: action.payload
            }
        case GET_LATEST_ORDERS:
            return {
                ...state,
                orders: action.payload
            }
        case GET_LATEST_ORDERS_LOADING:
            return {
                ...state,
                loading: true
            }

        case GET_LATEST_ORDERS_LOADING_RESET:
            return {
                ...state,
                loading: false
            }
        default: return state;
    }
}