import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Row, Col, NavbarBrand, Input, Form, Button, Collapse, FormGroup, Label, Badge } from 'reactstrap'
import frozberiesLogo from '../images/frozberries-logo.png'
import StepWizard from 'react-step-wizard';
import UserInfo from './UserInfo'
import Payment from './Payment'
import BadgeMT from '@material-ui/core/Badge';
import Shipping from './Shipping'
import CheckoutNavBar from './CheckoutNavBar'
import { Helmet } from "react-helmet";
import LoadingOverlay from 'react-loading-overlay';
import { couponInputUpdate, applyCoupon, resetCouponError } from '../actions/discountActions'
import ButtonSpinner from './ButtonSpinner'
import classnames from 'classnames'
import { validateCart, resetWarnings } from '../actions/shoppingCart'
import { setShippingFee } from '../actions/checkOutActions'

class Checkout extends Component {

    state = {
        isOpen: false,

    }

    componentDidUpdate(prevProps) {
        if (this.props.orders.order !== prevProps.orders.order) {
            this.props.history.push({
                pathname: `/order-received/${this.props.orders.order._id}`,
                state: {
                    order: this.props.orders.order
                }
            })
        }
        if (this.props.discount.errors !== prevProps.discount.errors && Object.keys(this.props.discount.errors).length > 0) {
            setTimeout(this.resetCoupon, 4000)
        }
        if (this.props.shoppingCart.cart !== prevProps.shoppingCart.cart && this.props.shoppingCart.cart.length === 0) {
            this.props.setShippingFee(0)
        }

    }
    updateCoupon = e => {
        var field = e.target.name;
        var inputValue = e.target.value
        this.props.couponInputUpdate(field, inputValue)
    }

    handleCoupon = e => {
        e.preventDefault()
        let couponData = {}
        couponData.coupon = this.props.discount.coupon
        this.props.applyCoupon(couponData)
    }


    toggle = (e) => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    resetCoupon = () => {
        this.props.resetCouponError()
    }

    componentDidMount() {


        let data = {}
        data.cart = this.props.shoppingCart.cart

        if (this.props.guest.userid !== '') {
            data.userid = this.props.guest.userid
        }
        if (this.props.auth.isAuthenticated) {
            data.userid = this.props.auth.user.id
        }


        this.props.validateCart(data)

    }
    componentWillUnmount() {
        this.props.resetWarnings()
    }

    render() {

        let subTotal = 0

        if (this.props.shoppingCart.cart.length !== 0) {
            subTotal = Math.round((this.props.shoppingCart.cart.map(eachItem => eachItem.quantity * eachItem.product.price).reduce((total, currentValue) => total + currentValue)) * 100) / 100
        }

        let total = subTotal;
        let discount;

        if (this.props.checkout.shippingFee !== null) {
            total = total + this.props.checkout.shippingFee
        }

        if (Object.keys(this.props.discount.userCoupon).length > 0) {
            if (subTotal >= this.props.discount.userCoupon.minimumSpend) {
                if (this.props.discount.userCoupon.isPercent) {
                    discount = (subTotal / 100) * this.props.discount.userCoupon.amount
                } else {
                    discount = this.props.discount.userCoupon.amount
                }
            }
        }

        if (discount !== undefined) {
            total = total - discount
        }
        if (total < 0) {
            total = 0
        }

        if (this.props.shoppingCart.cart.length === 0 && this.props.shoppingCart.warnings.length === 0) {
            return <Redirect to='/cart' />
        }

        return (
            <LoadingOverlay
                active={this.props.checkout.fullPageLoading}
                spinner
            >
                <div className='border' style={{ minHeight: '100vh' }}>
                    <div>
                        <Helmet>
                            <title>Checkout - Frozberries Victoria</title>
                            <meta name="description" content="Checkout - Frozberries Victoria" />
                        </Helmet>
                    </div>

                    <Row className='mt-3 checkout-row' >
                        <Col lg={7} className='border-right'>
                            <NavbarBrand href='/' className='w-100 text-center'>
                                <img src={frozberiesLogo} alt='frozberries-logo' />
                            </NavbarBrand>
                            <div className='px-3 border-top border-bottom mt-3 d-lg-none' onClick={this.toggle} style={{ cursor: 'pointer' }}>
                                <p className='pt-3'>
                                    <i className="fas fa-shopping-cart pr-2"></i>
                                    Show order summary
                                <i className="fas fa-chevron-down pl-2"></i>
                                    <span className='float-right'>
                                        AU ${
                                            ((Math.round((this.props.shoppingCart.cart.length !== 0 ?
                                                this.props.shoppingCart.cart.map(eachItem => eachItem.quantity * eachItem.product.price).reduce((total, currentValue) => total + currentValue) : 0
                                            ) * 100) / 100) + this.props.checkout.shippingFee).toFixed(2)}
                                    </span>
                                </p>

                            </div>
                            <Collapse isOpen={this.state.isOpen} className='mx-4 d-lg-none'>
                                {this.props.shoppingCart.cart.map(product => (
                                    <div key={product.product._id} className='border-bottom'>
                                        <Row className='px-2 py-3 ' >

                                            <Col xs='2'>
                                                <BadgeMT
                                                    badgeContent={product.quantity}
                                                    color='error'
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }} >
                                                    <img src={product.product.image} alt={product.product.name} style={{ height: 60 }} />

                                                </BadgeMT>
                                            </Col>
                                            <Col xs='10' >
                                                <div className='pt-2'>
                                                    <span className='w-75 d-inline float-left  pl-3'> {product.product.name}</span>
                                                    <span className=' text-muted  font-weight-bold w-25 float-right pl-2' > ${(Math.round((product.product.price * product.quantity) * 100) / 100).toFixed(2)}</span>
                                                </div>

                                            </Col>

                                        </Row>
                                        {this.props.shoppingCart.warnings.map(eachWarning => (
                                            eachWarning.product._id === product.product._id && (
                                                <Row className='px-4 py-1 '>
                                                    <p className='font-weight-bold text-info'>{eachWarning.definition}</p>
                                                </Row>
                                            )
                                        ))}
                                    </div>
                                ))}

                                {this.props.shoppingCart.warnings.map(eachWarning => (
                                    !eachWarning.product.inStock && (
                                        <div key={eachWarning.product._id} className='border-bottom'>
                                            <Row className='px-2 py-3'>
                                                <Col xs='2'>
                                                    <img src={eachWarning.product.image} alt={eachWarning.product.name} style={{ height: 60 }} />
                                                </Col>
                                                <Col xs='10' >
                                                    <div className='pt-2'>
                                                        <span className='w-75 d-inline float-left  pl-3'> {eachWarning.product.name}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className='px-4 py-1 '>
                                                <p className='font-weight-bold text-danger'>{eachWarning.definition}</p>
                                            </Row>
                                        </div>
                                    )
                                ))}

                                <Form className='my-3 border-bottom'>
                                    <FormGroup>
                                        <Input
                                            name='coupon'
                                            className={classnames(' d-inline', { 'is-invalid': this.props.discount.errors.coupon })}
                                            value={this.props.discount.coupon} onChange={this.updateCoupon} placeholder='Gift cart or discount code' />
                                        <div className='invalid-feedback'>{this.props.discount.errors.coupon}</div>
                                    </FormGroup>
                                    <Button size='sm' color='success' className='my-2 mb-3' disabled={this.props.discount.coupon === ''} outline onClick={this.handleCoupon}>
                                        {this.props.discount.loading ? <ButtonSpinner /> :
                                            <span>Apply</span>
                                        }
                                    </Button>
                                </Form>

                                <div className='border-bottom'>

                                    <p>SubTotal  <span className='float-right'>
                                        AU ${subTotal.toFixed(2)}

                                    </span></p>
                                    <p>Shipping
                            <span className='float-right' style={{ fontSize: this.props.checkout.shippingFee === null ? 12 : 16 }}>
                                            {this.props.checkout.shippingFee === null ? 'Not yet calculated' : (this.props.checkout.shippingFee === 0 ? 'Free' : 'AU $' + this.props.checkout.shippingFee.toFixed(2))}
                                        </span>
                                    </p>
                                    {Object.keys(this.props.discount.userCoupon).length > 0 && (
                                        <React.Fragment>
                                            <p>Discount  <span className='float-right'>
                                                {this.props.discount.userCoupon.isPercent ? '%' : 'AU $'}{this.props.discount.userCoupon.amount}
                                            </span>
                                                {subTotal < this.props.discount.userCoupon.minimumSpend && (
                                                    <React.Fragment>
                                                        <br />
                                                        <span className='text-danger' style={{ fontSize: 12 }}>
                                                            Minimum spend of AU ${this.props.discount.userCoupon.minimumSpend} is required. Otherwise discount won't be applied.
                                            </span>
                                                    </React.Fragment>
                                                )}

                                            </p>
                                            {discount !== undefined && (
                                                <p className='text-danger'>Discount <h6 className='d-inline'><Badge color='danger'><i className="fas fa-tags"></i> {this.props.discount.userCoupon.code}</Badge></h6> <span className='float-right text-danger'>
                                                    - AU ${discount.toFixed(2)}
                                                </span>
                                                </p>
                                            )}
                                        </React.Fragment>

                                    )}
                                </div>
                                <div className='my-3'>
                                    <h4>Total  <span className='float-right'>
                                        AU ${total.toFixed(2)}
                                    </span></h4>
                                </div>
                            </Collapse>

                            {this.props.checkout.errors.noMatch && (
                                <Row className='py-3'>
                                    <Col>
                                        <Label className='text-danger'>
                                            {this.props.checkout.errors.noMatch}
                                        </Label>
                                    </Col>
                                </Row>
                            )}


                            <StepWizard nav={<CheckoutNavBar />} isHashEnabled={true} >
                                <Shipping hashKey={'shipping'} />
                                <UserInfo hashKey={'userDetails'} />
                                <Payment hashKey={'payment'} />
                            </StepWizard>

                        </Col>



                        <Col lg={5} className='px-5 d-none d-lg-block'>
                            {this.props.shoppingCart.cart.map(product => (
                                <div key={product.product._id} className='border-bottom'>
                                    <Row className='px-2 py-3 ' >

                                        <Col xs='2'>
                                            <BadgeMT
                                                badgeContent={product.quantity}
                                                color='error'
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }} >
                                                <img src={product.product.image} alt={product.product.name} style={{ height: 60 }} />

                                            </BadgeMT>
                                        </Col>
                                        <Col xs='10' >
                                            <div className='pt-2'>
                                                <span className='w-75 d-inline float-left  pl-3'> {product.product.name}</span>
                                                <span className=' text-muted  font-weight-bold w-25 float-right pl-2' > ${(Math.round((product.product.price * product.quantity) * 100) / 100).toFixed(2)}</span>
                                            </div>

                                        </Col>

                                    </Row>

                                    {this.props.shoppingCart.warnings.map(eachWarning => (
                                        eachWarning.product._id === product.product._id && (
                                            <Row className='px-4 py-1 '>
                                                <p className='font-weight-bold text-danger'>{eachWarning.definition}</p>
                                            </Row>
                                        )
                                    ))}

                                </div>
                            ))}
                            {this.props.shoppingCart.warnings.map(eachWarning => (
                                !eachWarning.product.inStock && (
                                    <div key={eachWarning.product._id} className='border-bottom'>
                                        <Row className='px-2 py-3'>
                                            <Col xs='2'>
                                                <img src={eachWarning.product.image} alt={eachWarning.product.name} style={{ height: 60 }} />
                                            </Col>
                                            <Col xs='10' >
                                                <div className='pt-2'>
                                                    <span className='w-75 d-inline float-left  pl-3'> {eachWarning.product.name}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='px-4 py-1 '>
                                            <p className='font-weight-bold text-danger'>{eachWarning.definition}</p>
                                        </Row>
                                    </div>
                                )
                            ))}

                            <Form className='my-3 border-bottom' onSubmit={this.handleCoupon}>
                                <FormGroup>
                                    <Input
                                        name='coupon'
                                        className={classnames(' d-inline', { 'is-invalid': this.props.discount.errors.coupon })}
                                        value={this.props.discount.coupon} onChange={this.updateCoupon} placeholder='Gift cart or discount code' />
                                    <div className='invalid-feedback'>{this.props.discount.errors.coupon}</div>
                                    {Object.keys(this.props.discount.userCoupon).length > 0 && this.props.discount.coupon !== '' && (
                                        <span style={{ fontSize: 12 }}>You can only use one coupon at a time.</span>
                                    )}
                                </FormGroup>
                                <Button size='sm' color='success' className='my-2 mb-3' disabled={this.props.discount.coupon === ''} outline onClick={this.handleCoupon}>
                                    {this.props.discount.loading ? <ButtonSpinner /> :
                                        <span>Apply</span>
                                    }
                                </Button>
                            </Form>

                            <div className='border-bottom'>

                                <p>SubTotal  <span className='float-right'>
                                    AU ${subTotal.toFixed(2)}
                                </span></p>
                                <p>Shipping
                                                        <span className='float-right' style={{ fontSize: this.props.checkout.shippingFee === null ? 12 : 16 }}>
                                        {this.props.checkout.shippingFee === null ? 'Not yet calculated' : (this.props.checkout.shippingFee === 0 ? 'Free' : 'AU $' + this.props.checkout.shippingFee.toFixed(2))}

                                    </span>
                                </p>
                                {Object.keys(this.props.discount.userCoupon).length > 0 && (
                                    <React.Fragment>
                                        <p>Discount  <span className='float-right'>
                                            {this.props.discount.userCoupon.isPercent ? '%' : 'AU $'}{this.props.discount.userCoupon.amount}
                                        </span>
                                            {subTotal < this.props.discount.userCoupon.minimumSpend && (
                                                <React.Fragment>
                                                    <br />
                                                    <span className='text-danger' style={{ fontSize: 12 }}>
                                                        Minimum spend of AU ${this.props.discount.userCoupon.minimumSpend} is required. Otherwise discount won't be applied.
                                            </span>
                                                </React.Fragment>
                                            )}

                                        </p>
                                        {discount !== undefined && (
                                            <p className='text-danger'>Discount <h6 className='d-inline'><Badge color='danger'><i className="fas fa-tags"></i> {this.props.discount.userCoupon.code.toUpperCase()}</Badge></h6> <span className='float-right text-danger'>
                                                - AU ${discount.toFixed(2)}
                                            </span>
                                            </p>
                                        )}
                                    </React.Fragment>

                                )}
                            </div>

                            <div className='my-3'>
                                <h4>Total  <span className='float-right'>
                                    AU ${total.toFixed(2)}

                                </span></h4>
                            </div>

                        </Col>
                    </Row>
                </div>
            </LoadingOverlay>
        )
    }
}

const mapStateToProps = (state) => ({
    shoppingCart: state.cart,
    checkout: state.checkout,
    guest: state.guest,
    auth: state.form,
    orders: state.orders,
    discount: state.discount
})


export default connect(mapStateToProps, { setShippingFee, couponInputUpdate, applyCoupon, validateCart, resetWarnings, resetCouponError })(Checkout)