import React, { Component } from 'react'
import { Button, Container, Col, Row } from 'reactstrap'
import { connect } from 'react-redux'
import { notFound, found } from '../actions/notFoundAction'


class NotFound extends Component {
    componentDidMount() {
        this.props.notFound()
    }
    componentWillUnmount() {
        this.props.found()
    }
    render() {
        return (
            <div className="notfound">
                <Container className='  h-100 '>
                    <Row className='  h-100'>
                        <Col className=' my-auto'>
                            <div className=' text-center text-dark'>
                                <h1 className='font-weight-bold display-1'>
                                    404
                                </h1>
                                <h2>Oops! This Page Could Not Be Found</h2>
                                <p>Sorry but the page you are looking for does not exist, have been removed. name changed or is temporarily unavailable</p>

                                <Button href="/" size='lg' className='px-5 border-0' style={{ backgroundColor: ' rgb(235,24,79)' }} >
                                    Go To Homepage
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div >
        )
    }
}

const mapStateToProps = state => ({
    notfound: state.notfound

})


export default connect(mapStateToProps, { notFound, found })(NotFound)