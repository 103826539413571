import {
    GET_ADMIN_REVIEWS,
    GET_ADMIN_REVIEWS_COUNT,
    GET_ADMIN_REVIEWS_LOADING,
    RESET_GET_ADMIN_REVIEWS_LOADING,
    DELETE_REVIEW,
    UPDATE_ADMIN_REVIEW,
    RESET_ADMIN_REVIEWS
} from '../actions/types.js'


const initialState = {
    loading: false,
    reviews: [],
    reviewCount: 0,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_ADMIN_REVIEW:
            return {
                ...state,
                reviews: state.reviews.map(review => review._id === action.payload._id ? action.payload : review)
            }
        case DELETE_REVIEW:
            return {
                ...state,
                reviews: state.reviews.filter(review => review._id !== action.payload._id)
            }
        case GET_ADMIN_REVIEWS_COUNT:
            return {
                ...state,
                reviewCount: action.payload
            }
        case GET_ADMIN_REVIEWS:
            return {
                ...state,
                reviews: state.reviews.concat(action.payload)
            }
        case GET_ADMIN_REVIEWS_LOADING:
            return {
                ...state,
                loading: true
            }
        case RESET_GET_ADMIN_REVIEWS_LOADING:
            return {
                ...state,
                loading: false
            }
        case RESET_ADMIN_REVIEWS: {
            return {
                ...state,
                loading: false,
                reviews: [],
                reviewCount: 0,
            }
        }
        default: return state;
    }
}