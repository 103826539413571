import React, { Component } from 'react'
import Base from './Base'
import { Helmet } from "react-helmet";

class OurStory extends Component {
    render() {
        return (
            <React.Fragment>
                <div>
                    <Helmet>
                        <title>About - Our Story - Frozberries Victoria</title>
                        <meta name="description" content="About - Our Story - Frozberries Victoria" />
                    </Helmet>
                </div>
                <Base title='   Our Story' />
                <div className='px-5 py-5 '>
                    <h1 className='text-center pb-3' style={{ color: 'rgb(14,35,87)' }}>Grown by Nature
</h1>

                    <p className='ourstory mx-auto' style={{ color: 'rgb(14,35,87)', lineHeight: '1.8', fontSize: '18px', }} >
                        Frozberries, Harvestime Victoria as wholesaler,  was established with the goal to pack and supply the very best quality frozen berries, tropical fruit and purees available in Australia and to provide the best service to our customers.  We are able to tailor our products and packaging to meet your requirements. Whether its a 1kg bag of berries or enough to meet your bakery, jam, gelato or smoothie bar's production requirements, we can accommodate you accordingly.

    Our company is committed to quality, reliability and service. For this reason we are continually adopting new technology, packaging and fruit varieties as our ongoing commitment to our customers. Our staff are focused on supplying our customers with Frozberries very best.

                    </p>


                    <p className='ourstory mx-auto' style={{ color: 'rgb(14,35,87)', lineHeight: '1.8', fontSize: '18px', }} >
                        Where does our fruit originate from?  We endeavour to buy Australian wherever possible. Our Mango Cheeks (Halves) and Puree are sourced from Northern Queensland. We have occasional supply of Australian Blueberries.  However, due to harsh climatic conditions, very limited quantities of frozen berries and purees are available commercially in Australia. Our Boysenberries, for example, are sourced from lush growing regions in New Zealand and are the worlds best. Occasionally, we will buy Raspberries from Europe and, of course, Cranberries are not grown commercially in Australia and are imported from the USA.
    
                    </p>


                </div>
            </React.Fragment>
        )
    }
}
export default OurStory