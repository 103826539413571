import {
    DAILY_TOTAL_REVENUE,
    YESTERDAY_REVENUE,
    REVENUE_DETAILS_LOADING,
    RESET_REVENUE_DETAILS_LOADING,
    TODAYS_ORDER_COUNT,
    YESTERDAYS_ORDER_COUNT,
    CHANGE_STREAM_SET_TOTAL_ORDERS_YESTERDAY,
    CHANGE_STREAM_SET_TOTAL_ORDERS_TODAY,
    CHANGE_STREAM_SET_YESTERDAYS_TOTAL,
    CHANGE_STREAM_SET_TODAYS_TOTAL
} from '../actions/types.js'


const initialState = {
    loading: false,
    dailyRevenue: 0,
    yesterdayRevenue: 0,
    totalOrdersToday: 0,
    totalOrdersYesterday: 0
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CHANGE_STREAM_SET_TODAYS_TOTAL:
            return {
                ...state,
                dailyRevenue: action.payload
            }
            case CHANGE_STREAM_SET_YESTERDAYS_TOTAL:
                return {
                    ...state,
                    yesterdayRevenue: action.payload
                }
                case CHANGE_STREAM_SET_TOTAL_ORDERS_TODAY:
                    return {
                        ...state,
                        totalOrdersToday: action.payload
                    }
                    case CHANGE_STREAM_SET_TOTAL_ORDERS_YESTERDAY:
                        return {
                            ...state,
                            totalOrdersYesterday: action.payload
                        }
        case DAILY_TOTAL_REVENUE:
            return {
                ...state,
                dailyRevenue: action.payload
            }
        case YESTERDAY_REVENUE:
            return {
                ...state,
                yesterdayRevenue: action.payload
            }
        case TODAYS_ORDER_COUNT:
            return {
                ...state,
                totalOrdersToday: action.payload
            }
        case YESTERDAYS_ORDER_COUNT:
            return {
                ...state,
                totalOrdersYesterday: action.payload
            }
        case REVENUE_DETAILS_LOADING:
            return {
                ...state,
                loading: true
            }
        case RESET_REVENUE_DETAILS_LOADING:
            return {
                ...state,
                loading: false
            }
        default: return state;
    }
}