import {
    UPDATE_COUPON,
    COUPON_LOADING,
    COUPON_ENTERED,
    COUPON_ERROR,
    RESET_COUPON_ERRORS,
    RESET_COUPON
} from '../actions/types.js'


const initialState = {
    coupon: '',
    errors: {},
    loading: false,
    userCoupon: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case RESET_COUPON_ERRORS:
            return {
                ...state,
                errors: {}
            }
        case UPDATE_COUPON:
            return {
                ...state,
                [action.payload.field]: action.payload.value,
            }
        case COUPON_LOADING:
            return {
                ...state,
                loading: true
            }
        case COUPON_ERROR:
            return {
                ...state,
                errors: action.payload,
                loading: false,

            }
        case COUPON_ENTERED:
            return {
                ...state,
                userCoupon: action.payload,
                loading: false,
                errors: {},
                coupon: ''
            }
        case RESET_COUPON:
            return {
                ...state,
                userCoupon: {},
                loading: false,
                errors: {},
                coupon: ''
            }
        default: return state;
    }
}