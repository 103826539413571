import {
    MONTLY_SALES,
    MONTLY_SALES_LOADING,
    CHANGE_STREAM_SALES_GRAPH,
    MONTLY_SALES_LOADING_RESET
} from '../actions/types.js'


const initialState = {
    loading: false,
    sales: []

}

export default function (state = initialState, action) {
    switch (action.type) {
        case CHANGE_STREAM_SALES_GRAPH:
            return {
                ...state,
                sales: action.payload
            }
        case MONTLY_SALES:
            return {
                ...state,
                sales: action.payload
            }
        case MONTLY_SALES_LOADING:
            return {
                ...state,
                loading: true
            }

        case MONTLY_SALES_LOADING_RESET:
            return {
                ...state,
                loading: false
            }
        default: return state;
    }
}