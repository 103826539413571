import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toggleCart } from '../actions/shoppingCart'
import { Drawer } from '@material-ui/core';
import { Row, Col, Button } from 'reactstrap'
import { updateShoppingCart, removeFromCart } from '../actions/shoppingCart'
import { withRouter } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay';


class SideBar extends Component {

    state = { width: 0, height: 0 };

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = (e) => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }


    closeCart = (e) => {
        this.props.toggleCart()
    }
    routeToCart = (e) => {
        this.props.history.push('/cart')
        this.closeCart()
    }


    removeItem = (product) => {
        if (!this.props.auth.isAuthenticated && this.props.guest.userid === '') {
            let cart = [...this.props.shoppingCart.cart]
            cart = cart.filter(eachItem => eachItem.product._id !== product._id)
            this.props.updateShoppingCart(cart)
        }
        if (this.props.guest.userid !== '') {
            this.props.removeFromCart(product, this.props.guest.userid)
        }
        if (this.props.auth.isAuthenticated) {
            this.props.removeFromCart(product, this.props.auth.user.id)
        }
    }


    routeToSpecificPage = (product) => {
        this.props.history.push({
            pathname: `/shop/${product.name.replace(/[^A-Z0-9]+/ig, "-")}/${product._id}`,
            state: { product }
        })
        this.props.toggleCart()
    }


    render() {
        return (
            <Drawer
                anchor='right'
                open={this.props.shoppingCart.cartOpen === true}
                onClose={this.closeCart}
            >

                <div
                    style={{ width: '300px', lineHeight: 1 }}
                    role="presentation"
                >
                    <div className=' text-light font-weight-bold  ' style={{ height: '40px', backgroundColor: 'rgb(236,23,79)' }}>
                        <h5 className='text-left pl-2 d-inline' style={{ fontSize: '30px' }}>Shopping Cart</h5>
                        <span className='float-right close-btn mr-3' onClick={this.closeCart} >&times;</span>
                    </div>

                    {this.props.shoppingCart.cart.length === 0 ? (
                        <div className='px-3 py-3' style={{ fontSize: 20 }}>You have no items in your shopping cart.</div>
                    ) : (
                            <React.Fragment>
                                <div className='side-drawer position-absolute' style={{ overflowY: 'scroll', overflowX: 'hidden', height: this.state.height - 150 }}>
                                    {this.props.shoppingCart.cart.map(product => (
                                        <div key={product.product._id}>
                                            <LoadingOverlay
                                                active={this.props.shoppingCart.removeFromCartLoading.productId === product.product._id}
                                                spinner
                                            >
                                                <Row className='px-2 py-3 border-bottom ' >

                                                    <Col xs='3' >
                                                        <img src={product.product.image} alt={product.product.name} className='img-fluid' style={{ lineHeight: 0 }} />
                                                    </Col>
                                                    <Col xs='9' >
                                                        <span className=' item-delete-button text-dark float-right' onClick={() => this.removeItem(product.product)} >&times;</span>
                                                        <div className='eachRow ' style={{ lineHeight: '30px' }} onClick={() => this.routeToSpecificPage(product.product)}>
                                                            <p className='font-weight-bold pr-4 ' style={{ wordWrap: 'break-word', fontSize: '16px' }}>
                                                                {product.product.name}
                                                            </p>
                                                        </div>
                                                        <div className='mt-3 '>
                                                            <span className='text-muted d-inline'> QTY: {product.quantity}</span>
                                                            <span className=' text-muted  font-weight-bold float-right' > ${(Math.round((product.product.price * product.quantity) * 100) / 100).toFixed(2)}</span>
                                                        </div>
                                                    </Col>

                                                </Row>
                                            </LoadingOverlay>
                                        </div>
                                    ))}
                                </div>

                                <div className='inner-buttons-container border-top w-100 overflow-hidden' >
                                    {/* <Row className='py-3 mx-3'>
                                        <Col>

                                            <Progress max={99}
                                                color="danger"
                                                value={this.props.shoppingCart.cart.length !== 0 ?
                                                    ((this.props.shoppingCart.cart.map(eachItem => eachItem.quantity * eachItem.product.price).reduce((total, currentValue) => total + currentValue)) >= 99 ? 99 :
                                                        (this.props.shoppingCart.cart.map(eachItem => eachItem.quantity * eachItem.product.price).reduce((total, currentValue) => total + currentValue)))
                                                    :
                                                    0}
                                            >
                                                {this.props.shoppingCart.cart.length !== 0 ?
                                                    ((Math.round(((this.props.shoppingCart.cart.map(eachItem => eachItem.quantity * eachItem.product.price).reduce((total, currentValue) => total + currentValue)) * 100) / 100).toFixed(2)) >= 100 ? 100 :
                                                        (Math.round((((this.props.shoppingCart.cart.map(eachItem => eachItem.quantity * eachItem.product.price).reduce((total, currentValue) => total + currentValue)))) * 100) / 100)).toFixed(2)
                                                    :
                                                    0}%
                                </Progress>
                                            <div className='text-center  pt-4' style={{ fontSize: 12 }}>
                                                {(Math.round(((this.props.shoppingCart.cart.map(eachItem => eachItem.quantity * eachItem.product.price).reduce((total, currentValue) => total + currentValue)) * 100) / 100).toFixed(2)) >= 100 ?
                                                    (
                                                        'Eligible for FREE SHIPPING! '
                                                    ) : (
                                                        'Get FREE SHIPPING if you order $99.00!'
                                                    )}
                                            </div>
                                        </Col>
                                    </Row> */}
                                    <Row className='py-3 '>
                                        <Col >

                                            <span style={{ fontSize: 30 }} className='d-inline pl-2 font-weight-bold '>Total: </span>
                                            <span style={{ fontSize: 30 }} className='float-right font-weight-bold text-muted  pr-2'>
                                                ${
                                                    (Math.round((this.props.shoppingCart.cart.length !== 0 ?
                                                        this.props.shoppingCart.cart.map(eachItem => eachItem.quantity * eachItem.product.price).reduce((total, currentValue) => total + currentValue) : 0
                                                    ) * 100) / 100).toFixed(2)} AUD</span>

                                        </Col>
                                    </Row>

                                    <Row  >
                                        <Col className='p-0'>

                                            <Button className='border-0 btn-block bg-light text-muted font-weight-bold px-3 py-3 ' style={{ borderRadius: 0 }}
                                                onClick={this.routeToCart}>

                                                VIEW CART
                                                </Button>

                                        </Col>
                                        <Col className='p-0'>
                                            <Button className='font-weight-bold text-light btn-block px-3 py-3' color='success' style={{ borderRadius: 0 }}
                                                onClick={() => { this.props.history.push('/checkout'); this.props.toggleCart() }}
                                            >
                                                CHECKOUT
                                                </Button>
                                        </Col>
                                    </Row>

                                </div>
                            </React.Fragment>
                        )}

                </div>

            </Drawer >
        )
    }
}

const mapStateToProps = (state) => ({
    shoppingCart: state.cart,
    products: state.products,
    auth: state.form,
    guest: state.guest
})


export default connect(mapStateToProps, { toggleCart, updateShoppingCart, removeFromCart })(withRouter(SideBar))