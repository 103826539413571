import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col, Button, Breadcrumb, BreadcrumbItem } from 'reactstrap'
import ReactHtmlParser from 'react-html-parser';


class Article extends Component {

    state = {
        article: {}
    }

    addClassToImages = () => {
        var images = document.getElementsByTagName('img')
        if (images.length !== 0) {
            for (let i = 0; i < images.length; i++) {
                images[i].className += " img-fluid";
            }
        }
    }



    componentDidMount() {
        if (this.props.location.state !== undefined && this.props.location.state.article) {

            this.setState({
                article: this.props.location.state.article,
            }, () => {
                this.addClassToImages()

            })

        }

    }

    capitalizeFirstLetter = (str) => {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }


    render() {
        return (
            <div >
                <Row >
                    <Col >
                        <div className='mx-5 my-auto'>
                            <Breadcrumb >
                                <BreadcrumbItem className='font-weight-bold text-muted' style={{ cursor: 'pointer', fontSize: 12 }} onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                                <BreadcrumbItem className='font-weight-bold text-muted' style={{ cursor: 'pointer', fontSize: 12 }} onClick={() =>
                                    this.props.history.push('/blog')
                                }>Blog</BreadcrumbItem>
                                <BreadcrumbItem className='font-weight-bold text-muted' style={{ cursor: 'pointer', fontSize: 12 }} active>{this.state.article.title}</BreadcrumbItem>
                            </Breadcrumb>
                        </div>
                    </Col>
                </Row>

                {Object.keys(this.state.article).length !== 0 && (
                    <div key={this.state.article._id}>
                        <div >
                            <img style={{ height: '50vh', minHeight: '540px', objectFit: 'cover', }} className="d-block w-100 position-absolute" src={this.state.article.thumbnailImage} alt={this.state.article.title} />
                            <div className="blog-overlay text-center w-100 position-relative"
                            >
                            </div>
                            <div className='h-100 pb-5' style={{ backgroundColor: 'rgb(251,242,222)' }}>
                                <div className='mx-auto desc-container position-relative' style={{ backgroundColor: 'white', marginTop: -80, borderRadius: '10px' }}>
                                    <div style={{ paddingTop: '50px' }}>
                                        <h1 className='journal-title text-center font-weight-bold ' style={{ letterSpacing: '2px', color: 'rgb(197,83,59)' }}>
                                            {this.state.article.title}

                                        </h1>
                                        <p className='journal-subTitle text-center px-5 mt-5 ' style={{ fontSize: '18px', fontWeight: 600, color: 'rgb(197,83,59)', }}> {this.state.article.subTitle}</p>

                                    </div>
                                    <div className='container-md'>
                                        {this.state.article.paragraphs.map((eachParagraph, i) => (
                                            <Row>
                                                <Col md='8' className='mx-auto'>
                                                    <div className='mt-4 px-4'>
                                                        <h4 className='font-weight-bold' style={{ letterSpacing: '2px', color: 'rgb(197,83,59)' }}>
                                                            {eachParagraph.paragraphTitle}

                                                        </h4>
                                                    </div>


                                                    <div className='mt-4 px-4' style={{ color: '#454337', fontSize: '18px', lineHeight: 1.7, fontFamily: `'Yantramanav', sans-serif`, paddingBottom: i + 1 === this.state.article.paragraphs.length && '50px' }}>
                                                        {ReactHtmlParser(eachParagraph.paragraph)}

                                                    </div>

                                                </Col>
                                            </Row>
                                        ))}

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                )
                }
                {/* {this.state.journals.length !== 0 && (this.state.journals.map(journal => (
                    <div key={journal._id} >

                        <div>
                            <img style={{ height: '50vh', minHeight: '540px', objectFit: 'cover', }} className="d-block w-100 position-absolute" src={image2} alt={image2} />
                            <div className="blog-overlay text-center w-100 position-relative"
                            >
                            </div>
                        </div >
                        <div style={{ backgroundColor: 'rgb(251,242,222)' }}>
                            <div className='mx-auto desc-container position-relative' style={{ backgroundColor: 'white', marginTop: -80, borderRadius: '10px' }}>
                                <div style={{ paddingTop: '50px' }}>
                                    <h1 className='journal-title text-center font-weight-bold ' style={{ letterSpacing: '2px', color: 'rgb(197,83,59)' }}>
                                        {journal.title}

                                    </h1>
                                    <p className='journal-subTitle text-center px-5 mt-5 ' style={{ fontSize: '18px', fontWeight: 600, color: 'rgb(197,83,59)', }}> {journal.subTitle}</p>
                                    <p className='text-center px-3 mt-5' style={{ fontSize: '18px', fontWeight: 600, color: 'rgb(197,83,59)', }}>      By {this.capitalizeFirstLetter(journal.createdBy)}</p>

                                </div>
                                <div className='container-md'>
                                    <Row>
                                        <Col md='8' className='mx-auto'>


                                            <div className='mt-4 px-4' style={{ fontSize: '18px', lineHeight: 1.7, color: '#454347', fontFamily: `'Yantramanav', sans-serif` }}>
                                                {ReactHtmlParser(journal.blog)}

                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
                )} */}

            </div >
        )
    }
}


const mapStateToProps = state => ({
    journals: state.journals
})


export default connect(mapStateToProps, {})(Article)