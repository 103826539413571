import {
    ADMIN_PRODUCTS_LOADING,
    ADMIN_PRODUCTS_LOADING_RESET,
    GET_ADMIN_PRODUCTS,
    GET_ADMIN_PRODUCTS_COUNT,
    PRODUCT_FORM_ERRORS,
    NEW_PRODUCT_CREATED,
    GET_SINGLE_ADMIN_PRODUCT,
    DELETE_SINGLE_ADMIN_PRODUCT,
    DELETE_BUTTON_LOADING,
    RESET_DELETE_BUTTON_LOADING,
    UPDATE_PRODUCT,
    RESET_ADMIN_PRODUCT_FORM

} from './types'
import axios from 'axios'
import { logoutUser } from './formActions'


export const resetForm = () => dispatch => {
    dispatch({
        type: RESET_ADMIN_PRODUCT_FORM
    })
}


export const getAdminProducts = (page, filter, query) => dispatch => {
    dispatch({
        type: ADMIN_PRODUCTS_LOADING
    })
    axios.get(`/api/admin/getAdminProducts/${page}/${filter}/${query}`)
        .then(res => {
            dispatch({
                type: GET_ADMIN_PRODUCTS,
                payload: res.data.rangeProducts
            })
            dispatch({
                type: GET_ADMIN_PRODUCTS_COUNT,
                payload: res.data.count
            })
            dispatch({
                type: ADMIN_PRODUCTS_LOADING_RESET
            })
        })
        .catch(err => {
            dispatch({
                type: ADMIN_PRODUCTS_LOADING_RESET
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
            console.log(err)
        })

}

export const newProduct = data => dispatch => {
    dispatch({
        type: ADMIN_PRODUCTS_LOADING
    })
    axios.post(`/api/admin/newProduct`, data)
        .then(res => {
            dispatch({
                type: NEW_PRODUCT_CREATED,
                payload: res.data
            })
            dispatch({
                type: ADMIN_PRODUCTS_LOADING_RESET
            })
        })
        .catch(err => {
            dispatch({
                type: PRODUCT_FORM_ERRORS,
                payload: err.response.data
            })
            dispatch({
                type: ADMIN_PRODUCTS_LOADING_RESET
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
        })
}

export const getSingleProduct = id => dispatch => {
    dispatch({
        type: ADMIN_PRODUCTS_LOADING
    })
    axios.get(`/api/admin/getSingleProduct/${id}`)
        .then(res => {
            dispatch({
                type: GET_SINGLE_ADMIN_PRODUCT,
                payload: res.data
            })
            dispatch({
                type: ADMIN_PRODUCTS_LOADING_RESET
            })
        })
        .catch(err => {
            dispatch({
                type: ADMIN_PRODUCTS_LOADING_RESET
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
        })
}


export const deleteProduct = (id) => dispatch => {
    dispatch({
        type: ADMIN_PRODUCTS_LOADING
    })
    dispatch({
        type: DELETE_BUTTON_LOADING
    })
    axios.get(`/api/admin/deleteProduct/${id}`)
        .then(res => {
            dispatch({
                type: DELETE_SINGLE_ADMIN_PRODUCT,
                payload: res.data
            })
            dispatch({
                type: ADMIN_PRODUCTS_LOADING_RESET
            })
            dispatch({
                type: RESET_DELETE_BUTTON_LOADING
            })
        })
        .catch(err => {
            dispatch({
                type: ADMIN_PRODUCTS_LOADING_RESET
            })
            dispatch({
                type: RESET_DELETE_BUTTON_LOADING
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
        })
}


export const UpdateStoreProduct = (data, id) => dispatch => {
    dispatch({
        type: ADMIN_PRODUCTS_LOADING
    })

    axios.post(`/api/admin/updateProduct/${id}`, data)
        .then(res => {
            dispatch({
                type: UPDATE_PRODUCT,
                payload: res.data
            })
            dispatch({
                type: ADMIN_PRODUCTS_LOADING_RESET
            })
        })
        .catch(err => {
            dispatch({
                type: PRODUCT_FORM_ERRORS,
                payload: err.response.data
            })
            dispatch({
                type: ADMIN_PRODUCTS_LOADING_RESET
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
        })
}