import {
    TOGGLE_CART,
    UPDATE_SHOPPING_CART,
    RESET_CART,
    GET_DB_CART,
    ADD_TO_CART_LOADING,
    RESET_ADD_TO_CART_LOADING,
    RESET_REMOVE_FROM_CART,
    REMOVE_FROM_CART_LOADING,
    DISABLE_CART_SYNC,
    RESET_CART_SYNC,
    VALIDATE_CART,
    CART_WARNINGS, RESET_WARNINGS
} from '../actions/types.js'

const initialState = {
    cartOpen: false,
    cart: [],
    addToCartLoading: {
        productId: null,
        loading: false
    },
    removeFromCartLoading: {
        productId: null,
        loading: false
    },
    cartLoading: false,
    syncCart: true,
    warnings: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case RESET_WARNINGS:
            return {
                ...state,
                warnings: []
            }
        case CART_WARNINGS:
            return {
                ...state,
                warnings: action.payload
            }
        case VALIDATE_CART:
            return {
                ...state,
                cart: action.payload
            }
        case RESET_CART_SYNC:
            return {
                ...state,
                syncCart: true
            }

        case DISABLE_CART_SYNC:
            return {
                ...state,
                syncCart: false
            }
        case TOGGLE_CART:
            return {
                ...state,
                cartOpen: !state.cartOpen
            }
        case UPDATE_SHOPPING_CART:
            return {
                ...state,
                cart: action.payload
            }
        case GET_DB_CART:
            return {
                ...state,
                cart: action.payload
            }

        case RESET_CART:
            return {
                ...state,
                cart: []
            }
        case ADD_TO_CART_LOADING:
            return {
                ...state,
                addToCartLoading: {
                    productId: action.payload,
                    loading: true
                }
            }
        case REMOVE_FROM_CART_LOADING:
            return {
                ...state,
                removeFromCartLoading: {
                    productId: action.payload,
                    loading: true
                }
            }
        case RESET_ADD_TO_CART_LOADING:
            return {
                ...state,
                addToCartLoading: {
                    productId: null,
                    loading: false
                }
            }
        case RESET_REMOVE_FROM_CART:
            return {
                ...state,
                removeFromCartLoading: {
                    productId: null,
                    loading: false
                }
            }

        default: return state;
    }
}