import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import br1 from '../images/aco.png'
import br2 from '../slideShow/br2.png'
import br5 from '../slideShow/br5.png'
import br3 from '../slideShow/br3.png'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Fade from 'react-reveal/Fade';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 750 },
        items: 3,
        slidesToSlide: 2
    },
    mobile: {
        breakpoint: { max: 750, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};


export default class Brands extends Component {
    render() {
        return (
            <div className='my-5'>
                <Row >
                    <Col >
                        <Fade bottom>
                            <Carousel
                                className='container text-center'
                                swipeable={true}
                                draggable={true}
                                responsive={responsive}
                                autoPlay
                                autoPlaySpeed={3000}
                                infinite={true}
                                removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                            >
                                <div>
                                    <img src={br1} alt={br1} style={{ height: 90 }} alt='Frozberries' />
                                </div>
                                <div>
                                    <img src={br2} alt={br2} style={{ height: 90 }} alt='Elgin Organic' />
                                </div>
                                <div>
                                    <img src={br3} alt='Australian Grown Frozen Fruit' style={{ height: 90 }} />
                                </div>
                                <div>
                                    <img src={br5} alt={br5} style={{ height: 90 }} alt='Harvestime' />
                                </div>
                            </Carousel>
                        </Fade>
                    </Col>
                </Row>
            </div>
        )
    }
}
