import {
    GET_PRODUCTS,
    FILTER_PRODUCTS,
    LOAD_PRODUCTS,
    GET_SINGLE_PRODUCT,
    GET_TOTAL_PAGE_NUMBER,
    RESET_PRODUCTS,
    GET_YOU_MAY_ALSO_LIKE_PRODUCTS,
    RESET_YOU_MAY_ALSO_LIKE_PRODUCTS,
    SINGLE_PRODUCT_LOADING,
    RESET_SINGLE_PRODUCT_LOADING
} from './types'
import axios from 'axios'


export const getProducts = (page, orderby, searchQuery, category, subCategory) => dispatch => {
    dispatch({
        type: LOAD_PRODUCTS
    })

    axios.get(`/api/products/getproducts/${page}/${orderby}/${searchQuery}/${category}/${subCategory}`)
        .then(res => {
            if (res.data.rangeProducts) {
                dispatch({
                    type: GET_PRODUCTS,
                    payload: res.data.rangeProducts
                })
            }
            if (res.data.count) {
                dispatch({
                    type: GET_TOTAL_PAGE_NUMBER,
                    payload: res.data.count
                })
            }

        })
        .catch(err => console.log(err))
}


export const filterProducts = (filter) => dispatch => {
    dispatch({
        type: FILTER_PRODUCTS,
        payload: filter
    })
}

export const resetProducts = () => dispatch => {
    dispatch({
        type: RESET_PRODUCTS,
    })
}

export const getSingleProduct = (id) => dispatch => {
    dispatch({
        type: SINGLE_PRODUCT_LOADING
    })
    axios.get(`/api/products/getSpecificProduct/${id}`)
        .then(res => {
            dispatch({
                type: GET_SINGLE_PRODUCT,
                payload: res.data
            })
            dispatch({
                type: RESET_SINGLE_PRODUCT_LOADING
            })
        })
        .catch(err => {
            dispatch({
                type: RESET_SINGLE_PRODUCT_LOADING
            })
            console.log(err)
        }
        )

}

export const getYouMayAlsoLike = (product) => dispatch => {
    axios.get(`/api/products/getYouMayAlsoLike`, { params: product })
        .then(res => {
            dispatch({
                type: GET_YOU_MAY_ALSO_LIKE_PRODUCTS,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const resetYouMayAlsoLike = () => dispatch => {
    dispatch({
        type: RESET_YOU_MAY_ALSO_LIKE_PRODUCTS,
    })
}





