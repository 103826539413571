import {
    UPDATE_COUPON,
    COUPON_ERROR,
    COUPON_ENTERED,
    RESET_COUPON_ERRORS,
    RESET_COUPON
} from './types'
import axios from 'axios'

export const couponInputUpdate = (field, value) => dispatch => {
    dispatch({
        type: UPDATE_COUPON,
        payload: {
            field,
            value
        }
    })
}

export const applyCoupon = coupon => dispatch => {
    axios.post('/api/discounts/applyDiscount', coupon)
        .then(res => {
            dispatch({
                type: COUPON_ENTERED,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: COUPON_ERROR,
                payload: err.response.data
            })
        }
        )

}

export const resetCouponError = () => dispatch => {
    dispatch({
        type: RESET_COUPON_ERRORS
    })
}

export const resetCoupon = () => dispatch => {
    dispatch({
        type: RESET_COUPON
    })
}

