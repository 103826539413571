import {
    GET_ADMIN_BLOGS,
    GET_ADMIN_BLOGS_COUNT,
    GET_ADMIN_BLOGS_LOADING,
    GET_ADMIN_BLOGS_LOADING_RESET,
    RESET_BLOG_REDUCER,
    PARAGRAPH_CHANGE,
    UPDATE_BLOG_DELETE_LIST,
    BLOG_CHANGE,
    ADD_NEW_PARAGRAPH,
    REMOVE_PARAGRAPH,
    BLOG_CREATED_SUCCESS,
    BLOG_ERRORS,
    CREATE_NEW_BLOG_ID,
    UPDATE_BLOG,
    SET_BLOG,
    RESET_BLOG_TYPING,
    NEW_IMAGE_UPLOADED,
    BLOG_DELETED,
    RESET_BLOG_DELETE_STATUS,

} from '../actions/types.js'


const initialState = {
    loading: false,
    blogs: [],
    blogsCount: 0,
    blog: {
        title: '',
        subTitle: '',
        paragraphs: [{
            paragraphTitle: '',
            paragraph: '',
        }],
    },
    blogDeleted: false,
    deleteList: [],
    success: false,
    errors: {},
    typing: false,
    newArticleClick: false,
    routeClick: false,
    addedImages: [],
    blogDeleted: false,

}

export default function (state = initialState, action) {
    switch (action.type) {
        case RESET_BLOG_DELETE_STATUS:
            return {
                ...state,
                blogDeleted: false
            }
        case BLOG_DELETED:
            return {
                ...state,
                blogDeleted: true,
                blogs: state.blogs.filter(blog => blog._id !== action.payload._id)
            }
        case CREATE_NEW_BLOG_ID:
            return {
                ...state,
                blog: action.payload,
                newArticleClick: true
            }
        case UPDATE_BLOG:
            return {
                ...state,
                blog: action.payload,
                errors: {}
            }
        case SET_BLOG:
            return {
                ...state,
                blog: action.payload,
                routeClick: true
            }


        case BLOG_ERRORS:
            return {
                ...state,
                errors: action.payload
            }
        case BLOG_CREATED_SUCCESS:
            return {
                ...state,
                success: true
            }
        case UPDATE_BLOG_DELETE_LIST:
            return {
                ...state,
                deleteList: action.payload
            }
        case REMOVE_PARAGRAPH:
            return {
                ...state,
                blog: {
                    ...state.blog,
                    paragraphs: [...state.blog.paragraphs.filter((paragraph, index) => index !== action.payload)]
                    // [...state.blog.paragraphs.splice(action.payload - 1, 1)]
                },
                errors: {}
            }
        case ADD_NEW_PARAGRAPH:
            return {
                ...state,
                blog: {
                    ...state.blog,
                    paragraphs: [...state.blog.paragraphs, action.payload]
                },
                errors: {}
            }
        case BLOG_CHANGE:
            return {
                ...state,
                blog: action.payload,
                typing: true
            }
        case PARAGRAPH_CHANGE:
            return {
                ...state,
                blog: {
                    ...state.blog,
                    paragraphs: action.payload,
                },
                typing: true,
                errors: {}

            }
        case GET_ADMIN_BLOGS:
            return {
                ...state,
                blogs: action.payload
            }
        case GET_ADMIN_BLOGS_COUNT:
            return {
                ...state,
                blogsCount: action.payload
            }
        case GET_ADMIN_BLOGS_LOADING:
            return {
                ...state,
                loading: true
            }
        case GET_ADMIN_BLOGS_LOADING_RESET:
            return {
                ...state,
                loading: false
            }
        case RESET_BLOG_TYPING:
            return {
                ...state,
                typing: false
            }

        case NEW_IMAGE_UPLOADED:
            return {
                ...state,
                addedImages: [...state.addedImages, action.payload]
            }
        case RESET_BLOG_REDUCER:
            return {
                ...state,
                loading: false,
                blogs: [],
                blogsCount: 0,
                blog: {
                    title: '',
                    subTitle: '',
                    paragraphs: [{
                        paragraphTitle: '',
                        paragraph: '',
                    }]
                },
                blogDeleted: false,
                deleteList: [],
                errors: {},
                success: false,
                newArticleClick: false,
                routeClick: false,
                typing: false,
                addedImages: [],
            }
        default: return state;
    }
}