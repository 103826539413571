import React from 'react'
import { InputGroup, InputGroupAddon, InputGroupText, Input, Alert } from 'reactstrap';
import { connect } from 'react-redux'
import classnames from 'classnames'
import { Helmet } from "react-helmet";
import { resetPassword, resetShowMessage } from '../actions/formActions'
import { Redirect } from 'react-router-dom'

class ResetPassword extends React.Component {
    state = {
        email: '',
        errors: {}
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    onSubmit = (e) => {
        e.preventDefault();
        const data = {
            email: this.state.email,
        }
        this.props.resetPassword(data)

    }

    componentDidUpdate(prevProps) {
        if (this.props.errors !== prevProps.errors) {
            this.setState({
                errors: this.props.errors
            })
        }
    }
    componentWillUnmount() {
        this.props.resetShowMessage()
    }

    render() {
        const { errors } = this.state
        if (localStorage.jwtToken) {
            return <Redirect to='/dashboard' />
        }

        return (
            <div className='container my-5 py-5' style={{ minHeight: '50vh' }}>
                <div>
                    <Helmet>
                        <title>Reset Password - Frozberries Victoria</title>
                        <meta name="description" content="Reset Password - Frozberries Victoria" />
                    </Helmet>
                </div>
                <div className='row'>
                    <div className='col-md-8 m-auto'>
                        <h1 className='display-4 text-center mb-5'>Reset Password</h1>
                        <p>We will send you an email to reset your password.</p>
                        {this.props.auth.showResetPasswordSuccess && (
                            <Alert color='success'>
                                We've sent you an email with a link to update your password.
                                If you haven't received any emails, please re-submit the form below.
                            </Alert>
                        )}
                        <form onSubmit={this.onSubmit} >

                            <InputGroup className='my-2'>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>@</InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="email" onChange={this.onChange} className={classnames('', { 'is-invalid': errors.email })} value={this.state.email} name='email' />
                                <div className='invalid-feedback'>{errors.email}</div>
                            </InputGroup>

                            <input
                                disabled={this.state.email === '' ? true : false}
                                type='submit' value='Reset' className=' text-light btn-block mt-4 p-2 border-0' style={{ backgroundColor: 'rgb(236,23,79)' }} />
                        </form>
                    </div>
                </div>
            </div >
        )
    }
}
const mapStateToProps = state => ({
    errors: state.errors,
    auth: state.form,
    cart: state.cart
})

export default connect(mapStateToProps, { resetPassword, resetShowMessage })(ResetPassword)
