import axios from 'axios';
import {
    GET_JOURNALS,
    JOURNAL_ERRORS
} from './types'




export const getJournals = (page) => dispatch => {
    // dispatch({
    //     type: SUBMIT_JOURNAL_LOADING
    // })
    axios.get(`/api/journal/getJournals/${page}`)
        .then(res => {
            dispatch({
                type: GET_JOURNALS,
                payload: res.data.journals
            })
            // dispatch({
            //     type: SUBMIT_JOURNAL_LOADING_RESET
            // })
        })
        .catch(err => {
            // dispatch({
            //     type: SUBMIT_JOURNAL_LOADING_RESET
            // })
            dispatch({
                type: JOURNAL_ERRORS,
                payload: err.response.data

            })
        })

}