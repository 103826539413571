import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getLatestOrders, todaysOrdersUpdate } from '../../actions/LatestOrders'
import moment from 'moment'
import {
    Row, Col
} from 'reactstrap'
import Spinner from '../Spinner'
import { withRouter } from 'react-router-dom'


class LatestOrders extends Component {


    routeToOrder = order => {
        this.props.history.push({
            pathname: `/adminpanel/orders/${order._id}`,
        })
    }


    render() {
        return (
            <div className=' text-left bg-light px-3 py-3  h-100 ' style={{ borderRadius: 10, overflow: 'scroll' }}>
                <Row>
                    <Col>
                        <h6 className='font-weight-bold text-secondary'>Today's Orders</h6>
                        <div className='table-responsive'>
                            <table className="table">
                                <thead className='table-borderless'>
                                    <tr>
                                        <th scope="col">ORDER ID</th>
                                        <th scope="col">USER</th>
                                        <th scope="col">TOTAL</th>
                                        <th scope='col'>ORDER STATUS</th>
                                        <th scope='col'>ORDER DATE</th>

                                    </tr>
                                </thead>

                                <tbody >
                                    {this.props.latestOrders.orders.map(item => (
                                        <tr onClick={(e) => this.routeToOrder(item)} className='admin-each-product' key={item._id} style={{ cursor: 'pointer' }}>
                                            <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>  {item.orderid.toUpperCase()}</td>
                                            <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{item.email}</td>
                                            <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>                                            AUD {(item.total / 100).toFixed(2)}
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{item.orderStatus}</td>
                                            <td style={{ verticalAlign: 'middle' }} className='font-weight-bold '> {moment(item.created_at).fromNow()}</td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                        {this.props.latestOrders.loading && <div className='text-center'>
                            <Spinner />
                        </div>}
                        {!this.props.latestOrders.loading && this.props.latestOrders.orders.length === 0 &&
                            <div>
                                <h5 className='text-center text-muted'>No orders found.</h5>
                            </div>}
                    </Col>
                </Row>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    latestOrders: state.latestOrders
})


export default connect(mapStateToProps, { getLatestOrders, todaysOrdersUpdate })(withRouter(LatestOrders))
