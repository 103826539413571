import React, { Component } from 'react'
import { Col, Row, } from 'reactstrap'
import Fade from 'react-reveal/Fade';
import wildberries from '../images/wildberries.jpg'
import spinach from '../images/spinach.jpeg'
import frozenberries from '../images/frozenberries.jpg'

const products = [{
    title: 'Frozen wild blueberries',
    description: 'The most notable difference between wild and cultivated blueberries probably lies in nutritional content. Wild blueberries are believed to have one of the highest antioxidant levels of all common fruits and vegetables',
    image: wildberries,
    buttonText: 'Shop Frozen Wild Blueberries',
    altText: 'Wild blueberries Australia',
    route: '/shop?productSearch=wild%20blueberries'
},

{
    title: 'Australian Grown Frozen Berries',
    description: 'Just the worlds best berries, flash frozen at the peak of their freshness, sourced from the farms here in our own backyard. ',
    image: frozenberries,
    buttonText: 'Shop Australian Frozen Berries',
    route: '/shop?category=australian&page=1',
    altText: 'Australian Frozen Fruit',

}
    , {
    title: 'Organic Frozen Vegetables',
    description: 'Individually quickly frozen, locking in fresh vegetable taste, texture and nutritions. Choose from frozen kale, spinach, cauliflower and many more, available in 500g pouches.',
    image: spinach,
    buttonText: 'Shop Frozen Vegetables',
    route: '/shop?category=organic&page=1&subCategory=vegetables',
    altText: 'Frozen Organic Vegetables',



}]



export default class ShopRange extends Component {
    render() {
        return (
            <div>
                <div className='p-5' style={{ backgroundColor: 'whitesmoke' }}>
                    <div className='container'>
                        <Row >
                            {products.map((product, index) => (
                                <Col key={index} lg='4' md='12' className='my-2'>
                                    <Fade bottom>
                                        <div className='h-100 position-relative' style={{ backgroundColor: 'white' }}>
                                            <img src={product.image} alt={product.altText} className='img-fluid' />
                                            <div className='p-3 ' style={{ minHeight: 230 }}>
                                                <h1 className='font-weight-bold' style={{ fontSize: 18 }}>
                                                    {product.title}
                                                </h1>
                                                <p style={{ fontSize: 15 }}>
                                                    {product.description}
                                                </p>
                                                <div className='position-absolute' style={{ bottom: 0 }}>
                                                    <p style={{ fontSize: 14, }}>
                                                        <a href={product.route} style={{ color: 'crimson' }}>
                                                            {product.buttonText}  <i className="fas fa-chevron-right pl-1"></i>
                                                        </a>
                                                    </p>
                                                </div>

                                            </div>

                                        </div>
                                    </Fade >
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}
