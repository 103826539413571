import React, { Component } from 'react'
import { Col, Row, Button } from 'reactstrap'
import Slide from 'react-reveal/Slide';
import acai from '../images/acaipacks.png'
import australia from '../images/australiawide.png'
import natural from '../images/natural.png'
import { Link } from 'react-router-dom'


export default class AcaiPacks extends Component {
    render() {
        return (
            <div>
                <div className=' mb-4 p-5' style={{ backgroundColor: 'whitesmoke' }}>
                    <div className='container'>
                        <Row >
                            <Col md='4' className='text-center'>
                                <Slide left>
                                    <img src={acai} className='img-fluid' alt='Amazonia Organic Frozen Acai Puree' />

                                </Slide>

                            </Col>

                            <Col>
                                <Slide right>
                                    <h1>Organic Frozen Acai Smootie Packs</h1>
                                    <p>
                                        Looking for a place to buy frozen acai packs? You've come to the right place! Our acai purée is the tastiest and healtiest around! Known for their multiple health benefits;
                                         they contain 7x the antioxidants of blueberries plus Omega-3, 6 & 9 essential fatty acids and is a perfect healty treat.
                                </p>
                                    <ul>
                                        <li>Hand harvested</li>
                                        <li>Grown wild in the Amazon rainforest</li>
                                        <li>Certified Organic</li>
                                        <li>Simply blend it in your smoothie to create refreshing, nutrient-dense açaí bowl</li>
                                    </ul>
                                    <div style={{ paddingLeft: 40 }}>
                                        <Row>
                                            <Col sm='12' md='6'>
                                                <div>
                                                    <Button size='sm'
                                                        href='/shop/Amazonia-Frozen-Acai-Energy-4-Pack-4-x-100g-/5f0d9b5e25bc69e19e06b945'
                                                        className='my-2 acai-button border-0'  >
                                                        Acai Energy
                                        <i className="fas fa-chevron-right pl-3"></i>
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button size='sm'
                                                        href='/shop/Amazonia-Frozen-Acai-Pure-4-Pack-4-x-100g-/5f0d9a6f25bc69e19e06b944'
                                                        className='my-2 acai-button border-0'  >
                                                        Acai Puree
                                        <i className="fas fa-chevron-right pl-3"></i>
                                                    </Button>
                                                </div>
                                            </Col>

                                            <Col sm='12' md='6'>
                                                <div>
                                                    <Button size='sm'
                                                        href='/shop/Amazonia-Frozen-Coconut-Puree-4-Pack-4-x-100g-/5f0d988925bc69e19e06b942'
                                                        className='my-2 acai-button border-0'  >
                                                        Coconut Puree
                                        <i className="fas fa-chevron-right pl-3"></i>
                                                    </Button>
                                                </div>

                                                <div>
                                                    <Button size='sm'
                                                        href='/shop/Frozen-Amazonia-Pitaya-Pack-4-x-100g/5f0d999225bc69e19e06b943'
                                                        className='my-2 acai-button border-0'  >
                                                        Frozen Pitaya
                                        <i className="fas fa-chevron-right pl-3"></i>
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>


                                    </div>
                                </Slide>




                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}
