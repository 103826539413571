import {
    GET_MAP_DATA,
    MAP_DATA_LOADING,
    MAP_DATA_LOADING_RESET
} from '../actions/types.js'


const initialState = {
    loading: false,
    countryList: []

}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_MAP_DATA:
            return {
                ...state,
                countryList: action.payload
            }
        case MAP_DATA_LOADING:
            return {
                ...state,
                loading: true
            }

        case MAP_DATA_LOADING_RESET:
            return {
                ...state,
                loading: false
            }
        default: return state;
    }
}