import {
    GET_ADMIN_USER_LIST,
    ADMIN_USER_LIST_LOADING,
    ADMIN_USER_LIST_LOADING_RESET,
    GET_ADMIN_USER_LIST_COUNT,
    GET_SINGLE_USER,
    DELETE_SINGLE_USER_BUTTON_LOADING,
    DELETE_SINGLE_USER_BUTTON_LOADING_RESET,
    DELETE_SINGLE_USER,
    RESET_SINGLE_USER,
    UPDATE_SINGLE_USER,
    UPDATE_SINGLE_USER_LOADING,
    UPDATE_SINGLE_USER_LOADING_RESET,
    USER_TABLE_UPDATED,
    USER_COUNT_UPDATED
} from './types'
import axios from 'axios'
import { logoutUser } from './formActions'



export const getUserList = (page, filter, query) => dispatch => {
    dispatch({
        type: ADMIN_USER_LIST_LOADING
    })
    axios.get(`/api/admin/getUsers/${page}/${filter}/${query}`)
        .then(res => {
            dispatch({
                type: GET_ADMIN_USER_LIST,
                payload: res.data.users
            })
            dispatch({
                type: GET_ADMIN_USER_LIST_COUNT,
                payload: res.data.count
            })

            dispatch({
                type: ADMIN_USER_LIST_LOADING_RESET
            })
        })
        .catch(err => {
            dispatch({
                type: ADMIN_USER_LIST_LOADING_RESET
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
            console.log(err)
        })

}


export const getSingleUser = (userid) => dispatch => {
    dispatch({
        type: ADMIN_USER_LIST_LOADING
    })
    axios.get(`/api/admin/getSingleUser/${userid}`)
        .then(res => {
            dispatch({
                type: GET_SINGLE_USER,
                payload: res.data
            })

            dispatch({
                type: ADMIN_USER_LIST_LOADING_RESET
            })
        })
        .catch(err => {
            dispatch({
                type: ADMIN_USER_LIST_LOADING_RESET
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
            console.log(err)
        })

}


export const deleteSingleUser = (userid) => dispatch => {
    dispatch({
        type: DELETE_SINGLE_USER_BUTTON_LOADING
    })
    axios.get(`/api/admin/deleteSingleUser/${userid}`)
        .then(res => {
            dispatch({
                type: DELETE_SINGLE_USER,
            })

            dispatch({
                type: DELETE_SINGLE_USER_BUTTON_LOADING_RESET
            })
        })
        .catch(err => {
            dispatch({
                type: DELETE_SINGLE_USER_BUTTON_LOADING_RESET
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }

            console.log(err)
        })

}

export const resetUserForm = () => dispatch => {
    dispatch({
        type: RESET_SINGLE_USER
    })
}


export const updateSingleUser = (userid, data) => dispatch => {
    dispatch({
        type: UPDATE_SINGLE_USER_LOADING
    })
    axios.post(`/api/admin/updateSingleUser/${userid}`, data)
        .then(res => {
            dispatch({
                type: UPDATE_SINGLE_USER,
                payload: res.data
            })

            dispatch({
                type: UPDATE_SINGLE_USER_LOADING_RESET
            })
        })
        .catch(err => {
            dispatch({
                type: UPDATE_SINGLE_USER_LOADING_RESET
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
            console.log(err)
        })

}

export const updatedUsers = (newUserList) => dispatch => {
    dispatch({
        type: USER_TABLE_UPDATED,
        payload: newUserList
    })
}
export const updatedUserCount = userCount => dispatch => {
    dispatch({
        type: USER_COUNT_UPDATED,
        payload: userCount
    })
}