import {
    ADMIN_ANALYTICS_LIST_LOADING,
    ADMIN_ANALYTICS_LIST_LOADING_RESET,
    GET_COUNTRY_LIST,
    GET_REGION_LIST,
    GET_CITY_LIST
} from './types'
import axios from 'axios'
import { logoutUser } from './formActions'



export const analyticLists = (date) => dispatch => {
    dispatch({
        type: ADMIN_ANALYTICS_LIST_LOADING
    })
    axios.get(`/api/admin/googleAnalyticsLists/${date}`)
        .then(res => {
            dispatch({
                type: GET_COUNTRY_LIST,
                payload: res.data.countryList
            })
            dispatch({
                type: GET_REGION_LIST,
                payload: res.data.regionList
            })
            dispatch({
                type: GET_CITY_LIST,
                payload: res.data.cityList
            })

            dispatch({
                type: ADMIN_ANALYTICS_LIST_LOADING_RESET
            })
        })
        .catch(err => {
            dispatch({
                type: ADMIN_ANALYTICS_LIST_LOADING_RESET
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
            console.log(err)
        })

}
