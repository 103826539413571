import {
    GET_ADMIN_REVIEWS,
    GET_ADMIN_REVIEWS_LOADING,
    RESET_GET_ADMIN_REVIEWS_LOADING,
    GET_ADMIN_REVIEWS_COUNT,
    DELETE_REVIEW,
    UPDATE_ADMIN_REVIEW,
    RESET_ADMIN_REVIEWS
} from './types'
import axios from 'axios'
import { logoutUser } from './formActions'

export const resetReviews = () => dispatch => {
    dispatch({
        type: RESET_ADMIN_REVIEWS
    })
}


export const updateReview = (id, data) => dispatch => {
    axios.post(`/api/admin/updateReview/${id}`, data)
        .then(res => {
            dispatch({
                type: UPDATE_ADMIN_REVIEW,
                payload: res.data
            })
        })
        .catch(err => {
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
        })
}


export const getReviews = (page) => dispatch => {
    dispatch({
        type: GET_ADMIN_REVIEWS_LOADING
    })
    axios.get(`/api/admin/getAdminReviews/${page}`)
        .then(res => {
            dispatch({
                type: GET_ADMIN_REVIEWS,
                payload: res.data.reviews
            })
            dispatch({
                type: GET_ADMIN_REVIEWS_COUNT,
                payload: res.data.count
            })

        })
        .catch(err => {
            dispatch({
                type: RESET_GET_ADMIN_REVIEWS_LOADING
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
        })

}


export const deleteSelectedReview = (id) => dispatch => {
    axios.post(`/api/admin/deleteAdminReview/${id}`)
        .then(res => {
            dispatch({
                type: DELETE_REVIEW,
                payload: res.data.review
            })
            dispatch({
                type: GET_ADMIN_REVIEWS_COUNT,
                payload: res.data.count
            })
        })
        .catch(err => {
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
        })
}