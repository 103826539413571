import React, { Component } from 'react'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    NavLink,
    NavbarBrand,
    Dropdown, DropdownMenu, DropdownToggle
} from 'reactstrap';
import HamburgerMenu from 'react-hamburger-menu'
import frozberiesLogo from '../images/frozberries-logo.png'
import { connect } from 'react-redux'
import { withRouter, NavLink as NLink } from 'react-router-dom'
import SideBar from './SideBar';
import Cart from './Cart';
import { matchPath } from "react-router";
import { resetProducts } from '../actions/productsActions'


class NavBar extends Component {
    state = {
        isOpen: false,
        windowWidth: 0,
        aboutDropdown: false,
        toggleCollapse: false,
    }
    componentDidMount() {
        this.setState({
            windowWidth: window.innerWidth,
        })
        window.addEventListener('resize', this.updateWindowDimension);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimension);
    }
    updateWindowDimension = (e) => {
        this.setState({
            windowWidth: window.innerWidth
        })
    }


    toggleHamburger = (e) => {
        if (this.state.innerWidth !== null && this.state.windowWidth < 768) {
            this.setState({
                isOpen: !this.state.isOpen
            })
        }
    }

    toggleAbout = a => {
        this.setState({
            aboutDropdown: !this.state.aboutDropdown
        })
    }
    toggle = () => {

    }

    collapseToggle = (e) => {
        this.setState({
            toggleCollapse: !this.state.toggleCollapse
        })
    }

    handleShopClick = e => {
        if (this.state.windowWidth < 768) {
            this.toggleHamburger()
        }
        if (this.props.history.location.pathname !== '/shop') {
            this.props.resetProducts()
        }
    }


    render() {
        const hash = window.location.pathname
        const customMatch = matchPath(hash, {
            path: `/shop/:title/:productid`,
            exact: true,
            strict: false
        });



        return (
            this.props.location.pathname !== '/adminpanel' && this.props.location.pathname !== '/checkout' && (

                <div style={{
                    backgroundColor: 'white',
                }}>

                    <Navbar
                        expand="md"
                    >
                        {this.state.windowWidth >= 768 &&
                            <NavbarBrand href='/' >
                                <img className='img-fluid'
                                    style={{ height: 50 }}
                                    src={frozberiesLogo} alt='frozberries-logo' />
                            </NavbarBrand>
                        }

                        <NavbarToggler onClick={this.toggle} className='border-0 mt-2 mr-auto' >
                            <HamburgerMenu
                                isOpen={this.state.isOpen}
                                navbar
                                menuClicked={this.toggleHamburger}
                                borderRadi={0}
                                color='rgb(14,35,87)'
                                animationDuration={0.5}
                            />
                        </NavbarToggler>

                        {this.state.windowWidth < 768 &&
                            <NavbarBrand href='/' className='text-center mx-auto animate__bounceIn'>
                                <img style={{ height: 50 }} src={frozberiesLogo} alt='frozberries-logo' />
                            </NavbarBrand>
                        }

                        {this.state.windowWidth < 768 && (
                            <Cart />
                        )}


                        <Collapse id='nav-collapse' isOpen={this.state.isOpen} navbar className='text-center'>
                            <Nav className="mx-auto font-weight-bold" navbar>
                                <NavItem>
                                    <NavLink
                                        style={{
                                            color: this.props.location.pathname === '/' ? 'white' : 'rgb(14,35,87)',
                                            backgroundColor: this.props.location.pathname === '/' && 'rgb(236,23,79)',
                                            borderRadius: this.props.location.pathname === '/' && '30px'
                                        }}
                                        exact to='/'
                                        tag={NLink}
                                        onClick={this.state.windowWidth < 768 ? this.toggleHamburger : null}
                                    >
                                        HOME
                                    </NavLink>
                                </NavItem>
                                <NavItem onMouseEnter={this.toggleAbout}
                                    onMouseLeave={this.toggleAbout}>
                                    {this.state.windowWidth >= 768 ? (
                                        <Dropdown isOpen={this.state.aboutDropdown}
                                            toggle={this.toggle}
                                        >
                                            <DropdownToggle tag='nav-link'
                                                data-toggle='dropdown'
                                                aria-expanded={this.state.aboutDropdown}
                                            >
                                                <NavLink
                                                    style={{
                                                        color: (this.props.location.pathname === '/ourstory' || this.props.location.pathname === '/elginorganic' || this.props.location.pathname === '/faqs') ? 'white' : 'rgb(14,35,87)',
                                                        backgroundColor: (this.props.location.pathname === '/ourstory' || this.props.location.pathname === '/elginorganic' || this.props.location.pathname === '/faqs') && 'rgb(236,23,79)',
                                                        borderRadius: (this.props.location.pathname === '/ourstory' || this.props.location.pathname === '/elginorganic' || this.props.location.pathname === '/faqs') && '30px'
                                                    }}
                                                    href='#'
                                                    className={(this.props.location.pathname === '/ourstory' || this.props.location.pathname === '/elginorganic' || this.props.location.pathname === '/faqs') ? 'active' : ''}
                                                >
                                                    ABOUT
              </NavLink>
                                            </DropdownToggle>
                                            <DropdownMenu className='nav-dropdown-menu text-center border-0' >
                                                    <NavLink href='/ourstory' style={{ color: this.props.location.pathname === '/ourstory' ? '#f05053' : 'rgb(14,35,87)' }}>OUR STORY</NavLink>
                                                    <NavLink href='/elginorganic' style={{ color: this.props.location.pathname === '/elginorganic' ? '#f05053' : 'rgb(14,35,87)' }}>ELGIN ORGANIC</NavLink>
                                                    <NavLink href='/faqs' style={{ color: this.props.location.pathname === '/faqs' ? '#f05053' : 'rgb(14,35,87)' }}>F.A.Q's</NavLink>
                                            </DropdownMenu>
                                        </Dropdown>
                                    ) : (
                                            <React.Fragment>
                                                <NavLink href='#'
                                                    style={{
                                                        color: (this.props.location.pathname === '/ourstory' || this.props.location.pathname === '/elginorganic' || this.props.location.pathname === '/faqs') ? 'white' : 'rgb(14,35,87)',
                                                        backgroundColor: (this.props.location.pathname === '/ourstory' || this.props.location.pathname === '/elginorganic' || this.props.location.pathname === '/faqs') && 'rgb(236,23,79)',
                                                        borderRadius: (this.props.location.pathname === '/ourstory' || this.props.location.pathname === '/elginorganic' || this.props.location.pathname === '/faqs') && '30px'
                                                    }}
                                                    className={(this.props.location.pathname === '/ourstory' || this.props.location.pathname === '/elginorganic' || this.props.location.pathname === '/faqs') ? 'active' : ''}
                                                    onClick={this.collapseToggle}>
                                                    {this.state.toggleCollapse ?
                                                        <i style={{ fontSize: '15px', color: 'rgb(14,35,87)' }}
                                                            className="fas fa-minus pr-2" /> :
                                                        <i style={{ fontSize: '15px', color: 'rgb(14,35,87)' }}
                                                            className="fas fa-plus pr-2" />}
                                                    ABOUT
                                        </NavLink>
                                                <Collapse isOpen={this.state.toggleCollapse}>
                                                    <NavLink href='/ourstory' style={{ fontSize: '15px', color: this.props.location.pathname === '/ourstory' ? '#f05053' : 'rgb(14,35,87)' }}
                                                        onClick={this.state.windowWidth < 768 ? this.toggleHamburger : null}
                                                    >OUR STORY</NavLink>
                                                    <NavLink href='/elginorganic' style={{ fontSize: '15px', color: this.props.location.pathname === '/elginorganic' ? '#f05053' : 'rgb(14,35,87)' }}
                                                        onClick={this.state.windowWidth < 768 ? this.toggleHamburger : null}
                                                    >ELGIN ORGANIC</NavLink>
                                                    <NavLink href='/faqs' style={{ fontSize: '15px', color: this.props.location.pathname === '/faqs' ? '#f05053' : 'rgb(14,35,87)' }}
                                                        onClick={this.state.windowWidth < 768 ? this.toggleHamburger : null}
                                                    >F.A.Q's</NavLink>
                                                </Collapse>
                                            </React.Fragment>
                                        )}



                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{
                                            color: (this.props.location.pathname === '/shop' || customMatch) ? 'white' : 'rgb(14,35,87)',
                                            backgroundColor: (this.props.location.pathname === '/shop' || customMatch) && 'rgb(236,23,79)',
                                            borderRadius: (this.props.location.pathname === '/shop' || customMatch) && '30px'
                                        }}
                                        to='/shop'
                                        tag={NLink}
                                        onClick={this.handleShopClick}
                                    >
                                        SHOP
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        style={{
                                            color: (this.props.location.pathname === '/recipes') ? 'white' : 'rgb(14,35,87)',
                                            backgroundColor: (this.props.location.pathname === '/recipes') && 'rgb(236,23,79)',
                                            borderRadius: (this.props.location.pathname === '/recipes') && '30px'
                                        }}
                                        to='/recipes'
                                        tag={NLink}
                                        onClick={this.state.windowWidth < 768 ? this.toggleHamburger : null}
                                    >
                                        RECIPES
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        style={{
                                            color: this.props.location.pathname === '/deliverylocations' ? 'white' : 'rgb(14,35,87)',
                                            backgroundColor: this.props.location.pathname === '/deliverylocations' && 'rgb(236,23,79)',
                                            borderRadius: this.props.location.pathname === '/deliverylocations' && '30px'
                                        }}
                                        to='/deliverylocations'
                                        tag={NLink}
                                        onClick={this.state.windowWidth < 768 ? this.toggleHamburger : null}
                                    >
                                        DELIVERY
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{
                                            color: this.props.location.pathname === '/contact' ? 'white' : 'rgb(14,35,87)',
                                            backgroundColor: this.props.location.pathname === '/contact' && 'rgb(236,23,79)',
                                            borderRadius: this.props.location.pathname === '/contact' && '30px'
                                        }}
                                        to='/contact'
                                        tag={NLink}
                                        onClick={this.state.windowWidth < 768 ? this.toggleHamburger : null}
                                    >
                                        CONTACT
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <div style={{ fontSize: '20px', borderRadius: '99px' }}
                                className=' p-2 px-3 shadow-lg contact-icon d-lg-none d-xl-block'>
                                <a className='phone-link' href='tel:03 9335 6331'>
                                    <i className="fas fa-phone pr-1 "></i>

                                    03 9335 6331
                                </a>
                            </div>


                            {this.state.windowWidth >= 768 && (
                                <Cart />
                            )}

                        </Collapse>

                        <SideBar />
                    </Navbar>

                </div>
            )
        )
    }
}


const mapStateToProps = (state) => ({
    shoppingCart: state.cart,
    products: state.products,

})


export default connect(mapStateToProps, { resetProducts })(withRouter(NavBar))