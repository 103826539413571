import React, { Component } from 'react'
import { Helmet } from "react-helmet";
import { Row, Col, Input, InputGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { getSingleProduct, UpdateStoreProduct, deleteProduct, resetForm } from '../../actions/adminProducts'

class UpdateProduct extends Component {

    state = {
        name: '',
        description: '',
        benefits: '',
        didYouKnow: '',
        didYouKnowImage: null,
        productCode: '',
        origin: '',
        unitSize: '',
        category: '',
        subCategory: '',
        image: null,
        price: '',
        inStock: 'inStock',
        errors: {},
        productImageUrl: '',
        didYouKnowImageUrl: '',
        showUpdateModal: false,
        showDeleteModal: false,
        product: {}
    }

    componentWillUnmount() {
        this.props.resetForm()
    }

    componentDidMount() {
        if (this.props.location.state !== undefined && this.props.location.state.product) {
            this.setState({
                name: this.props.location.state.product.name,
                description: this.props.location.state.product.description,
                productCode: this.props.location.state.product.productCode,
                origin: this.props.location.state.product.origin,
                unitSize: this.props.location.state.product.unitSize,
                category: this.props.location.state.product.category,
                subCategory: this.props.location.state.product.subCategory,
                price: this.props.location.state.product.price,
                productImageUrl: this.props.location.state.product.image,
                benefits: this.props.location.state.product.benefits,
                didYouKnow: this.props.location.state.product.didYouKnow,
                didYouKnowImageUrl: this.props.location.state.product.didYouKnowImage,
                product: this.props.location.state.product
            })
            if (this.props.location.state.product.inStock) {
                this.setState({
                    inStock: 'inStock'
                })
            } else {
                this.setState({
                    inStock: 'outofStock'
                })
            }
        }
        else {
            this.props.getSingleProduct(this.props.match.params.productid)
        }
    }


    componentDidUpdate(prevProps) {

        if (this.props.adminProducts.errors !== prevProps.adminProducts.errors) {
            this.setState({
                errors: this.props.adminProducts.errors
            })
        }

        if (this.props.adminProducts.product !== prevProps.adminProducts.product) {
            this.setState({
                name: this.props.adminProducts.product.name,
                description: this.props.adminProducts.product.description,
                productCode: this.props.adminProducts.product.productCode,
                origin: this.props.adminProducts.product.origin,
                unitSize: this.props.adminProducts.product.unitSize,
                category: this.props.adminProducts.product.category,
                subCategory: this.props.adminProducts.product.subCategory,
                price: this.props.adminProducts.product.price,
                productImageUrl: this.props.adminProducts.product.image,
                benefits: this.props.adminProducts.product.benefits,
                didYouKnow: this.props.adminProducts.product.didYouKnow,
                didYouKnowImageUrl: this.props.adminProducts.product.didYouKnowImage,
                product: this.props.adminProducts.product
            })
            if (this.props.adminProducts.product.inStock) {
                this.setState({
                    inStock: 'inStock'
                })
            } else {
                this.setState({
                    inStock: 'outofStock'
                })
            }

        }

        if (this.props.adminProducts.productDeleted !== prevProps.adminProducts.productDeleted && this.props.adminProducts.productDeleted) {
            this.setState({
                showDeleteModal: true,
                name: '',
                description: '',
                benefits: '',
                didYouKnow: '',
                didYouKnowImage: null,
                productCode: '',
                origin: '',
                unitSize: '',
                category: '',
                subCategory: '',
                image: null,
                price: '',
                inStock: '',
                errors: {},
                productImageUrl: '',
                didYouKnowImageUrl: '',
            })

            setTimeout(this.sendToProductsPage, 3000)
        }

        if (this.props.adminProducts.productUpdated !== prevProps.adminProducts.productUpdated && this.props.adminProducts.productUpdated) {
            this.setState({
                showUpdateModal: true
            })
        }
    }
    sendToProductsPage = () => {
        this.props.history.push('/adminpanel/products')
    }

    handleDeleteButton = e => {
        this.props.deleteProduct(this.state.product._id)
    }

    onChange = (e) => {
        let value = e.target.value
        if (e.target.name === 'price' && value !== '') {
            value = parseFloat(value)
        }
        this.setState({ [e.target.name]: value })

        if (this.props.adminProducts.productUpdated) {
            this.props.resetForm()
        }

    }

    onImageSelect = e => {

        if (e.target.name === 'image') {
            let reader = new FileReader();
            let file = e.target.files[0];

            reader.onloadend = () => {
                this.setState({
                    image: file,
                    productImageUrl: reader.result
                });
            }

            if (file) {
                reader.readAsDataURL(file);
            }

        }

        if (e.target.name === 'didYouKnowImage') {

            let reader = new FileReader();
            let file = e.target.files[0];

            reader.onloadend = () => {
                this.setState({
                    didYouKnowImage: file,
                    didYouKnowImageUrl: reader.result
                });
            }

            if (file) {
                reader.readAsDataURL(file);
            }
        }

        if (this.props.adminProducts.productUpdated) {
            this.props.resetForm()
        }
    }


    handleFormSubmit = e => {
        e.preventDefault()

        let formData = new FormData()

        if (this.state.image !== null) {
            formData.append('image', this.state.image)
        }
        if (this.state.didYouKnowImage !== null) {
            formData.append('didYouKnowImage', this.state.didYouKnowImage)
        }

        if (this.state.name !== '') {
            formData.append('name', this.state.name)
        }
        if (this.state.description !== '') {
            formData.append('description', this.state.description)

        }

        if (this.state.benefits !== '') {
            formData.append('benefits', this.state.benefits)

        }
        if (this.state.didYouKnow !== '') {
            formData.append('didYouKnow', this.state.didYouKnow)

        }
        if (this.state.productCode !== '') {
            formData.append('productCode', this.state.productCode)

        }
        if (this.state.unitSize !== '') {
            formData.append('unitSize', this.state.unitSize)

        }
        if (this.state.category !== '') {
            formData.append('category', this.state.category)

        }
        if (this.state.subCategory !== '') {
            formData.append('subCategory', this.state.subCategory)

        }

        if (this.state.origin !== '') {
            formData.append('origin', this.state.origin)

        }
        if (this.state.price !== '') {
            formData.append('price', this.state.price)

        }
        if (this.state.inStock === 'inStock') {
            formData.append('inStock', true)
        }
        if (this.state.inStock === 'outofStock') {
            formData.append('inStock', false)
        }

        this.props.UpdateStoreProduct(formData, this.state.product._id)

    }

    handleNewProductRoute = () => {
        this.props.history.push(`/shop/${this.props.adminProducts.product.name.replace(/[^A-Z0-9]+/ig, "-")}/${this.props.adminProducts.product._id}`)
    }

    toggleDeleteModal = () => {
        this.setState({
            showDeleteModal: !this.state.showDeleteModal
        })
    }

    toggleUpdateModal = () => {
        this.setState({
            showUpdateModal: !this.state.showUpdateModal
        })
    }

    render() {
        const { errors } = this.state
        return (
            <div>
                <div>
                    <Helmet>
                        <title>{(this.props.location.state !== undefined && this.props.location.state.product) ?
                            this.props.location.state.product.name : (this.props.match.params.title.replace(/-/g, " "))} - Frozberries Victoria</title>
                        <meta name="description" content={`${(this.props.location.state !== undefined && this.props.location.state.product) ?
                            this.props.location.state.product.name : (this.props.match.params.title.replace(/-/g, " "))} - Frozberries Victoria`} />
                    </Helmet>
                </div>
                <div>
                    {(this.props.adminProducts.loading && Object.keys(this.state.product).length === 0) ? (
                        <div className='text-center text-light my-5'>
                            <h5>Please wait. Product details are being fetched.</h5>
                        </div>
                    ) : (
                            <Row className='pb-5'>
                                <Col>
                                    <Modal isOpen={this.state.showUpdateModal} toggle={this.toggleUpdateModal}>
                                        <ModalHeader >Product has been successfully updated.</ModalHeader>
                                        <ModalBody>
                                            <img style={{ maxHeight: 300 }} src={this.props.adminProducts.product.image} alt={this.props.adminProducts.product.name} />
                                            <p>{this.props.adminProducts.product.name}</p>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color="primary" onClick={this.handleNewProductRoute}>Go to updated product</Button>{' '}
                                            <Button color="danger" onClick={this.toggleUpdateModal}>Cancel</Button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={this.state.showDeleteModal} toggle={this.toggleDeleteModal}>
                                        <ModalHeader >Product successfully deleted.</ModalHeader>
                                        <ModalBody>You're being redirected to products page.</ModalBody>
                                    </Modal>

                                    <form encType="multipart/form-data" onSubmit={this.handleFormSubmit}>
                                        <Label className='text-light h6 my-3' >*All fields are required. The form is case sensitive.</Label>

                                        <InputGroup className='my-2'>
                                            <FormGroup className='w-100 text-light'>
                                                <Label >Title of product </Label>
                                                <Input placeholder="Title of product" onChange={this.onChange} className={classnames('', { 'is-invalid': errors.name })} value={this.state.name} name='name' />
                                                <div className='invalid-feedback'>{errors.name}</div>
                                            </FormGroup>
                                        </InputGroup>
                                        <InputGroup className='my-2'>
                                            <FormGroup className='w-100 text-light'>

                                                <Label>Description of product </Label>
                                                <Input type="textarea" placeholder="Description of product" onChange={this.onChange} className={classnames('', { 'is-invalid': errors.description })} value={this.state.description} name='description' />
                                                <div className='invalid-feedback'>{errors.description}</div>
                                            </FormGroup>
                                        </InputGroup>
                                        <InputGroup className='my-2'>
                                            <FormGroup className='w-100 text-light'>
                                                <Label>Benefits of product </Label>
                                                <Input type="textarea" placeholder="Benefits of product" onChange={this.onChange} className={classnames('', { 'is-invalid': errors.benefits })} value={this.state.benefits} name='benefits' />
                                                <div className='invalid-feedback'>{errors.benefits}</div>
                                            </FormGroup>
                                        </InputGroup>
                                        <InputGroup className='my-2'>
                                            <FormGroup className='w-100 text-light'>

                                                <Label>Did you know of product</Label>
                                                <Input type="textarea" placeholder="Did you know of product" onChange={this.onChange} className={classnames('', { 'is-invalid': errors.didYouKnow })} value={this.state.didYouKnow} name='didYouKnow' />
                                                <div className='invalid-feedback'>{errors.didYouKnow}</div>
                                            </FormGroup>

                                        </InputGroup>
                                        <InputGroup className='my-2'>
                                            <FormGroup className='w-100 text-light'>

                                                <Label>Product code of product</Label>
                                                <Input placeholder="Product code of product" onChange={this.onChange} className={classnames('', { 'is-invalid': errors.productCode })} value={this.state.productCode} name='productCode' />
                                                <div className='invalid-feedback'>{errors.productCode}</div>
                                            </FormGroup>

                                        </InputGroup>

                                        <InputGroup className='my-2'>
                                            <FormGroup className='w-100 text-light'>
                                                <Label>Origin of product</Label>
                                                <Input placeholder="Origin of product" onChange={this.onChange} className={classnames('', { 'is-invalid': errors.origin })} value={this.state.origin} name='origin' />
                                                <div className='invalid-feedback'>{errors.origin}</div>
                                            </FormGroup>

                                        </InputGroup>

                                        <InputGroup className='my-2'>
                                            <FormGroup className='w-100 text-light'>

                                                <Label>Unit size</Label>
                                                <Input type="select" onChange={this.onChange} className={classnames('', { 'is-invalid': errors.unitSize })} value={this.state.unitSize} name='unitSize'>
                                                    <option hidden>Unit size</option>
                                                    <option value='150g'>150g</option>
                                                    <option value='250g'>250g</option>
                                                    <option value='350g'>350g</option>
                                                    <option value='500g'>500g</option>
                                                    <option value='600g'>600g</option>
                                                    <option value='1KG'>1KG</option>
                                                    <option value='1.5KG'>1.5KG</option>
                                                    <option value='2KG'>2KG</option>
                                                    <option value='2.5KG'>2.5KG</option>
                                                    <option value='5KG'>5KG</option>
                                                    <option value='10KG'>10KG</option>
                                                    <option value='12KG'>12KG</option>
                                                    <option value='13.6KG'>13.6KG</option>
                                                    <option value='15KG'>15KG</option>
                                                    <option value='20KG'>20KG</option>
                                                    <option value='4 x 100g'>4 x 100g</option>
                                                </Input>
                                                <div className='invalid-feedback'>{errors.unitSize}</div>
                                            </FormGroup>

                                        </InputGroup>

                                        <InputGroup className='my-2'>
                                            <FormGroup className='w-100 text-light'>

                                                <Label>Category</Label>

                                                <Input type="select" onChange={this.onChange} className={classnames('', { 'is-invalid': errors.category })} value={this.state.category} name='category'>
                                                    <option hidden>Category</option>
                                                    <option value='organic'>Organic</option>
                                                    <option value='australian'>Australian</option>
                                                    <option value='conventional'>Conventional</option>
                                                    <option value='puree'>Puree</option>

                                                </Input>
                                                <div className='invalid-feedback'>{errors.category}</div>
                                            </FormGroup>

                                        </InputGroup>
                                        <InputGroup className='my-2'>
                                            <FormGroup className='w-100 text-light'>

                                                <Label>Sub-category</Label>

                                                <Input type="select" onChange={this.onChange} className={classnames('', { 'is-invalid': errors.subCategory })} value={this.state.subCategory} name='subCategory'>
                                                    <option hidden>Sub-category</option>
                                                    <option value='fruits'>Fruit</option>
                                                    <option value='vegetables'>Vegetable</option>
                                                </Input>
                                                <div className='invalid-feedback'>{errors.subCategory}</div>
                                            </FormGroup>

                                        </InputGroup>


                                        <InputGroup className='my-2'>
                                            <FormGroup className='w-100 text-light'>

                                                <Label>Stock level</Label>

                                                <Input type="select" onChange={this.onChange} className={classnames('', { 'is-invalid': errors.inStock })} value={this.state.inStock} name='inStock'>
                                                    <option hidden>Stock Level</option>
                                                    <option value='inStock'>In stock</option>
                                                    <option value='outofStock'>Out of stock</option>

                                                </Input>
                                                <div className='invalid-feedback'>{errors.inStock}</div>
                                            </FormGroup>

                                        </InputGroup>




                                        <InputGroup className='my-2'>
                                            <FormGroup className='w-100 text-light'>
                                                <Label>Price of product</Label>
                                                <Input min='0' step='0.01' type="number" placeholder="Price of product" onChange={this.onChange} className={classnames('', { 'is-invalid': errors.price })} value={this.state.price} name='price' />
                                                <div className='invalid-feedback'>{errors.price}</div>
                                            </FormGroup>

                                        </InputGroup>


                                        <InputGroup>
                                            <Label className='text-light h5' for="image">Select an image for new product. If you select a new image, former image will be deleted.</Label>
                                            <Input ref="productImage" type="file" name="image" id="image" onChange={this.onImageSelect} className={classnames('', { 'is-invalid': errors.image })} />
                                            <div className='invalid-feedback'>{errors.image}</div>
                                        </InputGroup>
                                        {this.state.productImageUrl !== '' && this.state.productImageUrl !== 'undefined' && (
                                            <img className='my-3' style={{ height: 200 }} src={this.state.productImageUrl} alt={this.state.name} />
                                        )}


                                        <InputGroup>
                                            <Label className='text-light h5' for="didYouKnowImage">Select an image for did you know part. If you select a new image, former image will be deleted.</Label>
                                            <Input ref="didYouKnowImage" type="file" name="didYouKnowImage" id="didYouKnowImage" onChange={this.onImageSelect} className={classnames('', { 'is-invalid': errors.didYouKnowImage })} />
                                            <div className='invalid-feedback'>{errors.didYouKnowImage}</div>

                                        </InputGroup>
                                        {this.state.didYouKnowImageUrl !== '' && this.state.didYouKnowImageUrl !== 'undefined' && (
                                            <img className='my-3' style={{ height: 200 }} src={this.state.didYouKnowImageUrl} alt={this.state.name} />
                                        )}

                                        <Row>
                                            <Col xs='4'>
                                                <Input type='submit' value={(this.props.adminProducts.loading && !this.props.adminProducts.deleteButtonLoading) ? 'Please Wait..' : 'Update product'} className='bg-info text-light btn-block mt-4 p-2 border-0 d-inline' />

                                            </Col>
                                            <Col xs='4'>
                                                <Button onClick={this.handleDeleteButton} color='danger' className=' btn-block mt-4 p-2 border-0 d-inline'>{this.props.adminProducts.deleteButtonLoading ? 'Please wait.' : 'Delete product'} </Button>
                                            </Col>
                                            <Col xs='4'>
                                                <Button onClick={() => this.props.history.push('/adminpanel/products')} color='light' className=' btn-block mt-4 p-2 border-0 d-inline'>Go back to products </Button>
                                            </Col>
                                        </Row>

                                    </form>
                                </Col>
                            </Row>
                        )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    adminProducts: state.adminProducts
})

export default connect(mapStateToProps, { getSingleProduct, UpdateStoreProduct, deleteProduct, resetForm })(UpdateProduct)
