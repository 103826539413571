import React, { Component } from 'react'
import { injectStripe } from 'react-stripe-elements';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
} from 'react-stripe-elements';
import { Form, Row, Input, Label, InputGroup, InputGroupAddon, InputGroupText, UncontrolledTooltip } from 'reactstrap'
import { setCardNumber, setExpiryDate, setCardCvc, updateInputs, sendPayment } from '../actions/checkOutActions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

const createOptions = (fontSize, padding) => {
    return {
        style: {
            base: {
                fontSize: '16px',
                color: '#424770',
                letterSpacing: '0.025em',
                fontFamily: 'Source Code Pro, monospace',
                '::placeholder': {
                    color: '#aab7c4',
                },
                padding,
            },
            invalid: {
                color: '#9e2146',
            },
        },
    };
};

class StripeForm extends Component {

    componentDidUpdate(prevProps) {
        if (this.props.checkout.payButton !== prevProps.checkout.payButton) {
            if (this.props.checkout.payButton) {
                const cardElement = this.props.elements.getElement('cardNumber');

                this.props.stripe
                    .createPaymentMethod({
                        type: 'card',
                        card: cardElement,
                        billing_details: { name: this.props.checkout.nameOnCard },
                    })
                    .then(({ paymentMethod }) => {
                        let data = {}
                        data.firstName = this.props.checkout.firstname;
                        data.lastName = this.props.checkout.lastname;
                        data.address = this.props.checkout.address;
                        data.suburb = this.props.checkout.suburb;
                        data.postcode = this.props.checkout.postcode;
                        data.phoneNumber = this.props.checkout.phoneNumber;
                        data.message = this.props.checkout.description;
                        data.shippingFee = this.props.checkout.shippingFee;
                        data.shippingType = this.props.checkout.shippingMethod;
                        data.date = this.props.checkout.date;
                        data.email = this.props.checkout.email
                        data.pickupTime = this.props.checkout.pickupTime
                        data.paymentId = paymentMethod.id
                        data.cart = this.props.shoppingCart.cart

                        if (Object.keys(this.props.discount.userCoupon).length > 0) {
                            data.coupon = this.props.discount.userCoupon
                        }
                        if (this.props.auth.isAuthenticated) {
                            data.userid = this.props.auth.user.id
                        }
                        else if (this.props.guest.userid !== '') {
                            data.userid = this.props.guest.userid
                        }

                        this.props.sendPayment(data)
                    });
            }
        }
    }

    handleChange = (e) => {
        if (e.elementType === 'cardNumber') {
            this.props.setCardNumber(e)
        }
        if (e.elementType === 'cardExpiry') {
            this.props.setExpiryDate(e)
        }
        if (e.elementType === 'cardCvc') {
            this.props.setCardCvc(e)
        }
    }

    updateCardDetails = e => {

        var field = e.target.name;
        var inputValue = e.target.value

        this.props.updateInputs(field, inputValue)
    }

    render() {
        return (
            <Row>
                <Form className='w-100'>
                    <InputGroup>
                        <Input name='nameOnCard' placeholder='Name on Card' className='my-2' onChange={this.updateCardDetails} />
                    </InputGroup>

                    <InputGroup>
                        <CardNumberElement onChange={this.handleChange} className='my-2 form-control'   {...createOptions(this.props.fontSize)} />
                        <InputGroupAddon addonType="append" className='my-2'>
                            <InputGroupText id="cc-tooltip"><i className="fas fa-lock"></i>
                                <UncontrolledTooltip placement='right' target="cc-tooltip">
                                    All transactions are secure and encrypted.
                                </UncontrolledTooltip>
                            </InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                    <CardExpiryElement onChange={this.handleChange} className='my-2 form-control'  {...createOptions(this.props.fontSize)} />
                    <InputGroup>
                        <CardCVCElement onChange={this.handleChange} className='my-2 form-control'  {...createOptions(this.props.fontSize)} />
                        <InputGroupAddon addonType="append" className='my-2'>
                            <InputGroupText id="cvc-tooltip">    <i className="fas fa-question-circle"></i>
                                <UncontrolledTooltip placement='right' target="cvc-tooltip">
                                    3-digit security code usually found on the back of your card. American Express cards have a 4-digit code located on the front.
                                </UncontrolledTooltip>
                            </InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>

                    {this.props.checkout.errors.cardError && (
                        <Label className='text-danger'>
                            {this.props.checkout.errors.cardError}
                        </Label>
                    )}
                    {this.props.checkout.errors.stripeRefund && (
                        <Label className='text-danger'>
                            {this.props.checkout.errors.stripeRefund}
                        </Label>
                    )}
                </Form>
            </Row>
        )
    }
}

const mapStateToProps = (state) => ({
    checkout: state.checkout,
    shoppingCart: state.cart,
    guest: state.guest,
    auth: state.form,
    discount: state.discount

})


export default connect(mapStateToProps, { setCardNumber, setExpiryDate, setCardCvc, updateInputs, sendPayment })(injectStripe(withRouter(StripeForm)))