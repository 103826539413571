import {
    GET_FAQS,
    FAQS_LOADING
} from '../actions/types.js'


const initialState = {
    faqs: [],
    loading:false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FAQS_LOADING:
            return {
                ...state,
                loading: true
            }
        case GET_FAQS:
            return {
                ...state,
                faqs: action.payload,
                loading: false
            }
        default: return state;
    }
}