import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import organic from '../images/organic-logo.png'
import australianOrganic from '../images/aco.png'
import greenApple from '../images/greenApple.png'
import productOfAus from '../images/productOfAus.png'
import additivesFree from '../images/additivesFree.png'
import natural from '../images/100natural.png'
import agrade from '../images/agrade.png'


class Benefits extends Component {

    render() {
        return (
            <div className='benefits  pt-5 border-top'>
                <h1 className='px-3' >Benefits</h1>

                <Row className='px-3'>
                    <Col md='3' className=' my-3'>
                        <p className='pt-3 '>{this.props.product.benefits}</p>
                    </Col>
                    {this.props.product.category === 'organic' && (
                        < React.Fragment >
                            <Col className=' text-center my-3' md='3'>
                                <img style={{ height: 90, }} src={organic} alt='All Natural Fruit' />
                                <h4 className='pt-3'>All Natural Fruit</h4>
                            </Col>
                            <Col className=' text-center  my-3 ' md='3'>
                                <img style={{ width: 180, }} src={australianOrganic} alt='Certified Australian Organic' />
                                <h4 className='pt-3'>Certified Australian Organic</h4>
                            </Col>
                            <Col className=' text-center  my-3' md='3'>
                                <img style={{ height: 90, }} src={greenApple} alt='Just as Nutritious as Fresh Fruit' />
                                <h4 className='pt-3'>Just as Nutritious as Fresh Fruit</h4>
                            </Col>
                        </React.Fragment>
                    )}
                    {this.props.product.category === 'australian' && (
                        < React.Fragment >
                            <Col className=' text-center  my-auto' md='3'>
                                <img style={{ width: 90, }} src={productOfAus} alt='100% Australian Grown' />
                                <h4 className='pt-3'>100% Australian Grown</h4>
                            </Col>
                            <Col className=' text-center  my-auto ' md='3'>
                                <img style={{ width: 150 }} src={additivesFree} alt='No Added Preservatives' />
                                <h4 >No Added Preservatives</h4>
                            </Col>
                            <Col className=' text-center  my-3' md='3'>
                                <img style={{ width: 135 }} src={natural} alt='100% Natural Fruit' />
                                <h4 className='pt-3'>100% Natural Fruit</h4>
                            </Col>
                        </React.Fragment>
                    )}
                    {this.props.product.category === 'conventional' && (
                        < React.Fragment >
                            <Col className=' text-center  my-auto' md='3'>
                                <img className='mt-3' style={{ width: 100, }} src={agrade} alt='A Grade Premium ' />
                                <h4 style={{paddingTop:'2rem'}}>A Grade</h4>
                            </Col>
                            <Col className=' text-center  my-auto ' md='3'>
                                <img style={{ width: 150 }} src={additivesFree} alt='No Added Preservatives' />
                                <h4 >No Added Preservatives</h4>
                            </Col>
                            <Col className=' text-center  my-3' md='3'>
                                <img style={{ width: 135 }} src={natural} alt='100% Natural Fruit' />
                                <h4 className='pt-3'>100% Natural Fruit</h4>
                            </Col>
                        </React.Fragment>
                    )}
                    {this.props.product.category === 'puree' && (
                        < React.Fragment >
                            <Col className=' text-center my-3' md='3'>
                                <img style={{ height: 90, }} src={organic} alt='All Natural Fruit' />
                                <h4 className='pt-3'>All Natural Fruit</h4>
                            </Col>
                            <Col className=' text-center  my-3 ' md='3'>
                                <img style={{ width: 180, }} src={australianOrganic} alt='Certified Australian Organic' />
                                <h4 className='pt-3'>Certified Australian Organic</h4>
                            </Col>
                            <Col className=' text-center  my-3' md='3'>
                                <img style={{ height: 90, }} src={greenApple} alt='Just as Nutritious as Fresh Fruit' />
                                <h4 className='pt-3'>Just as Nutritious as Fresh Fruit</h4>
                            </Col>
                        </React.Fragment>
                    )}
                </Row>

            </div >
        )
    }
}
export default Benefits