import React, { Component } from 'react'
import { connect } from 'react-redux'
import { verifyUser } from '../actions/formActions'
import { Alert, Container, Col, Row } from 'reactstrap'
import Base from './Base'
import { Redirect, } from 'react-router-dom'
import NotFound from './NotFound'
import Spinner from './Spinner'

class AccountVerified extends Component {
    componentDidMount() {
        this.props.verifyUser(this.props.match.params.code)
    }

    componentDidUpdate(prevProps) {
        if (this.props.auth.verified !== prevProps.auth.verified) {
            setTimeout(() => {
                this.props.history.push('/login')
            }, 3000)
        }

    }

    render() {
        if (this.props.match.params.code.length < 16) {
            return <Redirect to='/' />
        }
        return (

            <React.Fragment>
                {this.props.auth.verified !== null && (
                    <React.Fragment>
                        <Base title={`Welcome Back`} />
                        <Container className='mt-5' style={{ minHeight: '30vh' }}>
                            <Row>
                                <Col className='  py-auto'>

                                    <h4 className='font-weight-light mb-3 text-center '>
                                        Thank you for signing up !</h4>
                                    <Alert>
                                        Congratulations, your account has been verified. You are being redirected to login page.

                                    </Alert>
                                </Col>
                            </Row>
                        </Container>
                    </React.Fragment>
                )}

                {!this.props.errors.code ?
                    <div className='text-center' style={{ minHeight: '50vh' }}>
                        <Spinner />
                    </div>
                    : <NotFound />}
            </React.Fragment>

        )
    }
}


const mapStateToProps = state => ({
    errors: state.errors,
    auth: state.form

})

export default connect(mapStateToProps, { verifyUser })(AccountVerified)