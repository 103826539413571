import React, { Component } from 'react'
import {
    Col, Row, Card, CardImg, CardBody,
    CardTitle
} from 'reactstrap'
import Fade from 'react-reveal/Fade';
import { withRouter } from 'react-router-dom'

class Products extends Component {

    routeToProducts = (value) => {

        if (value === 'australian') {
            this.props.history.push({
                pathname: '/shop',
                search: '?category=australian&page=1'
            })
        } else if (value === 'conventional') {
            this.props.history.push({
                pathname: '/shop',
                search: '?category=conventional&page=1'
            })
        } else if (value === 'organic') {
            this.props.history.push({
                pathname: '/shop',
                search: '?category=organic&page=1'
            })
        }

    }

    render() {
        return (
            <div className='my-5'>
                <div className='container pb-3 '>
                    <Fade bottom>
                        <Row className='products-row'>
                            <Col md='4' className='text-center  pb-5 '>
                                <Card className='shadow-lg border-0 products' onClick={() => this.routeToProducts('australian')} style={{ cursor: 'pointer' }}>
                                    <CardImg top width='100%' src='	https://frozberries-vic.s3.ap-southeast-2.amazonaws.com/MANDAUS101BAGS-1698285872799' alt='Big range of Australian Grown & sourced from Australian growers,
                                    Our Australian Range includes: Australian Frozen Blueberries, Australian Frozen Mixed Berries, Australian Frozen Mango, Australian Frozen Mango Cheeks, Australian Frozen Raspberry Crumbles, Australian Frozen Pineapple, Australian Frozen Boysenberries, Australian Frozen Blackcurrants, Australian Frozen Blackberries ' />
                                    <CardBody>
                                        <CardTitle className='text-center text ' style={{ color: 'rgb(14,35,87)' }}>
                                            Australian Range
                                        </CardTitle>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md='4' className='text-center pb-5'>
                                <Card className='shadow-lg border-0 products' onClick={() => this.routeToProducts('conventional')} style={{ cursor: 'pointer' }}>
                                    <CardImg top width='100%' src='https://frozberries-vic.s3-ap-southeast-2.amazonaws.com/conventional-rasberry.jpg' 
                                    alt='Big range of Imported frozen fruit and vegetables are A Grade. Our conventional range products include: Conventional Frozen Wild Blueberries,Conventional Frozen Blueberries,
                                    Conventional Frozen Raspberries, Conventional Frozen Dragonfruit, Conventional Frozen Sour Cherries, Conventional Frozen Sweet Cherries, Conventional Frozen Rhubarb, Conventional Frozen Mango, Conventional Frozen Mixed Berries' />
                                    <CardBody>
                                        <CardTitle className='text-center text' style={{ color: 'rgb(14,35,87)' }}>
                                            Conventional Range
                                        </CardTitle>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md='4' className='text-center  pb-5' >
                                <Card className='shadow-lg border-0 products' onClick={() => this.routeToProducts('organic')} style={{ cursor: 'pointer' }}>
                                    <CardImg top width='100%' src='https://frozberries-vic.s3-ap-southeast-2.amazonaws.com/ELGWBB1KG.jpg' 
                                    alt='Frozberries Victoria offers quality organic frozen wildblueberries, mixed berries, blueberries, raspberries and many more.. 
                                    100% certified snap frozen organic fruit & berries brought to you organically by Frozberries Victoria and delivered to your doorstep. 
                                    Our organic range includes Elgin Organic Frozen Wild Blueberries, Elgin Organic Frozen Blueberries, Elgin Organic Frozen Mixed Berries, Elgin Organic Frozen Raspberries, Elgin Organic Frozen Mango,
                                    Elgin Organic Frozen Sour Cherries, Elgin Organic Frozen Sweet Cherries, Elgin Organic Frozen Strawberries' />
                                    <CardBody>
                                        <CardTitle className='text-center text' style={{ color: 'rgb(14,35,87)' }}>
                                            Organic Range
                                            </CardTitle>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Fade>

                </div>

            </div>
        )
    }
}


export default withRouter(Products)