import React, { Component } from 'react'
import { getOrder } from '../actions/orderActions'
import { connect } from 'react-redux'
import { Row, Col, Container, Badge } from 'reactstrap'
import moment from 'moment'
import Spinner from './Spinner';


class OrderDetails extends Component {

    componentDidMount() {
        this.props.getOrder(this.props.match.params.orderid)
    }

    handleClick = e => {
        this.props.history.push('/dashboard/addresses')
    }
    handleSignleOrder = (item) => {
        this.props.history.push(`/dashboard/order-details/${item._id}`)
    }

    render() {
        let SubTotal;
        if (this.props.orders.order.products) {
            SubTotal = Math.round((this.props.orders.order.products.map(eachItem => eachItem.productQuantity * eachItem.productPrice).reduce((total, currentValue) => total + currentValue)) * 100) / 100
        }

        let total = this.props.orders.order.total / 100;

        let discount;


        if (this.props.orders.order.coupon) {
            if (SubTotal >= this.props.orders.order.coupon.minimumSpend) {
                if (this.props.orders.order.coupon.isPercent) {
                    discount = (SubTotal / 100) * this.props.orders.order.coupon.amount
                } else {
                    discount = this.props.orders.order.coupon.amount
                }
            }
        }

        return (
            <div
            >
                {this.props.orders.fetching ? (
                    <div className='text-center' >
                        <Container>
                            <Row>
                                <Col className='my-5'>
                                    <Spinner />
                                </Col>
                            </Row>
                        </Container>

                    </div>
                ) : (
                        Object.keys(this.props.orders.order).length > 0 && (
                            <Container className='my-5 '>
                                <Row className='my-3 border-bottom '>
                                    <Col>
                                        <h4 style={{ fontWeight: 'bolder' }}>Order Details</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='8' >
                                        <p className='my-2 px-2 text-muted'>Order Status: {' '}
                                            <Badge style={{ fontSize: '100%' }} color={this.props.orders.order.orderStatus === 'Processing' ? 'warning' : 'success'}>
                                                {this.props.orders.order.orderStatus}
                                            </Badge></p>
                                        <p className='my-2 px-2 text-muted'>Order Number : {this.props.match.params.orderid.toUpperCase()}</p>
                                        <table className="table">
                                            <thead className='table-borderless'>
                                                <tr>
                                                    <th scope="col">PRODUCT</th>
                                                    <th></th>
                                                    <th scope="col">QTY</th>
                                                    <th scope="col">PRICE</th>
                                                </tr>
                                            </thead>
                                            <tbody >

                                                {this.props.orders.order.products.map(item => (
                                                    <tr key={item.productId}>
                                                        <td style={{ width: '100px' }}>
                                                            <img src={item.productImage} alt={item.productName} className='img-fluid' />
                                                        </td>
                                                        <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>  {item.productName}</td>
                                                        <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{item.productQuantity}</td>
                                                        <td style={{ verticalAlign: 'middle', width: '100px' }} className='font-weight-bold text-muted'>${(Math.round((item.productQuantity * item.productPrice) * 100) / 100).toFixed(2)}</td>
                                                    </tr>
                                                ))}


                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col sm='4' >
                                        <Row>
                                            <Col className='border py-3 mx-1'>
                                                {this.props.orders.order.shippingType === 'pickup' ? (
                                                    <React.Fragment>
                                                        <h5>Pick Up</h5>
                                                        <p>{this.props.orders.order.firstName} {this.props.orders.order.lastName}</p>
                                                        <p>Phone Number: {this.props.orders.order.phoneNumber}</p>
                                                        <p>Pick up date: {moment(this.props.orders.order.pickUpDate).format('L')}</p>
                                                        <p>Pick up time: {(this.props.orders.order.pickUpTime / 3600)}:00 </p>

                                                    </React.Fragment>) : (
                                                        <React.Fragment>
                                                            <h5>Delivery Address</h5>
                                                            <p>{this.props.orders.order.firstName} {this.props.orders.order.lastName}</p>
                                                            <p>Phone Number: {this.props.orders.order.phoneNumber}</p>
                                                            <p>{this.props.orders.order.address}</p>
                                                            <p>{this.props.orders.order.suburb.toUpperCase()} VIC {this.props.orders.order.postcode}</p>
                                                            {this.props.orders.order.shippingDate && (
                                                                <p className='text-success'>Shipping Date: {moment(this.props.orders.order.shippingDate).format('LL')}</p>

                                                            )}
                                                        </React.Fragment>
                                                    )}

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='border py-3 mx-1 my-1'>
                                                <h5>Order Total</h5>
                                                <div className='border-bottom py-2'>
                                                    <p >SubTotal <span className='float-right'>AU ${SubTotal.toFixed(2)}</span></p>
                                                    <p >Shipping  <span className='float-right'>{this.props.orders.order.shippingFee !== 0 ? ('AU $' + this.props.orders.order.shippingFee.toFixed(2)) : 'Free'}</span></p>
                                                    {this.props.orders.order.coupon && discount !== undefined && (
                                                        <React.Fragment>
                                                            <p>Discount  <span className='float-right'>
                                                                {this.props.orders.order.coupon.isPercent ? '%' : 'AU $'}{this.props.orders.order.coupon.amount}
                                                            </span>
                                                            </p>
                                                            <p className='text-danger'>Discount Applied <span className='float-right text-danger'>
                                                                - AU ${discount.toFixed(2)}
                                                            </span>
                                                            </p>
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                                <h1>Total <span className='float-right'>AU ${total.toFixed(2)}</span></h1>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        )
                    )}
            </div>
        )
    }
}
const mapStateToProps = state => ({
    errors: state.errors,
    auth: state.form,
    cart: state.cart,
    fullPageLoading: state.fullPageLoading,
    orders: state.orders
})
export default connect(mapStateToProps, { getOrder })(OrderDetails)