import { combineReducers } from 'redux'
import errorReducer from './errorReducer'
import faqReducer from './faqReducer'
import shoppingCart from './shoppingCart'
import formReducer from './formReducer'
import notFoundReducer from './notFoundReducer'
import productsReducer from './productsReducer'
import checkOutReducer from './checkOutReducer'
import orderReducer from './orderReducer'
import guestReducer from './guestReducer'
import discountReducer from './discountReducer'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import fullPageLoadingReducer from './fullPageLoadingReducer'
import adminUserReducer from './adminUserReducer'
import adminRevenueReducer from './adminRevenueReducer'
import adminAnalyticsReducer from './adminAnalyticsReducer'
import salesGraphReducer from './salesGraphReducer'
import recipeReducer from './recipeReducer'
import adminProductReducer from './adminProductReducer'
import adminRecipes from './adminRecipes'
import adminMapReducer from './adminMapReducer'
import LatestOrders from './LatestOrders'
import adminUserDash from './adminUserDash'
import adminOrdersDash from './adminOrdersDash'
import adminFaqReducer from './adminFaqReducer'
import adminLists from './adminLists'
import adminCouponReducer from './adminCouponReducer'
import reviewReducer from './reviewReducer'
import adminReviews from './adminReviews'
import adminBlogReducer from './adminBlogReducer'
import journalReducer from './journalReducer'



const persistConfig = {
    key: 'root',
    storage,
    whitelist: []
}

const authPersistConfig = {
    key: 'cart',
    storage,
    blacklist: ['cartOpen', 'addToCartLoading', 'removeFromCartLoading']
};

const authUserConfig = {
    key: 'user',
    storage,
    blacklist: ['isAuthenticated', 'user', 'verified', 'verifyloading', 'showResetPasswordSuccess', 'passwordUpdated', 'resendLink', 'spinner', 'showRegistrationSuccess']
}

const authGuestConfig = {
    key: 'guest',
    storage,
}

const rootReducer = combineReducers({
    fullPageLoading: fullPageLoadingReducer,
    checkout: checkOutReducer,
    errors: errorReducer,
    faqsData: faqReducer,
    cart: persistReducer(authPersistConfig, shoppingCart),
    form: persistReducer(authUserConfig, formReducer),
    notfound: notFoundReducer,
    products: productsReducer,
    orders: orderReducer,
    discount: discountReducer,
    guest: persistReducer(authGuestConfig, guestReducer),
    adminUserCalc: adminUserReducer,
    adminRevenueCalc: adminRevenueReducer,
    adminAnalytics: adminAnalyticsReducer,
    salesGraphReducer: salesGraphReducer,
    recipes: recipeReducer,
    adminProducts: adminProductReducer,
    adminRecipes: adminRecipes,
    map: adminMapReducer,
    latestOrders: LatestOrders,
    users: adminUserDash,
    adminOrders: adminOrdersDash,
    adminFaqs: adminFaqReducer,
    adminLists: adminLists,
    adminCouponReducer: adminCouponReducer,
    reviews: reviewReducer,
    adminReviews: adminReviews,
    adminBlogs: adminBlogReducer,
    journals: journalReducer,


})

export default persistReducer(persistConfig, rootReducer)