import { NOT_FOUND, FOUND } from './types'

export const notFound = () => dispatch => {
    dispatch({
        type: NOT_FOUND
    })
}

export const found = () => dispatch => {
    dispatch({
        type: FOUND
    })
}