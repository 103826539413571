import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setImages } from '../actions/reviewActions'
import { Button } from 'reactstrap'
import { Form, Input, InputGroup } from 'reactstrap'
import classnames from 'classnames'

class ReviewStep2 extends Component {

   
    handleUploadPhotoClick=(e)=>{
    document.getElementById('reviewImage').click()
    }

    onImageSelect = e => {
            let reader = new FileReader();
            let file = e.target.files[0];
            let data={}
            reader.onloadend = () => {
                data.image=file
                data.url=reader.result
                this.props.setImages(data)
            }

            if (file) {
                reader.readAsDataURL(file);
            }

    }


    render() {
        return (
            <div className='text-center  parent'>
                <div className='px-5  py-3'>
                    <h4 className='font-weight-bold my-3 '>SHOW IT OFF</h4>
                    <h4 className='font-weight-bold my-3 text-muted '>WE'D LOVE TO SEE IT IN ACTION!</h4>

                    <div className='my-3 '>
                        <Form encType="multipart/form-data" onSubmit={e=>e.preventDefault()}>
                    <InputGroup className='mx-auto'>
                        <Input  
                   id='reviewImage'
                   accept="image/png, image/jpeg"
                        type="file" 
                        style={{display:'none'}}
                        onChange={this.onImageSelect}
                        // className={classnames('', { 'is-invalid': this.props.reviews.errors.image })} 
                        />
                        {/* <div className='invalid-feedback'>{this.props.reviews.errors.image}</div> */}
                    </InputGroup>
            </Form>
                    

                </div>
                    <div>
                {this.props.reviews.imageUrl !=='' && (
                                <img className='my-3' style={{ height: 200 }} src={this.props.reviews.imageUrl} alt='new review' />
                            )}
    </div>
                <Button outline color='dark'  onClick={this.handleUploadPhotoClick}>
        Upload a photo
      </Button>
                </div>
              

                <div style={{ bottom: 0 }}>
                    <div className='float-left'>
                        <Button outline color='dark' className='border-0' onClick={() => this.props.previousStep()}> Back </Button>
                    </div>
                    <div className='float-right'>
                        <Button outline color='dark' className='border-0' onClick={() => this.props.nextStep()}> {this.props.reviews.imageUrl !=='' ?'Next':'Skip'} </Button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    reviews: state.reviews
})

export default connect(mapStateToProps, {setImages})(ReviewStep2)
