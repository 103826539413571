import {
    SUBMIT_JOURNAL_LOADING,
    JOURNAL_CREATED_SUCCESS,
    SUBMIT_JOURNAL_LOADING_RESET,
    JOURNAL_ERRORS,
    RESET_JOURNAL_REDUCER,
    GET_JOURNALS
} from '../actions/types'


const initialState = {
    journals: [],
    isSuccess: false,
    isLoading: false,
    errors: {}
}

export default function (state = initialState, action) {
    switch (action.type) {

        case GET_JOURNALS:
            return {
                ...state,
                journals: action.payload
            }
        case JOURNAL_ERRORS:
            return {
                ...state,
                errors: action.payload
            }

        default: return state;
    }
}