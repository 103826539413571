import React, { Component } from 'react'
import {
    Row, Col, Input, Card, CardBody, Button

} from 'reactstrap';
import { withScriptjs, GoogleMap, withGoogleMap, Marker } from "react-google-maps"
import { SearchBox } from "react-google-maps/lib/components/places/SearchBox"
import { Circle } from "react-google-maps";
import { Helmet } from "react-helmet";
import pin from '../images/pin.png'
class Map extends Component {

    state = {
        markers: [{ lat: -37.712094, lng: 144.863595, label: 'Frozberries Victoria' }],
        center: {
            lat: -37.809903, lng: 144.962795,
        },
        flatFeePostcodes: [
            3000,
            2890,
            8009,
            8001,
            8003,
            8007,
            8006,
            3006,
            3008,
            8001,
            3003,
            8005,
            3005,
            3056,
            3006,
            3207,
            3002,
            3051,
            3205,
            3050,
            3010,
            3065,
            3053,
            3053,
            3066,
            3052,
            8002,
            3052,
            3121,
            3121,
            3004,
            3121,
            3121,
            3121,
            3067,
            3206,
            8873,
            3054,
            3142,
            3031,
            3141,
            3207,
            3068,
            3054,
            3068,
            3206,
            3121,
            3121,
            8015,
            8015,
            3031,
            3066,
            3181,
            3122,
            3142,
            3057,
            8008,
            3032,
            3057,
            3011,
            3011,
            3055,
            3181,
            3032,
            3011,
            3056,
            3182,
            3013,
            3013,
            3101,
            3122,
            3122,
            3070,
            3070,
            3055,
            3078,
            3182,
            3056,
            3182,
            3144,
            3015,
            3039,
            3143,
            3143,
            3181,
            3078,
            3123,
            3122,
            3183,
            3012,
            3004,
            3004,
            3055,
            3055,
            3183,
            3071,
            3015,
            3016,
            3051,
            3146,
            3102,
            3123,
            3144,
            3144,
            3012,
            3083,
            3015,
            3032,
            3124,
            3058,
            3124,
            3124,
            3124,
            3185,
            3040,
            3032,
            3103,
            3161,
            3126,
            3012,
            3103,
            3184,
            3103,
            3079,
            3079,
            3124,
            8576,
            3058,
            3184,
            3161,
            3040,
            3012,
            3041,
            3044,
            3016,
            3185,
            3104,
            8627,
            3079,
            3012,
            3041,
            3040,
            3162,
            3146,
            3019,
            3072,
            3072,
            3072,
            3103,
            3034,
            3072,
            3126,
            3145,
            3058,
            3025,
            3162,
            3041,
            3058,
            3081,
            3081,
            3086,
            3081,
            3072,
            3072,
            3044,
            3084,
            3185,
            3019,
            3145,
            3163,
            3145,
            3084,
            3025,
            3127,
            3125,
            3072,
            3127,
            3081,
            3042,
            3058,
            3186,
            3147,
            3020,
            3145,
            3104,
            3187,
            3187,
            3145,
            3041,
            3105,
            3127,
            3128,
            3018,
            3125,
            3042,
            3151,
            3163,
            3084,
            3020,
            3046,
            3186,
            3020,
            3129,
            3073,
            3147,
            3176,
            3163,
            3204,
            3033,
            3020,
            3025,
            3018,
            3148,
            3128,
            3041,
            3128,
            3020,
            3186,
            3046,
            3125,
            3148,
            3204,
            3128,
            3163,
            3084,
            3083,
            3060,
            3129,
            3166,
            3109,
            3026,
            3085,
            3085,
            3108,
            3046,
            3022,
            3148,
            3204,
            3107,
            3042,
            3043,
            3021,
            3148,
            3084,
            3129,
            3128,
            3166,
            3188,
            3188,
            3165,
            3130,
            3186,
            3130,
            3023,
            3149,
            3188,
            3085,
            3047,
            3036,
            3106,
            3189,
            3189,
            3130,
            3166,
            3087,
            3093,
            3130,
            3189,
            3061,
            3021,
            3030,
            3193,
            3151,
            3166,
            3083,
            3023,
            3023,
            3047,
            3043,
            3038,
            3074,
            3028,
            3131,
            3191,
            3149,
            3149,
            3043,
            3087,
            3047,
            3042,
            3022,
            3167,
            3109,
            3094,
            3190,
            3088,
            3110,
            3131,
            3029,
            3049,
            3028,
            3021,
            3800,
            3088,
            3111,
            3150,
            3168,
            3075,
            3131,
            3168,
            3028,
            3023,
            3049,
            3192,
            3021,
            3192,
            3027,
            3133,
            3132,
            3132,
            3192,
            3037,
            3169,
            3038,
            3133,
            3023,
            3082,
            3095,
            3132,
            3193,
            3023,
            3048,
            3038,
            3038,
            3048,
            3030,
            3194,
            3169,
            3088,
            3036,
            3170,
            3076,
            3037,
            3202,
            3062,
            3023,
            3095,
            3170,
            3037,
            3114,
            3193,
            3064,
            3193,
            3194,
            3134,
            3090,
            3150,
            3194,
            3059,
            3152,
            3029,
            3095,
            3152,
            3134,
            3171,
            3171,
            3172,
            3029,
            3037,
            3135,
            3134,
            3152,
            3089,
            3113,
            3135,
            3135,
            3195,
            3113,
            3037,
            3179,
            3752,
            3152,
            3134,
            3172,
            3134,
            3063,
            3428,
            3030,
            3153,
            3136,
            3195,
            3174,
            3091,
            3174,
            3096,
            3097,
            3136,
            3180,
            3164,
            3178,
            3153,
            3064,
            3136,
            3195,
            3155,
            3115,
            3175,
            3750
        ],
        showError: false,
        showFlatFee: false,
        showWarning: false
    }



    handlePlacesChanged = () => {
        const places = this.searcbox.getPlaces();
        var latitude = places[0].geometry.location.lat();
        var longitude = places[0].geometry.location.lng();
        let position = {}
        position.lat = latitude;
        position.lng = longitude
        position.label = places[0].formatted_address

        for (var i = 0; i < places[0].address_components.length; i++) {
            for (var j = 0; j < places[0].address_components[i].types.length; j++) {
                if (places[0].address_components[i].types[j] === "postal_code") {
                    if (this.state.flatFeePostcodes.includes(Number(places[0].address_components[i].long_name))) {
                        this.setState({
                            showFlatFee: true
                        })
                    }
                    else {
                        this.setState({
                            showError: true
                        })
                    }
                }
            }
        }
        if (this.state.showFlatFee !== true && this.state.showError !== true) {
            this.setState({
                showWarning: true
            })
        } else {
            this.setState({
                markers: [...this.state.markers, position],
            })
        }
    }
    removeMarker = (index) => {
        let markers = [...this.state.markers]
        if (index !== 0) {
            markers.splice(index, 1)
            this.setState({
                markers,
                center: {
                    lat: -37.712094, lng: 144.863595,

                },
                showError: false,
                showFlatFee: false,
                showRegionalFee: false
            })
        }
    }

    handleButtonClick = (e) => {
        this.setState({
            showError: false,
            showFlatFee: false,
            showRegionalFee: false,
            showWarning: false
        })
    }


    render() {
        return (
            <GoogleMap
                defaultZoom={10}
                center={{ lat: this.state.center.lat, lng: this.state.center.lng }}
            >
                {this.state.markers.map((marker, index) => (
                    <Marker
                        icon={{ labelOrigin: new window.google.maps.Point(0, -10), url: pin, scaledSize: new window.google.maps.Size(40, 40) }}
                        label={{ text: marker.label, fontSize: '18px', }} onClick={() => this.removeMarker(index)} key={index} position={{ lat: marker.lat, lng: marker.lng }} />
                ))}


                {this.state.markers.length <= 1 && (
                    <SearchBox
                        controlPosition={window.google.maps.ControlPosition.TOP}
                        onPlacesChanged={this.handlePlacesChanged}
                        ref={(searcbox) => { this.searcbox = searcbox }}
                    >
                        <Input type='text' placeholder='Enter your address'
                            className='img-fluid'
                            style={{
                                boxSizing: `border-box`,
                                border: `1px solid transparent`,
                                width: `70vw`,
                                height: `50px`,
                                marginTop: `70px`,
                                padding: `0 12px`,
                                borderRadius: `3px`,
                                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                fontSize: `14px`,
                                outline: `none`,
                                textOverflow: `ellipses`,
                            }}
                        />
                    </SearchBox>

                )}
                {
                    this.state.showWarning && (
                        <div className='position-absolute  ' style={{ top: 200, left: '50%' }}>
                            <Card className='text-center     py-3' style={{ left: '-50%' }} >

                                <i className="fas fa-exclamation-circle fa-3x text-warning"></i>

                                <CardBody>
                                    <h4>Please enter an address with a zipcode.</h4>
                                </CardBody>
                                <Button color='success' className='mx-5 mb-3' outline onClick={this.handleButtonClick}>OK!</Button>
                            </Card>
                        </div>
                    )
                }
                {
                    this.state.showFlatFee && (
                        <div className='position-absolute  ' style={{ top: 200, left: '50%' }}>
                            <Card className='text-center     py-3' style={{ left: '-50%' }} >

                                <i className="fas fa-check fa-3x text-success"></i>
                                <CardBody>
                                    <h4>We deliver to your area.</h4>
                                    <p>Delivery Charge : $25.00</p>
                                </CardBody>
                                <Button color='success' className='mx-5 mb-3' outline onClick={this.handleButtonClick}>OK, Thanks!</Button>
                            </Card>
                        </div>
                    )
                }

                {
                    this.state.showError && (
                        <div className='position-absolute  ' style={{ top: 200, left: '50%' }}>
                            <Card className='text-center     py-3' style={{ left: '-50%' }} >

                                <i className="fas fa-exclamation-circle fa-3x text-danger"></i>
                                <CardBody>
                                    <h4>Your address is outside our Melbourne Metro delivery area.

Please contact us at <a className='phone-link' href='tel:03 9335 6331'>

                                            03 9335 6331
                                </a> to discuss freight rates to your location.</h4>
                                </CardBody>
                                <Button color='success' className='mx-5 mb-3' outline onClick={this.handleButtonClick}>OK, Thanks!</Button>
                            </Card>
                        </div>
                    )
                }
                <Circle
                    center={{
                        lat: -37.809903, lng: 144.962795
                    }}
                    options={{
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity: 0.3,
                    }}
                    radius={30000}
                />

            </GoogleMap>
        )
    }
}


const WrappedMap = withScriptjs(withGoogleMap(Map))



export default class DeliveryLocations extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Delivery Locations - Frozberries Victoria</title>
                    <meta name="description" content="Delivery Locations - Frozberries Victoria" />
                </Helmet>
                <Row>
                    <Col style={{ minHeight: '90vh' }}>
                        <WrappedMap
                            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div className='w-100 h-100 mapContainer' />}
                            mapElement={<div style={{ height: `100%` }} />}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}
