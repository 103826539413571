import {
    GET_FAQS,
    FAQS_LOADING
} from './types'
import axios from 'axios'

export const getFaqs = () => dispatch => {

    dispatch({
        type: FAQS_LOADING
    })

    axios.get(`/api/faqs/getFaqs`)
        .then(res => {

                dispatch({
                    type: GET_FAQS,
                    payload: res.data
                })
        })
        .catch(err => console.log(err))
}



