import {
    GET_ADMIN_COUPONS,
    GET_ADMIN_COUPONS_LOADING,
    GET_ADMIN_COUPONS_LOADING_RESET,
    GET_ADMIN_COUPONS_COUNT,
    CREATE_NEW_COUPON,
    CREATE_NEW_COUPON_LOADING,
    CREATE_NEW_COUPON_LOADING_RESET,
    CREATE_NEW_COUPON_ERRORS,
    RESET_CREATE_NEW_COUPON_FORM,
    DEACTIVATE_COUPON,
    DELETE_COUPON,
    RESET_COUPON_STATUS
} from '../actions/types.js'


const initialState = {
    coupons: [],
    couponCount: 0,
    loading: false,
    coupon: {},
    errors: {},
    newCouponCreated: false,
    newCouponLoading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case RESET_COUPON_STATUS:
            return {
                ...state,
                newCouponCreated: false,
                newCouponLoading: false
            }
        case DELETE_COUPON:
            return {
                ...state,
                coupons: state.coupons.filter(eachCoupon => eachCoupon._id !== action.payload._id)
            }
        case DEACTIVATE_COUPON:
            return {
                ...state,
                coupons: state.coupons.map(eachCoupon => eachCoupon._id === action.payload._id ? action.payload : eachCoupon)
            }
        case GET_ADMIN_COUPONS_COUNT:
            return {
                ...state,
                couponCount: action.payload
            }
        case GET_ADMIN_COUPONS:
            return {
                ...state,
                coupons: state.coupons.concat(action.payload)
            }
        case CREATE_NEW_COUPON:
            return {
                ...state,
                newCouponCreated: true,
                newCouponLoading: false,
                errors: {}
            }
        case GET_ADMIN_COUPONS_LOADING:
            return {
                ...state,
                loading: true
            }
        case GET_ADMIN_COUPONS_LOADING_RESET:
            return {
                ...state,
                loading: false
            }
        case CREATE_NEW_COUPON_LOADING:
            return {
                ...state,
                newCouponLoading: true
            }
        case CREATE_NEW_COUPON_LOADING_RESET:
            return {
                ...state,
                newCouponLoading: false
            }
        case CREATE_NEW_COUPON_ERRORS:
            return {
                ...state,
                errors: action.payload
            }
        case RESET_CREATE_NEW_COUPON_FORM:
            return {
                ...state,
                coupons: [],
                couponCount: 0,
                loading: false,
                coupon: {},
                errors: {},
                newCouponCreated: false,
                newCouponLoading: false
            }
        default: return state;
    }
}