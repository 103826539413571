import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getJournals } from '../actions/journalActions.js'
import { Fade } from 'react-reveal';
import Base from './Base'
import { Helmet } from "react-helmet";



class Blogs extends Component {
    state = {
        journals: [], 
        windowWidth: 0

    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimension);
    }
    updateWindowDimension = (e) => {
        this.setState({
            windowWidth: window.innerWidth
        })
    }




    componentDidMount() {
        let page = 0
        this.props.getJournals(page)

        this.setState({
            windowWidth: window.innerWidth,
        })
        window.addEventListener('resize', this.updateWindowDimension);

    }
    componentDidUpdate(prevProps) {
        if (this.props.journals.journals !== prevProps.journals.journals) {
            this.setState({
                journals: this.props.journals.journals
            })
        }
    }


    routeToSpecificPage = article => {
        this.props.history.push({
            pathname: `/blogs/${article.title.replace(/[^A-Z0-9]+/ig, "-")}`,
            state: { article }
        })
    }


    render() {
        return (
            <div>
                <div>
                    <Helmet>
                        <title>Blog - Frozberries Victoria - Check out latest information about our frozen fruit and vegetable range.  </title>
                        <meta name="description" content="Blog - Frozberries Victoria - Check out latest information about our frozen fruit and vegetable range." />
                    </Helmet>
                </div>
                <Base title='Blog' />
                <div className='py-5'>
                    <div className='container-md '>
                        <h4 className='text-center' style={{ color: '#0d2357' }}>
                            Check out latest information about our frozen fruit and vegetable range.
                        </h4>
                    </div>
                    <div className='paddingClass mx-auto mt-5'>
                        <div className='px-3 '
                            style={{
                                display: "grid",
                                gridTemplateColumns: `repeat(${this.state.windowWidth > 1200 ? 4 : this.state.windowWidth > 992 ? 3 : 2}, 1fr)`,
                                gridGap: "15px",
                            }}>
                            {this.state.journals.map((eachPost, i) => (
                                <div key={eachPost._id} className='w-100 h-100'
                                    style={{
                                        gridColumn: this.state.windowWidth > 1200 ? ((i < 5 && i === 0) ? 'span 2' : ((i + 1) % 6 === 0 || i % 6 === 0) ? 'span 2' : 'span 1') : (this.state.windowWidth > 992 ? (i < 1 ? 'span 2' : 'span 1') : 'span 1'),
                                    }}>
                                    <Fade >
                                        <div className='w-100 h-100 position-relative article-container border' style={{ cursor: 'pointer' }} onClick={() => this.routeToSpecificPage(eachPost)}>
                                            <img className=' w-100 h-100 position-relative' style={{ objectFit: 'cover', borderRadius: '.25rem', maxHeight: 300, minHeight:150 }}
                                                src={eachPost.thumbnailImage} alt={eachPost.title} />
                                            <div className="article-overlay " >
                                                <div className={`article-title `}  >{eachPost.title}
                                                </div>
                                                <div className="article-text" style={{ fontSize: this.state.windowWidth > 1200 ? 'medium' : 'small' }}>{eachPost.subTitle}
                                                    <br />
                                                    {eachPost.subTitle !== '' && (
                                                        <span className='read-more-button font-weight-bold' >
                                                            Read More
</span>
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    journals: state.journals
})


export default connect(mapStateToProps, { getJournals })(Blogs)