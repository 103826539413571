import {
    GET_GUEST_USER,
    REMOVE_GUEST_USER
} from '../actions/types'

const initialState = {
    userid: '',
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_GUEST_USER:
            return {
                ...state,
                userid: action.payload
            }
        case REMOVE_GUEST_USER:
            return {
                ...state,
                userid: ''
            }
        default: return state;
    }
}