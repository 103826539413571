import React, { Component } from 'react'
import { Container, Row, Col, Button, Label, Alert } from 'reactstrap'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { InputGroup, Input } from 'reactstrap';
import { resetJournalForm, submitJournal, handleArticleChange, addParagraph, updateJournal, newThumbnailImage } from '../../actions/adminJournalActions'
import TrixEditor from './TrixEditor';


class NewBlog extends Component {

    state = {
        errors: {},
        thumbnailImage: null,
        showSuccessAlert: false
    }

    componentDidMount() {
        if (!this.props.adminBlogs.blog._id) {
            this.props.history.push('/adminpanel/blogs-dashboard')
        }
    }


    componentWillUnmount() {
        this.props.resetJournalForm()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.adminBlogs.errors !== prevProps.adminBlogs.errors) {
            this.setState({
                errors: this.props.adminBlogs.errors
            })
        }
        if (this.props.adminBlogs.success !== prevProps.adminBlogs.success && this.props.adminBlogs.success) {
            this.setState({
                showSuccessAlert: true
            })
        }
    }

    onChange = (e) => {
        let blog = { ...this.props.adminBlogs.blog }
        blog[e.target.name] = e.target.value
        this.props.handleArticleChange(blog)
    }



    onSubmit = (e) => {
        e.preventDefault();

        let formData = new FormData()

        if (this.props.adminBlogs.blog.thumbnailImage) {
            formData.append('thumbnailImage', this.props.adminBlogs.blog.thumbnailImage)

        }
        formData.append('blogId', this.props.adminBlogs.blog._id)
        formData.append('title', this.props.adminBlogs.blog.title)
        formData.append('subTitle', this.props.adminBlogs.blog.subTitle)
        formData.append('paragraphs', JSON.stringify(this.props.adminBlogs.blog.paragraphs))
        formData.append('deleteList', JSON.stringify(this.props.adminBlogs.deleteList))
        formData.append('addedImages', JSON.stringify(this.props.adminBlogs.addedImages))
        this.props.submitJournal(formData)






    }

    inputOnBlur = (e) => {
        if (this.props.adminBlogs.typing) {
            this.props.updateJournal(this.props.adminBlogs.blog)
        }
    }


    onThumbnailImageSelect = e => {
        let errors = this.state.errors

        if (errors.thumbnailImage) {
            delete errors.thumbnailImage
        }


        if (e.target.name === 'thumbnailImage') {
            let formData = new FormData()
            formData.append('thumbnailImage', e.target.files[0])
            formData.append('blogId', this.props.adminBlogs.blog._id)

            this.props.newThumbnailImage(formData)
        }
    }

    addNewParagraph = e => {
        let newParagraph = {
            paragraphTitle: '',
            paragraph: '',
        }

        this.props.addParagraph(newParagraph)
    }


    routeToNewArticle = e => {
        this.props.history.push({
            pathname: `/blogs/${this.props.adminBlogs.blog.title.replace(/[^A-Z0-9]+/ig, "-")}`,
            state: { blog: this.props.adminBlogs.blog }
        })
    }



    render() {
        const { errors } = this.state
        return (
            <div>
                <Container>
                    <Row>
                        <Col >
                            <div>
                                <h1 className='display-4 text-center ' style={{ color: 'white' }}>
                                    {this.props.history.location.state !== undefined && this.props.history.location.state.update ? 'Update this Blog' : 'Create a new Blog'}</h1>

                            </div>


                            <form >
                                <InputGroup className='my-2 bg-light p-3' style={{ borderRadius: 10 }}>
                                    <Label className='font-weight-bold ' for="thumbnailImage">Select a thumbnail image</Label>
                                    <Input ref="thumbnailImage" type="file" name="thumbnailImage" id="thumbnailImage" onChange={this.onThumbnailImageSelect} className={classnames('', { 'is-invalid': errors.thumbnailImage })} />
                                    <div className='invalid-feedback'>{errors.thumbnailImage}</div>
                                </InputGroup>

                                {this.props.adminBlogs.blog.thumbnailImage && this.props.adminBlogs.blog.thumbnailImage !== '' && (
                                    <div className='my-3'>
                                        <img style={{ maxHeight: 300 }} src={this.props.adminBlogs.blog.thumbnailImage} />
                                    </div>
                                )}


                                <InputGroup className='my-2 bg-light p-3' style={{ borderRadius: 10 }}>
                                    <Input placeholder="Title" onChange={this.onChange} onBlur={this.inputOnBlur} className={classnames('', { 'is-invalid': errors.title })} value={this.props.adminBlogs.blog.title} name='title' />
                                    <div className='invalid-feedback'>{errors.title}</div>
                                </InputGroup>

                                <InputGroup className='my-2 bg-light p-3' style={{ borderRadius: 10 }}>
                                    <Input placeholder="Subtitle" onChange={this.onChange} onBlur={this.inputOnBlur} className={classnames('', { 'is-invalid': errors.subTitle })} value={this.props.adminBlogs.blog.subTitle} name='subTitle' />
                                    <div className='invalid-feedback'>{errors.subTitle}</div>
                                </InputGroup>


                            </form>

                            {Object.keys(this.props.adminBlogs.blog).length > 0 && this.props.adminBlogs.blog.paragraphs && this.props.adminBlogs.blog.paragraphs.map((eachParagraph, index) => (
                                <div key={index}>
                                    <TrixEditor index={index} />
                                </div>
                            ))}


                            {this.state.showSuccessAlert && (
                                <div className='my-3'>
                                    <Alert color="success">
                                        This blog successfully {this.props.history.location.state !== undefined && this.props.history.location.state.update ? 'updated' : 'published'}. Go back to <a href='/adminpanel/blogs-dashboard'>Blogs Dashboard</a> or click <span onClick={this.routeToNewArticle} style={{ color: 'rgb(204,90,66)', textDecoration: 'underline', cursor: 'pointer' }}>here</span> to see the new blog.
                                    </Alert>
                                </div>
                            )}



                            <Button color='success' onClick={this.addNewParagraph} className='btn  btn-block mt-4 p-2 border-0 text-light '>
                                Add New Paragraph
                                </Button>





                            <Button
                                onClick={this.onSubmit}
                                // disabled={this.state.title === '' || this.state.subTitle === '' ? true : false}
                                className='btn  btn-block my-4 p-2 border-0 text-light ' style={{
                                    backgroundColor: 'rgb(204,90,66)'
                                }} >{this.props.adminBlogs.isLoading ? 'Please Wait..' : (this.props.history.location.state !== undefined && this.props.history.location.state.update) ? 'Update Journal' : 'Submit Journal'} </Button>

                        </Col>

                    </Row>
                </Container>
            </div>


        )
    }
}
const mapStateToProps = state => ({
    adminBlogs: state.adminBlogs
})


export default connect(mapStateToProps, { resetJournalForm, submitJournal, handleArticleChange, addParagraph, updateJournal, newThumbnailImage })(NewBlog)