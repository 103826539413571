import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import { Form, Input, InputGroup, Label } from 'reactstrap'
import classnames from 'classnames'
import { updateReview, newReview } from '../actions/reviewActions'


class ReviewStep4 extends Component {

    state = {
        review: '',
        errors: {}
    }

    componentDidUpdate(prevProps) {
        if (this.props.reviews.errors !== prevProps.reviews.errors) {
            this.setState({
                errors: this.props.reviews.errors
            })
        }

        if (this.props.reviews.success !== prevProps.reviews.success && this.props.reviews.success) {
            this.props.nextStep()
        }
    }

    handleChange = e => {

        let field = e.target.name
        let value = e.target.value

        if (Object.keys(this.state.errors)) {
            this.setState({
                errors: {}
            })
        }

        this.props.updateReview(field, value)
    }

    handleSubmit = e => {
        let errors = this.state.errors

        if (this.props.reviews.firstName === '') {
            errors.firstName = 'Required field'
        }
        if (this.props.reviews.lastName === '') {
            errors.lastName = 'Required field'
        }
        if (this.props.reviews.email === '') {
            errors.email = 'Required field'
        }

        if (Object.keys(errors).length !== 0) {
            this.setState({
                errors
            })
        } else {
            let formData = new FormData()

            if (this.props.reviews.images) {
                formData.append(`reviewImage`, this.props.reviews.images)
            }
            formData.append('review', this.props.reviews.review)
            formData.append('firstName', this.props.reviews.firstName)
            formData.append('lastName', this.props.reviews.lastName)
            formData.append('email', this.props.reviews.email)
            formData.append('productId', this.props.product._id)
            formData.append('rating', this.props.reviews.rating)
            formData.append('cart', JSON.stringify(this.props.cart.cart))
            this.props.newReview(formData)

        }
    }


    render() {
        return (
            <div className='text-center  parent'>
                <div className='px-5  py-3'>
                    <div>
                        <h4 className='font-weight-bold my-3 '>ABOUT YOU</h4>
                    </div>

                    <div>
                        <Form onSubmit={(e) => e.preventDefault()}>
                            <InputGroup className='my-2'>
                                <Input
                                    className={classnames('', { 'is-invalid': this.state.errors.firstName })}
                                    onChange={this.handleChange} name='firstName' value={this.props.reviews.firstName} placeholder='First Name' />
                                <div className='invalid-feedback'>{this.state.errors.firstName}</div>
                            </InputGroup>
                            <InputGroup className='my-2'>

                                <Input
                                    className={classnames('', { 'is-invalid': this.state.errors.lastName })}
                                    onChange={this.handleChange} name='lastName' value={this.props.reviews.lastName} placeholder='Last Name' />
                                <div className='invalid-feedback'>{this.state.errors.lastName}</div>
                            </InputGroup>
                            <InputGroup className='my-2'>

                                <Input
                                    className={classnames('', { 'is-invalid': this.state.errors.email })}
                                    onChange={this.handleChange} name='email' value={this.props.reviews.email} placeholder='Email' />
                                <div className='invalid-feedback'>{this.state.errors.email}</div>
                            </InputGroup>

                            <Label className='text-muted my-2'>By submitting,
                                I acknowledge the Terms of Service and Privacy Policy and that my review will be publicly posted and shared online.</Label>

                        </Form>


                    </div>
                </div>

                <div style={{ bottom: 0 }}>
                    <div className='float-left'>
                        <Button outline color='dark' className='border-0' onClick={() => this.props.previousStep()}> Back </Button>
                    </div>
                    <div className='float-right'>
                        <Button disabled={this.props.reviews.loading} outline color='dark' className='border-0' onClick={this.handleSubmit}>{this.props.reviews.loading ? 'Please Wait' : 'Submit'}  </Button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    reviews: state.reviews,
    cart: state.cart,
})

export default connect(mapStateToProps, { updateReview, newReview })(ReviewStep4)
