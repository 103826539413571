import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import Slide from 'react-reveal/Slide';
import delivery from '../images/delivery.png'
import australia from '../images/australiawide.png'
import natural from '../images/natural.png'
import { Link } from 'react-router-dom'

export default class Categories extends Component {
    render() {
        return (
            <div>
                <div className='container pb-2 mb-3'>

                    <Row className='mb-3'>
                        <Col md='4' className='text-center'>
                            <Slide left>
                                <img src={australia} alt='Frozberries is 100% Australian owned and operated.' style={{ height: 90 }} />
                                <h5 className='pt-3' style={{ color: 'rgb(14,35,87)' }}>Australian Owned Frozen Fruit Supplier</h5>
                                <p style={{ color: 'rgb(14,35,87)' }}> We are located in six Australian states delivering best quality australian grown frozen berries all year round.</p>
                            </Slide>
                        </Col>
                        <Col md='4' className='text-center'>
                            <Slide bottom>
                                <img src={delivery} alt='Where to Buy Australian Frozen Berries? Our wide range of frozen fruit and vegetables are delivered to Melbourne metropolitan area and beyond!' style={{ height: 90 }} />
                                <h5 className='pt-3' style={{ color: 'rgb(14,35,87)' }}  >Large Delivery Zone</h5>
                                <p style={{ color: 'rgb(14,35,87)' }}>Our frozen fruit and vegetables are delivered to Melbourne metropolitan area and beyond! Click <Link to='/deliverylocations'>here</Link> to see if we deliver to your area.</p>

                            </Slide>
                        </Col>
                        <Col md='4' className='text-center'>
                            <Slide right>
                                <img src={natural} alt='Our frozen fruit and vegetables are 100% fruit with no preservatives' style={{ height: 90 }} />
                                <h5 className='pt-3' style={{ color: 'rgb(14,35,87)' }} >No Added Preservatives </h5>
                                <p style={{ color: 'rgb(14,35,87)' }}> Organic colors and flavors. 100%  frozen berries and vegetables with no preservatives.
  </p>
                            </Slide>
                        </Col>
                    </Row>


                </div>
            </div>
        )
    }
}
