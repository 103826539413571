import React, { Component } from "react";
import Products from "./Products";
import Instagram from "./Instagram";
import Landing from "./Landing";
import Categories from "./Categories";
import Brands from "./Brands";
import { Helmet } from "react-helmet";
import Testimonials from "./Testimonials";
import Messages from "./Messages";
import AcaiPacks from "./AcaiPacks";
import ShopRange from "./ShopRange";

class MainPage extends Component {
  render() {
    return (
      <div>
        <div>
          <Helmet>
            <title>
              Frozen Berries, Frozen Vegetables, Bulk Buy Fruit and Vegetables |
              Frozberries Victoria | Wild Blueberries | Wild Blueberries
              Melbourne | Wild Blueberries Australia Supplier | Organic Wild
              Blueberries Melbourne | Organic Wild Blueberries Australia | Buy
              Wild Blueberries Australia | Where to Buy Wild Blueberries in
              Australia
            </title>
            <meta
              name="description"
              content="Frozberries is a supplier of Australian Grown as well as imported frozen berries, tropical fruit and fruit purees. We are located in six Australian states delivering best quality all year round.

Our fruit is available throughout the year and not just whilst in season.

As each berry is individually frozen, you can pour out the quantity required and store the remainder in your freezer for future use.

Inside every package of Frozberries Fresh Frozen Fruit , you'll find 100% natural fruit. It's healthy nutrition you can feel good about. Our ranges include Australian, Elgin Organic, and Conventional Range. Our warehouses have wildblueberries all year along. "
            />
          </Helmet>
        </div>
        <Landing />
        {/* <Messages /> */}
        <Products />
        <Categories />
        <AcaiPacks />
        <Instagram />
        <ShopRange />
        <Brands />
        <Testimonials />
      </div>
    );
  }
}
export default MainPage;
