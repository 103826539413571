import axios from 'axios';
import {
    SUBMIT_JOURNAL_DATA,
    SUBMIT_JOURNAL_LOADING,
    BLOG_CREATED_SUCCESS,
    SUBMIT_JOURNAL_LOADING_RESET,
    BLOG_ERRORS,
    RESET_BLOG_REDUCER,
    PARAGRAPH_CHANGE,
    UPDATE_BLOG_DELETE_LIST,
    BLOG_CHANGE,
    ADD_NEW_PARAGRAPH,
    REMOVE_PARAGRAPH,
    CREATE_NEW_BLOG_ID,
    UPDATE_BLOG,
    SET_BLOG,
    RESET_BLOG_TYPING,
    NEW_IMAGE_UPLOADED,
    BLOG_DELETED,
    RESET_BLOG_DELETE_STATUS,
} from './types'

export const closeSuccessModal = () => dispatch => {
    dispatch({
        type: RESET_BLOG_DELETE_STATUS
    })
}

export const deleteArticle = (data) => dispatch => {
    axios.post('/api/admin/deleteArticle', data)
        .then(res => {
            dispatch({
                type: BLOG_DELETED,
                payload: data
            })
        })
        .catch(err => console.log(err))
}

export const newThumbnailImage = (data) => dispatch => {
    axios.post('/api/admin/addNewThumbnailImage', data)
        .then(res => {
            dispatch({
                type: UPDATE_BLOG,
                payload: res.data
            })
        })
        .catch(err => {
            console.log(err)
        })
}


export const createNewBlogId = () => dispatch => {

    axios.post('/api/admin/createNewBlogId')
        .then(res => {
            dispatch({
                type: CREATE_NEW_BLOG_ID,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: BLOG_ERRORS,
                payload: err.response.data

            })
        })
}

export const updateJournal = (article) => dispatch => {
    dispatch({
        type: RESET_BLOG_TYPING
    })
    axios.post('/api/admin/updateJournal', article)
        .then(res => {
            dispatch({
                type: UPDATE_BLOG,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const setJournal = (article) => dispatch => {
    dispatch({
        type: SET_BLOG,
        payload: article
    })
}





export const submitJournal = (data) => dispatch => {
    dispatch({
        type: SUBMIT_JOURNAL_LOADING
    })
    axios.post('/api/admin/submitArticle', data)
        .then(res => {
            dispatch({
                type: BLOG_CREATED_SUCCESS,
            })
            dispatch({
                type: SUBMIT_JOURNAL_LOADING_RESET
            })
        })
        .catch(err => {
            dispatch({
                type: SUBMIT_JOURNAL_LOADING_RESET
            })
            dispatch({
                type: BLOG_ERRORS,
                payload: err.response.data

            })
        })



}

export const handleParagraphChange = (paragraph) => dispatch => {
    dispatch({
        type: PARAGRAPH_CHANGE,
        payload: paragraph
    })
}

export const handleArticleChange = (article) => dispatch => {
    dispatch({
        type: BLOG_CHANGE,
        payload: article
    })
}
export const addParagraph = (paragraph) => dispatch => {
    dispatch({
        type: ADD_NEW_PARAGRAPH,
        payload: paragraph
    })
}

export const updateDeleteList = (list) => dispatch => {
    dispatch({
        type: UPDATE_BLOG_DELETE_LIST,
        payload: list
    })
}

export const removeParagraph = (index) => dispatch => {
    dispatch({
        type: REMOVE_PARAGRAPH,
        payload: index
    })
}




export const addToParagraphImageList = url => dispatch => {
    dispatch({
        type: NEW_IMAGE_UPLOADED,
        payload: url
    })
}


export const resetJournalForm = () => dispatch => {
    dispatch({
        type: RESET_BLOG_REDUCER
    })
}
