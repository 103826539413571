import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import { store, persistor } from './store'
import jwt_decode from 'jwt-decode'
import { logoutUser } from './actions/formActions'
import setAuthToken from './setAuthToken'
import { SET_CURRENT_USER } from './actions/types'
import { PersistGate } from 'redux-persist/integration/react'


if (localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken);

  const decoded = jwt_decode(localStorage.jwtToken)

  store.dispatch({
    type: SET_CURRENT_USER,
    payload: decoded.userDetails
  })

  const currentTime = Date.now() / 1000;

  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
  }
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
  , document.getElementById('root'));

serviceWorker.unregister();
