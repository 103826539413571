import React, { Component } from 'react'
import { Row, Col, DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap'
import ListofCountries from './ListofCountries'
import ListofCities from './ListofCities'
import ListofRegions from './ListofRegions'
import { connect } from 'react-redux'
import { analyticLists } from '../../actions/adminLists'

class ListData extends Component {

    state = {
        dropdownOpen: false,
        dropDownDate: 'Today',
        fetchDate: 'today',
    }

    toggle = e => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    }

    handleDateChange = date => {
        let dropDownDate;
        let fetchDate;
        if (date === 'today') {
            dropDownDate = 'Today'
            fetchDate = 'today'
        }
        else if (date === 'yesterday') {
            dropDownDate = 'Yesterday'
            fetchDate = 'yesterday'
        }
        else if (date === 'last7days') {
            dropDownDate = 'Last 7 days'
            fetchDate = '7daysAgo'
        }
        else if (date === 'last28days') {
            dropDownDate = 'Last 28 days'
            fetchDate = '28daysAgo'
        }
        else if (date === 'last90days') {
            dropDownDate = 'Last 90 days'
            fetchDate = '90daysAgo'
        }

        this.setState({
            dropDownDate,
            fetchDate
        })
    }

    componentDidMount() {

        this.props.analyticLists(this.state.fetchDate)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.fetchDate !== prevState.fetchDate) {
            this.props.analyticLists(this.state.fetchDate)
        }
    }


    render() {
        return (
            <div className=' text-left bg-light px-3 py-3 mb-2' style={{ borderRadius: 10 }}>
                <Row >
                    <Col md='4' sm='12'>
                        <ListofCountries />
                    </Col>
                    <Col md='4' sm='12'>
                        <ListofRegions />
                    </Col>
                    <Col md='4' sm='12'>
                        <ListofCities />
                    </Col>


                    <Dropdown className='border-0 ml-3' isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                        <DropdownToggle caret tag='span' style={{ cursor: 'pointer' }}>
                            {this.state.dropDownDate}
                        </DropdownToggle>
                        <DropdownMenu className='ml-auto'>
                            <DropdownItem header>  {this.state.dropDownDate}</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem onClick={() => this.handleDateChange('today')}>Today</DropdownItem>
                            <DropdownItem onClick={() => this.handleDateChange('yesterday')}>Yesterday</DropdownItem>
                            <DropdownItem onClick={() => this.handleDateChange('last7days')}>Last 7 days</DropdownItem>
                            <DropdownItem onClick={() => this.handleDateChange('last28days')}>Last 28 days</DropdownItem>
                            <DropdownItem onClick={() => this.handleDateChange('last90days')}>Last 90 days</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </Row>

            </div>
        )
    }
}


const mapStateToProps = state => ({
    adminLists: state.adminLists,
})

export default connect(mapStateToProps, { analyticLists })(ListData);

