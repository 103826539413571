import React, { Component } from 'react'
import {
    Col,
    Row,
    Container,
    Button,
    ListGroup, ListGroupItem,
    Breadcrumb, BreadcrumbItem,
    Form, Input,
    Dropdown, DropdownItem,
    DropdownToggle, DropdownMenu, Badge, Collapse

} from 'reactstrap';
import { connect } from 'react-redux'
import { getProducts, filterProducts, resetProducts } from '../actions/productsActions'
import { updateShoppingCart } from '../actions/shoppingCart'
import Spinner from './Spinner'
import Slide from 'react-reveal'
import { addToCart } from '../actions/shoppingCart'
import ButtonSpinner from './ButtonSpinner'
import { Helmet } from "react-helmet";
import Flip from 'react-reveal/Flip';
import queryString from 'query-string'
import Base from './Base'
import image from '../images/products.jpg'


class ProductsPage extends Component {
    state = {
        width: 0,
        height: 0,
        showId: '',
        sortDropDown: false,
        filter: 'default',
        productSearch: '',
        searchQuery: this.props.location.search,
        collapseAustralian: false,
        collapseAustralianTropical: false,
        collapseAustralianFruits: false,
        collapseOrganic: false,
        collapseOrganicVegetables: false,
        collapseOrganicFruits: false,

        collapseConventional: false,
        collapseConventionalVegetables: false,
        collapseConventionalFruits: false,
        collapseConventionalTropical: false,

        collapsePuree: false,
        collapsePureeFruit: false,
        collapsePureeSambazon: false,
        collapsePureePom: false

    };

    handleSubCategory = (category, subCategory) => {
        const parsed = queryString.parse(this.props.location.search);
        parsed.category = category
        parsed.subCategory = subCategory
        parsed.page = 1
        if (parsed.productSearch) {
            parsed.productSearch = undefined
        }
        let stringified = queryString.stringify(parsed);
        this.setState({
            searchQuery: '?' + stringified,
        })

    }

    handleCategory = (value) => {
        const parsed = queryString.parse(this.props.location.search);
        parsed.page = 1
        parsed.category = value

        if (value === 'other' || value === 'drygoods' || value === 'puree') {
            parsed.productSearch = undefined
            parsed.subCategory = undefined
        }
        let stringified = queryString.stringify(parsed);
        this.setState({
            searchQuery: '?' + stringified,
        })
    }


    handleSubCategoryProducts = (category, subCategory, productName) => {
        const parsed = queryString.parse(this.props.location.search);
        parsed.productSearch = productName
        parsed.category = category
        parsed.subCategory = subCategory

        let stringified = queryString.stringify(parsed);


        this.setState({
            searchQuery: '?' + stringified
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const parsed = queryString.parse(this.props.location.search);
        parsed.productSearch = this.state.productSearch

        let stringified = queryString.stringify(parsed);

        if (stringified.length > 0) {
            this.setState({
                searchQuery: '?' + stringified,
                productSearch: ''
            })
        } else {
            this.setState({
                searchQuery: '',
                productSearch: ''

            })
        }

    }

    handleSearch = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    dropdownToggle = (e) => {
        this.setState({
            sortDropDown: !this.state.sortDropDown
        })

    }

    setSort = (value) => {
        const parsed = queryString.parse(this.props.location.search);

        if (parsed.page) {
            parsed.page = 1
        }

        if (parsed.orderby && value !== 'default') {
            parsed.orderby = value
        } else if (value !== 'default') {
            parsed.orderby = value
        } else {
            parsed.orderby = undefined
        }
        let stringified = queryString.stringify(parsed);

        if (stringified.length > 0) {
            this.setState({
                filter: value,
                searchQuery: '?' + stringified
            })
        } else {

            this.setState({
                filter: value,
                searchQuery: ''
            })
        }

    }


    componentDidMount() {

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        const parsed = queryString.parse(this.props.location.search);

        let page = 1;
        let orderby = 'default';
        let query;
        let category;
        let subCategory;

        let filter = 'default'


        if (parsed.category) {
            category = parsed.category
        }
        if (parsed.subCategory) {
            subCategory = parsed.subCategory
        }

        if (parsed.page) {
            page = parsed.page
        }

        if (parsed.orderby) {
            orderby = parsed.orderby
            filter = parsed.orderby
        }

        if (parsed.productSearch) {
            query = parsed.productSearch
        }
        this.setState({
            filter: filter,
        })
        if (this.props.products.products.length === 0 || this.props.products.reset) {
            this.props.getProducts(page, orderby, query, category, subCategory)
        }
        if (this.props.products.reset) {
            this.props.resetProducts()
        }

        if (parsed.category === 'australian') {
            this.setState({
                collapseAustralian: true
            })

            if (parsed.subCategory === 'fruits') {
                this.setState({
                    collapseAustralianFruits: true
                })
            }

            if (parsed.subCategory === 'tropical') {
                this.setState({
                    collapseAustralianTropical: true
                })
            }
        }

        if (parsed.category === 'organic') {
            this.setState({
                collapseOrganic: true
            })

            if (parsed.subCategory === 'fruits') {
                this.setState({
                    collapseOrganicFruits: true
                })
            }

            if (parsed.subCategory === 'vegetables') {
                this.setState({
                    collapseOrganicVegetables: true
                })
            }
        }

        if (parsed.category === 'conventional') {
            this.setState({
                collapseConventional: true
            })

            if (parsed.subCategory === 'fruits') {
                this.setState({
                    collapseConventionalFruits: true
                })
            }

            if (parsed.subCategory === 'vegetables') {
                this.setState({
                    collapseConventionalVegetables: true
                })
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const parsed = queryString.parse(this.props.location.search);
        if (this.state.searchQuery !== prevState.searchQuery) {
            this.props.history.push(`/shop${this.state.searchQuery}`)
        }
        if (this.props.location.search !== prevProps.location.search) {
            let page;
            let orderby;
            let query;
            let category;
            let subCategory;
            let filter = 'default';

            if (parsed.page) {
                page = parsed.page
            }
            if (parsed.subCategory) {
                subCategory = parsed.subCategory
            }

            if (parsed.orderby && parsed.orderby !== 'default') {
                orderby = parsed.orderby
            }
            if (parsed.productSearch) {
                query = parsed.productSearch
            }

            if (parsed.category) {
                category = parsed.category
            }

            let stringified = queryString.stringify(parsed);

            if (stringified.length > 0) {
                this.setState({
                    searchQuery: '?' + stringified
                })
            } else {
                this.setState({
                    searchQuery: ''
                })
            }
            if (parsed.orderby) {
                filter = parsed.orderby
            }

            this.setState({
                filter
            })
            this.scrollToMyRef()
            this.props.getProducts(page, orderby, query, category, subCategory)

        }
    }

    scrollToMyRef = () => {
        if (!this.props.products.productsLoading) {
            window.scrollTo(0, this.myRef.offsetTop)
        }
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions = (e) => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    adjustCart = (product) => {
        if (this.props.cart.addToCartLoading.productId !== product._id) {

            let cart = [...this.props.cart.cart]
            if (!this.props.auth.isAuthenticated) {
                if (this.props.cart.cart.length !== 0) {
                    if (cart.filter(val => val.product._id === product._id).length !== 0) {
                        cart = cart.map(eachItem => {
                            if (eachItem.product._id === product._id) {
                                return {
                                    ...eachItem,
                                    quantity: eachItem.quantity + 1
                                }
                            } else {
                                return eachItem
                            }
                        })
                    } else {
                        cart = [...cart, {
                            product,
                            quantity: 1
                        }]
                    }

                }
                else {
                    cart = [...cart, {
                        product,
                        quantity: 1
                    }]
                }
                this.props.updateShoppingCart(cart)
            }
            if (this.props.auth.isAuthenticated) {
                this.props.addToCart(product, this.props.auth.user.id)
            }
            else if (this.props.guest.userid !== '') {
                this.props.addToCart(product, this.props.guest.userid)
            }

        }
    }
    handleHover = (product) => {
        this.setState({
            showId: product._id
        })
    }
    resetHover = () => {
        this.setState({
            showId: ''
        })
    }


    routeToSpecificPage = (product) => {
        this.props.history.push({
            pathname: `/shop/${product.name.replace(/[^A-Z0-9]+/ig, "-")}/${product._id}`,
            state: { product }
        })
    }



    handleShopClick = e => {
        this.setState({
            searchQuery: '',
        })
    }

    resetSearchQuery = e => {

        const parsed = queryString.parse(this.props.location.search);
        parsed.productSearch = undefined
        parsed.page = 1

        let stringified = queryString.stringify(parsed);
        if (stringified.length !== 0) {
            this.setState({
                searchQuery: '?' + stringified,
            })
        } else {
            this.setState({
                searchQuery: '',
            })
        }
    }
    resetSort = () => {
        const parsed = queryString.parse(this.props.location.search);
        parsed.page = 1

        if (parsed.orderby) {
            parsed.orderby = undefined
        }
        let stringified = queryString.stringify(parsed);

        if (stringified.length !== 0) {
            this.setState({
                filter: 'default',
                searchQuery: '?' + stringified,
            })
        } else {
            this.setState({
                filter: 'default',
                searchQuery: '',
            })
        }
    }
    handlePageChange = (i) => {
        const parsed = queryString.parse(this.props.location.search);
        parsed.page = i + 1

        let stringified = queryString.stringify(parsed);

        this.setState({
            searchQuery: '?' + stringified,
        })
    }

    previousPage = (e) => {
        const parsed = queryString.parse(this.props.location.search);
        parsed.page = parseInt(parsed.page) - 1

        let stringified = queryString.stringify(parsed);

        this.setState({
            searchQuery: '?' + stringified,
        })

    }
    nextPage = e => {
        const parsed = queryString.parse(this.props.location.search);
        if (parsed.page) {
            parsed.page = parseInt(parsed.page) + 1

        } else {
            parsed.page = 2
        }

        let stringified = queryString.stringify(parsed);

        this.setState({
            searchQuery: '?' + stringified,
        })
    }
    resetCategory = e => {
        const parsed = queryString.parse(this.props.location.search);
        parsed.category = undefined
        parsed.subCategory = undefined
        parsed.page = undefined
        let stringified = queryString.stringify(parsed);
        if (stringified.length > 0) {
            this.setState({
                searchQuery: '?' + stringified,
            })
        } else {
            this.setState({
                searchQuery: ''
            })
        }

    }

    resetSubCategory = e => {
        const parsed = queryString.parse(this.props.location.search);
        parsed.subCategory = undefined

        let stringified = queryString.stringify(parsed);
        if (stringified.length > 0) {
            this.setState({
                searchQuery: '?' + stringified,
            })
        } else {
            this.setState({
                searchQuery: ''
            })
        }

    }

    handleAustralianCollapse = e => {
        this.setState({
            collapseAustralian: !this.state.collapseAustralian
        })
    }

    handleAustralianTropicalCollapse = e => {
        this.setState({
            collapseAustralianTropical: !this.state.collapseAustralianTropical

        })
    }

    handleAustralianFruitsCollapse = e => {
        this.setState({
            collapseAustralianFruits: !this.state.collapseAustralianFruits

        })
    }


    handleOrganicCollapse = e => {
        this.setState({
            collapseOrganic: !this.state.collapseOrganic
        })
    }

    handleOrganicVegetablesCollapse = e => {
        this.setState({
            collapseOrganicVegetables: !this.state.collapseOrganicVegetables

        })
    }

    handleOrganicFruitsCollapse = e => {
        this.setState({
            collapseOrganicFruits: !this.state.collapseOrganicFruits

        })
    }


    handleConventionalCollapse = e => {
        this.setState({
            collapseConventional: !this.state.collapseConventional
        })
    }

    handleConventionalVegetablesCollapse = e => {
        this.setState({
            collapseConventionalVegetables: !this.state.collapseConventionalVegetables

        })
    }

    handleConventionalFruitsCollapse = e => {
        this.setState({
            collapseConventionalFruits: !this.state.collapseConventionalFruits

        })
    }
    handleConventionalTropicalCollapse = e => {
        this.setState({
            collapseConventionalTropical: !this.state.collapseConventionalTropical
        })
    }

    handlePureeCollapse = e => {
        this.setState({
            collapsePuree: !this.state.collapsePuree
        })
    }

    handlePureeFruitsCollapse = e => {
        this.setState({
            collapsePureeFruit: !this.state.collapsePureeFruit
        })
    }

    handlePureeSambazon = e => {
        this.setState({
            collapsePureeSambazon: !this.state.collapsePureeSambazon
        })
    }

    handlePureePom = e => {
        this.setState({
            collapsePureePom: !this.state.collapsePureePom
        })
    }

    render() {
        const parsed = queryString.parse(this.props.location.search);
        let end;
        let totalItemNumbers = this.props.products.totalPageNumbers
        let currentPage;
        if (parsed.page) {
            if (parsed.page > [...Array(Math.ceil(this.props.products.totalPageNumbers / 12))].length) {
                currentPage = [...Array(Math.ceil(this.props.products.totalPageNumbers / 12))].length
            }
            else if (parsed.page <= 1) {
                currentPage = 1

            }
            else {
                currentPage = parsed.page
            }
        } else {
            currentPage = 1
        }


        if (12 < totalItemNumbers) {
            end = 12 * currentPage
            if (end > totalItemNumbers) {
                end = totalItemNumbers
            }
        } else {
            end = totalItemNumbers
        }


        return (
            <React.Fragment>
                <div>
                    <Helmet>
                        <title>Shop - Frozberries VIC - Affordable, Healthy, Delicios</title>
                        <meta name="description"
                            content="Shop Frozen Fruit, Vegetables and Purees brought to you by Frozberries Victoria. Deliciously hand picked and flash frozen after carefully hand picked to preserve all the nutritions." />
                    </Helmet>
                </div>
                <Base title={'Products'} image={image} overlay />

                {this.props.products.productsLoading ? (
                    <div style={{ height: '40vh' }}>
                        <Container className='  h-100 ' >
                            <Row className='  h-100'>
                                <Col className=' my-auto'>
                                    <div className=' text-center text-light'>
                                        <Spinner />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                ) : (

                        <div className='products-page ' ref={(ref) => this.myRef = ref} >
                            <Row className='mt-3'>
                                <Col>
                                    <div className='border-bottom'>
                                        <Breadcrumb className=''>
                                            <BreadcrumbItem style={{ cursor: 'pointer' }} onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                                            <BreadcrumbItem style={{ cursor: 'pointer' }} onClick={this.handleShopClick}>Shop</BreadcrumbItem>
                                            {parsed.category && (
                                                <BreadcrumbItem style={{ cursor: 'pointer' }} active>Category: {parsed.category.toUpperCase()}</BreadcrumbItem>

                                            )}
                                            {parsed.subCategory && (
                                                <BreadcrumbItem style={{ cursor: 'pointer' }} active>Sub-category: {parsed.subCategory.toUpperCase()}</BreadcrumbItem>

                                            )}
                                            {parsed.productSearch && (
                                                <BreadcrumbItem style={{ cursor: 'pointer' }} active>Product Search: {parsed.productSearch.toUpperCase()}</BreadcrumbItem>

                                            )}
                                            {parsed.page ? (parsed.page > [...Array(Math.ceil(this.props.products.totalPageNumbers / 12))].length ?
                                                (<BreadcrumbItem style={{ cursor: 'pointer' }} active>Page {[...Array(Math.ceil(this.props.products.totalPageNumbers / 12))].length}</BreadcrumbItem>)
                                                : (parsed.page <= 1 ?
                                                    <BreadcrumbItem style={{ cursor: 'pointer' }} active>Page 1</BreadcrumbItem> :
                                                    <BreadcrumbItem style={{ cursor: 'pointer' }} active>Page {parsed.page}</BreadcrumbItem>
                                                )
                                            ) : (
                                                    <BreadcrumbItem style={{ cursor: 'pointer' }} active>Page 1</BreadcrumbItem>
                                                )}
                                        </Breadcrumb>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='px-3 py-3 mt-3 ' >
                                <Col lg='3' className={this.state.width < 992 ? 'order-2' : ''}  >

                                    <h5 className='font-weight-bold ' style={{ fontSize: '1.4rem' }}>PRODUCT SEARCH</h5>
                                    <Form className='my-3 pr-3' onSubmit={this.handleSubmit}>
                                        <Input name='productSearch' value={this.state.productSearch} placeholder='Search Products' onChange={this.handleSearch} />
                                    </Form>


                                    <h5 className='font-weight-bold mt-5' style={{ fontSize: '1.4rem' }}>SHOP</h5>
                                    <ListGroup className='mt-3' >
                                        <ListGroupItem

                                            style={{ padding: 0, lineHeight: 2, fontSize: '1.2rem', border: 0, }} className='border-top border-bottom'>
                                            <span style={{ cursor: 'pointer', color: parsed.category === 'australian' ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }} onClick={() => this.handleCategory('australian')} >AUSTRALIAN</span>
                                            <span className='float-right'>
                                                {this.state.collapseAustralian ? (
                                                    <i style={{ cursor: 'pointer' }} onClick={this.handleAustralianCollapse} className="fas fa-minus-circle"></i>

                                                ) : (
                                                        <i style={{ cursor: 'pointer' }} onClick={this.handleAustralianCollapse} className="fas fa-plus-circle"></i>
                                                    )}

                                            </span>

                                            <Collapse isOpen={this.state.collapseAustralian} >
                                                <div
                                                    style={{
                                                        fontSize: '1rem', border: 0, paddingLeft: 10
                                                    }}
                                                >
                                                    <span
                                                        onClick={() => this.handleSubCategory('australian', 'fruits')}

                                                        style={{ lineHeight: 1.5, cursor: 'pointer', color: (parsed.category === 'australian' && parsed.subCategory === 'fruits') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}>Fruits </span>
                                                    <span className='float-right'>
                                                        {this.state.collapseAustralianFruits ? (
                                                            <i style={{ cursor: 'pointer' }} onClick={this.handleAustralianFruitsCollapse} className="fas fa-minus-circle"></i>

                                                        ) : (
                                                                <i style={{ cursor: 'pointer' }} onClick={this.handleAustralianFruitsCollapse} className="fas fa-plus-circle"></i>
                                                            )}

                                                    </span>


                                                    <Collapse isOpen={this.state.collapseAustralianFruits} style={{ paddingLeft: 10, }}>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'australian' && parsed.productSearch === 'blackberries') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('australian', 'fruits', 'blackberries')}
                                                        >
                                                            Blackberries
                                                        </div>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'australian' && parsed.productSearch === 'blackcurrants') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('australian', 'fruits', 'blackcurrants')}
                                                        >
                                                            Blackcurrants
                                                        </div>

                                                        <div
                                                            style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'australian' && parsed.productSearch === 'blueberries') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('australian', 'fruits', 'blueberries')}
                                                        >
                                                            Blueberries
                                                        </div>

                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'australian' && parsed.productSearch === 'blackcurrants') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('australian', 'fruits', 'boysenberries')}
                                                        >
                                                            Boysenberries
                                                        </div>
                                                        {/* <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'australian' && parsed.productSearch === 'figs') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('australian', 'fruits', 'figs')}
                                                        >
                                                            Figs
                                                        </div>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'australian' && parsed.productSearch === 'gooseberries') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('australian', 'fruits', 'gooseberries')}
                                                        >
                                                            Gooseberries
                                                        </div> */}
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'australian' && parsed.productSearch === 'mixed berries') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('australian', 'fruits', 'mixed berries')}
                                                        >
                                                            Mixed Berries
                                                        </div>

                                                        {/* <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'australian' && parsed.productSearch === 'mulberries') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('australian', 'fruits', 'mulberries')}
                                                        >
                                                            Mulberries
                                                        </div> */}
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'australian' && parsed.productSearch === 'raspberries') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('australian', 'fruits', 'raspberries')}
                                                        >
                                                            Raspberries
                                                        </div>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'australian' && parsed.productSearch === 'strawberries') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('australian', 'fruits', 'strawberries')}
                                                        >
                                                            Strawberries
                                                        </div>

                                                    </Collapse>
                                                </div>

                                                <div
                                                    style={{
                                                        border: 0, fontSize: '1rem', paddingLeft: 10
                                                    }}
                                                ><span
                                                    onClick={() => this.handleSubCategory('australian', 'tropical')}

                                                    style={{ lineHeight: 1.5, cursor: 'pointer', color: (parsed.category === 'australian' && parsed.subCategory === 'tropical') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}>Tropical </span>

                                                    <span className='float-right'>
                                                        {this.state.collapseAustralianTropical ? (
                                                            <i style={{ cursor: 'pointer' }} onClick={this.handleAustralianTropicalCollapse} className="fas fa-minus-circle"></i>

                                                        ) : (
                                                                <i style={{ cursor: 'pointer' }} onClick={this.handleAustralianTropicalCollapse} className="fas fa-plus-circle"></i>
                                                            )}

                                                    </span>

                                                    <Collapse isOpen={this.state.collapseAustralianTropical} style={{ paddingLeft: 10 }}>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'australian' && parsed.productSearch === 'mango cheeks') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('australian', 'tropical', 'mango cheeks')} >
                                                            Mango Cheeks
                                                        </div>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'australian' && parsed.productSearch === 'mango diced') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('australian', 'tropical', 'mango diced')} >
                                                            Mango Diced
                                                        </div>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'australian' && parsed.productSearch === 'mango puree') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('australian', 'tropical', 'mango puree')} >
                                                            Mango Puree
                                                        </div>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'australian' && parsed.productSearch === 'pineapple chunks') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('australian', 'tropical', 'pineapple chunks')}>
                                                            Pineapple Chunks
                                                        </div>

                                                    </Collapse>

                                                </div>

                                            </Collapse>
                                        </ListGroupItem>

                                        <ListGroupItem

                                            style={{ padding: 0, lineHeight: 2, fontSize: '1.2rem', border: 0, }} className=' border-bottom'>
                                            <span style={{ cursor: 'pointer', color: parsed.category === 'organic' ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }} onClick={() => this.handleCategory('organic')} >ELGIN ORGANIC</span>
                                            <span className='float-right'>
                                                {this.state.collapseOrganic ? (
                                                    <i style={{ cursor: 'pointer' }} onClick={this.handleOrganicCollapse} className="fas fa-minus-circle"></i>

                                                ) : (
                                                        <i style={{ cursor: 'pointer' }} onClick={this.handleOrganicCollapse} className="fas fa-plus-circle"></i>
                                                    )}

                                            </span>

                                            <Collapse isOpen={this.state.collapseOrganic} >
                                                <div
                                                    style={{
                                                        fontSize: '1rem', border: 0, paddingLeft: 10
                                                    }}
                                                >
                                                    <span
                                                        onClick={() => this.handleSubCategory('organic', 'fruits')}

                                                        style={{ lineHeight: 1.5, cursor: 'pointer', color: (parsed.category === 'organic' && parsed.subCategory === 'fruits') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}>Fruits </span>
                                                    <span className='float-right'>
                                                        {this.state.collapseOrganicFruits ? (
                                                            <i style={{ cursor: 'pointer' }} onClick={this.handleOrganicFruitsCollapse} className="fas fa-minus-circle"></i>

                                                        ) : (
                                                                <i style={{ cursor: 'pointer' }} onClick={this.handleOrganicFruitsCollapse} className="fas fa-plus-circle"></i>
                                                            )}

                                                    </span>


                                                    <Collapse isOpen={this.state.collapseOrganicFruits} style={{ paddingLeft: 10, }}>
                                                        <div
                                                            style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'organic' && parsed.productSearch === 'banana slices') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('organic', 'fruits', 'banana slices')}
                                                        >
                                                            Banana Slices
                                                        </div>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'organic' && parsed.productSearch === 'blackberries') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('organic', 'fruits', 'blackberries')}
                                                        >
                                                            Blackberries
                                                        </div>
                                                        <div
                                                            style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'organic' && parsed.productSearch === 'blueberries') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('organic', 'fruits', 'blueberries')}
                                                        >
                                                            Blueberries
                                                        </div>
                                                        <div
                                                            style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'organic' && parsed.productSearch === 'wild blueberries') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('organic', 'fruits', 'wild blueberries')}
                                                        >
                                                            Wild Blueberries
                                                        </div>


                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'organic' && parsed.productSearch === 'mango chunks') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('organic', 'fruits', 'mango chunks')}
                                                        >
                                                            Mango Chunks
                                                        </div>
                                                        <div
                                                            style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'organic' && parsed.productSearch === 'mixed berries') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('organic', 'fruits', 'mixed berries')}
                                                        >
                                                            Mixed berries
                                                        </div>
                                                        <div
                                                            style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'organic' && parsed.productSearch === 'pineapple pieces') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('organic', 'fruits', 'pineapple pieces')}
                                                        >
                                                            Pineapple Pieces
                                                        </div>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'organic' && parsed.productSearch === 'raspberries') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('organic', 'fruits', 'raspberries')}
                                                        >
                                                            Raspberries
                                                        </div>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'organic' && parsed.productSearch === 'strawberries') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('organic', 'fruits', 'strawberries')}
                                                        >
                                                            Strawberries
                                                        </div>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'organic' && parsed.productSearch === 'sour cherries') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('organic', 'fruits', 'sour cherries')}
                                                        >
                                                            Sour Cherries - Pitted
                                                        </div>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'organic' && parsed.productSearch === 'sweet cherries') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('organic', 'fruits', 'sweet cherries')}
                                                        >
                                                            Sweet Cherries - Pitted
                                                        </div>

                                                    </Collapse>
                                                </div>

                                                <div
                                                    style={{
                                                        border: 0, fontSize: '1rem', paddingLeft: 10
                                                    }}
                                                ><span
                                                    onClick={() => this.handleSubCategory('organic', 'vegetables')}

                                                    style={{ lineHeight: 1.5, cursor: 'pointer', color: (parsed.category === 'organic' && parsed.subCategory === 'vegetables') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}>Vegetables </span>

                                                    <span className='float-right'>
                                                        {this.state.collapseOrganicVegetables ? (
                                                            <i style={{ cursor: 'pointer' }} onClick={this.handleOrganicVegetablesCollapse} className="fas fa-minus-circle"></i>

                                                        ) : (
                                                                <i style={{ cursor: 'pointer' }} onClick={this.handleOrganicVegetablesCollapse} className="fas fa-plus-circle"></i>
                                                            )}

                                                    </span>

                                                    <Collapse isOpen={this.state.collapseOrganicVegetables} style={{ paddingLeft: 10 }}>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'organic' && parsed.productSearch === 'beans green') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('organic', 'vegetables', 'beans green')} >
                                                            Beans Green
                                                        </div>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'organic' && parsed.productSearch === 'broccoli florets') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('organic', 'vegetables', 'broccoli florets')}>
                                                            Broccoli Florets
                                                        </div>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'organic' && parsed.productSearch === 'cauliflower florets') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('organic', 'vegetables', 'cauliflower florets')}>
                                                            Cauliflower Florets
                                                        </div>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'organic' && parsed.productSearch === 'corn kernels') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('organic', 'vegetables', 'corn kernels')}>
                                                            Corn Kernels
                                                        </div>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'organic' && parsed.productSearch === 'chopped kale') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('organic', 'vegetables', 'chopped kale')}>
                                                            Chopped Kale
                                                        </div>

                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'organic' && parsed.productSearch === 'chopped spinach') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('organic', 'vegetables', 'chopped spinach')}>
                                                            Chopped Spinach
                                                        </div>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'organic' && parsed.productSearch === 'peas') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('organic', 'vegetables', 'peas')}>
                                                            Peas
                                                        </div>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'organic' && parsed.productSearch === 'spring harvest mix') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('organic', 'vegetables', 'spring harvest mix')}>
                                                            Spring Harvest Mix
                                                        </div>

                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'organic' && parsed.productSearch === 'carrots diced') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('organic', 'vegetables', 'carrots diced')}>
                                                            Carrots Diced
                                                        </div>

                                                    </Collapse>

                                                </div>
                                            </Collapse>
                                        </ListGroupItem>


                                        <ListGroupItem

                                            style={{ padding: 0, lineHeight: 2, fontSize: '1.2rem', border: 0, }} className=' border-bottom'>
                                            <span style={{ cursor: 'pointer', color: parsed.category === 'conventional' ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }} onClick={() => this.handleCategory('conventional')} >CONVENTIONAL</span>
                                            <span className='float-right'>
                                                {this.state.collapseConventional ? (
                                                    <i style={{ cursor: 'pointer' }} onClick={this.handleConventionalCollapse} className="fas fa-minus-circle"></i>

                                                ) : (
                                                        <i style={{ cursor: 'pointer' }} onClick={this.handleConventionalCollapse} className="fas fa-plus-circle"></i>
                                                    )}

                                            </span>

                                            <Collapse isOpen={this.state.collapseConventional} >
                                                <div
                                                    style={{
                                                        fontSize: '1rem', border: 0, paddingLeft: 10
                                                    }}
                                                >
                                                    <span
                                                        onClick={() => this.handleSubCategory('conventional', 'fruits')}

                                                        style={{ lineHeight: 1.5, cursor: 'pointer', color: (parsed.category === 'conventional' && parsed.subCategory === 'fruits') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}>Fruits </span>
                                                    <span className='float-right'>
                                                        {this.state.collapseConventionalFruits ? (
                                                            <i style={{ cursor: 'pointer' }} onClick={this.handleConventionalFruitsCollapse} className="fas fa-minus-circle"></i>

                                                        ) : (
                                                                <i style={{ cursor: 'pointer' }} onClick={this.handleConventionalFruitsCollapse} className="fas fa-plus-circle"></i>
                                                            )}

                                                    </span>


                                                    <Collapse isOpen={this.state.collapseConventionalFruits} style={{ paddingLeft: 10, }}>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'conventional' && parsed.productSearch === 'blackberries') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('conventional', 'fruits', 'blackberries')}
                                                        >
                                                            Blackberries
                                                        </div>

                                                        <div
                                                            style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'conventional' && parsed.productSearch === 'blueberries') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('conventional', 'fruits', 'blueberries')}
                                                        >
                                                            Blueberries
                                                        </div>
                                                        <div
                                                            style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'conventional' && parsed.productSearch === 'wild blueberries') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('conventional', 'fruits', 'wild blueberries')}
                                                        >
                                                            Wild Blueberries
                                                        </div>

                                                        <div
                                                            style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'conventional' && parsed.productSearch === 'sweet cherries') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('conventional', 'fruits', 'sweet cherries')}
                                                        >
                                                            Cherries - Dark Sweet
                                                        </div>

                                                        <div
                                                            style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'conventional' && parsed.productSearch === 'sour cherries') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('conventional', 'fruits', 'sour cherries')}
                                                        >
                                                            Cherries - Red Sour
                                                        </div>

                                                        <div
                                                            style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'conventional' && parsed.productSearch === 'cranberries') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('conventional', 'fruits', 'cranberries')}
                                                        >
                                                            Cranberries
                                                        </div>

                                                        {/* <div
                                                            style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'conventional' && parsed.productSearch === 'elderberries') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('conventional', 'fruits', 'elderberries')}
                                                        >
                                                            Elderberries
                                                        </div> */}
                                                        <div
                                                            style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'conventional' && parsed.productSearch === 'mixed berries') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('conventional', 'fruits', 'mixed berries')}
                                                        >
                                                            Mixed Berries (7 Berries)
                                                        </div>

                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'conventional' && parsed.productSearch === 'raspberries') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('conventional', 'fruits', 'raspberries')}
                                                        >
                                                            Raspberries (Whole)
                                                        </div>

                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'conventional' && parsed.productSearch === 'redcurrants') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('conventional', 'fruits', 'redcurrants')}
                                                        >
                                                            Redcurrants
                                                        </div>
                                                    </Collapse>
                                                </div>

                                                <div
                                                    style={{
                                                        border: 0, fontSize: '1rem', paddingLeft: 10
                                                    }}
                                                ><span
                                                    onClick={() => this.handleSubCategory('conventional', 'vegetables')}

                                                    style={{ lineHeight: 1.5, cursor: 'pointer', color: (parsed.category === 'conventional' && parsed.subCategory === 'vegetables') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}>Vegetables </span>

                                                    <span className='float-right'>
                                                        {this.state.collapseConventionalVegetables ? (
                                                            <i style={{ cursor: 'pointer' }} onClick={this.handleConventionalVegetablesCollapse} className="fas fa-minus-circle"></i>

                                                        ) : (
                                                                <i style={{ cursor: 'pointer' }} onClick={this.handleConventionalVegetablesCollapse} className="fas fa-plus-circle"></i>
                                                            )}

                                                    </span>

                                                    <Collapse isOpen={this.state.collapseConventionalVegetables} style={{ paddingLeft: 10 }}>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'conventional' && parsed.productSearch === 'rhubarb') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('conventional', 'vegetables', 'rhubarb')} >
                                                            Rhubarb
                                                        </div>
                                                        {/* <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'conventional' && parsed.productSearch === 'spinach') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('conventional', 'vegetables', 'spinach')}>
                                                            Spinach
                                                        </div> */}

                                                    </Collapse>
                                                </div>


                                                <div
                                                    style={{
                                                        border: 0, fontSize: '1rem', paddingLeft: 10
                                                    }}
                                                >
                                                    <span
                                                        onClick={() => this.handleSubCategory('conventional', 'tropical')}

                                                        style={{ lineHeight: 1.5, cursor: 'pointer', color: (parsed.category === 'conventional' && parsed.subCategory === 'tropical') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}>Tropical </span>

                                                    <span className='float-right'>
                                                        {this.state.collapseConventionalTropical ? (
                                                            <i style={{ cursor: 'pointer' }} onClick={this.handleConventionalTropicalCollapse} className="fas fa-minus-circle"></i>

                                                        ) : (
                                                                <i style={{ cursor: 'pointer' }} onClick={this.handleConventionalTropicalCollapse} className="fas fa-plus-circle"></i>
                                                            )}

                                                    </span>

                                                    <Collapse isOpen={this.state.collapseConventionalTropical} style={{ paddingLeft: 10 }}>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'conventional' && parsed.productSearch === 'avocado quarters') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('conventional', 'tropical', 'avocado quarters')} >
                                                            Avocado Quarters
                                                        </div>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'conventional' && parsed.productSearch === 'banana slices') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('conventional', 'tropical', 'banana slices')}>
                                                            Banana Slices
                                                        </div>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'conventional' && parsed.productSearch === 'banana chunks') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('conventional', 'tropical', 'banana chunks')}>
                                                            Banana Chunks
                                                        </div>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'conventional' && parsed.productSearch === 'dragonfruit') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('conventional', 'tropical', 'dragonfruit')}>
                                                            Dragonfruit
                                                        </div>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'conventional' && parsed.productSearch === 'mango chunks') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('conventional', 'tropical', 'mango chunks')}>
                                                            Mango Chunks
                                                        </div>
                                                        {/* <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'conventional' && parsed.productSearch === 'papaya chunks') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('conventional', 'tropical', 'papaya chunks')}>
                                                            Papaya Chunks
                                                        </div>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'conventional' && parsed.productSearch === 'plum halves') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('conventional', 'tropical', 'plum halves')}>
                                                            Plum Halves
                                                        </div>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'conventional' && parsed.productSearch === 'tropical mix') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('conventional', 'tropical', 'tropical mix')}>
                                                            Tropical Mix ( Papaya, Pineapple, Mango)
                                                        </div> */}
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'conventional' && parsed.productSearch === 'pineapple chunks') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('conventional', 'tropical', 'pineapple chunks')}>
                                                            Pineapple Chunks
                                                        </div>
                                                    </Collapse>
                                                </div>

                                            </Collapse>
                                        </ListGroupItem>







                                        <ListGroupItem

                                            style={{ padding: 0, lineHeight: 2, fontSize: '1.2rem', border: 0, }} className=' border-bottom'>
                                            <span style={{ cursor: 'pointer', color: parsed.category === 'puree' ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }} onClick={() => this.handleCategory('puree')} >PUREES & JUICES</span>
                                            <span className='float-right'>
                                                {this.state.collapsePuree ? (
                                                    <i style={{ cursor: 'pointer' }} onClick={this.handlePureeCollapse} className="fas fa-minus-circle"></i>

                                                ) : (
                                                        <i style={{ cursor: 'pointer' }} onClick={this.handlePureeCollapse} className="fas fa-plus-circle"></i>
                                                    )}

                                            </span>

                                            <Collapse isOpen={this.state.collapsePuree}>
                                                <div
                                                    style={{
                                                        fontSize: '1rem', border: 0, paddingLeft: 10
                                                    }}
                                                >
                                                    <span
                                                        onClick={() => this.handleSubCategory('puree', 'fruit')}

                                                        style={{ lineHeight: 1.5, cursor: 'pointer', color: (parsed.category === 'puree' && parsed.subCategory === 'fruit') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}>Fruit Purees / Pulps </span>
                                                    <span className='float-right'>
                                                        {this.state.collapsePureeFruit ? (
                                                            <i style={{ cursor: 'pointer' }} onClick={this.handlePureeFruitsCollapse} className="fas fa-minus-circle"></i>

                                                        ) : (
                                                                <i style={{ cursor: 'pointer' }} onClick={this.handlePureeFruitsCollapse} className="fas fa-plus-circle"></i>
                                                            )}

                                                    </span>


                                                    <Collapse isOpen={this.state.collapsePureeFruit} style={{ paddingLeft: 10, }}>
                                                        <div
                                                            style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'puree' && parsed.productSearch === 'passionfruit cubes seed in') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('puree', 'fruit', 'passionfruit cubes seed in')}
                                                        >
                                                            Passionfruit Cubes Seed In
                                                        </div>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'puree' && parsed.productSearch === 'passionfruit pulp seed in') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('puree', 'fruit', 'passionfruit pulp seed in')}
                                                        >
                                                            Passionfruit Pulp Seed In

                                                        </div>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'puree' && parsed.productSearch === 'passionfruit pulp seed out') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('puree', 'fruit', 'passionfruit pulp seed out')}
                                                        >
                                                            Passionfruit Pulp Seed Out

                                                        </div>
                                                        {/* <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'puree' && parsed.productSearch === 'raspberry puree cubes') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('puree', 'fruit', 'raspberry puree cubes')}
                                                        >
                                                            Raspberry Puree Cubes (Seedless)
                                                        </div> */}
                                                    </Collapse>
                                                </div>




                                                {/* <div
                                                    style={{
                                                        fontSize: '1rem', border: 0, paddingLeft: 10
                                                    }}
                                                >
                                                    <span
                                                        onClick={() => this.handleSubCategory('puree', 'sambazon')}

                                                        style={{ lineHeight: 1.5, cursor: 'pointer', color: (parsed.category === 'puree' && parsed.subCategory === 'sambazon') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}>Sambazon Acai </span>
                                                    <span className='float-right'>
                                                        {this.state.collapsePureeSambazon ? (
                                                            <i style={{ cursor: 'pointer' }} onClick={this.handlePureeSambazon} className="fas fa-minus-circle"></i>

                                                        ) : (
                                                                <i style={{ cursor: 'pointer' }} onClick={this.handlePureeSambazon} className="fas fa-plus-circle"></i>
                                                            )}

                                                    </span>


                                                    <Collapse isOpen={this.state.collapsePureeSambazon} style={{ paddingLeft: 10, }}>
                                                        <div
                                                            style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'puree' && parsed.productSearch === 'acai puree') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('puree', 'sambazon', 'acai puree')}
                                                        >
                                                            Original Acai Puree 4x100g
                                                        </div>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'puree' && parsed.productSearch === 'unsweetened acai puree') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('puree', 'sambazon', 'unsweetened acai puree')}
                                                        >
                                                            Unsweetened Acai Puree 4x100g

                                                        </div>

                                                    </Collapse>
                                                </div> */}


                                                {/* <div
                                                    style={{
                                                        fontSize: '1rem', border: 0, paddingLeft: 10
                                                    }}
                                                >
                                                    <span
                                                        onClick={() => this.handleSubCategory('puree', 'pomegranate')}

                                                        style={{ lineHeight: 1.5, cursor: 'pointer', color: (parsed.category === 'puree' && parsed.subCategory === 'pomegranate') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}>Pomegranate </span>
                                                    <span className='float-right'>
                                                        {this.state.collapsePureePom ? (
                                                            <i style={{ cursor: 'pointer' }} onClick={this.handlePureePom} className="fas fa-minus-circle"></i>

                                                        ) : (
                                                                <i style={{ cursor: 'pointer' }} onClick={this.handlePureePom} className="fas fa-plus-circle"></i>
                                                            )}

                                                    </span>


                                                    <Collapse isOpen={this.state.collapsePureePom} style={{ paddingLeft: 10, }}>
                                                        <div
                                                            style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'puree' && parsed.productSearch === 'pomegranate arils') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('puree', 'pomegranate', 'pomegranate arils')}
                                                        >
                                                            Pomegranate Arils
                                                        </div>
                                                        <div style={{ fontSize: '0.9rem', cursor: 'pointer', color: (parsed.category === 'puree' && parsed.productSearch === 'pomegranate juice') ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }}
                                                            onClick={() => this.handleSubCategoryProducts('puree', 'pomegranate', 'pomegranate juice')}
                                                        >
                                                            Pomegranate Juice

                                                        </div>

                                                    </Collapse>
                                                </div> */}
                                            </Collapse>
                                        </ListGroupItem>



                                        {/* 
                                        <ListGroupItem
                                            onClick={() => this.handleCategory('drygoods')}
                                            style={{ padding: 0, lineHeight: 2, fontSize: '1.2rem', border: 0, cursor: 'pointer', color: parsed.category === 'drygoods' ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }} className='border-bottom'>DRY GOODS
                                        </ListGroupItem>

                                        <ListGroupItem
                                            onClick={() => this.handleCategory('others')}
                                            style={{ padding: 0, lineHeight: 2, fontSize: '1.2rem', border: 0, cursor: 'pointer', color: parsed.category === 'other' ? 'rgb(235,24,79)' : 'rgb(109,117,125)' }} className='border-bottom'>OTHERS
                                        </ListGroupItem> */}
                                    </ListGroup>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <div className='my-3'>


                                                {parsed.category && (
                                                    <h5 className='d-inline pr-3' style={{ cursor: 'pointer' }} onClick={this.resetCategory}> <Badge color="danger">
                                                        Category: {parsed.category === 'puree' ? 'PUREES & JUICES' : parsed.category.toUpperCase()}
                                                        <span className='ml-2' style={{ fontSize: '1.2rem' }}>
                                                            &times;
                                                     </span></Badge></h5>

                                                )}

                                                {parsed.subCategory && (
                                                    <h5 className='d-inline pr-3' style={{ cursor: 'pointer' }} onClick={this.resetSubCategory}> <Badge color="danger">
                                                        Sub-category: {parsed.subCategory.toUpperCase()}
                                                        <span className='ml-2' style={{ fontSize: '1.2rem' }}>
                                                            &times;
                                                     </span></Badge></h5>

                                                )}
                                                {parsed.productSearch && (
                                                    <h5 className='d-inline pr-3' style={{ cursor: 'pointer' }} onClick={this.resetSearchQuery}> <Badge color="danger">
                                                        {parsed.productSearch.toUpperCase()}
                                                        <span className='ml-2' style={{ fontSize: '1.2rem' }}>
                                                            &times;
                                                     </span></Badge></h5>

                                                )}


                                                {parsed.orderby && (parsed.orderby === 'pricelow'
                                                    ? (
                                                        <h5 className='d-inline px-3' style={{ cursor: 'pointer' }} onClick={this.resetSort}> <Badge color="danger">PRICE LOW TO HIGH
                                                        <span className='ml-2' style={{ fontSize: '1.2rem' }}>
                                                                &times;
                                                            </span></Badge></h5>

                                                    ) : (parsed.orderby === 'pricehigh' && (
                                                        <h5 className='d-inline px-3' style={{ cursor: 'pointer' }} onClick={this.resetSort} > <Badge color="danger">PRICE HIGH TO LOW
                                                        <span className='ml-2' style={{ fontSize: '1.2rem' }}>
                                                                &times;
                                                            </span>
                                                        </Badge></h5>
                                                    )))}
                                            </div>
                                            {
                                                (this.props.products.products.length !== 0) && (
                                                    <h1 className='float-left text-muted' style={{ fontSize: '0.9rem' }}>
                                                        Showing {queryString.parse(this.props.location.search).page ?
                                                            (queryString.parse(this.props.location.search).page <= 1 ? 1 :
                                                                (queryString.parse(this.props.location.search).page > [...Array(Math.ceil(this.props.products.totalPageNumbers / 12))].length ?
                                                                    (([...Array(Math.ceil(this.props.products.totalPageNumbers / 12))].length - 1) * 13 - 1)
                                                                    : ((parseInt(queryString.parse(this.props.location.search).page) - 1) * (12) + 1)))
                                                            : 1} – {end} of {this.props.products.totalPageNumbers} results</h1>
                                                )}

                                            {this.props.products.products.length !== 0 && (
                                                <div className='float-right'>
                                                    <h1 style={{ fontSize: '0.9rem' }} className='d-inline text-muted'>Sort by :</h1>
                                                    <Dropdown size='sm' className='btn-sm  ml-auto d-inline' isOpen={this.state.sortDropDown} toggle={this.dropdownToggle}>
                                                        <DropdownToggle caret className=' bg-light text-dark'>


                                                            {(this.state.filter === '' || this.state.filter === 'default') ? 'Best selling' : (
                                                                this.state.filter === 'pricelow' ? 'Price(low to high)' : (
                                                                    this.state.filter === 'pricehigh' && 'Price(high to low)'
                                                                )
                                                            )}
                                                        </DropdownToggle>
                                                        <DropdownMenu right>
                                                            <DropdownItem onClick={() => this.setSort('default')}>Best selling</DropdownItem>
                                                            <DropdownItem onClick={() => this.setSort('pricelow')}>Price(low to high)</DropdownItem>
                                                            <DropdownItem onClick={() => this.setSort('pricehigh')}>Price(high to low)</DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        {this.props.products.products.length !== 0 ?
                                            this.props.products.products.map(product => (
                                                <Col md='4' sm='6' xs='6' key={product._id} className=' text-center  item my-2'
                                                    onMouseEnter={() => this.handleHover(product)}
                                                    onMouseLeave={() => this.resetHover()}
                                                >
                                                    <div style={{ cursor: 'pointer' }} onClick={() => this.routeToSpecificPage(product)}>
                                                        <div className='img-outer '   >
                                                            <div className='position-relative'>
                                                                {!product.inStock && (
                                                                    <div className="corner-ribbon top-right sticky blue">Out of stock</div>

                                                                )}

                                                                <img
                                                                    className='img-fluid product-image'
                                                                    src={product.image}
                                                                    alt={product.name} />

                                                            </div>
                                                        </div>
                                                        <div  >
                                                            <h5 className={this.state.width > 576 ? `text-left pt-3 product-desc` : `text-center pt-3 product-desc`} style={{ fontSize: '16px', fontWeight: 400 }} >
                                                                {product.name}

                                                            </h5>
                                                            <h5 className='font-weight-bold d-inline' style={{ fontSize: '17px', color: 'rgb(236,23,79)' }} >
                                                                <span className={this.state.width > 576 ? 'float-left' : 'text-center'}>
                                                                    ${product.price.toFixed(2)}
                                                                </span>
                                                                {this.state.width < 576 && <br />}
                                                                {this.props.cart.cart.map(each => each.product._id === product._id && (
                                                                    <Slide key={each.product._id} left warning={this.props.cart.cart.length === 0 ? false : (
                                                                        this.props.cart.cart.map(each => each.product._id === product._id) ? true : false
                                                                    )}>
                                                                        <span style={{ fontSize: '14px' }} className={this.state.width > 576 ? 'float-right font-weight-bold text-muted' : 'font-weight-bold text-muted'}>+{each.quantity} Added</span>
                                                                    </Slide>
                                                                ))}
                                                            </h5>

                                                        </div>

                                                    </div>

                                                    {this.state.width > 576 ? (
                                                        <Flip top when={this.state.showId === product._id}>
                                                            {/* {!product.inStock && (
                                                            <Row>
                                                                <Col>
                                                                    <div>
                                                                        <h5 className='text-danger font-weight-bold'>Currently out of stock</h5>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        )} */}
                                                            <div className='text-center border-top ' style={{ minWidth: '100px', marginTop: 30 }}>
                                                                <Button
                                                                    size='sm'
                                                                    disabled={!product.inStock}
                                                                    style={{ backgroundColor: 'rgb(236,23,79)', borderRadius: 30, minWidth: '103.44px' }} className='  mx-2 my-2 border-0 '
                                                                    onClick={() => this.adjustCart(product)} >
                                                                    {this.props.cart.addToCartLoading.productId === product._id ? (

                                                                        <ButtonSpinner />
                                                                    ) :
                                                                        '    Add to Cart'}

                                                                </Button>
                                                                <Button size='sm' onClick={() => this.routeToSpecificPage(product)} style={{ borderRadius: 30, }} color='dark' outline>
                                                                    View Details
                                         </Button>
                                                            </div>
                                                        </Flip>
                                                    ) : (
                                                            <div className='text-center border-top ' style={{ minWidth: '100px', marginTop: 10 }}>
                                                                <Button
                                                                    size='sm'
                                                                    disabled={!product.inStock}
                                                                    style={{ backgroundColor: 'rgb(236,23,79)', borderRadius: 30, minWidth: '103.44px' }} className='  mx-2 my-2 border-0 '
                                                                    onClick={() => this.adjustCart(product)} >
                                                                    {this.props.cart.addToCartLoading.productId === product._id ? (

                                                                        <ButtonSpinner />
                                                                    ) :
                                                                        '    Add to Cart'}

                                                                </Button>
                                                                <Button size='sm' onClick={() => this.routeToSpecificPage(product)} style={{ borderRadius: 30, }} color='dark' outline>
                                                                    View Details
                                    </Button>
                                                            </div>
                                                        )}

                                                </Col>
                                            )) : (
                                                <Col>
                                                    <h5>No results found.   </h5>
                                                </Col>
                                            )}

                                    </Row>

                                    {[...Array(Math.ceil(this.props.products.totalPageNumbers / 12))].length > 1 &&
                                        (this.props.products.products.length !== 0) && (
                                            <Row className='text-center mt-5'>
                                                <Col className='text-center '>

                                                    <nav aria-label="Page navigation example">
                                                        <ul className="pagination justify-content-center">
                                                            <li className={(!queryString.parse(this.props.location.search).page ||
                                                                queryString.parse(this.props.location.search).page <= 1) ?
                                                                'disabled page-item ' : `page-item`}>
                                                                <span className="page-link" style={{ color: 'rgb(235,24,79)', cursor: 'pointer' }}
                                                                    onClick={this.previousPage}>Previous</span>
                                                            </li>
                                                            {[...Array(Math.ceil(this.props.products.totalPageNumbers / 12))].map((page, i) => (
                                                                <li key={i} className={(!queryString.parse(this.props.location.search).page && (i + 1 === 1)) ? 'active page-item'
                                                                    : (queryString.parse(this.props.location.search).page <= 1 && (i + 1 === 1) ? 'active page-item' :
                                                                        (queryString.parse(this.props.location.search).page >= 2 && (i + 1 === parseInt(queryString.parse(this.props.location.search).page)) ? 'active page-item'
                                                                            : ((queryString.parse(this.props.location.search).page > [...Array(Math.ceil(this.props.products.totalPageNumbers / 12))].length &&
                                                                                (i + 1 === [...Array(Math.ceil(this.props.products.totalPageNumbers / 12))].length)) ? 'active page-item' : 'page-item')))}>
                                                                    <span className="page-link" style={{ color: 'rgb(235,24,79)', cursor: 'pointer' }}
                                                                        onClick={() => this.handlePageChange(i)} >  {i + 1}</span></li>
                                                            ))}

                                                            <li className={(queryString.parse(this.props.location.search).page >= [...Array(Math.ceil(this.props.products.totalPageNumbers / 12))].length ?
                                                                'disabled page-item' : "page-item")}>
                                                                <span style={{ color: 'rgb(235,24,79)', cursor: 'pointer' }} className="page-link"
                                                                    onClick={this.nextPage}>Next</span>
                                                            </li>
                                                        </ul>
                                                    </nav>
                                                </Col>

                                            </Row>
                                        )}
                                </Col>

                            </Row>
                        </div>
                    )
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    products: state.products,
    auth: state.form,
    cart: state.cart,
    guest: state.guest
})

export default connect(mapStateToProps, { getProducts, updateShoppingCart, filterProducts, addToCart, resetProducts })(ProductsPage)