import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAdminBlogs, } from '../../actions/adminBlogsDashboard'
import { Helmet } from "react-helmet";
import moment from 'moment'
import { Row, Col, Button, Modal, ModalBody, ModalFooter } from 'reactstrap'
import queryString from 'query-string'
import { createNewBlogId, setJournal, closeSuccessModal, deleteArticle } from '../../actions/adminJournalActions'



class BlogsDashboard extends Component {

    state = {
        showDeleteModal: false,
        showBlogDeletedModal: false,
        deleteItem: {}
    }

    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search);
        let page = 1

        if (parsed.page) {
            page = parsed.page
        }
        this.props.getAdminBlogs(page)

    }

    componentDidUpdate(prevProps) {
        if (this.props.adminBlogs.blog !== prevProps.adminBlogs.blog && this.props.adminBlogs.newArticleClick) {
            this.props.history.push('/adminpanel/blogs-dashboard/new-blog')
        }
        if (this.props.adminBlogs.blog !== prevProps.adminBlogs.blog && this.props.adminBlogs.routeClick) {
            this.props.history.push({
                pathname: `/adminpanel/blogs-dashboard/new-blog`,
                state: { update: true }
            })
        }

        if (this.props.adminBlogs.blogDeleted !== prevProps.adminBlogs.blogDeleted && this.props.adminBlogs.blogDeleted) {
            this.setState({
                deleteItem: {},
                showDeleteModal: false,
            }, () => {
                this.setState({
                    showBlogDeletedModal: true,

                })
            })
        }
    }

    handleNewBlogClick = e => {
        this.props.createNewBlogId()

    }
    handleUserClick = blog => {
        this.props.setJournal(blog)
    }

    handleDelete = item => {
        this.setState({
            showDeleteModal: true,
            deleteItem: item
        })
    }

    toggleDeleteModal = e => {
        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
            deleteItem: {}
        })
    }

    approveDelete = e => {
        this.props.deleteArticle(this.state.deleteItem)
    }

    closeDeleteSuccessModal = e => {
        this.setState({
            showBlogDeletedModal: false
        })
        this.props.closeSuccessModal()
    }

    render() {
        return (
            <div>
                <div>
                    <Helmet>
                        <title>Blogs Dashboard - FROZBERRIES VICTORIA</title>
                        <meta name="description" content="Blogs Dashboard - FROZBERRIES VICTORIA" />
                    </Helmet>
                </div>

                <div>
                    <Modal isOpen={this.state.showDeleteModal} toggle={this.toggleDeleteModal}>
                        <ModalBody>
                            Are you sure that you want to delete this blog? All data will be lost.
        </ModalBody>
                        <ModalFooter>
                            <Button color="success" onClick={this.approveDelete}>Yes</Button>
                            <Button color="danger" onClick={this.toggleDeleteModal}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>

                <div>
                    <Modal isOpen={this.state.showBlogDeletedModal} toggle={this.closeDeleteSuccessModal}>
                        <ModalBody>
                            Blog successfully deleted.
        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.closeDeleteSuccessModal}>OK</Button>
                        </ModalFooter>
                    </Modal>
                </div>

                {this.props.adminBlogs.loading ? (
                    <div className='text-center text-light  my-5'>
                        <h5>Please wait. Blogs are being fetched.</h5>
                    </div>
                ) : (

                        <div>
                            <div>
                                <Button color='success' onClick={this.handleNewBlogClick}>New Blog</Button>

                            </div>
                            {this.props.adminBlogs.blogs.length !== 0 ? (
                                <div className='bg-light mt-3' style={{ borderRadius: 10 }}>
                                    <Row >
                                        <Col >
                                            <div className='table-responsive'>
                                                <table className="table">
                                                    <thead className='table-borderless'>
                                                        <tr>
                                                            <th scope="col">TITLE</th>
                                                            <th scope='col'>DATE</th>
                                                            <th scope='col'></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody >
                                                        {this.props.adminBlogs.blogs.length > 0 && this.props.adminBlogs.blogs.map(item => (
                                                            <tr key={item._id} style={{ backgroundColor: !item.active && 'gold' }}>
                                                                <td onClick={() => this.handleUserClick(item)} style={{ verticalAlign: 'middle', cursor: 'pointer', }} className='font-weight-bold '>{item.title}</td>
                                                                <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{moment(item.createdAt).format("Do MMM YY")}</td>
                                                                <td className='text-right' onClick={() => this.handleDelete(item)} style={{ cursor: 'pointer', }} > &times; </td>
                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </table>
                                            </div>

                                        </Col>
                                    </Row>

                                </div>
                            ) : (
                                    <div className='text-center text-light  my-5'>
                                        <h5>No results found.</h5>
                                    </div>
                                )}

                        </div>
                    )}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    adminBlogs: state.adminBlogs
})


export default connect(mapStateToProps, { getAdminBlogs, createNewBlogId, setJournal, closeSuccessModal, deleteArticle })(BlogsDashboard)
