import React, { Component } from 'react'
import {
    Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input,
    Form, FormGroup, Label
} from 'reactstrap'
import { Helmet } from "react-helmet";
import { connect } from 'react-redux'
import { deleteSelectedAddress, newAddress, editAddress } from '../actions/formActions'
import classnames from 'classnames'


class Addresses extends Component {
    state = {
        modal: false,
        selected: null,
        showNewAddressForm: false,
        address: '',
        suburb: '',
        postcode: '',
        editAddressId: '',
        editAddress: '',
        editSuburb: '',
        editPostcode: '',
        editShowSetAsDefault: false,
        setAsDefault: false,
        errors: {},
        showEditForm: false,
        editSetAsDefault: false
    }

    handleEditForm = (address) => {

        if (this.state.showEditForm) {
            if (address.streetAdress !== this.state.editAddress) {
                if (address.addressType === 'default') {
                    this.setState({
                        showEditForm: true,
                        editAddressId: address._id,
                        editAddress: address.streetAdress,
                        editSuburb: address.suburb,
                        editPostcode: address.zipCode,
                        editShowSetAsDefault: false
                    })
                } else {
                    this.setState({
                        showEditForm: true,
                        editAddressId: address._id,
                        editAddress: address.streetAdress,
                        editSuburb: address.suburb,
                        editPostcode: address.zipCode,
                        editShowSetAsDefault: true
                    })

                }
            } else {
                this.setState({
                    showEditForm: false,
                    editAddress: '',
                    editSuburb: '',
                    editPostcode: '',
                    editAddressId: '',
                    editShowSetAsDefault: false
                })
            }
        } else {
            if (address.addressType === 'default') {
                this.setState({
                    showEditForm: true,
                    editAddressId: address._id,
                    editAddress: address.streetAdress,
                    editSuburb: address.suburb,
                    editPostcode: address.zipCode,
                })
            } else {
                this.setState({
                    showEditForm: true,
                    editAddressId: address._id,
                    editAddress: address.streetAdress,
                    editSuburb: address.suburb,
                    editPostcode: address.zipCode,
                    editShowSetAsDefault: true
                })

            }
        }
    }

    handleCheckBox = e => {
        this.setState({
            setAsDefault: !this.state.setAsDefault
        })
    }
    editHandleCheckBox = e => {
        this.setState({
            editSetAsDefault: !this.state.editSetAsDefault
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.auth.user.adress !== prevProps.auth.user.adress) {
            this.setState({
                showNewAddressForm: false,
                address: '',
                suburb: '',
                postcode: '',
                errors: {},
                editAddressId: '',
                editAddress: '',
                editSuburb: '',
                editPostcode: '',
                editShowSetAsDefault: false,
                showEditForm: false,
                editSetAsDefault: false
            })
        }
        if (this.props.errors !== prevProps.errors) {
            this.setState({
                errors: this.props.errors
            })
        }
    }
    setSelectedAddress = (address) => {
        this.setState({
            selected: address
        }, () => {
            this.toggle()
        })
    }
    toggle = (e) => {
        this.setState({
            modal: !this.state.modal
        })
    }
    deleteAddress = e => {
        this.setState({
            modal: false
        })
        let data = {}
        data.user = this.props.auth.user
        data.address = this.state.selected
        this.props.deleteSelectedAddress(data)
        this.setState({
            selected: null
        })
    }
    handleNewAddress = e => {
        this.setState({
            showNewAddressForm: !this.state.showNewAddressForm
        })
    }
    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    addNewAddress = (e) => {
        let data = {}
        data.user = this.props.auth.user
        data.streetAdress = this.state.address
        data.suburb = this.state.suburb
        data.zipCode = this.state.postcode
        data.addNewAddress = true
        if (this.state.setAsDefault) {
            data.setAsDefault = true
        }
        this.props.newAddress(data)
    }

    editUserAddress = e => {
        let data = {}
        data.user = this.props.auth.user
        data.editAddressId = this.state.editAddressId
        data.editStreetAdress = this.state.editAddress
        data.editSuburb = this.state.editSuburb
        data.editPostcode = this.state.editPostcode
        data.editSetAsDefault = this.state.editSetAsDefault
        data.editAddress = true
        this.props.editAddress(data)
        this.setState({
            showEditForm: false
        })
    }
    render() {
        return (
            <div className='dashboard-view'>
                <div>
                    <Helmet>
                        <title>Addresses - Frozberries Victoria</title>
                        <meta name="description" content="Account - Frozberries Victoria" />
                    </Helmet>
                </div>
                <Row className='mx-5 my-3 border-bottom py-3'>
                    <Col>
                        <h4 style={{ fontWeight: 'bolder' }}>My Account</h4>
                    </Col>
                </Row>
                <Row className='mx-5 my-3  py-3'>
                    <Col>
                        <Button onClick={this.handleNewAddress} color='success' className='btn font-weight-bold' >Add New Address</Button>
                    </Col>
                </Row>
                {this.state.showNewAddressForm && (
                    <Row className='mx-5'>
                        <Col>
                            <Form className='pt-3'>
                                <FormGroup>
                                    <Input
                                        className={classnames('', { 'is-invalid': this.state.errors.address })}
                                        placeholder='Address*' type='text' onChange={this.onChange} value={this.state.address} name='address' />
                                    <div className='invalid-feedback'>{this.state.errors.address}</div>

                                </FormGroup>

                                <FormGroup>
                                    <Input
                                        className={classnames('', { 'is-invalid': this.state.errors.suburb })}
                                        placeholder='Suburb*' type='text' onChange={this.onChange} value={this.state.suburb} name='suburb' />
                                    <div className='invalid-feedback'>{this.state.errors.suburb}</div>
                                </FormGroup>

                                <Row form>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Input
                                                placeholder='State*' type='text' onChange={this.onChange} value='Victoria' disabled={true} name='region' />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Input
                                                className={classnames('', { 'is-invalid': this.state.errors.postcode })}
                                                placeholder='Postal Code*' type='text' onChange={this.onChange} value={this.state.postcode} name='postcode' />
                                            <div className='invalid-feedback'>{this.state.errors.postcode}</div>
                                        </FormGroup>
                                    </Col>

                                </Row>
                                {this.props.auth.user.adress.length !== 0 && (
                                    <FormGroup className='ml-4'>
                                        <Label check>
                                            <Input type="checkbox" id="handleCheckBox" onClick={this.handleCheckBox} />
                                            Set as Default
                                         </Label>
                                    </FormGroup>
                                )}
                            </Form>
                            <Button
                                onClick={this.addNewAddress}
                                disabled={this.state.address === '' || this.state.suburb === '' || this.state.postcode === ''} outline color='dark'>ADD NEW ADDRESS</Button>
                        </Col>
                    </Row>
                )}
                <Row className='mx-5 my-3  py-3'>
                    <Col>
                        <h5 className='border-bottom' style={{ fontWeight: 'bold' }}>Customer Address Book</h5>



                        <div>
                            <p>{this.props.auth.user.firstName} {this.props.auth.user.lastName}</p>

                            <Row>
                                {this.props.auth.user.adress.length !== 0 && (
                                    this.props.auth.user.adress
                                        .sort(function (left, right) {
                                            return left.hasOwnProperty("addressType") ? -1 : right.hasOwnProperty("addressType") ? 1 : 0
                                        })
                                        .map(eachAdress => (
                                            <Col className=' my-3' key={eachAdress._id} md='3' sm='4' xs='6'>
                                                <p style={{ minHeight: '80px' }}>
                                                    <span>   {eachAdress.streetAdress}  {eachAdress.addressType && (`(default)`)}</span> <br />
                                                    <span>        {eachAdress.suburb} Victoria {eachAdress.zipCode} </span>

                                                </p>
                                                <Button
                                                    onClick={() => this.handleEditForm(eachAdress)}
                                                    color='dark' outline className='btn btn-sm font-weight-bold d-inline mr-2' >Edit</Button>
                                                <Button onClick={() => this.setSelectedAddress(eachAdress)} color='danger' outline className='btn btn-sm font-weight-bold d-inline' >Delete</Button>
                                            </Col>
                                        ))
                                )}
                            </Row>


                            {this.state.showEditForm && (
                                <Row>
                                    <Col>
                                        <Form className='pt-3'>
                                            <FormGroup>
                                                <Input
                                                    className={classnames('', { 'is-invalid': this.state.errors.editAddress })}
                                                    placeholder='Address*' type='text' onChange={this.onChange} value={this.state.editAddress} name='editAddress' />
                                                <div className='invalid-feedback'>{this.state.errors.editAddress}</div>

                                            </FormGroup>

                                            <FormGroup>
                                                <Input
                                                    className={classnames('', { 'is-invalid': this.state.errors.editSuburb })}
                                                    placeholder='Suburb*' type='text' onChange={this.onChange} value={this.state.editSuburb} name='editSuburb' />
                                                <div className='invalid-feedback'>{this.state.errors.editSuburb}</div>
                                            </FormGroup>

                                            <Row form>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Input
                                                            placeholder='State*' type='text' onChange={this.onChange} value='Victoria' disabled={true} name='region' />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Input
                                                            className={classnames('', { 'is-invalid': this.state.errors.editPostcode })}
                                                            placeholder='Postal Code*' type='text' onChange={this.onChange} value={this.state.editPostcode} name='editPostcode' />
                                                        <div className='invalid-feedback'>{this.state.errors.editPostcode}</div>
                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                            {this.state.editShowSetAsDefault && (
                                                <FormGroup className='ml-4'>
                                                    <Label check>
                                                        <Input type="checkbox" id="editHandleCheckBox" onClick={this.editHandleCheckBox} />
                                                        Set as Default
                                         </Label>
                                                </FormGroup>
                                            )}
                                        </Form>
                                        <Button
                                            onClick={this.editUserAddress}
                                            disabled={this.state.editAddress === '' || this.state.editSuburb === '' || this.state.editSuburb === ''} outline color='dark'>UPDATE</Button>
                                    </Col>
                                </Row>
                            )}






                            {this.props.auth.user.adress.length === 0 &&
                                <div>
                                    <p className='font-weight-bold'>
                                        You don't have any saved addresses.
                                   </p>
                                </div>
                            }

                            <Modal isOpen={this.state.modal} toggle={this.toggle} >
                                <ModalHeader toggle={this.toggle}>Are you sure you wish to delete the following address?</ModalHeader>
                                <ModalBody className='font-weight-bold'>
                                    {this.state.selected !== null &&
                                        <p>
                                            <span>   {this.state.selected.streetAdress} </span> <br />
                                            <span>        {this.state.selected.suburb} Victoria {this.state.selected.zipCode} </span>
                                        </p>}
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="danger" onClick={this.deleteAddress}>Delete</Button>{' '}
                                    <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                                </ModalFooter>
                            </Modal>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    errors: state.errors,
    auth: state.form,
    cart: state.cart
})

export default connect(mapStateToProps, { deleteSelectedAddress, newAddress, editAddress })(Addresses)