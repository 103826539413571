import React from 'react'
import { InputGroup, InputGroupAddon, InputGroupText, Input, Alert } from 'reactstrap';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { loginUser, sendVerification } from '../actions/formActions'
import { Redirect } from 'react-router-dom'
import { resetSyncCart } from '../actions/shoppingCart'
import { removeGuestReducer } from '../actions/guestActions'
import { Helmet } from "react-helmet";
import Spinner from './Spinner';


class Login extends React.Component {

    state = {
        email: '',
        password: '',
        errors: {}
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }


    onSubmit = (e) => {
        e.preventDefault();

        let cart;

        const userData = {

            email: this.state.email,
            password: this.state.password,

        }

        cart = [...this.props.cart.cart]
        userData.cart = cart


        if (!this.props.cart.syncCart) {
            userData.sync = false
        } else {
            userData.sync = true
        }


        this.props.loginUser(userData)

    }
    componentDidUpdate(prevProps) {

        if (this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated) {

            this.props.resetSyncCart()
            this.props.removeGuestReducer()

            if (this.props.auth.user.isAdmin) {
                this.props.history.push('/adminpanel')
            } else {
                this.props.history.push('/dashboard')
            }
        }

        if (this.props.errors !== prevProps.errors) {
            this.setState({
                errors: this.props.errors
            })
        }
    }

    sendVerification = e => {
        let data = {}
        data.email = this.state.email
        if (this.state.email !== '') {
            this.props.sendVerification(data)
        }
    }


    render() {
        if (localStorage.jwtToken) {
            return <Redirect to='/dashboard' />
        }

        const { errors } = this.state

        return (
            <div className='container my-5 py-5' style={{ minHeight: '50vh' }}>
                <div>
                    <Helmet>
                        <title>Login - Frozberries Victoria</title>
                        <meta name="description" content="Login - Frozberries Victoria" />
                    </Helmet>
                </div>
                <div className='row'>
                    <div className='col-md-8 m-auto'>
                        <h1 className='display-4 text-center mb-5'>Log In</h1>

                        {errors.verify && (
                            <Alert color='danger'>
                                Please verify your email. Click <span onClick={this.sendVerification} style={{ cursor: 'pointer', color: 'cornflowerblue' }}>here</span> to receive another verification link.
                            </Alert>
                        )}
                        {this.props.auth.resendLink && (
                            <Alert color='success'>
                                Verification link has been resent.
                         </Alert>
                        )}

                        <form onSubmit={this.onSubmit} >

                            <InputGroup className='my-2'>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>@</InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="email" onChange={this.onChange} className={classnames('', { 'is-invalid': errors.email })} value={this.state.email} name='email' />
                                <div className='invalid-feedback'>{errors.email}</div>
                            </InputGroup>

                            <InputGroup className='my-2'>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>@</InputGroupText>
                                </InputGroupAddon>
                                <Input type='password' placeholder="password" onChange={this.onChange} className={classnames('', { 'is-invalid': errors.password })} value={this.state.password} name='password' autoComplete="on" />
                                <div className='invalid-feedback'>{errors.password}</div>
                            </InputGroup>

                            <small className='float-left pb-2'>Not a member? <Link to='/signup'>Sign Up</Link></small>
                            <small className='float-right pb-2'><Link to='/reset-password'>Forgot password?</Link></small>
                            {this.props.auth.spinner && (
                                <div className='text-center'>
                                    <Spinner />

                                </div>
                            )}
                            <input
                                disabled={this.state.email === '' || this.state.password === '' ? true : false}
                                type='submit' value={this.props.auth.spinner ? 'Please Wait..' : 'Login'} className=' text-light btn-block mt-4 p-2 border-0' style={{ backgroundColor: 'rgb(236,23,79)' }} />
                        </form>
                    </div>
                </div>
            </div>


        )
    }

}

const mapStateToProps = state => ({
    errors: state.errors,
    auth: state.form,
    cart: state.cart
})

export default connect(mapStateToProps, { loginUser, resetSyncCart, removeGuestReducer, sendVerification })(Login)