import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { connect } from 'react-redux'
import { } from '../../actions/adminUserActions'
import Spinner from '../Spinner'


class TotalOrders extends Component {
    render() {
        let percent;
        let totalOrdersToday = this.props.adminRevenueCalc.totalOrdersToday
        let totalOrdersYesterday = this.props.adminRevenueCalc.totalOrdersYesterday

        if (totalOrdersToday > totalOrdersYesterday) {
            if (totalOrdersYesterday === 0) {
                percent = totalOrdersToday
            } else {
                percent = ((totalOrdersToday - totalOrdersYesterday) / totalOrdersYesterday * 100)
            }
        }
        else if (totalOrdersToday < totalOrdersYesterday) {
            if (totalOrdersToday === 0) {
                percent = -totalOrdersYesterday
            } else {
                percent = ((totalOrdersToday - totalOrdersYesterday) / totalOrdersYesterday * 100)
            }
        } else {
            percent = 0
        }
        percent = Math.floor(percent);



        return (
            <div className=' text-left bg-light px-3 py-3 my-2' style={{ borderRadius: 10 }}>
                <Row>
                    <Col xs='8' >
                        <h6 className='font-weight-bold text-secondary'>TOTAL ORDERS TODAY</h6>
                        {this.props.adminRevenueCalc.loading ? (
                            <div className='text-center my-auto'>
                                <Spinner />

                            </div>
                        ) : (
                                <React.Fragment>
                                    <h5 className='font-weight-bold text-dark'>{this.props.adminRevenueCalc.totalOrdersToday}</h5>
                                    <p className={percent > 0 ? 'text-success' : percent < 0 ? 'text-danger' : ''}>
                                        {percent > 0 ?
                                            <i className="fas fa-arrow-up mr-1"></i>
                                            : percent < 0 ?
                                                <i className="fas fa-arrow-down mr-1"></i>
                                                :
                                                <i className="fas fa-square mr-1 "></i>
                                        }
                                        {percent}% <span className='pl-2 text-secondary'>Since yesterday</span></p>
                                </React.Fragment>
                            )}

                    </Col>
                    <Col xs='4' className='my-3  text-center'>
                        <div className='rounded-circle text-white shadow px-3 py-3 bg-info' style={{ width: '3em', height: '3em', alignItems: 'center', justifyContent: 'center', display: 'inline-flex', textAlign: 'center', }}>
                            <i className="fas fa-shopping-cart" style={{ fontSize: '1.25em', }}></i>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.form,
    adminRevenueCalc: state.adminRevenueCalc

})


export default connect(mapStateToProps, {})(TotalOrders)