import { NOT_FOUND, FOUND } from '../actions/types'


const initialState = {
    notfound: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case NOT_FOUND:
            return {
                ...state,
                notfound: true
            }

        case FOUND:
            return {
                ...state,
                notfound: false

            }


        default: return state;
    }
}