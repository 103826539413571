import React, { Component } from 'react'
import Trix from "trix";
import classnames from 'classnames'
import 'trix/dist/trix.css';
import { InputGroup, Input, Label, UncontrolledTooltip } from 'reactstrap';
import { handleParagraphChange, updateDeleteList, removeParagraph, updateJournal, addToParagraphImageList } from '../../actions/adminJournalActions'
import axios from 'axios'
import { connect } from 'react-redux'


Trix.config.attachments.preview.caption.name = false
Trix.config.attachments.preview.caption.size = false

Trix.config.textAttributes.textColour = {
    styleProperty: "color",
    inheritable: true,

}

class TrixEditor extends Component {

    constructor(props) {
        super(props);
        this.trixInput = React.createRef();
    }

    state = {
        errors: {},

    }


    componentWillUnmount() {
        this.trixInput.current.removeEventListener("trix-change", event => {
            this.handleEditorChange(event)
        })
        this.trixInput.current.removeEventListener("trix-attachment-add", event => {
            this.handleAttachment(event)
        })
        this.trixInput.current.removeEventListener("trix-attachment-remove", event => {
            this.handleRemoveAttachment(event)
        })

    }



    handleEditorChange = e => {
        let paragraphs = [...this.props.adminBlogs.blog.paragraphs]
        paragraphs[this.props.index].paragraph = e.target.value
        this.props.handleParagraphChange(paragraphs)
    }

    handleParagraphChange = (e, index) => {
        let paragraphs = [...this.props.adminBlogs.blog.paragraphs]
        paragraphs[index][e.target.name] = e.target.value
        this.props.handleParagraphChange(paragraphs)

    }

    handleAttachment = e => {
        if (e.attachment.file) {
            console.log(e.attachment.file)
            let formData = new FormData()
            formData.append('image', e.attachment.file)
            axios.post('/api/admin/trixImageUpload', formData)
                .then(res => {
                    let attribute = {
                        url: res.data[0].Location,
                        href: '',
                    }



                    this.props.addToParagraphImageList(res.data[0].Location)

                    // this.setState({
                    //     addedImages: [...this.state.addedImages, res.data[0].Location]
                    // })
                    e.attachment.setAttributes(attribute)
                })
                .catch(err => console.log(err))
        } else {

            let deleteList = [...this.props.adminBlogs.deleteList]
            deleteList = deleteList.filter((link) => link !== e.attachment.getAttributes().url)

            this.props.updateDeleteList(deleteList)
        }

    }

    handleRemoveAttachment = e => {
        let deleteList = [...this.props.adminBlogs.deleteList]
        deleteList.push(e.attachment.getAttributes().url)
        this.props.updateDeleteList(deleteList)

    }



    componentDidMount() {

        this.trixInput.current.addEventListener('trix-initialize', event => {
            var extraHTML = '<input type="color" class="trix-button colourPicker"/>'

            if (event.target.toolbarElement.querySelector(".trix-button-group--text-tools")) {
                event.target.toolbarElement.
                    querySelector(".trix-button-group--text-tools").
                    insertAdjacentHTML("beforeend", extraHTML)
            }

            document.
                querySelectorAll(".colourPicker").forEach(function (cp) {
                    cp.addEventListener("input", function (event) {
                        var trix_element = event.target.closest('trix-toolbar').nextElementSibling
                        trix_element.editor.activateAttribute("textColour", event.target.value)
                    })
                })

        })


        this.trixInput.current.addEventListener("trix-change", event => {
            this.handleEditorChange(event)
        })
        this.trixInput.current.addEventListener("trix-attachment-add", event => {
            this.handleAttachment(event)
        })
        this.trixInput.current.addEventListener("trix-attachment-remove", event => {
            this.handleRemoveAttachment(event)
        })
        this.trixInput.current.addEventListener("trix-blur", event => {
            this.handleBlur(event)
        })

    }

    handleBlur = (e) => {
        if (this.props.adminBlogs.typing) {
            this.props.updateJournal(this.props.adminBlogs.blog)
        }
    }



    handleRemoveParagraph = e => {

        if (this.props.adminBlogs.addedImages !== 0) {
            let deleteList = [...this.props.adminBlogs.deleteList]

            for (let i = 0; i < this.props.adminBlogs.addedImages.length; i++) {
                deleteList.filter((link) => {
                    if (link !== this.props.adminBlogs.addedImages[i]) {
                        deleteList.push(this.props.adminBlogs.addedImages[i])
                    }
                })
            }
            this.props.updateDeleteList(deleteList)

        }

        this.props.removeParagraph(this.props.index)
    }

    componentDidUpdate(prevProps) {
        if (this.props.adminBlogs.errors !== prevProps.adminBlogs.errors) {
            this.setState({
                errors: this.props.adminBlogs.errors
            })
        }
    }



    render() {
        const { errors } = this.state


        return (
            <div className='my-2 bg-light p-3' style={{ borderRadius: 10 }}>

                {this.props.index === 0 && (
                    <Label className='font-weight-bold text-dark' >PARAGRAPHS</Label>
                )}
                <br />

                {this.props.index !== 0 && (
                    <div>

                        <Label style={{ fontSize: 12 }} className='font-weight-bold text-dark  w-100' >PARAGRAPH {this.props.index + 1}
                            <span onClick={this.handleRemoveParagraph} style={{ fontSize: 16, cursor: 'pointer' }} className='float-right' href="#" id="removeParagraph">&times; </span>
                        </Label>
                        <UncontrolledTooltip placement="bottom" target="removeParagraph">
                            Delete Paragraph      </UncontrolledTooltip>
                    </div>
                )}


                <InputGroup className='mb-2'>
                    <Input placeholder={`${this.props.index + 1}. Paragraph Title`}
                        onChange={(e) => this.handleParagraphChange(e, this.props.index)}
                        value={this.props.adminBlogs.blog.paragraphs[this.props.index].paragraphTitle}
                        name='paragraphTitle' />
                </InputGroup>



                <trix-editor
                    style={{ minHeight: 300 }}
                    input={"trix" + this.props.index}
                    ref={this.trixInput}
                />

                <input
                    type="hidden"
                    id={"trix" + this.props.index}
                    value={this.props.adminBlogs.blog.paragraphs[this.props.index].paragraph}
                    className={classnames('', { 'is-invalid': errors.paragraph })}
                />

                <div className='invalid-feedback'>{errors.paragraph}</div>


            </div>
        )
    }
}

const mapStateToProps = state => ({
    adminBlogs: state.adminBlogs
})



export default connect(mapStateToProps, { handleParagraphChange, updateDeleteList, removeParagraph, updateJournal, addToParagraphImageList })(TrixEditor)
