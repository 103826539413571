import React, { Component } from 'react'
import image from '../images/recipe_bg.jpg'
import Base from './Base'
import { Helmet } from "react-helmet";
import RecipePage from './RecipePage';


class Recipes extends Component {
    render() {
        return (
            <div>
                <div>
                    <Helmet>
                        <title>Recipes - Frozberries Victoria</title>
                        <meta name="description" content="Recipes - Frozberries Victoria" />
                    </Helmet>
                </div>
                <Base title={'Make Something Special'} image={image} overlay searchBar />
                <RecipePage />
            </div>
        )
    }
}
export default Recipes