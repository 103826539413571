import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getSingleUser, deleteSingleUser, resetUserForm, updateSingleUser } from '../../actions/adminUserDash'
import { Helmet } from "react-helmet";
import {
    Col, Row,
    Badge, Button, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Collapse
} from 'reactstrap';
import moment from 'moment'


class SelectedUser extends Component {

    state = {
        user: {},
        formDisabled: '',
        showWarningModal: false,
        showDeleteSuccess: false,
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        showUpdateSuccess: false,
        updateClicked: false,
        updateAddressId: '',
        addressReplica: [],
        addressUpdateClicked: false
    }


    ucFirstAllWords = (str) => {
        var pieces = str.split(" ");
        for (var i = 0; i < pieces.length; i++) {
            var j = pieces[i].charAt(0).toUpperCase();
            pieces[i] = j + pieces[i].substr(1);
        }
        return pieces.join(" ");
    }

    componentDidMount() {
        if (this.props.location.state === undefined) {
            this.props.getSingleUser(this.props.match.params.userid)
        }

        if (this.props.location.state !== undefined && this.props.location.state.user) {
            this.setState({
                user: this.props.location.state.user,
                firstName: this.props.location.state.user.firstName,
                lastName: this.props.location.state.user.lastName,
                email: this.props.location.state.user.email,
                phoneNumber: this.props.location.state.user.phoneNumber,
                addressReplica: this.props.location.state.user.adress

            })
        }


    }

    componentDidUpdate(prevProps) {

        if (this.props.users.user !== prevProps.users.user) {
            this.setState({
                user: this.props.users.user,
                firstName: this.props.users.user.firstName,
                lastName: this.props.users.user.lastName,
                email: this.props.users.user.email,
                phoneNumber: this.props.users.user.phoneNumber,
                addressReplica: this.props.users.user.adress
            })

            if (this.state.updateClicked) {
                this.setState({
                    showUpdateSuccess: true
                })

                setTimeout(this.resetUpdateClick, 3000)

            }
        }
        if (this.props.users.userDeleted !== prevProps.users.userDeleted && this.props.users.userDeleted) {
            this.setState({
                showDeleteSuccess: true
            })

            setTimeout(this.sendToUsersDashboard, 3000)

        }
    }

    resetUpdateClick = () => {
        this.setState({
            updateClicked: false,
            showUpdateSuccess: false,
            formDisabled: '',
            addressUpdateClicked: false,
            updateAddressId: ''
        })
    }

    componentWillUnmount() {
        this.props.resetUserForm()
    }

    sendToUsersDashboard = () => {
        this.props.history.push('/adminpanel/users')

    }

    deleteUser = () => {
        this.setState({
            showWarningModal: true
        })
    }

    routeToOrder = order => {
        this.props.history.push({
            pathname: `/adminpanel/orders/${order._id}`,
        })
    }
    toggleDeleteWarningModal = () => {
        if (!this.props.users.deleteButtonLoading) {
            this.setState({
                showWarningModal: !this.state.showWarningModal
            })
        }
    }

    deleteUserApproved = () => {
        if (!this.props.users.deleteButtonLoading) {
            this.props.deleteSingleUser(this.state.user._id)
        }
    }

    updateUserInfoButton = () => {
        if (!this.state.showUpdateSuccess) {
            if (this.state.formDisabled !== 'userInfo') {
                this.setState({
                    formDisabled: 'userInfo'
                })
            } else {
                this.setState({
                    formDisabled: ''
                })
            }
        }
    }

    assignAdmin = e => {

        let data = {}

        data.isAdmin = true

        this.props.updateSingleUser(this.state.user._id, data)
    }
    unassignAdmin = e => {
        let data = {}

        data.isAdmin = false

        this.props.updateSingleUser(this.state.user._id, data)
    }


    cancelUserInfoUpdate = e => {
        this.setState({
            formDisabled: '',
            firstName: this.state.user.firstName,
            lastName: this.state.user.lastName,
            email: this.state.user.email,
            phoneNumber: this.state.user.phoneNumber
        })
    }

    activateAccount = e => {
        let data = {}
        data.isActive = true
        this.props.updateSingleUser(this.state.user._id, data)

    }

    addToMailingList = e => {
        let data = {}

        data.sendOffers = true

        this.props.updateSingleUser(this.state.user._id, data)
    }

    removeFromMailingList = e => {
        let data = {}

        data.sendOffers = false

        this.props.updateSingleUser(this.state.user._id, data)
    }

    updateUserInfo = e => {
        let data = {}
        if (this.state.user.firstName !== this.state.firstName) {
            data.firstName = this.state.firstName
        }
        if (this.state.user.lastName !== this.state.lastName) {
            data.lastName = this.state.lastName
        }
        if (this.state.user.phoneNumber !== this.state.phoneNumber) {
            data.phoneNumber = this.state.phoneNumber
        }
        if (this.state.user.email !== this.state.email) {
            data.email = this.state.email
        }


        if (Object.keys(data).length > 0) {

            this.setState({
                updateClicked: true
            })

            this.props.updateSingleUser(this.state.user._id, data)

        }

    }

    updateAddressId = (address) => {
        if (!this.state.showUpdateSuccess) {
            if (this.state.updateAddressId === '' || this.state.updateAddressId !== address) {
                this.setState({
                    updateAddressId: address
                })
            } else {
                this.setState({
                    updateAddressId: ''
                })
            }

            if (this.state.user.adress !== this.state.addressReplica) {
                this.setState({
                    addressReplica: this.state.user.adress
                })
            }
        }


    }
    updateAddress = e => {
        let data = {}

        data.adress = this.state.addressReplica

        this.setState({
            updateClicked: true
        })

        if (this.state.user.adress !== this.state.addressReplica) {
            this.props.updateSingleUser(this.state.user._id, data)
        }
    }

    cancelAddressUpdate = e => {
        this.setState({
            updateAddressId: '',
            addressReplica: this.state.user.adress
        })
    }

    userInfoOnChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onAddressChange = (e, id) => {

        let addressReplica = this.state.addressReplica.map((eachAddress) => {
            if (eachAddress._id === id) {
                return { ...eachAddress, [e.target.name]: e.target.value };
            } else {
                return eachAddress
            }
        })

        this.setState({
            addressReplica
        })

    }

    render() {
        return (
            <div className='mb-3'>
                <div>
                    <Helmet>
                        <title>{(this.props.location.state !== undefined && this.props.location.state.user) ?
                            (this.props.location.state.user.firstName + ' ' + this.props.location.state.user.lastName) : (this.props.match.params.name)} - Frozberries Victoria</title>
                        <meta name="description" content={`${(this.props.location.state !== undefined && this.props.location.state.user) ?
                            (this.props.location.state.user.firstName + ' ' + this.props.location.state.user.lastName) : (this.props.match.params.name)} - Frozberries Victoria`} />
                    </Helmet>
                </div>

                <div>
                    <Modal isOpen={this.state.showWarningModal} toggle={this.toggleDeleteWarningModal}>
                        <ModalHeader >Are you sure you want to permanently delete this user? </ModalHeader>
                        <ModalBody>
                            <p>Note: Orders placed by this user will not be deleted.</p>
                        </ModalBody>
                        {this.state.showDeleteSuccess && (
                            <ModalBody>
                                <p className='text-success'>User successfully deleted. You're being redirected to Users Dashboard.</p>
                            </ModalBody>
                        )}

                        <ModalFooter>

                            <Button color="danger" onClick={this.deleteUserApproved}>{this.props.users.deleteButtonLoading ? 'Please Wait.' : 'Delete User'} </Button>
                            <Button color="light" className='border' onClick={this.toggleDeleteWarningModal}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>
                {Object.keys(this.state.user).length > 0 ? (
                    <div>
                        <Row>
                            <Col>
                                <h1 className="display-4 text-white">{this.ucFirstAllWords(this.state.user.firstName)} {this.ucFirstAllWords(this.state.user.lastName)}</h1>
                                {!this.state.user.isAdmin && !this.state.user.registeredUser && (
                                    <h5 className='d-inline pr-2 py-1'><Badge color='danger'>Guest User</Badge></h5>

                                )}

                                {!this.state.user.isAdmin && !this.state.user.isActive && (
                                    <h5 className='d-inline pr-2 py-1'><Badge color='danger'>Not Active</Badge></h5>
                                )}


                                {this.state.user.isActive && !this.state.user.isAdmin && (
                                    <h5 className='d-inline pr-2 py-1'><Badge color='info'>Active</Badge></h5>
                                )}


                                {this.state.user.isAdmin ? (
                                    <h5 className='d-inline pr-2 py-1'><Badge color='success'>Admin</Badge></h5>
                                ) : (
                                        this.state.user.sendOffers ? (
                                            <h5 className='d-inline pr-2 py-1 '><Badge className='text-light' color='warning'>Accepts marketing emails</Badge></h5>
                                        ) : (
                                                <h5 className='d-inline pr-2 py-1 '><Badge className='text-light' color='danger'>Doesn't accepts marketing emails</Badge></h5>
                                            )
                                    )}



                                <p className="text-white mt-2">Member since {moment(this.state.user.dateCreated).format("MMM Do YYYY")}</p>
                                {this.state.user.isActive && this.state.user.lastLogin !== undefined && (
                                    <p className="text-white">Last login  {moment(this.state.user.lastLogin).startOf('hour').fromNow()}</p>
                                )}


                            </Col>
                        </Row >

                        <Row className='my-4'>
                            <Col>
                                <div className='bg-white  border-0  shadow card'>
                                    <div className="bg-secondary card-header">
                                        <div className="align-items-center row">
                                            <div className="col-8">
                                                <h3 className="mb-0 text-light">Details</h3>
                                            </div>
                                            <div className="text-right col-4">

                                                {!this.state.user.isAdmin && (
                                                    <Button color="info" size='sm' className='mr-1 mb-1' onClick={this.assignAdmin}>Assign Admin</Button>

                                                )}
                                                {!this.state.user.isActive && this.state.user.registeredUser && (
                                                    <Button color="light" size='sm' className='mr-1 mb-1' onClick={this.activateAccount}> Activate Account</Button>

                                                )}
                                                {!this.state.user.isAdmin && !this.state.user.sendOffers && (
                                                    <Button color="warning" size='sm' className='mr-1 mb-1' onClick={this.addToMailingList}> Add to mailing list</Button>

                                                )}

                                                {!this.state.user.isAdmin && this.state.user.sendOffers && (
                                                    <Button color="warning" size='sm' className='mr-1 mb-1' onClick={this.removeFromMailingList}> Remove from mailing list</Button>

                                                )}

                                                {this.state.user.isAdmin && (
                                                    <Button color="info" size='sm' className='mr-1 mb-1' onClick={this.unassignAdmin}>Unassign Admin</Button>

                                                )}
                                                <Button color='danger' size='sm' className='mr-1 mb-1 ' onClick={this.deleteUser}>Delete User</Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <Form>
                                            <Row>
                                                <Col xs='8'>
                                                    <h6 className="heading-small  mb-4 font-weight-bold text-muted" style={{ fontSize: '0.75rem' }}>USER INFORMATION</h6>
                                                </Col>
                                                <Col xs='4'>
                                                    <div className="text-right ">
                                                        <i className="far fa-edit text-muted " onClick={this.updateUserInfoButton} style={{ fontSize: '1.2rem', cursor: 'pointer' }}></i>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg='6' md='12'>
                                                    <FormGroup>
                                                        <Label for="firstName">First Name</Label>
                                                        <Input onChange={this.userInfoOnChange} disabled={this.state.formDisabled !== 'userInfo'} type="text" name="firstName" id="firstName" value={this.state.firstName} placeholder="First Name" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg='6' md='12'>
                                                    <FormGroup>
                                                        <Label for="lastName">Last Name</Label>
                                                        <Input onChange={this.userInfoOnChange} disabled={this.state.formDisabled !== 'userInfo'} type="text" name="lastName" id="lastName" value={this.state.lastName} placeholder="Last Name" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg='6' md='12'>
                                                    <FormGroup>
                                                        <Label for="email">Email</Label>
                                                        <Input onChange={this.userInfoOnChange} disabled={this.state.formDisabled !== 'userInfo'} type="text" name="email" id="email" value={this.state.email} placeholder="Email" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg='6' md='12'>
                                                    <FormGroup>
                                                        <Label for="phoneNumber">Phone Number</Label>
                                                        <Input onChange={this.userInfoOnChange} disabled={this.state.formDisabled !== 'userInfo'} type="number" name="phoneNumber" id="phoneNumber" value={this.state.phoneNumber} placeholder="Phone Number" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            {this.state.showUpdateSuccess && this.state.updateAddressId === '' && (
                                                <p className='text-success mb-2'>User details successfully updated.</p>

                                            )}


                                            <   Collapse isOpen={this.state.formDisabled === 'userInfo' ? true : false}>
                                                <div>
                                                    <Button size='sm' color='primary' className='mx-1 my-1' onClick={this.updateUserInfo}>{this.props.users.updateButtonLoading ? 'Please Wait' : 'Update'} </Button>
                                                    <Button onClick={this.cancelUserInfoUpdate} size='sm' color='danger' className='mx-1 my-1'>Cancel </Button>
                                                </div>
                                            </Collapse>

                                            {this.state.user.adress.length > 0 && (
                                                <hr className='my-4' />

                                            )}

                                            {this.state.user.adress.map((eachAddress, index) => (
                                                <div key={eachAddress._id}>
                                                    {eachAddress.addressType === 'default' ? (
                                                        <React.Fragment >

                                                            <Row>
                                                                <Col xs='8'>
                                                                    <h6 className="heading-small  mb-4 font-weight-bold text-muted" style={{ fontSize: '0.75rem' }}>PRIMARY ADDRESS</h6>
                                                                </Col>
                                                                <Col xs='4'>
                                                                    <div className="text-right ">
                                                                        <i className="far fa-edit text-muted " onClick={() => this.updateAddressId(eachAddress._id)} style={{ fontSize: '1.2rem', cursor: 'pointer' }}></i>
                                                                    </div>
                                                                </Col>
                                                            </Row>


                                                            <Row>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <Label for="streetAdress">Street</Label>
                                                                        <Input onChange={(e) => this.onAddressChange(e, eachAddress._id)} disabled={this.state.updateAddressId !== eachAddress._id} type="text" name="streetAdress" id="streetAdress" value={this.state.addressReplica[index].streetAdress} placeholder="Street" />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg='4' md='12'>
                                                                    <FormGroup>
                                                                        <Label for="suburb">Suburb</Label>
                                                                        <Input onChange={(e) => this.onAddressChange(e, eachAddress._id)} disabled={this.state.updateAddressId !== eachAddress._id} type="text" name="suburb" id="suburb" value={this.state.addressReplica[index].suburb} placeholder="Suburb" />
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col lg='4' md='12'>
                                                                    <FormGroup>
                                                                        <Label for="state">State</Label>
                                                                        <Input disabled type="text" name="state" id="state" value='Victoria' placeholder="State" />
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col lg='4' md='12'>
                                                                    <FormGroup>
                                                                        <Label for="zipCode">Post Code</Label>
                                                                        <Input onChange={(e) => this.onAddressChange(e, eachAddress._id)} disabled={this.state.updateAddressId !== eachAddress._id} type="text" name="zipCode" id="zipCode" value={this.state.addressReplica[index].zipCode} placeholder="Post Code" />
                                                                    </FormGroup>
                                                                </Col>

                                                            </Row>


                                                            {this.state.showUpdateSuccess && this.state.updateAddressId === eachAddress._id && (
                                                                <p className='text-success mb-2'>User details successfully updated.</p>

                                                            )}

                                                            <   Collapse isOpen={this.state.updateAddressId === eachAddress._id ? true : false}>
                                                                <div>
                                                                    <Button size='sm' color='primary' className='mx-1 my-1' onClick={this.updateAddress}>{this.props.users.updateButtonLoading ? 'Please Wait' : 'Update'} </Button>
                                                                    <Button onClick={this.cancelAddressUpdate} size='sm' color='danger' className='mx-1 my-1'>Cancel </Button>
                                                                </div>
                                                            </Collapse>

                                                        </React.Fragment>
                                                    ) : (
                                                            <React.Fragment >
                                                                <hr className='my-4' />

                                                                <Row>
                                                                    <Col xs='8'>
                                                                        <h6 className="heading-small  mb-4 font-weight-bold text-muted" style={{ fontSize: '0.75rem' }}>{index}. SECONDARY ADDRESS</h6>
                                                                    </Col>
                                                                    <Col xs='4'>
                                                                        <div className="text-right ">
                                                                            <i className="far fa-edit text-muted " onClick={() => this.updateAddressId(eachAddress._id)} style={{ fontSize: '1.2rem', cursor: 'pointer' }}></i>
                                                                        </div>
                                                                    </Col>
                                                                </Row>


                                                                <Row>
                                                                    <Col>
                                                                        <FormGroup>
                                                                            <Label for="streetAdress">Street</Label>
                                                                            <Input onChange={(e) => this.onAddressChange(e, eachAddress._id)} disabled={this.state.updateAddressId !== eachAddress._id} type="text" name="streetAdress" id="streetAdress" value={this.state.addressReplica[index].streetAdress} placeholder="Street" />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg='4' md='12'>
                                                                        <FormGroup>
                                                                            <Label for="suburb">Suburb</Label>
                                                                            <Input onChange={(e) => this.onAddressChange(e, eachAddress._id)} disabled={this.state.updateAddressId !== eachAddress._id} type="text" name="suburb" id="suburb" value={this.state.addressReplica[index].suburb} placeholder="Suburb" />
                                                                        </FormGroup>
                                                                    </Col>

                                                                    <Col lg='4' md='12'>
                                                                        <FormGroup>
                                                                            <Label for="state">State</Label>
                                                                            <Input disabled={this.state.updateAddressId !== eachAddress._id} type="text" name="state" id="state" value='Victoria' placeholder="State" />
                                                                        </FormGroup>
                                                                    </Col>

                                                                    <Col lg='4' md='12'>
                                                                        <FormGroup>
                                                                            <Label for="zipCode">Post Code</Label>
                                                                            <Input onChange={(e) => this.onAddressChange(e, eachAddress._id)} disabled={this.state.updateAddressId !== eachAddress._id} type="text" name="zipCode" id="zipCode" value={this.state.addressReplica[index].zipCode} placeholder="Post Code" />
                                                                        </FormGroup>
                                                                    </Col>

                                                                </Row>

                                                                {this.state.showUpdateSuccess && this.state.updateAddressId === eachAddress._id && (
                                                                    <p className='text-success mb-2'>User details successfully updated.</p>

                                                                )}

                                                                <   Collapse isOpen={this.state.updateAddressId === eachAddress._id ? true : false}>
                                                                    <div>
                                                                        <Button size='sm' color='primary' className='mx-1 my-1' onClick={this.updateAddress}>{this.props.users.updateButtonLoading ? 'Please Wait' : 'Update'} </Button>
                                                                        <Button onClick={this.cancelAddressUpdate} size='sm' color='danger' className='mx-1 my-1'>Cancel </Button>
                                                                    </div>
                                                                </Collapse>

                                                            </React.Fragment>

                                                        )}
                                                </div>
                                            ))}

                                        </Form>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className='bg-white  border-0  shadow card'>
                                    <div className="bg-secondary card-header">
                                        <div className="align-items-center row">
                                            <div className="col-8">
                                                <h3 className="mb-0 text-light">Orders</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        {this.state.user.orders.length !== 0 ? (
                                            <div>
                                                <div className='table-responsive'>
                                                    <table className="table">
                                                        <thead className='table-borderless'>
                                                            <tr>
                                                                <th scope="col">ORDER ID</th>
                                                                <th scope="col">TIME</th>
                                                                <th scope="col">TOTAL</th>
                                                                <th scope='col'>SHIPPING</th>
                                                                <th scope='col'>PAYMENT TYPE</th>
                                                                <th scope='col'>ORDER STATUS</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody >
                                                            {this.state.user.orders.map(order => (
                                                                <React.Fragment key={order._id}>
                                                                    <tr onClick={(e) => this.routeToOrder(order)} className='admin-each-product' style={{ cursor: 'pointer' }}>
                                                                        <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{order.orderid.toUpperCase()}</td>
                                                                        <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{moment(order.created_at).startOf('hour').fromNow()}</td>
                                                                        <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>AUD {(order.total / 100).toFixed(2)}</td>
                                                                        <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{this.ucFirstAllWords(order.shippingType)}</td>
                                                                        <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'> {this.ucFirstAllWords(order.paymentType)}</td>
                                                                        <td style={{ verticalAlign: 'middle' }} className={order.orderStatus === 'Processing' ? 'text-warning font-weight-bold' : (order.orderStatus === 'Confirmed' ?
                                                                            'text-success font-weight-bold' : 'text-danger font-weight-bold')} > {order.orderStatus}</td>

                                                                    </tr>

                                                                </React.Fragment>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        ) : (
                                                <div>
                                                    <p>{this.ucFirstAllWords(this.state.user.firstName)} hasn't placed any orders yet.</p>
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </div>
                ) : (
                        <div className='text-center text-light'>
                            <h4>Please wait. User is being fetched.</h4>
                        </div>
                    )}

            </div>
        )
    }
}

const mapStateToProps = state => ({
    users: state.users
})


export default connect(mapStateToProps, { getSingleUser, deleteSingleUser, resetUserForm, updateSingleUser })(SelectedUser)

