import React, { Component } from 'react'
import {
    Row, Col, Form, Input, Dropdown, DropdownItem,
    DropdownToggle, DropdownMenu, Button, Badge
} from 'reactstrap'
import { connect } from 'react-redux'
import { getAdminProducts } from '../../actions/adminProducts'
import queryString from 'query-string'

class ProductsDashboard extends Component {


    state = {
        searchQuery: this.props.location.search,
        filter: '',
        priceDropdown: false,
        stockLevelDropdown: false,
        dateDropdown: false,
        popularityDropdown: false,
        queryInput: ''
    }
    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search);
        let page = 1;
        let filter;
        let query;

        if (parsed.page) {
            page = parsed.page
        }
        if (parsed.filter) {
            filter = parsed.filter
            this.setState({
                filter
            })
        }

        if (parsed.query) {
            query = parsed.query
            this.setState({
                queryInput: ''
            })
        }


        this.props.getAdminProducts(page, filter, query)
    }

    scrollToMyRef = () => window.scrollTo(0, 0)

    componentDidUpdate(prevProps, prevState) {
        const parsed = queryString.parse(this.props.location.search);

        if (this.state.searchQuery !== prevState.searchQuery) {
            this.props.history.push(`/adminpanel/products${this.state.searchQuery}`)
        }
        if (this.props.location.search !== prevProps.location.search) {
            let page;
            let filter;
            let query;


            if (parsed.page) {
                page = parsed.page
            }
            if (parsed.filter) {
                filter = parsed.filter
            }
            if (parsed.query) {
                query = parsed.query
            }

            this.scrollToMyRef()
            this.props.getAdminProducts(page, filter, query)
        }
    }

    previousPage = (e) => {
        const parsed = queryString.parse(this.props.location.search);
        parsed.page = parseInt(parsed.page) - 1

        let stringified = queryString.stringify(parsed);

        this.setState({
            searchQuery: '?' + stringified,
        })

    }

    nextPage = e => {
        const parsed = queryString.parse(this.props.location.search);
        if (parsed.page) {
            parsed.page = parseInt(parsed.page) + 1

        } else {
            parsed.page = 2
        }

        let stringified = queryString.stringify(parsed);

        this.setState({
            searchQuery: '?' + stringified,
        })
    }

    handlePageChange = (i) => {
        const parsed = queryString.parse(this.props.location.search);
        parsed.page = i + 1

        let stringified = queryString.stringify(parsed);

        this.setState({
            searchQuery: '?' + stringified,
        })
    }
    priceDropdownToggle = e => {
        this.setState({
            priceDropdown: !this.state.priceDropdown
        })
    }
    stockLevelDropdownToggle = e => {
        this.setState({
            stockLevelDropdown: !this.state.stockLevelDropdown
        })
    }
    dateDropdownToggle = e => {
        this.setState({
            dateDropdown: !this.state.dateDropdown
        })
    }
    popularityDropdownToggle = e => {
        this.setState({
            popularityDropdown: !this.state.popularityDropdown
        })
    }
    setFilter = (value) => {
        const parsed = queryString.parse(this.props.location.search)
        if (parsed.page) {
            parsed.page = 1
        }
        parsed.filter = value

        let stringified = queryString.stringify(parsed)

        this.setState({
            filter: value,
            searchQuery: '?' + stringified
        })
    }

    handleInputChange = e => {
        this.setState({
            queryInput: e.target.value
        })
    }
    handleSubmit = e => {
        e.preventDefault()
        const parsed = queryString.parse(this.props.location.search)
        parsed.query = this.state.queryInput

        if (parsed.page) {
            parsed.page = undefined
        }
        let stringified = queryString.stringify(parsed)


        this.setState({
            queryInput: '',
            searchQuery: '?' + stringified
        })
    }
    handleReset = e => {
        this.setState({
            searchQuery: ''
        })
    }

    handleNewProductClick = e => {
        this.props.history.push('/adminpanel/products/newProduct')
    }

    handleProductClick = product => {
        this.props.history.push({
            pathname: `/adminpanel/products/${product.name.replace(/[^A-Z0-9]+/ig, "-")}/${product._id}`,
            state: { product }
        })
    }

    render() {
        const parsed = queryString.parse(this.props.location.search)
        let noResultWarning;
        if (parsed.query && this.props.adminProducts.productCount === 0) {
            noResultWarning = true
        }

        let end;
        let totalItemNumbers = this.props.adminProducts.productCount
        let currentPage;
        if (parsed.page) {
            if (parsed.page > [...Array(Math.ceil(this.props.adminProducts.productCount / 12))].length) {
                currentPage = [...Array(Math.ceil(this.props.adminProducts.productCount / 12))].length
            }
            else if (parsed.page <= 1) {
                currentPage = 1

            }
            else {
                currentPage = parsed.page
            }
        } else {
            currentPage = 1
        }


        if (12 < totalItemNumbers) {
            end = 12 * currentPage
            if (end > totalItemNumbers) {
                end = totalItemNumbers
            }
        } else {
            end = totalItemNumbers
        }


        return (
            <div>


                {this.props.adminProducts.loading ?
                    <div className='text-center text-light my-5'>
                        <h5>Please wait. Products are being fetched.</h5>
                    </div>
                    : (
                        <React.Fragment>
                            <div >
                                <Row className='my-3'>
                                    <Col>
                                        <Form onSubmit={this.handleSubmit}>
                                            <Input placeholder='Search Products' value={this.state.queryInput} onChange={this.handleInputChange} />
                                        </Form>
                                        {noResultWarning &&
                                            <Button className='my-3' color='light' onClick={this.handleReset}>Reset</Button>}
                                    </Col>
                                </Row>
                            </div>
                            <div className='my-2'>
                                {parsed.query && (
                                                    <h5 className='d-inline pr-3' style={{ cursor: 'pointer' }} onClick={this.handleReset}> <Badge color="danger">
                                                        {parsed.query}
                                                        <span className='ml-2' style={{ fontSize: '1.2rem' }}>
                                                            &times;
                                                     </span></Badge></h5>
                                                )}
                                    </div>
                            {noResultWarning ? (
                                <div className='text-center text-light my-5'>
                                    <h5>No results found.</h5>
                                </div>
                            ) : (
                                    <React.Fragment>
                                        <div>

                                            <h4 className='text-light'>
                                                Filter by
                                            </h4>

                                            <div className='my-3'>
                                                <Dropdown className='btn-sm  ml-auto d-inline border-0' isOpen={this.state.priceDropdown} toggle={this.priceDropdownToggle}>
                                                    <DropdownToggle caret className=' bg-light text-dark'>
                                                        {((this.state.filter === 'pricelow' && 'Price (low to high)') || (this.state.filter === 'pricehigh' && 'Price (high to low)')) || 'Price'}
                                                    </DropdownToggle>
                                                    <DropdownMenu >
                                                        <DropdownItem onClick={() => this.setFilter('pricelow')}>price(low to high)</DropdownItem>
                                                        <DropdownItem onClick={() => this.setFilter('pricehigh')}>price(high to low)</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                                <Dropdown className='btn-sm  ml-auto d-inline border-0' isOpen={this.state.stockLevelDropdown} toggle={this.stockLevelDropdownToggle}>
                                                    <DropdownToggle caret className=' bg-light text-dark'>
                                                        {((this.state.filter === 'inStock' && 'In Stock') || (this.state.filter === 'OutofStock' && 'Out of Stock')) || 'Stock Level '}
                                                    </DropdownToggle>
                                                    <DropdownMenu >
                                                        <DropdownItem onClick={() => this.setFilter('inStock')}>In Stock</DropdownItem>
                                                        <DropdownItem onClick={() => this.setFilter('OutofStock')}>Out of Stock</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                                <Dropdown className='btn-sm  ml-auto d-inline border-0' isOpen={this.state.dateDropdown} toggle={this.dateDropdownToggle}>
                                                    <DropdownToggle caret className=' bg-light text-dark'>

                                                        {((this.state.filter === 'newest' && 'Newest') || (this.state.filter === 'oldest' && 'Oldest')) || 'Date'}

                                                    </DropdownToggle>
                                                    <DropdownMenu >
                                                        <DropdownItem onClick={() => this.setFilter('newest')}>Newest</DropdownItem>
                                                        <DropdownItem onClick={() => this.setFilter('oldest')}>Oldest</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                                <Dropdown className='btn-sm  ml-auto d-inline border-0' isOpen={this.state.popularityDropdown} toggle={this.popularityDropdownToggle}>
                                                    <DropdownToggle caret className=' bg-light text-dark'>

                                                        {((this.state.filter === 'popularDesc' && 'Popularity Descending') || (this.state.filter === 'popularAsc' && 'Popularity Ascending')) || 'Popularity'}

                                                    </DropdownToggle>
                                                    <DropdownMenu >
                                                        <DropdownItem onClick={() => this.setFilter('popularDesc')}>Popularity Descending</DropdownItem>
                                                        <DropdownItem onClick={() => this.setFilter('popularAsc')}>Popularity Ascending</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                                <Button color='light' className='ml-2' onClick={this.handleReset}>Reset</Button>
                                                <Button color='success' className='ml-2' onClick={this.handleNewProductClick}>New Product</Button>

                                                {(this.props.adminProducts.products.length !== 0) && (
                                                    <h1 className='float-right text-light' style={{ fontSize: '0.9rem' }}>
                                                        Showing {queryString.parse(this.props.location.search).page ?
                                                            (queryString.parse(this.props.location.search).page <= 1 ? 1 :
                                                                (queryString.parse(this.props.location.search).page > [...Array(Math.ceil(this.props.adminProducts.productCount / 12))].length ?
                                                                    (([...Array(Math.ceil(this.props.adminProducts.productCount / 12))].length - 1) * 13 - 1)
                                                                    : ((parseInt(queryString.parse(this.props.location.search).page) - 1) * (12) + 1)))
                                                            : 1} – {end} of {this.props.adminProducts.productCount} results</h1>
                                                )}
                                            </div>

                                        </div>


                                        <div className='bg-light' style={{ borderRadius: 10 }}>
                                            <Row>
                                                <Col className='px-3'>
                                                    <div className='table-responsive'>
                                                        <table className="table">
                                                            <thead className='table-borderless'>
                                                                <tr>
                                                                    <th scope="col">PRODUCT</th>
                                                                    <th scope="col">NAME</th>
                                                                    <th scope="col">PRODUCT CODE</th>
                                                                    <th scope="col">STOCK LEVEL</th>
                                                                    <th scope='col'>PRICE</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody >
                                                                {this.props.adminProducts.products.map(item => (
                                                                    <tr onClick={() => this.handleProductClick(item)} key={item._id} className={item.inStock ? 'admin-each-product' : 'admin-each-product bg-warning'} style={{ cursor: 'pointer' }}>
                                                                        <td style={{ width: '100px' }}>
                                                                            <img src={item.image} alt={item.name} className='img-fluid' />
                                                                        </td>
                                                                        <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>  {item.name}</td>
                                                                        <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{item.productCode}</td>
                                                                        <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{item.inStock ? 'In Stock' : 'Out of Stock'}</td>
                                                                        <td style={{ verticalAlign: 'middle', width: '100px' }} className='font-weight-bold text-muted'>AUD {item.price.toFixed(2)}</td>
                                                                    </tr>
                                                                ))}

                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </Col>
                                            </Row>

                                            {[...Array(Math.ceil(this.props.adminProducts.productCount / 12))].length > 1 &&
                                                (this.props.adminProducts.products.length !== 0) && (
                                                    <Row className='text-center mt-5'>
                                                        <Col className='text-center '>

                                                            <nav aria-label="Page navigation example">
                                                                <ul className="pagination justify-content-center">
                                                                    <li className={(!queryString.parse(this.props.location.search).page ||
                                                                        queryString.parse(this.props.location.search).page <= 1) ?
                                                                        'disabled page-item ' : `page-item`}>
                                                                        <span className="page-link" style={{ color: 'rgb(235,24,79)', cursor: 'pointer' }}
                                                                            onClick={this.previousPage}>Previous</span>
                                                                    </li>
                                                                    {[...Array(Math.ceil(this.props.adminProducts.productCount / 12))].map((page, i) => (
                                                                        <li key={i} className={(!queryString.parse(this.props.location.search).page && (i + 1 === 1)) ? 'active page-item'
                                                                            : (queryString.parse(this.props.location.search).page <= 1 && (i + 1 === 1) ? 'active page-item' :
                                                                                (queryString.parse(this.props.location.search).page >= 2 && (i + 1 === parseInt(queryString.parse(this.props.location.search).page)) ? 'active page-item'
                                                                                    : ((queryString.parse(this.props.location.search).page > [...Array(Math.ceil(this.props.adminProducts.productCount / 12))].length &&
                                                                                        (i + 1 === [...Array(Math.ceil(this.props.adminProducts.productCount / 12))].length)) ? 'active page-item' : 'page-item')))}>
                                                                            <span className="page-link" style={{ color: 'rgb(235,24,79)', cursor: 'pointer' }}
                                                                                onClick={() => this.handlePageChange(i)} >  {i + 1}</span></li>
                                                                    ))}

                                                                    <li className={(queryString.parse(this.props.location.search).page >= [...Array(Math.ceil(this.props.adminProducts.productCount / 12))].length ?
                                                                        'disabled page-item' : "page-item")}>
                                                                        <span style={{ color: 'rgb(235,24,79)', cursor: 'pointer' }} className="page-link"
                                                                            onClick={this.nextPage}>Next</span>
                                                                    </li>
                                                                </ul>
                                                            </nav>
                                                        </Col>

                                                    </Row>
                                                )}

                                        </div>
                                    </React.Fragment>
                                )}
                        </React.Fragment>
                    )}
            </div >
        )
    }
}

const mapStateToProps = state => ({
    adminProducts: state.adminProducts
})

export default connect(mapStateToProps, { getAdminProducts })(ProductsDashboard)
