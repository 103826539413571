import React, { Component } from 'react'
import {
    Col,
    Row,
    Collapse,
    Container

} from 'reactstrap';
import { connect } from 'react-redux'
import { getFaqs } from '../actions/faqAction'
import Base from './Base';
import { Helmet } from "react-helmet";
import Spinner from './Spinner'


class Faq extends Component {

    state={
        selected:''
    }

    componentDidMount() {
        this.props.getFaqs()
    }

    updateFaqs = (id) => {
        if(this.state.selected !== id){
            this.setState({
                selected:id
            })
        }else{
            this.setState({
                selected:''
            })
        }
     

    }


    render() {
        return (
            <React.Fragment>
                <div>
                    <Helmet>
                        <title>FAQ's - Frozberries Victoria</title>
                        <meta name="description" content="Frequently Asked Questions - Frozberries Victoria" />
                    </Helmet>
                </div>
                <Base title='  Frequently Asked Questions' />
                <div className='py-5'>
                    <Container>
                        <Row className=''>
                            <Col>
                                {this.props.faqsData.faqs.length !==0 ? (this.props.faqsData.faqs.map(faq => (
                                    <div key={faq._id}>
                                        <h4 style={{ color: 'rgb(14,35,87)', cursor: 'pointer' }} className='font-weight-bold py-2'
                                            onClick={(e) => this.updateFaqs(faq._id)}>
                                            {this.state.selected === faq._id ? (<i style={{ fontSize: '15px', color: 'rgb(14,35,87)' }}
                                                className="fas fa-minus pr-2" />) : (
                                                    <i style={{ fontSize: '15px', color: 'rgb(14,35,87)' }}
                                                        className="fas fa-plus pr-2" />
                                                )}

                                            {faq.question}</h4>
                                        <Collapse isOpen={this.state.selected === faq._id}>
                                            <h5 style={{ color: 'rgb(14,35,87)' }}>
                                                {faq.answer}

                                            </h5>
                                        </Collapse>

                                        <hr />

                                    </div>
                                ))):(
                                    <div className=' text-center text-light'>
                                    <Spinner />
                                </div>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    faqsData: state.faqsData
})

export default connect(mapStateToProps, { getFaqs })(Faq)